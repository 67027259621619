export enum ErrorsList{
  'Creation' = 'Daten konnten nicht gespeichert werden. Bitte Formular erneut senden.',
  'Completion' = 'Daten konnten nicht gespeichert werden. Bitte Formular erneut senden.',
  'FilesUploading' = 'Dateien konnten nicht gespeichert werden. Bitte Formular erneut senden.'
};

export enum LoadingMessagesList{
  'Creation' = 'Wir speichern Ihre Daten ab. Bitte schließen Sie das Fenster nicht',
  'Completion' = 'Abschluss der Anfrage-Speicherung. Bitte schließen Sie das Fenster nicht',
  'FilesUploading' = 'Wir speichern Ihre Dateien ab. Bitte schließen Sie das Fenster nicht'
};

export const SuccessLabel = 'Daten erfolgreich gespeichert';
