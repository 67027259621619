import { useEffect, useMemo } from 'react';
import { AnyObject } from 'common/interfaces';
import { useQuery, useQueryClient } from 'react-query';
import { PAGES, ReactQueryKeys } from 'common/enums';
import useOverlay from 'hooks/use-overlay';
import { SessionFilter } from 'hooks/use-session-filter';
import { getRecords, getTasksByContactFilterData, modifyTableData } from 'components/aufgaben-component/utils';
import { isMobileWidth } from '../../../constants';
import {
  defaultTableData,
  emptyFilterStateServer,
  defaultFilterData
} from 'components/aufgaben-component/aufgaben-component-view';
import { isEmpty } from 'lodash';

export const useTasksQuery = (
  tableParams: TableParams,
  sessionFilter: SessionFilter,
  sessionSorting: AnyObject,
  caseId: AnyObject | null | string
) => {
  const [showOverlay, hideOverlay] = useOverlay();
  const queryClient = useQueryClient();

  const {
    data: table,
    refetch: refetch,
    isFetching: tableIsFetching,
    isFetched: tableIsFetched
  } = useQuery(
    [PAGES.TASKS, tableParams, sessionFilter?.filterForServer, sessionSorting, caseId],
    () =>
      getRecords({
        ...tableParams,
        sort: sessionSorting,
        search: isMobileWidth || !!caseId ? emptyFilterStateServer : sessionFilter?.filterForServer,
        SelectedTaskId: caseId,
        ShortResponce: true
      }),
    {
      enabled: !isEmpty(sessionFilter?.filterForServer) && !!sessionSorting
    }
  );

  const {
    data: filter,
    refetch: filterRefetch,
    isFetching: filterIsFetching
  } = useQuery([ReactQueryKeys.Filter, PAGES.TASKS], () => getTasksByContactFilterData());

  const modifyTable = useMemo(() => modifyTableData(table?.data?.data), [table?.data?.data]);
  const isFetching = tableIsFetching || filterIsFetching;

  useEffect(() => {
    if (isFetching) {
      showOverlay();
    } else {
      hideOverlay();
    }

    return hideOverlay;
  }, [isFetching]);

  return {
    table: modifyTable || defaultTableData,
    tableRefetch: () => {
      queryClient.resetQueries([ReactQueryKeys.UnfoldedTask]);
      refetch();
    },
    filter: filter?.data?.data || defaultFilterData,
    filterRefetch,
    tableIsFetched
  };
};

interface TableParams {
  pagination: { start: number; number: number };
}
