import { createContext } from 'react';
import { ChecklistInfo, ChecklistParts } from 'request-config/checklist-modal/checklist-modal.types';
import { DamageFormSection } from './components/condition-step/use-condition-step.types';

interface ChecklistModalContextProps {
  checklistInfo: ChecklistInfo;
  damagedSections: DamageFormSection[][];
  setDamagedSections: React.Dispatch<React.SetStateAction<DamageFormSection[][]>>;
  handlePrevStep: () => void;
}

export const ChecklistModalContext = createContext(<ChecklistModalContextProps>({} as ChecklistModalContextProps));
