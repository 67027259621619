import React, { useContext } from 'react';
import { formFieldProvider } from '../../form-field';
import { IFormFieldContext } from '../../form.props';
import { StyledTextArea } from './text-area.styled';
import { FormLabel } from '../common/label/label';
import { PrefixForId } from 'common/enums';

export const Textarea = () => {
  const {
    field: { prefix, disabled, textAreaProps, name, placeholder },
    value,
    error,
    onChange,
    forceUpdate
  } = useContext<IFormFieldContext<string>>(formFieldProvider);

  return (
    <div>
      <FormLabel />
      <StyledTextArea
        id={name + PrefixForId.FormTextArea}
        error={!!error}
        isPrefix={!!prefix}
        placeholder={placeholder || 'Bitte eintragen'}
        disabled={!!disabled}
        onChange={event => {
          onChange(event.target.value);
          forceUpdate();
        }}
        value={value}
        {...textAreaProps}
        ref={(e: HTMLTextAreaElement) => {
          if (error) {
            e?.scrollIntoView({ behavior: 'smooth', block: 'center' });
          }
        }}
      />
    </div>
  );
};
