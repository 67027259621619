import React from 'react';
import { RequestedVehiclesProps } from './requested-vehicles.types';
import { Flex, Text } from 'common/common-components.styled';
import { useTheme } from 'styled-components';
import { getDateString } from 'utils/convert-UTC-date-to-local-date';

const RequestedVehicles = ({ requestedVehicles }: RequestedVehiclesProps) => {
  const theme = useTheme();

  return (
    <div>
      <Text bottom='30' color='grey900'>
        Achtung, es wurden bereits Fahrzeuge angefordert:
      </Text>
      {requestedVehicles.map((requestedVehicle, index) => (
        <Flex
          key={requestedVehicle.faNumber}
          gap='15%'
          padding='10px'
          backgroundColor={index % 2 ? theme.colors.white : theme.colors.grey50}
        >
          <div>
            <Text bold uppercase size='10' bottom='5'>
              FA-Nummer
            </Text>
            <Text>{requestedVehicle.faNumber}</Text>
          </div>
          <div>
            <Text bold uppercase size='10' bottom='5'>
              Angefordern am:
            </Text>
            <Text>{getDateString(requestedVehicle.eventCreatedOn)}</Text>
          </div>
          <div>
            <Text bold uppercase size='10' bottom='5'>
              Fahrer:
            </Text>
            <Text>{requestedVehicle.driver}</Text>
          </div>
        </Flex>
      ))}
    </div>
  );
};

export default RequestedVehicles;
