import React, { cloneElement, MouseEvent, useEffect, useCallback, useState, useContext } from 'react';
import classes from './row-expandable.module.scss';
import rowClasses from '../cell/cell.module.scss';
import classNames from 'classnames';
import useExpand from 'hooks/use-expand';
import { Cell } from '../cell/cell';
import { IRowContext, RowExpandableProps } from '../table-component.props';
import { useScroll } from '../../../hooks/use-scroll/use-scroll';
import { IRowExpandable } from './row-expandable.props';
import { useUnsavedChangesModal } from 'hooks/modals/unsaved-changes.modal/unsaved-changes.modal';
import { CellContext, RowContext } from 'components/table-component/table-component-contexts';
import { PrefixForId } from 'common/enums';
import { removeSpaces } from 'utils/remove-spaces';
import { openInNewTabHandler } from 'utils/open-page-in-new-tab';

export const RowExpandable = ({ columns, row, rootTag, toggleAllAccordions, openedItemId }: RowExpandableProps) => {
  const { rowId } = useContext<IRowContext>(RowContext);
  const [isShowRow, setIsShowRow] = useState(true);

  const { height, content, toggleAccordion, expand } = useExpand(
    row[rootTag.keyName || rootTag.propName] === openedItemId
  );
  const { ref: containerRef, handlerAsync } = useScroll();
  const { openUnsavedChangesModal } = useUnsavedChangesModal();

  const expandRowHeaderClassName = classNames(rowClasses.table_row, classes.expand_row_header, {
    [classes.expanded]: expand
  });

  const scrollHandler = useCallback(
    (isExpand: boolean) => {
      handlerAsync((scroll, { top, bottom }) => {
        if (isExpand) {
          if (top < 60) {
            return scroll();
          }

          if (window.innerHeight < bottom) {
            scroll();
          }
        }
      });
    },
    [handlerAsync]
  );

  const toggleHandler = (event: MouseEvent<HTMLDivElement>, isDisable: boolean = false) => {
    if (
      (event.target as HTMLElement).tagName === 'INPUT' ||
      event.currentTarget.classList.contains('react-datepicker-popper') ||
      isDisable
    ) {
      return;
    }
    if(event.ctrlKey){
      const link = `${window.location.origin}${rootTag.to}`;
      return openInNewTabHandler(link, 500);
    }
    openUnsavedChangesModal(() => {
      toggleAccordion();
      toggleAllAccordions(row[rootTag.propName]);
    });
  };

  useEffect(() => {
    scrollHandler(expand);
  }, [expand, scrollHandler]);

  return (
    <div
      id={rowId}
      className={classNames(classes.expand_row, 'table', {
        ['table_row_container_disable']: row.isDisableRow && !expand
      })}
      ref={containerRef as any}
    >
      {isShowRow ? (
        <div onClick={event => toggleHandler(event, row.isDisableRow && !expand)} className={expandRowHeaderClassName}>
          {columns.map((column, columnIndex) => (
            <CellContext.Provider
              value={{ cellId: rowId + PrefixForId.Cell + columnIndex + removeSpaces(column.name) }}
            >
              <Cell key={columnIndex} column={column} row={row} openedItemId={openedItemId} toggle={toggleHandler} />
            </CellContext.Provider>
          ))}
        </div>
      ) : null}
      <div ref={content} style={{ height: height }} className={classes.expand_row_content}>
        {rootTag.expandComponent &&
          cloneElement<IRowExpandable>(rootTag.expandComponent, {
            row,
            toggleExpand: toggleAllAccordions,
            isExpanded: expand,
            setIsShowRow
          })}
      </div>
    </div>
  );
};
