import React from 'react';
import { Flex, Text } from 'common/common-components.styled';
import { usePersonalDataStep } from './use-personal-data-step';
import { useFormContext } from 'react-hook-form';
import FormHook from 'components/form-hook/form-hook';
import InfoHint from 'components/info-hint';

const PersonalDataStep = () => {
  const reactHookFormData = useFormContext();
  const { nameField, carField, personalDataFields, streetField, cityField, postalCodeField } = usePersonalDataStep();

  return (
    <Flex width='100%' direction='column'>
      <div>
        <Text size='16' color='darkBlue'>
          Persönliche Daten
        </Text>
      </div>

      <Flex margin='24px 0'>
        <InfoHint>Prüfe anhand der Checkliste, ob dein Fahrzeug für die Rückgabe bereit ist. </InfoHint>
      </Flex>

      <Flex direction='column'>
        <Flex gap='10px' width='100%'>
          <FormHook reactHookFormData={reactHookFormData} formFields={nameField} distanceBetweenFields='24' />
          <FormHook reactHookFormData={reactHookFormData} formFields={carField} distanceBetweenFields='24' />
        </Flex>

        <Flex>
          <FormHook reactHookFormData={reactHookFormData} formFields={personalDataFields} distanceBetweenFields='24' />
        </Flex>

        <Flex direction='column'>
          <Flex bottom='24'>
            <Text size='16' color='darkBlue'>
              Fahrzeugstandort für Abholung / Begutachtung
            </Text>
          </Flex>

          <FormHook reactHookFormData={reactHookFormData} formFields={streetField} distanceBetweenFields='24' />
        </Flex>

        <Flex gap='10px' width='100%'>
          <FormHook reactHookFormData={reactHookFormData} formFields={postalCodeField} distanceBetweenFields='0' />
          <FormHook reactHookFormData={reactHookFormData} formFields={cityField} distanceBetweenFields='0' />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default PersonalDataStep;
