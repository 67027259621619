import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { userSelector } from 'selectors';
import { useHistory, useLocation } from 'react-router-dom';
import { useIsUserHaveRole } from 'hooks/use-is-user-have-role';
import { USER_ROLES } from '../../constants';
import { useTypedSelector } from 'hooks/use-typed-selector';
import { User } from 'common/interfaces';
import cloneDeep from 'lodash/cloneDeep';
import { setSideMenuOpen } from 'actions/app_action';
import { MainMenuTitles, SubMainMenuTitles } from 'components/side-menu/side-menu-enums';
import Backdrop from 'components/backdrop/index';
import { MobexoLogoIcon } from 'components/icons/mobexo-logo-icon';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { PAGES, PrefixForId, UNAUTHORIZED_PAGES } from 'common/enums';
import { MainMenuItem } from 'components/side-menu/side-menu-interfaces';
import MenuItem from 'components/side-menu/menu-item';
import { StyledDoubleRightArrow, StyledHeader, StyledSideMenu } from 'components/side-menu/side-menu.styled';
import { Divider, Flex, Text } from 'common/common-components.styled';
import { LogoMobexo } from 'components/icons/logo-mobexo';
import UserInfoContainer from 'components/side-menu/user-info-container';
import MainRoleSwitcher from 'components/main-role-switcher';
import fillMenuItemsWithRoles from './get-default-menu-items';
import { useMainMenuItem } from 'components/side-menu/menu-items';
import { useIsProductAvailable } from 'hooks/use-is-product-available/use-is-product-available';
import { ProductId } from 'hooks/react-query/product/get-user-product/get-user-product.props';
import { availabilityRollsForProduct } from 'pages/create-damage/utils';
import { useIsMinimized } from './hooks/use-is-minimized';
import { useUnsavedChangesModal } from 'hooks/modals/unsaved-changes.modal/unsaved-changes.modal';
import useSize from '@react-hook/size';
import { useReportDamageCustomLink } from 'hooks/custom-links/use-report-damage';
import useWindowSize from 'hooks/use-window-size';
import { cloneDeepWithReactElements } from 'utils/clone-deep-with-react-elements';

const SideMenu = () => {
  const dispatch = useDispatch();
  const sideMenuOpen: boolean = useTypedSelector(state => state['app'].sideMenuOpen);
  const isSiteAvailable: boolean = useTypedSelector(state => state.app.isSiteAvailable);
  const hidePriceAndPackagesMenuItem: boolean = useTypedSelector(state => state.app.hidePriceAndPackagesMenuItem);
  const user: User = useTypedSelector(userSelector);
  const mainRoleId = user.mainRole?.id;

  const { mainMenuItem } = useMainMenuItem();

  const [menuItems, setMenuItems] = useState(fillMenuItemsWithRoles(cloneDeepWithReactElements(mainMenuItem), user));

  const { isMinimized, setIsMinimized } = useIsMinimized();
  const { isAvailable: isShowCreateDamage } = useIsProductAvailable(
    ProductId.SelfManagedClaims,
    availabilityRollsForProduct[ProductId.SelfManagedClaims]
  );
  const perfectScrollbarContainerRef = useRef<HTMLDivElement | null>(null);
  useSize(perfectScrollbarContainerRef.current);

  const { reportDamageCustomLink } = useReportDamageCustomLink();

  const location = useLocation();
  const history = useHistory();

  const isDriver = useIsUserHaveRole(USER_ROLES.driver.id);
  const { windowWidth } = useWindowSize();
  const isDesktopWidth = windowWidth >= 1200;

  const { openUnsavedChangesModal } = useUnsavedChangesModal();

  const isMenuItemVisible = ({ roles, subMenuItems }: MainMenuItem) => {
    if (!subMenuItems.length && roles && isSiteAvailable) {
      return roles.some(role => role.id === mainRoleId);
    }

    if (!isSiteAvailable) {
      return isDriver ? false : subMenuItems.some(menuItem => menuItem.showWhenSiteUnavailable);
    }

    return subMenuItems.some(menuItem => menuItem.roles.some(role => role.id === mainRoleId));
  };

  useEffect(() => {
    const path =
      location.pathname[location.pathname.length - 1] === '/' ? location.pathname.slice(0, -1) : location.pathname;
    const newMenuItems = cloneDeepWithReactElements(menuItems);

    newMenuItems.forEach(async item => {
      item.expand = item.subMenuItems.some(menuItem =>
        menuItem.links.some(link => link === path && !(menuItem.hideIfParams && location.search))
      );
      if (!item.subMenuItems.length) {
        item.expand = item.links?.some(link => link === path) || false;
      }
    });

    const bestellungenItem = newMenuItems.find(menuItem =>
      [MainMenuTitles.Orders, MainMenuTitles.Order].includes(menuItem.menuTitle)
    );
    if (bestellungenItem) {
      bestellungenItem.menuTitle = isDriver ? MainMenuTitles.Order : MainMenuTitles.Orders;
    }

    newMenuItems.forEach(item => {
      if (item?.subMenuItems) {
        item.subMenuItems?.forEach(subMenuItem => {
          if (subMenuItem?.title === SubMainMenuTitles.ReportDamage) {
            subMenuItem.title = isShowCreateDamage ? SubMainMenuTitles.CreateDamage : SubMainMenuTitles.ReportDamage;
            subMenuItem.external = !isShowCreateDamage;
            subMenuItem.links = subMenuItem.links.map(link => {
              if (link?.includes(UNAUTHORIZED_PAGES.REPORT_DAMAGE)) {
                return isShowCreateDamage ? PAGES.CREATE_DAMAGE : reportDamageCustomLink;
              }
            });
          }
        });
      }
    });

    setMenuItems(newMenuItems);
  }, [location.pathname, hidePriceAndPackagesMenuItem, isMinimized, isShowCreateDamage, reportDamageCustomLink]);

  const toggleExpandItem = (menuTitle: MainMenuTitles) => {
    if (
      [
        MainMenuTitles.Dashboard,
        MainMenuTitles.Contact,
        MainMenuTitles.APIDoc,
        MainMenuTitles.Report,
        MainMenuTitles.GREENHOUSE_GAS_EMISSION_QUOTA,
        MainMenuTitles.WALL_E_BUSINESS_PARTNER_PROMOTION,
        MainMenuTitles.DownloadsAndFaq
      ].includes(menuTitle)
    ) {
      const clickedMenuItem = menuItems.find(item => item.menuTitle === menuTitle);
      clickedMenuItem!.expand = true;
      openUnsavedChangesModal(() => {
        history.push(clickedMenuItem!.links![0]);
        dispatch(setSideMenuOpen(false));
      });
    }

    const newMenuItems = cloneDeepWithReactElements(menuItems);
    const menuItem = newMenuItems.find(item => item.menuTitle === menuTitle);
    if (!menuItem) {
      return;
    }

    menuItem.expand = !menuItem.expand;

    setMenuItems(newMenuItems);
  };

  return (
    <>
      <Backdrop zIndex={100} show={sideMenuOpen} click={() => dispatch(setSideMenuOpen(false))} />
      <StyledSideMenu isMinimized={isMinimized} open={sideMenuOpen} isDesktop={isDesktopWidth}>
        <StyledHeader isMinimized={isMinimized}>{isMinimized ? <LogoMobexo /> : <MobexoLogoIcon />}</StyledHeader>

        <Flex minHeight='40px' align='center' margin='30px 17px 0'>
          <UserInfoContainer isMinimized={isMinimized} />
        </Flex>

        <Flex padding='5px 0 0 45px'>
          {user.roles.length > 0 && !isDesktopWidth && (
            <>
              {user.roles.length === 1 ? (
                <Text padding='10px 0 10px 15px' size='14' color='grey600'>
                  {user.mainRole.name}
                </Text>
              ) : (
                <MainRoleSwitcher textColor='grey600' />
              )}
            </>
          )}
        </Flex>

        <Divider color='grey50' margin='15px 15px 0' />
        <PerfectScrollbar id={PrefixForId.SideMenu}>
          <div ref={perfectScrollbarContainerRef}>
            {menuItems.filter(isMenuItemVisible).map(menuItem => (
              <MenuItem
                key={menuItem.menuTitle}
                item={menuItem}
                isMinimized={isMinimized}
                toggleExpandItem={toggleExpandItem}
              />
            ))}
          </div>
        </PerfectScrollbar>

        {isDesktopWidth && (
          <>
            <Divider color='grey50' margin='0 15px' />
            <Flex
              id={'expandViewSwitcher'}
              padding='15px'
              justify={isMinimized ? 'center' : 'flex-end'}
              pointer
              onClick={() => setIsMinimized(!isMinimized)}
            >
              <StyledDoubleRightArrow isMinimized={isMinimized} width={16} height={16} />
            </Flex>
          </>
        )}
      </StyledSideMenu>
    </>
  );
};

export default SideMenu;
