import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import styled from 'styled-components';

export const StyledDialogTitle = styled(DialogTitle)`
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 30px 30px 0 !important;
`;

export const StyledDialogContent = styled(DialogContent)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px !important;
  gap: 30px;
`;

export const StyledDialogActions = styled(DialogActions)<{ isOneButtonExist: boolean }>`
  display: flex;
  justify-content: ${({ isOneButtonExist }) => (isOneButtonExist ? 'flex-end' : 'space-between')} !important;
  padding: 30px !important;
`;

export const StepperContainer = styled.div`
  display: flex;
  justify-content: center;
  font-size: 14px;
  padding-bottom: 10px;
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.grey100}`};
`;
