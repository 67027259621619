import { AxiosRequestConfig } from 'axios';
import {
  CategoryNames,
  ConfiguratorLanguages,
  OptionResponse
} from 'pages/car-configurator/hooks/use-options-by-type/use-options-by-type.types';
import { CreatePersonalConfigData, GetBaseOptionsData, UpSertBaseOptionsData } from './car-configurator.types';

class CarConfiguratorRequestConfig {
  getBrandList = (driverId: string): AxiosRequestConfig => ({
    method: 'POST',
    url: 'carconfigurator/getBrandList',
    data: {
      driverId
    }
  });

  getModelList = (driverId: string, brandId: string): AxiosRequestConfig => ({
    method: 'POST',
    url: 'carconfigurator/getModelList',
    data: {
      driverId,
      brandId
    }
  });

  getVehicleList = (driverId: string, modelId: string): AxiosRequestConfig => ({
    method: 'POST',
    url: 'carconfigurator/getVehicleList',
    data: {
      driverId,
      modelId
    }
  });

  getVehicleDetails = (driverId: string, vehicleId: string): AxiosRequestConfig => ({
    method: 'POST',
    url: 'carconfigurator/getVehicleDetails',
    data: {
      driverId,
      vehicleId
    }
  });

  getOptionsByType = (
    fahrzeugangeboteId: string,
    categoryName: CategoryNames,
    driverId: string
  ): AxiosRequestConfig => ({
    method: 'POST',
    url: 'carconfigurator/getOptionsByType',
    data: {
      fahrzeugangeboteId,
      categoryName,
      driverId,
      languageId: ConfiguratorLanguages.German
    }
  });

  validateOption = (
    addedOptions: OptionResponse[],
    fahrzeugangeboteId: string,
    removedOption: OptionResponse | null
  ): AxiosRequestConfig => ({
    method: 'POST',
    url: 'carconfigurator/validateOption',
    data: {
      fahrzeugangeboteId,
      addedOptions,
      removedOption,
      languageId: ConfiguratorLanguages.German
    }
  });

  createPersonalConfig = (data: CreatePersonalConfigData): AxiosRequestConfig => ({
    method: 'POST',
    url: 'carconfigurator/createPersonalConfig',
    data
  });

  getBaseOptions = (data: GetBaseOptionsData): AxiosRequestConfig => ({
    method: 'POST',
    url: 'carconfigurator/getBaseOptions',
    data
  });

  upSertBaseOptions = (data: UpSertBaseOptionsData): AxiosRequestConfig => ({
    method: 'POST',
    url: 'carconfigurator/upSertBaseOptions',
    data
  });
}

export const carConfiguratorRequestConfig = new CarConfiguratorRequestConfig();
