import React from 'react';
import { AnyObject } from 'common/interfaces';
import { Flex, Button } from 'common/common-components.styled';
import { useForm } from 'react-hook-form';
import FormHook from 'components/form-hook/form-hook';
import { imgDependingOnTypeTask } from 'components/aufgaben-component/utils';
import { StyledCoverImage, StyledLoadingContainer } from './create-new-task-modal.styled';
import { PrefixForId } from '../../../../common/enums';
import Spinner from 'components/spinner';
import { useCreateNewTaskModal } from './use-create-new-task-modal';
import ExplenationMoal from './components/explenation-modal';

interface CreateNewTaskInterface {
  // eslint-disable-next-line no-unused-vars
  onClose: () => void;
  isDriversFlow?: boolean;
  selectedDrivers?: string[];
  updateSelectedDrivers?: (updatedListOfDrivers: string | null) => void;
  setIsSubmitted?: React.Dispatch<React.SetStateAction<boolean>>;
}

const CreateNewTaskModal = ({
  onClose,
  isDriversFlow,
  selectedDrivers,
  updateSelectedDrivers,
  setIsSubmitted
}: CreateNewTaskInterface) => {
  const reactHookFormData = useForm();
  const {
    createTaskFormFields,
    currentTaskCode,
    isLoading,
    isSubmitButtonDisabled,
    isEmplenationModalOpened,
    isExplenationModalNeeded,
    submit,
    openExplenationModal,
    closeExplenationModal
  } = useCreateNewTaskModal(
    reactHookFormData,
    Boolean(isDriversFlow),
    selectedDrivers,
    updateSelectedDrivers,
    setIsSubmitted
  );

  return (
    <Flex>
      {isLoading && (
        <StyledLoadingContainer>
          <Spinner
            style={{
              margin: '0 auto',
              width: '100px'
            }}
          />
        </StyledLoadingContainer>
      )}
      <Flex direction='column' width='100%'>
        <StyledCoverImage
          src={imgDependingOnTypeTask(currentTaskCode)}
          alt='create new task'
          id={PrefixForId.Modal + PrefixForId.Image}
        />

        <FormHook reactHookFormData={reactHookFormData} formFields={createTaskFormFields} />

        <Flex justify='flex-end'>
          <Button secondary right='10' id='newTaskModalCancelButton' onClick={onClose}>
            Abbrechen
          </Button>
          <Button
            disabled={isSubmitButtonDisabled}
            id='newTaskModalSaveButton'
            onClick={isExplenationModalNeeded ? openExplenationModal : submit}
          >
            Speichern
          </Button>
        </Flex>
      </Flex>

      <ExplenationMoal open={isEmplenationModalOpened} onClose={closeExplenationModal} submit={submit} />
    </Flex>
  );
};

export default CreateNewTaskModal;
