import { useQuery as useQueryLocation } from 'hooks';
import { PAGES, ReactQueryKeys } from 'common/enums';
import { ResponseModel } from 'common/interfaces';
import { useFetch } from 'hooks/use-fetch/use-fetch';
import { useQuery } from 'react-query';
import { GetDamageOptionsType, IFleetModel, IGetByIdData, IGetByIdResponse } from './get-damage-by-id.props';
import { AxiosResponse } from 'axios';
import { getDataForTable, getDataForTableItem } from 'utils/get-response-data';
import { damageRequestConfig } from 'request-config/damage/damage.request-config';
import { useRef } from 'react';
import { useLocation } from 'react-router-dom';

export const useGetDamageByIdQuery = (id?: string, options: GetDamageOptionsType = {}) => {
  const queryLocation = useQueryLocation();
  const damageId = (id || queryLocation.id) as string;
  const isDamageRecordingModelExistRef = useRef(false);

  const location = useLocation();

  const enableForPages = () => {
    const ACCESS_FOR_PAGES = [PAGES.DAMAGE, PAGES.DAMAGE_DETAILS];
    return ACCESS_FOR_PAGES.includes(location.pathname as PAGES);
  };

  const { fetch } = useFetch<ResponseModel<IGetByIdResponse>>({
    loadingKey: `getDamageById${damageId}`,
    isCancelRequestOnUnmount: true,
    ...damageRequestConfig.getById(damageId),
    ...options.fetch
  });

  const modifyTableData = ({ data }: AxiosResponse<ResponseModel<IGetByIdResponse>>): IGetByIdData => {
    let responseData = { ...data.data?.incidentModel };

    if (data.data.schadenAufnahmeModel) {
      responseData = { ...responseData, ...data?.data?.schadenAufnahmeModel[0] };
      isDamageRecordingModelExistRef.current = !!data?.data?.schadenAufnahmeModel.length;
    }

    return {
      ...getDataForTableItem(responseData),
      fleetModel: getDataForTableItem(data.data.fuhrparkModel || {}) as IFleetModel,
      damagePartsModel: getDataForTable(data.data.damagePartsModel || []),
      coodrinates: data.data.coodrinates,
      isDamageRecordingIsExist: !!data?.data?.schadenAufnahmeModel
    };
  };

  const { data, ...restQueryProps } = useQuery([ReactQueryKeys.DamageGetById, damageId], () => fetch(), {
    select: modifyTableData,
    enabled: enableForPages(),
    ...options.options
  });

  return {
    damageData: data || {},
    ...restQueryProps,
    isDamageRecordingModelExist: isDamageRecordingModelExistRef.current
  };
};
