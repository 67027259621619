import React, { memo, useEffect } from 'react';
import classes from './chatbot.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { userSelector } from '../../selectors';
import { getUserInitials } from '../../utils/get-initials';
import { RightSidebar } from 'components/right-sidebar/right-sidebar';
import { RightSidebarOpenButton } from 'components/right-sidebar/right-sidebar-open-button/right-sidebar-open-button';
import { toggleChatbot, toggleGreetingSideBar, toggleSidebarType } from 'actions/app_action';
import { useTypedSelector } from 'hooks/use-typed-selector';
import { SidebarType } from 'common/enums';

const ChatBot = memo(() => {
  const user = useSelector(userSelector);
  const dispatch = useDispatch();
  const isOpenChatBot = useTypedSelector(state => state['app']?.isOpenChatBot);
  const sidebarType = useTypedSelector(state => state['app']?.sidebarType);

  const openSidebar = () => {
    if (sidebarType === SidebarType.Greeting || !sidebarType) {
      dispatch(toggleGreetingSideBar(true));
      dispatch(toggleSidebarType(SidebarType.Greeting));
    } else {
      dispatch(toggleChatbot(true));
      dispatch(toggleSidebarType(SidebarType.Chat));
    }
  };

  useEffect(() => {
    const getUserAvatar = ({ avatar, name }) => {
      if (avatar) {
        return user.avatar;
      }

      if (!avatar && !getUserInitials(name)) {
        return '/assets/images/message_with_background.svg';
      }
    };

    if (!window.WebChat) {
      return;
    }

    const styleSet = window.WebChat.createStyleSet({
      rootHeight: '100%',
      rootWidth: '100%',
      bubbleBackground: '#F0F1F2',
      bubbleFromUserBackground: '#E6F7FF',
      userAvatarBackgroundColor: '#8FAFBF'
    });

    window.WebChat.renderWebChat(
      {
        directLine: window.WebChat.createDirectLine({
          token:
            process.env.REACT_APP_CURRENT_ENV === 'prod'
              ? '-Qd7A5YzIvI.sA91gdAamlDhbLK0q1Lwa6P1JUAXJp3XlaqhbXkG3xw'
              : '9aNY6Kc-IZ8.AqXkUfTyghuq-0ISzwiJn0GapjLj55SdbToDMML5PW4'
        }),
        userID: user.id ?? '',
        styleSet,
        styleOptions: {
          hideUploadButton: true,
          botAvatarImage: '/assets/images/chatbot_image.png',
          botAvatarInitials: 'BM',
          userAvatarImage: getUserAvatar(user),
          userAvatarInitials: (
            <span className={classes.chatbot_avatar_text}>{getUserInitials(user.name)?.toUpperCase() || 'Gast'}</span>
          )
        }
      },
      document.getElementById('mobexo_webchat')
    );
  }, [user]);

  return window.WebChat ? (
    <div className={classes.chatbot}>
      <RightSidebar
         isOpen={isOpenChatBot}
         id='mobexo_webchat_container'
         className={classes.container}
         isChatBot
      >
        <div id='mobexo_webchat' role='main' className={classes.chatbot_container} />
      </RightSidebar>

      <RightSidebarOpenButton id={'chatbot_button'} onClick={openSidebar}>
        <img src='/assets/images/bubble-chat.svg' alt='bubble chat' />
      </RightSidebarOpenButton>
    </div>
  ) : null;
});

export default ChatBot;
