export enum PAGES {
  DRIVERS_CHECK = '/fuhrerscheinkontrolle',
  DAMAGE = '/meine-schaden',
  DAMAGE_DETAILS = '/meine-schaden/schaden',
  ASSIGNMENTS = '/auftrage',
  ASSIGNMENTS_ACCIDENT_REPAIR = '/auftrage/unfallreparatur',
  ASSIGNMENTS_VEHICLE_TRANSFER = '/auftrage/fahrzeuguberfuhrung',
  ASSIGNMENTS_ORDER_RENTAL_CAR = '/auftrage/mietwagen-bestellen',
  ASSIGNMENTS_LEASE_RETURN = '/auftrage/leasingrueckgabe',
  ASSIGNMENTS_LEASE_RETURN_PRO = '/auftrage/leasingrueckgabepro',
  ASSIGNMENTS_ELECTRIC_CAR_CHARGING_COST = '/auftrage/abrechnung-elektroauto-ladekosten',
  ASSIGNMENTS_FUEL_RECEIPT_SUBMIT = '/auftrage/tankquittung-einreichen',
  ASSIGNMENTS_VEHICLE_TRANSFER_RECORD = '/auftrage/fahrzeuguberfuhrung-record',
  ASSIGNMENTS_ACCIDENT_REPAIR_RECORD = '/auftrage/unfallreparatur-record',
  ASSIGNMENTS_LEASE_RETURN_RECORD = '/auftrage/leasingrueckgabe-record',
  TASKS = '/aufgaben',
  VEHICLE_COSTS = '/fahrzeugkosten',
  MONTHLY_BILLS = '/monatsrechnungen',
  ORDERS = '/bestellungen',
  ORDER_DETAILS = '/bestellungen/bestellung',
  MY_REQUESTS = '/meine-anfragen',
  REQUESTS = '/fahrzeuganfragen',
  REQUESTS_DETAILS = '/fahrzeuganfragen/anfrage',
  SERVICE_PROVIDER_TASKS = '/dienstleisteraufgaben',
  MY_VEHICLES = '/meine-fahrzeuge',
  MY_VEHICLES_DETAILS = '/meine-fahrzeuge/fahrzeuge',
  MY_VEHICLES_NEW_VEHICLE = '/meine-fahrzeuge/neue-fahrzeuge',
  MY_DRIVERS = '/meine-fahrer',
  MY_DRIVERS_DETAILS = '/meine-fahrer/fahrer-bearbeiten',
  MY_DRIVERS_CREATE = '/meine-fahrer/fahrer-anlegen',
  CONFIGURATIONS = '/konfigurationen',
  EINGANGSRECHNUNG = '/eingangsrechnungen',

  DOCUMENTMANAGER = '/dokumentenmanager',
  DOCUMENTMANAGER_UPLOAD = '/dokumentenmanager/dokumentenmanager-upload',
  DOCUMENTMANAGER_EDIT = '/dokumentenmanager/dokumentenmanager-edit',

  EINGANGSRECHNUNG_EDIT = '/eingangsrechnungen/eingangsrechnung-edit',
  EINGANGSRECHNUNG_CREATE = '/eingangsrechnungen/eingangsrechnung-create',
  EINGANGSRECHNUNG_UPLOAD = '/eingangsrechnungen/eingangsrechnung-upload',

  NEW_CONFIGURATIONS = '/konfigurationen/neues-fahrzeug-konfigurieren',
  CONFIGURATION_DETAILS = '/konfigurationen/fahrzeugdetails',
  FUEL_CARDS = '/tankkarten',
  FUEL_CARDS_ORDER = '/tankkarten/order',
  FUEL_CARDS_ORDER_FUEL_CARD = '/tankkarten/tankkarte-bestellen',
  LEASE_END = '/leasingende',
  REPAIRED = '/reparaturstatus',
  EMPLOYEE_CONFIGURATIONS = '/mitarbeiterkonfigurationen',
  EMPLOYEE_CONFIGURATION_DETAILS = '/mitarbeiterkonfigurationen/fahrzeugdetails',
  REFERENCE_VEHICLES = '/referenzfahrzeuge',
  REFERENCE_VEHICLES_DETAILS = '/referenzfahrzeuge/fahrzeugdetails',
  GLOBAL_REFERENCE_VEHICLES = '/cockpit',
  NEW_REFERENCE_CONFIGURATIONS = '/referenzfahrzeuge/neues-fahrzeug-konfigurieren',
  DOCUMENTS = '/dokumente',
  DOCUMENTS_CONTRACT = '/dokumente/vertragsdokumente',
  LEASING_REQUESTS = '/leasinganfragen',
  LEASING_REQUESTS_DETAILS = '/leasinganfragen/anfrage',
  POOL_CALENDAR = '/pool-car-buchungen',
  POOL_CALENDAR_NEW = '/pool-car-buchungen/neue-buchung',
  POOL_CALENDAR_VEHICLE = '/pool-car-buchungen/fahrzeuge',
  DASHBOARD = '/dashboard',
  DASHBOARD_RELEASES = '/dashboard/freigaben',
  CONTRACTS = '/vertrage',
  IMPORT = '/import',
  SUPPORT = '/kontakt',
  DOWNLOADS_AND_FAQ = '/downloads-faq',
  FUEL_REPORT = '/kraftstoff-report',
  LONG_TERM_RENTAL = '/langzeitmiete',
  COMPANIES_BUSINESS_DETAILS = '/unternehmen/geschaeftsdetails',
  COMPANIES_EDIT_CHILD_COMPANY = '/unternehmen/kinderfirma-bearbeiten',
  COMPANIES_CONTACT = '/unternehmen/kontakt',
  COMPANIES_COMPANY = '/unternehmen/unternehmen',
  COMPANIES_LEASING = '/unternehmen/leasing',
  COMPANIES_ORDER_CONTROL = '/unternehmen/auftragsteuerung',
  MOBILITY_BUDGET = '/mobilitatsbudget',
  CAR_POLICY = '/car-policy',
  SETUP_ASSISTANT = '/einrichtungs-assistent',
  ACCOUNT_SETTINGS = '/kontoeinstellungen',
  PACKAGES = '/pakete-preise',
  PACKAGE_PAGE = '/pakete-preise/package',
  NEW_CONFIGURATION = '/neue-konfiguration',
  MY_POOL_CALENDAR = '/meine-pool-car-buchungen',
  MY_POOL_CALENDAR_NEW = '/meine-pool-car-buchungen/neue-buchung',
  MY_POOL_CALENDAR_VEHICLE = '/meine-pool-car-buchungen/fahrzeuge',
  ADD_COMPANY = '/pakete-preise/unternehmen-hinzufügen',
  DECISION_SUPPORT = '/entscheidungshilfen',
  CLIENTS_FLEETMANAGER = '/kunden-werben-kunden',
  INVITE_CLIENT_FLEETMANAGER = '/kunden-werben-kunden/freunde-einladen',
  CLIENTS_DEALER = '/kunden',
  INVITE_CLIENT_DEALER = '/kunden-einladen',
  CREATE_OFFER = '/angebot-erstellen',
  CREATE_OFFER_PROCESS = '/angebot-erstellen/angebotsprozess-erstellen',
  TOP_DEALS = '/top-deals',
  VEHICLE_OFFER_DETAILS = '/details',
  TANK_RECEIPT_SUBMIT = '/tankquittung-einreichen',
  BILLING_ELECTRIC_CAR_CHARGING_COSTS = '/abrechnung-elektroauto-ladekosten',
  NO_ACCESS = '/no-access',
  SWAGGER = '/swagger',
  CHAT_TEST = '/chat-test',
  SIGN_IN = '/signin-oidc',
  APPROVALS = '/freigaben',
  APPROVALS_DETAILS = '/freigaben/freigabe',
  LIST = '/list',
  ONBOARDING = '/onboarding',
  BOOKING_RENTAL_CAR = '/mietwagen-buchen',
  CREATE_DAMAGE = '/meine-schaden/schaden-anlegen',
  FLEET_SETTINGS_NOTIFICATION = '/flotteneinstellungen/benachrichtigung',
  FLEET_SETTINGS_POOL_CALENDAR = '/flotteneinstellungen/poolkalender',
  FLEET_SETTINGS_CONSUMPTION_SURCHARGE = '/flotteneinstellungen/verbrauchsaufschlag',
  FLEET_SETTINGS_INSPECTION_DATES = '/flotteneinstellungen/prueftermine',
  ACCOUNTING_SETTINGS_PAYMENT = '/buchhaltungseinstellungen/zahlung',
  ACCOUNTING_SETTINGS_LEDGER_ACCOUNT_MAPPING = '/buchhaltungseinstellungen/sachkonto-mapping',
  ACCOUNTING_SETTINGS_COST_CENTERS = '/buchhaltungseinstellungen/kostenstellen',
  PERSONAL_EMAIL_TEMPLATE = '/personalisierte-e-mail-vorlage',
  COCKPIT_2_0 = '/cockpit-2-0',
  CAR_CONFIGURATOR_BRANDS = '/konfigurator/:driver_id/marken',
  CAR_CONFIGURATOR_MODELS = '/konfigurator/:driver_id/marken/:brand_id/modelle',
  CAR_CONFIGURATOR_VEHICLES = '/konfigurator/:driver_id/marken/:brand_id/modelle/:model_id/fahrzeuge',
  CAR_CONFIGURATOR_OPTIONS = '/konfigurator/:driver_id/marken/:brand_id/modelle/:model_id/fahrzeuge/:vehicle_id',
  REPORT = '/report',
  GREENHOUSE_GAS_EMISSION_QUOTA = '/thg-quote',
  WALL_E_BUSINESS_PARTNER_PROMOTION = '/wall-e'
}

export enum PrefixForId {
  Modal = 'modal',
  Filter = 'Filter',
  Sorting = 'Sorting',
  DotsMenu = 'DotsMenu',
  MenuItem = 'MenuItem',
  Table = 'table',
  Button = 'Button',
  Input = 'Input',
  DropList = 'DropList',
  Title = 'Title',
  Image = 'Image',
  Form = 'Form',
  FormText = 'FormText',
  FormTextArea = 'FormTextArea',
  FormSelect = 'FormSelect',
  FormOption = 'FormOption',
  FormUpload = 'FormUpload',
  FormDate = 'FormDate',
  FormSlider = 'FormSlider',
  Placeholder = 'Placeholder',
  FormLookup = 'FormLookup',
  ThreeState = 'ThreeState',
  Expand = 'Expand',
  Close = 'Close',
  Open = 'Open',
  Submit = 'Submit',
  Search = 'Search',
  LoadMore = 'LoadMore',
  NothingFound = 'NothingFound',
  Container = 'Container',
  List = 'List',
  Items = 'Item',
  Pagination = 'pagination',
  SideMenu = 'SideMenu',
  BreadcrumbsPanel = 'BreadcrumbsPanel',
  MoreMenuItem = 'moreMenuItem',
  Logout = 'Logout',
  ChildCompanies = 'ChildCompanies',
  TileContainer = 'TileContainer',
  DatePicker = 'DatePicker',
  Breadcrumbs = 'Breadcrumbs',
  Link = 'Link',
  Panel = 'Panel',
  Description = 'Description',
  Calendar = 'Calendar',
  Column = 'Column',
  Row = 'Row',
  Cell = 'Cell',
  SubTitle = 'SubTitle',
  Question = 'Question',
  Toggle = 'Toggle',
  Icon = 'Icon',
  Checkbox = 'Checkbox',
  File = 'File',
  Field = 'Field',
  Value = 'Value',
  Spinner = 'Spinner',
  Radio = 'RadioButton'
}

export enum ReactQueryKeys {
  CompanyDocuments = 'companyDocuments',
  VehicleList = 'VehicleLis',
  ReconfigurationsList = 'ReconfigurationsList',
  ReconfigurationsListFilterData = 'ReconfigurationsListFilterData',
  CompanyReferenceVehicleList = 'CompanyReferenceVehicleList',
  CompanyReferenceVehicleListFilterData = 'CompanyReferenceVehicleListFilterData',
  ReferenceVehicleList = 'ReferenceVehicleList',
  ReferenceVehicleListFilterData = 'ReferenceVehicleListFilterData',
  GlobalVehicleList = 'GlobalVehicleList',
  Filter = 'filter',
  GetVehiclesForCreateTask = 'getVehiclesForCreateTask',
  GetInfoForCreateTask = 'getInfoForCreateTask',
  AccountIsBooked = 'accountIsBooked',
  Status = 'status',
  Logo = 'logo',
  SharePoint = 'sharePoint',
  RetrieveRepairPartnerPhotos = 'retrieveRepairPartnerPhotos',
  RetrieveIncidentDetailedFiles = 'retrieveIncidentDetailedFiles',
  GetFilesByDocumentType = 'getFilesByDocumentType',
  DocumentTypeByApp = 'documentTypeByApp',
  DamageGetById = 'damageGetById',
  GetFilesById = 'getFilesById',
  GetStatusesById = 'getStatusesById',
  FleetGetFileForDownload = 'fleetGetFileForDownload',
  GetUserProduct = 'getUserProduct',
  GetAccountProduct = 'getAccountProduct',
  CostEstimateOfferDetails = 'costEstimateOfferDetails',
  GetMailingDialogById = 'getMailingDialogById',
  Dashboard = 'dashboard',
  GetRepairStatus = 'repairStatus',
  DamageGetCarById = 'damageGetCarById',
  IncomingInvoiceGetOptions = 'incomingInvoiceGetOptions',
  IncomingInvoicSeatchCreditor = 'incomingInvoicSeatchCreditor',
  GetCostCentersHistoryList = 'getCostCentersHistoryList',
  GetCostCenterAvailableRanges = 'getCostCenterAvailableRanges',
  GetChildCompanies = 'getChildCompanies',
  GetVehicleDeliveriesByVehicleOffer = 'getVehicleDeliveriesByVehicleOffer',
  GetOverviewFile = 'getUberfurungFile',
  GetUserData = 'getUserData',
  GetUserCars = 'getUserCars',
  GetVehicleOffersEventDescriptions = 'getVehicleOffersEventDescriptions',
  GetLedgerAccountMapping = 'getLedgerAccountMapping',
  GetAllRelatedLeasingOffers = 'getAllRelatedLeasingOffers',
  DamageGetRepairPartner = 'damageGetRepairPartner',
  DamageGetInsuranceFirms = 'damageGetInsuranceFirms',
  GetVehicleHistoryList = 'getVehicleHistoryList',
  GetAvailablePoolCars = 'getAvailablePoolCars',
  GetCarInfo = 'getCarInfo',
  GetPoolCalendarList = 'getPoolCalendarList',
  GetUnavailableDateRanges = 'getUnavailableDateRanges',
  GetFuelCardProductsByCompany = 'getFuelCardProductsByCompany',
  GetPoolCarSettingsData = 'getPoolCarSettingsData',
  GetPoolCarSettingsLocations = 'GetPoolCarSettingsLocations',
  GetPoolCarClasses = 'GetPoolCarClasses',
  GetPoolCarContacts = 'GetPoolCarContacts',
  GetPersonsForLocations = 'getPersonsForStandorts',
  GetPoolCarsWithBookings = 'getPoolCarsWithBookings',
  GetPoolCarsWithBookingsFilterData = 'getPoolCarsWithBookingsFilterData',
  GetAllCompany = 'getAllCompany',
  GetReferenceVehicleDiscounts = 'getReferenceVehicleDiscounts',
  GetContractDetails = 'getContractDetails',
  GetModelDiscount = 'getModelDiscount',
  GetRahmenvertragePaginationList = 'getRahmenvertragePaginationList',
  FleetGetVehicleConstQuery = 'FleetGetVehicleConstQuery',
  FleetCalendarAppointment = 'FleetCalendarAppointment',
  FleetLeasingPeriod = 'FleetLeasingPeriod',
  FleetById = 'fleetById',
  GetDriverWithCar = 'getDriverWithCar',
  GetLocationByCompanyCrmEntity = 'getLocationByCompanyCrmEntity',
  FleetFilesById = 'fleetFilesById',
  GetCostCenterList = 'getCostCenterList',
  CheckDownloadFlag = 'checkDownloadFlag',
  UnfoldedTask = 'unfoldedTask',
  LetterModal = 'letterModal',
  CompanyDataResponse = 'companyDataResponse',
  CompanyDataCompanyResponse = 'companyDataCompanyResponse',
  CompanyDataIbanResponse = 'companyDataIbanResponse',
  CompanyDataServicesResponse = 'companyDataServicesResponse',
  CompanyDataModelsResponse = 'companyDataModelsResponse',
  CompanyDataReminderSettingsResponse = 'companyDataReminderSettingsResponse',
  LeaseLengthMileageDetails = 'lease-length-mileage-details',
  GetAllServices = 'getAllServices',
  GetAvailableBrandsForGrv = 'getAvailableBrandsForGrv',
  GetAvailableModelsForGrv = 'getAvailableModelsForGrv',
  GetInspectionDates = 'getInspectionDates',
  HasChildrenInspectionDates = 'hasChildrenInspectionDates',
  VehicleReturn = 'vehicleReturn',
  CheckContract = 'checkContract',
  GetUserBasket = 'getUserBasket',
  GetUserCompanyBasket = 'getUserCompanyBasket',
  IsFutureBookingExist = 'isFutureBookingExist',
  GetDriversPersonalConfigurations = 'getDriversPersonalConfigurations',
  GetDriversForBooking = 'getDriversForBooking',
  GetAvailablePoolCarsFilterData = 'getAvailablePoolCarsFilterData',
  GetIncomingInvoices = 'getIncomingInvoices',
  GetSplitBookings = 'getSplitBookings',
  GetSplitBookingsFilterData = 'getSplitBookingsFilterData',
  GetConsumptionSurchargeData = 'getConsumptionSurchargeData',
  CustomEmailTemplateEditor = 'customEmailTemplateEditor',
  GetExternalSigningData = 'getExternalSigningData',
  DownloadExternalSigningFoto = 'downloadExternalSigningFoto',
  GetApprovalRecords = 'GetApprovalRecords',
  GetCarConfiguratorBrands = 'getCarConfiguratorBrands',
  GetCarConfiguratorModels = 'GetCarConfiguratorModels',
  GetCarConfiguratorVehicles = 'GetCarConfiguratorVehicles',
  GetCarConfiguratorVehicleDetails = 'GetCarConfiguratorVehicleDetails',
  GetCarConfiguratorOptionsByType = 'GetCarConfiguratorOptionsByType',
  GetBrandImage = 'getBrandImage',
  GetVehicleApprovalPageData = 'getVehicleApprovalPageData',
  GetUserInfo = 'GetUserInfo',
  GetRahmenvertrageFilterData = 'GetRahmenvertrageFilterData',
  GetFilesByDescriptions = 'GetFilesByDescriptions',
  ChecklistModal = 'ChecklistModal',
  GetBaseOptions = 'getBaseOptions'
}

export enum UNAUTHORIZED_PAGES {
  OPINION_POLL = '/umfrage',
  OFFER = '/offer',
  LIST = '/list',
  REPAIRED_STATUS = '/reparaturstatus/suche',
  REPORT_DAMAGE = '/schaden-melden',
  SIGNING = '/vertrag-akzetieren',
  SURVEY = '/umfrage/neuwagenanfrage',
  RELEASE = '/freigabe',
  ENTERPRISE = 'https://partners.rentalcar.com/mobexo/',
  FINN = 'https://www.finn.com/de-de/b2b'
}

export enum MimeTypes {
  CSV = 'text/csv',
  XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  XLS = 'application/vnd.ms-excel',
  TXT = 'text/plain',
  PDF = 'application/pdf',
  PNG = 'image/png',
  JPEG = 'image/jpeg',
  JPG = 'image/jpg',
  HEIC = '.heic',
  UTA = '.uta',
  GIF = 'image/gif',
  EML = '.eml',
  MSG = '.msg',
  XML = '.xml'
}

export enum ShortNameOfMonths {
  January = 'Jan.',
  February = 'Feb.',
  March = 'März',
  April = 'Apr.',
  May = 'Mai',
  June = 'Juni',
  July = 'Juli',
  August = 'Aug.',
  September = 'Sep.',
  October = 'Okt.',
  November = 'Nov.',
  December = 'Dez.'
}

export enum Position {
  Left = 'left',
  Center = 'center',
  Right = 'right',
  Bottom = 'bottom',
  Top = 'top'
}

export enum FormFieldTypes {
  Text = 'text',
  Number = 'number',
  OptionSet = 'optionSet',
  Lookup = 'lookup',
  Date = 'date',
  Time = 'time',
  DateTime = 'dateTime',
  Checkbox = 'checkbox',
  Range = 'range',
  Toggle = 'toggle',
  Money = 'money',
  Percentages = 'percentages',
  Radio = 'radioButton',
  TextArea = 'textArea',
  MulipleCheckoxes = 'multipleCheckboxes',
  File = 'file',
  Months = 'numberWithSuffix',
  Kilometers = 'kilometers',
  Millimeters = 'millimeters',
  Phone = 'phone',
  CarDamage = 'carDamage',
  OptionSetFile = 'optionSetFile',
  LookupFile = 'lookupFile',
  Chips = 'chips'
}

export enum DashboardItemIds {
  MyVehicle = 'myVehicle',
  NewVehicle = 'newVehicle'
}

export enum ConditionOperator {
  ContainValues = 87,
  Equal = 0,
  In = 8
}

export enum SidebarType {
  Greeting = 'greeting',
  Video = 'video',
  Chat = 'chat'
}

export enum PackageTypes {
  Professional = 'Professional',
  Basic = 'Basic',
  Enterprise = 'Enterprise',
  Comfort = 'Comfort'
}

export enum CrmDataTypes {
  Boolean = 0,
  Customer = 1,
  DateTime = 2,
  Decimal = 3,
  Double = 4,
  Integer = 5,
  Lookup = 6,
  Memo = 7,
  Money = 8,
  Owner = 9,
  Picklist = 11,
  State = 12,
  Status = 13,
  String = 14,
  UniqueIdentifier = 15,
  BigInt = 18,
  PicklistMultiple = 100
}

export enum SharePointTitle {
  PhotoDamage = 'Foto Schaden',
  CostEstimate = 'Kostenvoranschlag Werkstatt',
  MeasurementProtocol = 'Vermessungsprotokoll',
  EstimateWorkshop = 'Kostenvoranschlag Werkstatt',
  ErrorLog = 'Fehlerspeicher-Protokoll',
  PhotosAfterRepair = 'Fotos nach Reparatur',
  DeliveryNote = 'Lieferschein',
  RepairSchedule = 'Reparaturablaufplan',
  OtherInvoices = 'ER Sonstiges',
  InvoiceAccidentDamageRepair = 'ER Unfallschaden Reparatur',
  BonusStatementPartnerWorkshop = 'Bonusabrechnung Partnerwerkstatt',
  CertificateOfAchievement = 'Leistungsnachweis',
  ProtocolVehicleAcceptance = 'Protokolle',
  OtherDocuments = 'Sonstige Dokumente'
}

export enum Duration {
  Hour = 'aAhEW8hLX0iE7CF7IT6lOw2',
  HalfHour = 'fLoQr0r400y-XCyoSNM5ug2'
}

export enum DayOfWeek {
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
  Sunday = 7
}

export enum TimeMeasure {
  Hour = 'hour',
  Minutes = 'minutes'
}

export enum PaymentFrequency {
  PerMonth = 752560000,
  PerOperation = 752560003,
  Unique = 752560001
}

export enum ConfiguratorPriceTypes {
  Netto = 'Gewerblich',
  Brutto = 'Privat'
}

export enum FileFormatTypes {
  PDF = 'pdf',
  XLS = 'xls',
  XLSX = 'xlsx',
  JPG = 'jpg',
  JPEG = 'jpeg',
  HEIC = 'heic',
  PNG = 'png',
  WORD = 'docx',
  TEXT = 'txt',
  CSV = 'csv',
  MSG = 'msg',
  GIF = 'gif',
  EML = 'eml'
}

export enum CarPolicyTypes {
  Standard = '752560000',
  PoolCar = '752560001'
}

export enum ConfigurationTypes {
  Global = 'global',
  Reference = 'reference',
  BulkOrders = 'bulkOrders',
  CarConfigurator = 'carConfigurator'
}

export enum FuelNameEnum {
  Petrol = 'Benzin',
  Diesel = 'Diesel',
  Electricity = 'Strom'
}

export enum ColumnsSettingNames {
  LeaseEnd = 'leasingende',
  MyVehicles = 'myVehicles',
  Assignments = 'assignments',
  Drivers = 'drivers'
}

export enum GetFilterQueryByName {
  'Rg-Empfänger' = 'GetCompanyFilter',
  'Bu.-Nummer' = 'GetErNameFilter',
  'Kennzeichen' = 'GetFuhrparkFilter',
  'Rechnungsbezug' = 'GetNameFilter',
  'Kostenart' = 'GetKostenartFilter'
}
