import { AxiosRequestConfig } from 'axios';
import { GetFilesByDocumentTypeProps, UploadFilesByDocumentTypeProps } from './sharepoint-documents.props';

class SharePointDocumentsRequestConfig {
  getFilesByDocumentType = (data: GetFilesByDocumentTypeProps): AxiosRequestConfig => ({
    method: 'POST',
    url: `sharepointdocuments/getFilesByDocumentType`,
    data
  });

  uploadFilesByDocumentType = ({
    files,
    entityId,
    documentTypeId
  }: UploadFilesByDocumentTypeProps): AxiosRequestConfig => {
    const data = new FormData();

    files.forEach(file => {
      data.append('Files', file, file.name);
    });
    data.append('entityId', entityId);
    data.append('documentTypeId', documentTypeId);

    return {
      method: 'POST',
      url: `sharepointdocuments/uploadFilesByDocumentType`,
      data
    };
  };

  rotateFile = (fileId: string, angle: number): AxiosRequestConfig => ({
    method: 'POST',
    url: 'sharepointdocuments/Rotatefile',
    data: {
      fileId,
      angle
    }
  });
}

export const sharePointDocumentsRequestConfig = new SharePointDocumentsRequestConfig();
