import { useContext, useEffect, useMemo } from 'react';
import { FormFieldTypes } from 'common/enums';
import { ChecklistModalContext } from 'components/checklist-modal/checklist-modal-context';
import { FieldValues, UseFormReturn } from 'react-hook-form';

enum FieldsNames {
  ServiceIntervals = 'uds_service_isserviceontime',
  HUUCertificate = 'uds_service_ishuauoriginal'
}

export const useServiceInspections = (reactHookFormData: UseFormReturn<FieldValues, any>) => {
  const { setValue } = reactHookFormData;
  const { checklistInfo } = useContext(ChecklistModalContext);
  const { checklistData } = checklistInfo;

  const serviceIntervalsField = useMemo(() => {
    return [
      {
        name: FieldsNames.ServiceIntervals,
        type: FormFieldTypes.Checkbox,
        defaultValue: false,
        label: 'Inspektionen und Serviceintervalle eingehalten und im Serviceheft eingetragen?',
        validation: {}
      }
    ];
  }, []);

  const HUCertificateField = useMemo(() => {
    return [
      {
        name: FieldsNames.HUUCertificate,
        type: FormFieldTypes.Checkbox,
        defaultValue: false,
        label: 'HU/AU Bescheinigung Original',
        validation: {}
      }
    ];
  }, []);

  useEffect(() => {
    setValue(FieldsNames.ServiceIntervals, checklistData.attributes.uds_service_isserviceontime.value);
    setValue(FieldsNames.HUUCertificate, checklistData.attributes.uds_service_ishuauoriginal.value);
  }, [checklistData]);

  return { serviceIntervalsField, HUCertificateField };
};
