import { Text } from 'common/common-components.styled';
import styled from 'styled-components';

export const SuccessScreenDescription = styled(Text)`
  font-size: 16px;
  text-align: center;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.darkBlue} @media screen and (max-width: ${({ theme }) => theme.media.laptop}) {
    font-size: 18px;
  }

  @media screen and (max-width: ${({ theme }) => theme.media.tablet}) {
    font-size: 16px;
  }
`;
