import styled from 'styled-components';
import {Modal} from '@mui/material';

export const StyledModal = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(247 245 245 / 0.5);
`;

export const LoadingContainer = styled.div`
   display: flex;
   justify-content: center;
   align-items: center;
   gap: 10px;
`;

export const LoadingMessage = styled.label`
    font-family: 'Open-Sans', sans-serif;
    font-weight: 400;
    font-size:20px;
    color: #fff;
`;