import React, { useCallback, useContext, MouseEvent } from 'react';
import classes from './row.module.scss';
import classNames from 'classnames';
import { AnyObject } from 'common/interfaces';
import {
         AssignmentsItemMobile
       } from 'components/assignments-component/assignments-item-mobile/assignments-item-mobile';
import { useHistory } from 'react-router-dom';
import { Cell } from '../cell/cell';
import { ColumnType, IRowContext, RootTagName, RowProps } from '../table-component.props';
import { DataProps } from 'components/assignments-component/assignments-item-mobile/assignments-item-mobile.props';
import { CellContext, RowContext } from 'components/table-component/table-component-contexts';
import { PrefixForId } from 'common/enums';
import { removeSpaces } from 'utils/remove-spaces';
import { openInNewTabHandler } from 'utils/open-page-in-new-tab';
import { StyledRow } from './row-styled';

export const Row = ({ columns, row, rootTag, openedItemId }: RowProps) => {
  const { rowId } = useContext<IRowContext>(RowContext);
  const history = useHistory();

  const linkTableRowClassName = classNames(classes.table_row, 'table', 'pointer', {
    ['table_row_container_disable']: row.isDisableRow
  });

  const linkOnClickHandler = (event:MouseEvent<HTMLDivElement> | undefined) => {
    if (!row[rootTag.propName]) {
      return;
    }
    if(event?.ctrlKey){
      openInNewTabHandler(
        `${window.location.origin}${rootTag.to}${rootTag.encode ? btoa(row[rootTag.propName]) : row[rootTag.propName]}`,
        500
      );
    }else{
      history.push(`${rootTag.to}${rootTag.encode ? btoa(row[rootTag.propName]) : row[rootTag.propName]}`);
    }
  };

  const getContent = useCallback((columns: ColumnType[], row: AnyObject, openedItemId: string) => {
    return columns.map((column, columnIndex) => (
      <CellContext.Provider
        key={`${rowId}${columnIndex}`}
        value={{ cellId: rowId + PrefixForId.Cell + columnIndex + removeSpaces(column.name) }}
      >
        <Cell key={column.name + column.type} column={column} row={row} openedItemId={openedItemId} />
      </CellContext.Provider>
    ));
  }, []);

  const getMobileContent = useCallback((row: AnyObject) => <AssignmentsItemMobile data={row as DataProps} />, []);

  const onClickHandler = (event:MouseEvent<HTMLDivElement> | undefined) => {
    if (row.isDisableRow) {
      return;
    }

    rootTag.onClick ? rootTag.onClick(row, event?.ctrlKey) : linkOnClickHandler(event);
  };

  switch (rootTag.name) {
    case RootTagName.Link:
      return (
        <div id={rowId} className={linkTableRowClassName} onClick={onClickHandler}>
          <div className={classNames({ ['table_row_disable']: row.isDisableRow })} />
          {document.documentElement.clientWidth < 550 && rootTag?.to?.indexOf('auftrage') !== -1
            ? getMobileContent(row)
            : getContent(columns, row, openedItemId)}
        </div>
      );

    case RootTagName.Div:
      return (
        <StyledRow
          id={rowId}
          borderIsNeeded={row?.border?.isNeeded}
          color={row?.border?.color}
          onClick={(e:MouseEvent<HTMLDivElement> | undefined) => rootTag.onClick && rootTag.onClick(row, e?.ctrlKey)}
        >
          {getContent(columns, row, openedItemId)}
        </StyledRow>
      );

    default:
      return <></>;
  }
};
