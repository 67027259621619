import React, {FunctionComponent, ReactElement, ReactSVGElement} from 'react';
import { IconProps } from 'common/interfaces';

export const TelephoneContactIcon:FunctionComponent<IconProps> = ({ width = 31, height = 32, color: colorProp, fill: fillProp, hovered }: IconProps)
   :ReactElement<ReactSVGElement> => {
    const strokeColor = colorProp ? colorProp : "#335566";
    return (
      <svg width={width} height={height} viewBox="0 0 31 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.2198 20.3456C11.2198 20.3456 13.5838 21.5135 15.4614 21.5157C17.3466 21.5179 19.6851 20.3456 19.6851 20.3456" stroke={strokeColor} stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M4.98834 12.2634H1.5478C1.10597 12.2634 0.747803 12.6216 0.747803 13.0634V19.7908C0.747803 20.2326 1.10597 20.5908 1.5478 20.5908H4.98834V12.2634ZM4.98834 12.2634C4.98834 12.2634 4.98834 1 15.4484 1C25.9612 1 25.9612 12.2634 25.9612 12.2634M25.9612 12.2634L29.4521 12.2634C29.8939 12.2634 30.2521 12.6215 30.2521 13.0634V19.7908C30.2521 20.2326 29.8939 20.5907 29.4521 20.5908L25.9612 20.5908M25.9612 12.2634V20.5908M25.9612 20.5908C25.9612 20.5908 25.9612 24.6209 23.3394 27.5303C20.3604 30.836 15.3204 31 15.3204 31" stroke={strokeColor} stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    );
};
