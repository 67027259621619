import React from 'react';
import { Distance, Text } from 'common/common-components.styled';
import { StyledProgressStep } from 'components/progress-component/progress-step/progress-step.styled';
import DoneIcon from 'components/icons/done-icon';
import { useTheme } from 'styled-components';

interface ProgressStepProps {
  title: string;
  active: boolean;
  done: boolean;
  isAllStepsCompleted?: boolean;
  onClick?: (stepTitle: string) => void;
}

const ProgressStep = ({ title, active, done, isAllStepsCompleted, onClick }: ProgressStepProps) => {
  return (
    <StyledProgressStep
      pointer={!isAllStepsCompleted && !!onClick && done}
      margin='0 10px'
      whiteSrace='nowrap'
      onClick={onClick && done ? () => onClick(title) : () => {}}
    >
      {done && (
        <>
          <DoneIcon />
          <Distance side='5' />
        </>
      )}
      <Text
        bold={active}
        overflow='hidden'
        textOverflow='ellipsis'
        whiteSpace='nowrap'
        color={done ? 'green' : 'blue'}
        as='span'
      >
        {title}
      </Text>
    </StyledProgressStep>
  );
};

export default ProgressStep;
