import React, { useContext, useEffect, useMemo } from 'react';
import { FormFieldTypes } from 'common/enums';
import { FormFieldOption } from 'common/interfaces';
import { ChecklistModalContext } from 'components/checklist-modal/checklist-modal-context';
import { cloneDeep } from 'lodash';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import InfoHint from 'components/info-hint';
import { Flex } from 'common/common-components.styled';

export const useReturnStep = (reactHookFormData: UseFormReturn<FieldValues, any>) => {
  const { setValue } = reactHookFormData;
  const { checklistInfo } = useContext(ChecklistModalContext);
  const { checklistData } = checklistInfo;
  const { uds_returnoptions: returnOptions } = checklistData.attributes;

  const getCheckedStatus = (value: number) => {
    const searchedOptions = (cloneDeep(returnOptions) as FormFieldOption[]) || [];

    return searchedOptions.some(option => option.value === value);
  };

  const leftSectionOptions = useMemo(() => {
    return [
      {
        label: '2 Schlüssel',
        value: 10,
        isChecked: getCheckedStatus(10),
        bottomComponent: () => {
          return (
            <Flex bottom='24'>
              <InfoHint>Bitte Zweitschlüssel am Schlüsselbund des Fahrzeugsschlüssels befestigen</InfoHint>
            </Flex>
          );
        }
      },
      {
        label: 'Fahrzeugschein Original',
        value: 11,
        isChecked: getCheckedStatus(11)
      },
      {
        label: 'Tankkarte/n',
        value: 12,
        isChecked: getCheckedStatus(12)
      },
      {
        label: 'Service-Heft',
        value: 13,
        isChecked: getCheckedStatus(13)
      },
      {
        label: 'Service-Karte',
        value: 14,
        isChecked: getCheckedStatus(14)
      },
      {
        label: 'Borddbuch',
        value: 15,
        isChecked: getCheckedStatus(15)
      },
      {
        label: 'Radiokarte',
        value: 16,
        isChecked: getCheckedStatus(16)
      },
      {
        label: 'Navigations-CD/-DVD/-SD',
        value: 17,
        isChecked: getCheckedStatus(17)
      }
    ];
  }, [returnOptions]);

  const rightSectionOptions = useMemo(() => {
    return [
      {
        label: 'Dachgepäckträger',
        value: 18,
        isChecked: getCheckedStatus(18)
      },
      {
        label: 'Reparatur-Kit',
        value: 19,
        isChecked: getCheckedStatus(19)
      },
      {
        label: 'Verbandskasten/Warndreieck/Warnweste',
        value: 20,
        isChecked: getCheckedStatus(20)
      },
      {
        label: 'Laderaumabdeckung/Rollo',
        value: 21,
        isChecked: getCheckedStatus(21)
      },
      {
        label: 'Anhängerkupplung mit Schlüssel',
        value: 22,
        isChecked: getCheckedStatus(22)
      },
      {
        label: 'Zweiter Satz Räder/Reifen im Fahrzeug',
        value: 23,
        isChecked: getCheckedStatus(23),
        bottomComponent: () => {
          return (
            <InfoHint>
              Dies gilt nur für Fahrzeuge, die mit Sommer- und Winterradsatz geleast wurden. Bitte beschaffen Sie den
              zweiten Radsatz und deponieren Sie ihn bei der Rückführung ordnungsgemäß im Fahrzeug (siehe hier: § 22
              StVO)
            </InfoHint>
          );
        }
      }
    ];
  }, [returnOptions]);

  const returnFieldsLeftSection = useMemo(() => {
    return [
      {
        name: 'leftSection',
        type: FormFieldTypes.MulipleCheckoxes,
        defaultValue: null,
        label: '',
        options: leftSectionOptions,
        validation: {}
      }
    ];
  }, [leftSectionOptions]);

  const returnFieldsRightSection = useMemo(() => {
    return [
      {
        name: 'rightSection',
        type: FormFieldTypes.MulipleCheckoxes,
        defaultValue: null,
        label: '',
        options: rightSectionOptions,
        validation: {}
      }
    ];
  }, [returnOptions]);

  useEffect(() => {
    setValue('leftSection', leftSectionOptions);
    setValue('rightSection', rightSectionOptions);
  }, []);

  return { returnFieldsLeftSection, returnFieldsRightSection };
};
