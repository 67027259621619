import { cloneDeepWith } from 'lodash';
import { isValidElement } from 'react';

export const cloneDeepWithReactElements = <T>(myObject: T): T => {
  return cloneDeepWith(myObject, (value: unknown) => {
    if (isValidElement(value)) {
      return value;
    }
  });
};
