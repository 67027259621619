import { useContext, useMemo } from 'react';
import { FormFieldTypes } from 'common/enums';
import { ChecklistModalContext } from 'components/checklist-modal/checklist-modal-context';
import { errorMessages } from 'utils/error-messages';
import { regexValue } from 'utils/regex';

enum FieldsNames {
  Name = 'uds_persona_name',
  Car = 'uds_fuhrparkid',
  Email = 'uds_persona_emailaddress',
  Phone = 'uds_persona_phone',
  Company = 'uds_persona_firma',
  Street = 'uds_persona_street',
  PostalCode = 'uds_persona_plz',
  City = 'uds_persona_ort'
}

export const usePersonalDataStep = () => {
  const { checklistInfo } = useContext(ChecklistModalContext);
  const { checklistData, driverData, fuhrparkData: carData, accountData } = checklistInfo;

  const nameField = useMemo(() => {
    return [
      {
        name: FieldsNames.Name,
        type: FormFieldTypes.Text,
        placeholder: 'Bitte eintragen',
        label: 'Name',
        defaultValue: checklistData.attributes.uds_persona_name || driverData.attributes.fullname,
        validation: {
          required: errorMessages.required,
          maxLength: {
            value: 100,
            message: errorMessages.getMaxTextLength(100)
          }
        }
      }
    ];
  }, [checklistData, driverData]);

  const carField = useMemo(() => {
    return [
      {
        name: FieldsNames.Car,
        type: FormFieldTypes.Lookup,
        label: 'Kennzeichen',
        options: [{ label: carData.name as string, value: carData.id as string }],
        disabled: true,
        defaultValue: carData.id,
        validation: {
          required: errorMessages.required
        }
      }
    ];
  }, [carData, driverData]);

  const personalDataFields = useMemo(() => {
    return [
      {
        name: FieldsNames.Email,
        type: FormFieldTypes.Text,
        placeholder: 'musteremail@gmail.com',
        label: 'E-Mail-Adresse',
        defaultValue: checklistData.attributes.uds_persona_emailaddress || driverData.attributes.emailaddress1,
        validation: {
          required: errorMessages.required,
          pattern: {
            value: regexValue.email,
            message: errorMessages.email
          },
          maxLength: {
            value: 100,
            message: errorMessages.getMaxTextLength(100)
          }
        }
      },
      {
        name: FieldsNames.Phone,
        type: FormFieldTypes.Phone,
        label: 'Rufnummer',
        defaultValue: checklistData.attributes.uds_persona_phone || driverData.attributes.telephone1,
        validation: {
          required: errorMessages.required,
          maxLength: {
            value: 100,
            message: errorMessages.getMaxTextLength(100)
          }
        }
      },
      {
        name: FieldsNames.Company,
        type: FormFieldTypes.Text,
        label: 'Firma',
        defaultValue: checklistData.attributes.uds_persona_firma || carData.attributes.new_sparteid.name,
        disabled: true,
        validation: {
          maxLength: {
            value: 250,
            message: errorMessages.getMaxTextLength(250)
          }
        }
      }
    ];
  }, [checklistData, driverData, carData, accountData]);

  const streetField = useMemo(() => {
    return [
      {
        name: FieldsNames.Street,
        type: FormFieldTypes.Text,
        label: 'Straße',
        defaultValue: checklistData.attributes.uds_persona_street || accountData.attributes.address1_line1,
        validation: {
          required: errorMessages.required,
          maxLength: {
            value: 250,
            message: errorMessages.getMaxTextLength(250)
          }
        }
      }
    ];
  }, []);

  const postalCodeField = useMemo(() => {
    return [
      {
        name: FieldsNames.PostalCode,
        type: FormFieldTypes.Text,
        label: 'PLZ',
        defaultValue: checklistData.attributes.uds_persona_plz || accountData.attributes.address1_postalcode,
        validation: {
          required: errorMessages.required,
          pattern: { value: regexValue.onlyNumbers, message: errorMessages.number },
          maxLength: {
            value: 20,
            message: errorMessages.getMaxTextLength(20)
          }
        }
      }
    ];
  }, [checklistData, accountData]);

  const cityField = useMemo(() => {
    return [
      {
        name: FieldsNames.City,
        type: FormFieldTypes.Text,
        label: 'Ort',
        defaultValue: checklistData.attributes.uds_persona_ort || accountData.attributes.address1_city,
        validation: {
          required: errorMessages.required,
          maxLength: {
            value: 100,
            message: errorMessages.getMaxTextLength(100)
          }
        }
      }
    ];
  }, [checklistData, accountData]);

  return { nameField, carField, personalDataFields, streetField, postalCodeField, cityField };
};
