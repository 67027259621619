/* eslint-disable linebreak-style */
/* eslint-disable no-extra-boolean-cast */
/* eslint-disable linebreak-style */
import React, { useCallback, useEffect, useState } from 'react';
import styles from './detail-page.module.scss';
import { formatDate } from 'helpers';
import RequestComponent from '../request-component/request-component';
import File from './file-component/file';
import { MODALS, MODAL_NAMES } from '../../../constants';
import { useDispatch } from 'react-redux';
import { toggleModal } from 'actions/app_action';
import ConfirmationAlert from './confirmation-alert';
import { CSSTransition } from 'react-transition-group';
import slide from '../transition/damage-slide-detail.module.css';
import useWindowSize from 'hooks/use-window-size';
import { useTheme } from 'styled-components';
import { PencilIcon } from 'components/icons-new/pencil';
import { UseFormProps, FileData, OtherDamagePartsItemProps } from '../report-damage.props';
import {getDamageReportSavingContext} from '../damage-report-saving-context/damage-report-saving-context';

const DetailPage = ({ reactHookFormData }: UseFormProps) => {
  const {type, message} = getDamageReportSavingContext();
  const [showBlock, setShowBlock] = useState<boolean>(false);
  const { windowWidth } = useWindowSize();
  const dispatch = useDispatch();
  const theme = useTheme();
  const { watch, setValue } = reactHookFormData;

  useEffect(() => {
    setShowBlock(true);
  }, []);

  const closeAlert = useCallback(() => {
    dispatch(toggleModal(MODALS.alert, null));
  }, [dispatch]);

  const openModalOnClick = () => {
    let alertData = {
      title: 'Änderung Art des Schadens',
      children: <ConfirmationAlert />,
      buttons: [
        {
          type: 'cancel',
          title: 'Nein, doch nicht',
          action: closeAlert
        },
        {
          type: 'submit',
          title: 'Ja, Schadenart ändern',
          action: newStep
        }
      ]
    };

    dispatch(toggleModal(MODALS.alert, MODAL_NAMES.alert, alertData));
  };

  const returnToStep = (step: number) => {
    setValue('direction', 'back');
    setTimeout(() => setShowBlock(false), 100);
    setTimeout(() => {
      setValue('previousStep', 'detail');
      setValue('step', step);
    }, 800);
  };

  const stepFlowDamage = (step: number) => {
    setValue('direction', 'back');
    setTimeout(() => setShowBlock(false), 100);
    setTimeout(() => {
      setValue('neededStep', step);
      setValue('previousStep', 'detail');
      setValue('step', undefined);
    }, 800);
  };

  const newStep = () => {
    setValue('direction', 'back');
    setTimeout(() => setShowBlock(false), 100);
    setTimeout(() => {
      closeAlert();
      setValue('previousStep', 'detail');
      setValue('step', 2);
    }, 800);
  };

  const moveToPreviousStep = () => {
    setValue('direction', 'back');
    setTimeout(() => setShowBlock(false), 100);
    setValue('previousStep', 8);
    setTimeout(() => {
      setValue('step', undefined);
      setValue('neededStep', watch('lastFlowStep'));
    }, 800);
  };

  const CollisionParkingDetailBlockLeft = () => {
    return (
      <>
        <div className={styles.half_block} style={{ order: 1 }}>
          <div className={styles.block_name}>
            <h6>Daten zum Schaden</h6>
            <div id='returnToStepOneButton' className={styles.edit} onClick={() => returnToStep(1)}>
              <PencilIcon color={theme.colors.darkBlue} />
            </div>
          </div>

          <div className={styles.information_block}>
            <p>{watch('kennzeichen')}</p>
          </div>

          <div className={styles.information_block}>
            <span>
              <p className={styles.first_p}>{formatDate(watch('Schadendatum'))}</p>
              <p>{`${watch('Uhrzeit').hour}:${watch('Uhrzeit').minutes}`}</p>
            </span>
          </div>

          <div className={styles.information_block}>
            <p>{watch('Land')}</p>
            <span>
              <p className={styles.first_p}>{watch('Stadt')}</p>
              <p>{watch('strPLZ')}</p>
            </span>
            <p>{watch('Strasse')}</p>
          </div>

          {((watch('fuhrparkInfo') === null && watch('fahrerInfo') === null) || !!watch('schadenAlsAnderePerson')) && (
            <div className={styles.information_block}>
              {!!watch('anotherPersonFahrerName') && <p>{watch('anotherPersonFahrerName')}</p>}
              {!!watch('anotherPersonFahrerEmail') && <p>{watch('anotherPersonFahrerEmail')}</p>}
              {!!watch('anotherPersonFahrerTelefon') && (
                <p>{`${watch('anotherPersonFahrerTelefonCode')} ${watch('anotherPersonFahrerTelefon')}`}</p>
              )}
              {!!watch('anotherPersonFahrerTelefon2') && (
                <p>{`${watch('anotherPersonFahrerTelefon2Code')} ${watch('anotherPersonFahrerTelefon2')}`}</p>
              )}
            </div>
          )}

          {!!watch('schadenAlsFahrzeugnutzer') && (
            <div className={styles.information_block}>
              {!!watch('fahrerDataFullname') && <p>{watch('fahrerDataFullname')}</p>}
              {!!watch('fahrerDataEmailaddress1') && <p>{watch('fahrerDataEmailaddress1')}</p>}
              {!!watch('fahrerDataMobilephone') && (
                <p>{`${watch('fahrerDataMobilephoneCode')} ${watch('fahrerDataMobilephone')}`}</p>
              )}
              {!!watch('fahrerDataMobilephone1') && (
                <p>{`${watch('fahrerDataMobilephone1Code')} ${watch('fahrerDataMobilephone1')}`}</p>
              )}
            </div>
          )}

          <div className={styles.information_block}>
            <p>{watch('Postleitzahl')}</p>
            {(!!watch('Wunschtermin') || !!watch('AlternativerTermin')) && (
              <span>
                {!!watch('Wunschtermin') && <p className={styles.first_p}>{formatDate(watch('Wunschtermin'))}</p>}
                {!!watch('AlternativerTermin') && <p>{formatDate(watch('AlternativerTermin'))}</p>}
              </span>
            )}
          </div>

          <div className={styles.damagetype_block}>
            <span>{watch('damageType')}</span>
            <div id='openModalChangeTypeOfDamageButton' className={styles.edit} onClick={openModalOnClick}>
              <PencilIcon color={theme.colors.darkBlue} />
            </div>
          </div>
        </div>

        <div className={styles.half_block} style={{ order: 3 }}>
          <div className={styles.block_name}>
            <h6>Polizeiliche Unfalldaten</h6>
            <div id='stepFlowDamageThreeButton' className={styles.edit} onClick={() => stepFlowDamage(3)}>
              <PencilIcon color={theme.colors.darkBlue} />
            </div>
          </div>

          {watch('damagePrivatOrBusinessTrip') !== undefined && (
            <div className={styles.information_block}>
              {!!watch('damagePrivatOrBusinessTrip') && <p>Der Schaden entstand bei einer Privatfahrt</p>}
              {!watch('damagePrivatOrBusinessTrip') && <p>Der Schaden entstand bei einer Dienstfahrt</p>}
            </div>
          )}

          {watch('autoIsOkay') === false && (
            <div className={styles.information_block}>
              {!!watch('autoIsNotOkayFirma') && <p>{watch('autoIsNotOkayFirma')}</p>}
              {!!watch('autoIsNotOkayAdress') && <p>{watch('autoIsNotOkayAdress')}</p>}
              {!!watch('autoIsNotOkayTelephone') && (
                <p>{`${watch('autoIsNotOkayTelephoneCode')} ${watch('autoIsNotOkayTelephone')}`}</p>
              )}
              {!!watch('autoIsNotOkayPLZ') && <p>{watch('autoIsNotOkayPLZ')}</p>}
              {!!watch('autoIsNotOkayStadt') && <p>{watch('autoIsNotOkayStadt')}</p>}
              {!!watch('autoIsNotOkayStreet') && <p>{watch('autoIsNotOkayStreet')}</p>}
            </div>
          )}

          {!!watch('needPolice') && (
            <>
              <div className={styles.information_block}>
                {!!watch('needPoliceDienststelle') && <p>{watch('needPoliceDienststelle')}</p>}
                {!!watch('needPoliceAdressAndOffice') && <p>{watch('needPoliceAdressAndOffice')}</p>}
              </div>

              {!!watch('needPoliceGetCaseNumber') && (
                <div className={styles.information_block}>
                  {!!watch('needPoliceGetCaseNumberAktenzeichen') && (
                    <p>{watch('needPoliceGetCaseNumberAktenzeichen')}</p>
                  )}
                </div>
              )}

              {!!watch('needPoliceCriminalWarning') && (
                <div className={styles.information_block}>
                  {!!watch('needPoliceCriminalWarningWho') && <p>{watch('needPoliceCriminalWarningWho')}</p>}
                </div>
              )}

              {(!!watch('needPoliceDrunkDriverWasTested') || !!watch('needPoliceDrunkDrugsTest')) && (
                <>
                  <div className={styles.information_block}>
                    {!!watch('needPoliceDrunkDriverWasTested') &&
                      !!watch('needPoliceCriminalWarningWhoFirstPersonName') && (
                        <p>{watch('needPoliceCriminalWarningWhoFirstPersonName')}</p>
                      )}
                    {!!watch('needPoliceDrunkDriverWasTested') &&
                      !!watch('needPoliceCriminalWarningWhoFirstPersonDrunkLevel') && (
                        <p>{watch('needPoliceCriminalWarningWhoFirstPersonDrunkLevel')}</p>
                      )}
                    {!!watch('showSecondDrunkPerson') &&
                      !!watch('needPoliceDrunkDriverWasTested') &&
                      !!watch('needPoliceCriminalWarningWhoSecondPersonName') && (
                        <p>{watch('needPoliceCriminalWarningWhoSecondPersonName')}</p>
                      )}
                    {!!watch('showSecondDrunkPerson') &&
                      !!watch('needPoliceDrunkDriverWasTested') &&
                      !!watch('needPoliceCriminalWarningWhoSecondPersonDrunkLevel') && (
                        <p>{watch('needPoliceCriminalWarningWhoSecondPersonDrunkLevel')}</p>
                      )}
                  </div>

                  <div className={styles.information_block}>
                    {!!watch('needPoliceDrunkDrugsTest') && !!watch('needPoliceDrunkDrugsTestWhoWasTestedFirst') && (
                      <p>{watch('needPoliceDrunkDrugsTestWhoWasTestedFirst')}</p>
                    )}
                    {!!watch('needPoliceDrunkDrugsTest') && !!watch('needPoliceDrunkDrugsTestResultFirst') && (
                      <p>{watch('needPoliceDrunkDrugsTestResultFirst')}</p>
                    )}
                    {!!watch('showSecondDrugPerson') &&
                      !!watch('needPoliceDrunkDrugsTest') &&
                      !!watch('needPoliceDrunkDrugsTestWhoWasTestedSecond') && (
                        <p>{watch('needPoliceDrunkDrugsTestWhoWasTestedSecond')}</p>
                      )}
                    {!!watch('showSecondDrugPerson') &&
                      !!watch('needPoliceDrunkDrugsTest') &&
                      !!watch('needPoliceDrunkDrugsTestResultSecond') && (
                        <p>{watch('needPoliceDrunkDrugsTestResultSecond')}</p>
                      )}
                  </div>
                </>
              )}
            </>
          )}
        </div>

        {!!watch('anyWitnesses') && (
          <div className={styles.half_block} style={{ order: 5 }}>
            <div className={styles.block_name}>
              <h6>Zeugen</h6>
              <div id='stepFlowDamageFiveButton' className={styles.edit} onClick={() => stepFlowDamage(5)}>
                <PencilIcon color={theme.colors.darkBlue} />
              </div>
            </div>

            <div className={styles.small_header}>Erster Zeuge:</div>

            {!watch('anyWitnessesDateEmpty') && (
              <div className={styles.information_block}>
                <p>{watch('anyWitnessesName')}</p>
                <p>{watch('anyWitnessesAdress')}</p>
                <p>{watch('anyWitnessesEmail')}</p>
                <p>{`${watch('anyWitnessesPhoneCode')} ${watch('anyWitnessesPhone')}`}</p>
              </div>
            )}

            {!!watch('anyWitnessesDateEmpty') && (
              <div className={styles.information_block}>
                <p>Daten über die Zeuge unbekannt</p>
              </div>
            )}

            {!!watch('showSecondPersonAnyWitnesses') && (
              <>
                <div className={styles.small_header}>Zweiter Zeuge:</div>

                {!watch('anyWitnessesDateSecondEmpty') && (
                  <div className={styles.information_block}>
                    <p>{watch('anyWitnessesNameSecond')}</p>
                    <p>{watch('anyWitnessesAdressSecond')}</p>
                    <p>{watch('anyWitnessesEmailSecond')}</p>
                    <p>{`${watch('anyWitnessesPhoneSecondCode')} ${watch('anyWitnessesPhoneSecond')}`}</p>
                  </div>
                )}

                {!!watch('anyWitnessesDateSecondEmpty') && (
                  <div className={styles.information_block}>
                    <p>Daten über die Zeuge unbekannt</p>
                  </div>
                )}
              </>
            )}
          </div>
        )}

        {((!!watch('FotoSchaden')?.files && watch('FotoSchaden')?.files.length > 0) ||
          (!!watch('Skizze')?.files && watch('Skizze')?.files.length > 0) ||
          (!!watch('SonstigeUnterlagenzurSchadendokumentation')?.files &&
            watch('SonstigeUnterlagenzurSchadendokumentation')?.files.length > 0) ||
          (!!watch('polizei')?.files && watch('polizei')?.files.length > 0)) && (
          <div className={styles.half_block} style={{ order: 9 }}>
            <div className={styles.block_name}>
              <h6>Die Dateien</h6>
              <div id='stepFlowDamageSixButton' className={styles.edit} onClick={() => stepFlowDamage(6)}>
                <PencilIcon color={theme.colors.darkBlue} />
              </div>
            </div>

            {!!watch('FotoSchaden')?.files &&
              Array.isArray(watch('FotoSchaden')?.files) &&
              watch('FotoSchaden')?.files.length > 0 &&
              watch('FotoSchaden')?.files.map((el: FileData) => (
                <div key={el?.path}>
                  <File file={el} />
                </div>
              ))}

            {!!watch('Skizze')?.files &&
              Array.isArray(watch('Skizze')?.files) &&
              watch('Skizze')?.files.length > 0 &&
              watch('Skizze')?.files.map((el: FileData) => (
                <div key={el?.path}>
                  <File file={el} />
                </div>
              ))}

            {!!watch('SonstigeUnterlagenzurSchadendokumentation')?.files &&
              Array.isArray(watch('SonstigeUnterlagenzurSchadendokumentation')?.files) &&
              watch('SonstigeUnterlagenzurSchadendokumentation')?.files.length > 0 &&
              watch('SonstigeUnterlagenzurSchadendokumentation')?.files.map((el: FileData) => (
                <div key={el?.path}>
                  <File file={el} />
                </div>
              ))}

            {!!watch('polizei')?.files &&
              Array.isArray(watch('polizei')?.files) &&
              watch('polizei')?.files.length > 0 &&
              watch('polizei')?.files.map((el: FileData) => (
                <div key={el?.path}>
                  <File file={el} />
                </div>
              ))}
          </div>
        )}
      </>
    );
  };

  const CollisionParkingDetailBlockRight = () => {
    return (
      <>
        <div className={styles.half_block} style={{ order: 2 }}>
          <div className={styles.block_name}>
            <h6>Allgemeines zum Schaden</h6>
            <div id='stepFlowDamageTwoButton' className={styles.edit} onClick={() => stepFlowDamage(2)}>
              <PencilIcon color={theme.colors.darkBlue} />
            </div>
          </div>

          {!!watch('hasDamage') && (
            <div className={styles.information_block}>
              {!!watch('damageParts') &&
                Array.isArray(watch('damageParts')) &&
                watch('damageParts').length > 0 &&
                watch('damageParts').map((item: OtherDamagePartsItemProps, index: number) => (
                  <span key={index}>
                    <p className={styles.first_p}>{item[0]}</p>
                    <p>&#8212;</p>
                    <p className={styles.last_p}>{item[1]?.name}</p>
                  </span>
                ))}
            </div>
          )}

          {!!watch('damageSize') && (
            <div className={styles.information_block}>
              {watch('damageSize') === 'big' && <p>Schaden ist größer als 10x15 Zentimeter</p>}
              {watch('damageSize') === 'small' && <p>Schaden ist kleiner als 10x15 Zentimeter</p>}
            </div>
          )}
        </div>

        <div className={styles.half_block} style={{ order: 4 }}>
          <div className={styles.block_name}>
            <h6>Fahrerdaten und Schadenschilderung</h6>
            <div id='stepFlowDamageFourButton' className={styles.edit} onClick={() => stepFlowDamage(4)}>
              <PencilIcon color={theme.colors.darkBlue} />
            </div>
          </div>

          <div className={styles.information_block}>
            {watch('whoDrive') === 'Fahrzeugnutzer' && <p>Fahrzeugnutzer war der Fahrer des Kundenfahrzeugs</p>}
            {watch('whoDrive') === 'Andere Person' && <p>Andere Person war der Fahrer des Kundenfahrzeugs</p>}
            {watch('whoDrive') === 'Niemand' && <p>Niemand war der Fahrer des Kundenfahrzeugs</p>}
          </div>

          {watch('whoDrive') === 'Fahrzeugnutzer' && (
            <div className={styles.information_block}>
              {!!watch('whoDriveName') && <p>{watch('whoDriveName')}</p>}
              {!!watch('whoDrivePhone') && (
                <p>{`${!!watch('whoDrivePhoneCode') ? watch('whoDrivePhoneCode') : ''} ${watch('whoDrivePhone')}`}</p>
              )}
              {!!watch('whoDriveEmail') && !watch('whoDriveEmailEmpty') && <p>{watch('whoDriveEmail')}</p>}
              {!!watch('whoDrivePLZ') && <p>{watch('whoDrivePLZ')}</p>}
              {!!watch('whoDriveStadt') && <p>{watch('whoDriveStadt')}</p>}
              {!!watch('whoDriveAdress') && <p>{watch('whoDriveAdress')}</p>}
            </div>
          )}

          {watch('whoDrive') === 'Andere Person' && (
            <div className={styles.information_block}>
              {!!watch('whoDriveNameAndere') && <p>{watch('whoDriveNameAndere')}</p>}
              {!!watch('whoDrivePhoneAndere') && (
                <p>{`${watch('whoDrivePhoneAndereCode')} ${watch('whoDrivePhoneAndere')}`}</p>
              )}
              {!!watch('whoDriveEmailAndere') && !watch('whoDriveEmailAndereEmpty') && (
                <p>{watch('whoDriveEmailAndere')}</p>
              )}
              {!!watch('whoDrivePLZAndere') && <p>{watch('whoDrivePLZAndere')}</p>}
              {!!watch('whoDriveStadtAndere') && <p>{watch('whoDriveStadtAndere')}</p>}
              {!!watch('whoDriveAdressAndere') && <p>{watch('whoDriveAdressAndere')}</p>}
            </div>
          )}

          {!!watch('doYouHaveLicense') && (
            <div className={styles.information_block}>
              {!!watch('doYouHaveLicenseIssuingAuthority') && <p>{watch('doYouHaveLicenseIssuingAuthority')}</p>}
              {!!watch('doYouHaveLicenseLicenseNumber') && <p>{watch('doYouHaveLicenseLicenseNumber')}</p>}
              {(!!watch('doYouHaveLicenseDateIssue') || !!watch('doYouHaveLicenseClass')) && (
                <span>
                  {!!watch('doYouHaveLicenseDateIssue') && (
                    <p className={styles.first_p}>{formatDate(watch('doYouHaveLicenseDateIssue'))}</p>
                  )}
                  {!!watch('doYouHaveLicenseClass') && <p>{watch('doYouHaveLicenseClass')}</p>}
                </span>
              )}
            </div>
          )}

          {!!watch('detailDamageComment') && (
            <div className={styles.information_block}>
              <p>{watch('detailDamageComment')}</p>
            </div>
          )}
        </div>

        {!!watch('peopleInvolvedInCrash') && (
          <div className={styles.half_block} style={{ order: 6 }}>
            <div className={styles.block_name}>
              <h6>Unfallbeteiligte</h6>
              <div id='stepFlowDamageFiveButton' className={styles.edit} onClick={() => stepFlowDamage(5)}>
                <PencilIcon color={theme.colors.darkBlue} />
              </div>
            </div>
            <div className={styles.small_header}>Erste Beteiligte:</div>
            {!watch('peopleInvolvedInCrashDateEmpty') && (
              <div className={styles.information_block}>
                <p>{watch('peopleInvolvedInCrashName')}</p>
                <p>{watch('peopleInvolvedInCrashAdress')}</p>
                {!!watch('peopleInvolvedInCrashPhone') && (
                  <p>{`${watch('peopleInvolvedInCrashPhoneCode')} ${watch('peopleInvolvedInCrashPhone')}`}</p>
                )}
              </div>
            )}
            {!!watch('peopleInvolvedInCrashDateEmpty') && (
              <div className={styles.information_block}>
                <p>Daten über die beteiligte Person unbekannt</p>
              </div>
            )}
            <div className={styles.information_block}>
              {!!watch('peopleInvolvedInCrashPeopleDamage') ? (
                <p>Ja, diese Person wurde verletzt</p>
              ) : (
                <p>Nein, diese Person wurde nicht verletzt</p>
              )}
            </div>
            {!!watch('peopleInvolvedInCrashPeopleInvolvedInsuranse') && (
              <div className={styles.information_block}>
                <p>{watch('peopleInvolvedInCrashPeopleInvolvedInsuranseName')}</p>
                {!!watch('peopleInvolvedInCrashPeopleInvolvedInsuranseNumber') && (
                  <p>{watch('peopleInvolvedInCrashPeopleInvolvedInsuranseNumber')}</p>
                )}
              </div>
            )}
            {!!watch('peopleInvolvedInCrashCarOwnerIsDriver') && (
              <div className={styles.information_block}>
                <p>{watch('peopleInvolvedInCrashCarOwnerIsDriverName')}</p>
                {!!watch('peopleInvolvedInCrashCarOwnerIsDriverAdress') && (
                  <p>{watch('peopleInvolvedInCrashCarOwnerIsDriverAdress')}</p>
                )}
                {!!watch('peopleInvolvedInCrashCarOwnerIsDriverPhone') && (
                  <p>{`${watch('peopleInvolvedInCrashCarOwnerIsDriverPhoneCode')}${watch(
                    'peopleInvolvedInCrashCarOwnerIsDriverPhone'
                  )}`}</p>
                )}
              </div>
            )}
            {watch('peopleInvolvedInCrashWhatHasDamage.Fahrzeug') === 'Fahrzeug' &&
              (!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichenEmpty') ||
                !!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichen') ||
                !!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugHersteller') ||
                !!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugTyp')) && (
                <>
                  <div className={styles.information_block}>
                    <p>Fahrzeug wurde beschädigt</p>
                  </div>
                  <div className={styles.information_block}>
                    {!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichenEmpty') &&
                      !!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichen') && (
                        <p>{watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichen')}</p>
                      )}
                    {!!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugHersteller') && (
                      <p>{watch('peopleInvolvedInCrashWhatHasDamageFahrzeugHersteller')}</p>
                    )}
                    {!!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugTyp') && (
                      <p>{watch('peopleInvolvedInCrashWhatHasDamageFahrzeugTyp')}</p>
                    )}
                  </div>
                </>
              )}
            {watch('peopleInvolvedInCrashWhatHasDamage.Sonstiges') === 'Sonstiges' &&
              !!watch('peopleInvolvedInCrashWhatHasDamageSonstigesDamageDescription') && (
                <>
                  <div className={styles.information_block}>
                    <p>Sonstiges wurde beschädigt</p>
                  </div>
                  <div className={styles.information_block}>
                    <p>{watch('peopleInvolvedInCrashWhatHasDamageSonstigesDamageDescription')}</p>
                  </div>
                </>
              )}

            {!!watch('peopleInvolvedInCrash') && !!watch('peopleInvolvedInCrashAnotherPeopleInvolved') && (
              <>
                <div className={styles.small_header}>Zweite Beteiligte:</div>
                {!watch('peopleInvolvedInCrashDateEmpty2') && (
                  <div className={styles.information_block}>
                    <p>{watch('peopleInvolvedInCrashName2')}</p>
                    <p>{watch('peopleInvolvedInCrashAdress2')}</p>
                    {!!watch('peopleInvolvedInCrashPhone2') && (
                      <p>{`${watch('peopleInvolvedInCrashPhone2Code')} ${watch('peopleInvolvedInCrashPhone2')}`}</p>
                    )}
                  </div>
                )}
                {!!watch('peopleInvolvedInCrashDateEmpty2') && (
                  <div className={styles.information_block}>
                    <p>Daten über die beteiligte Person unbekannt</p>
                  </div>
                )}
                <div className={styles.information_block}>
                  {!!watch('peopleInvolvedInCrashPeopleDamage2') ? (
                    <p>Ja, diese Person wurde verletzt</p>
                  ) : (
                    <p>Nein, diese Person wurde nicht verletzt</p>
                  )}
                </div>

                {!!watch('peopleInvolvedInCrashPeopleInvolvedInsuranse2') && (
                  <div className={styles.information_block}>
                    <p>{watch('peopleInvolvedInCrashPeopleInvolvedInsuranseName2')}</p>
                    {!!watch('peopleInvolvedInCrashPeopleInvolvedInsuranseNumber2') && (
                      <p>{watch('peopleInvolvedInCrashPeopleInvolvedInsuranseNumber2')}</p>
                    )}
                  </div>
                )}

                {!!watch('peopleInvolvedInCrashCarOwnerIsDriver2') && (
                  <div className={styles.information_block}>
                    <p>{watch('peopleInvolvedInCrashCarOwnerIsDriverName2')}</p>
                    {!!watch('peopleInvolvedInCrashCarOwnerIsDriverAdress2') && (
                      <p>{watch('peopleInvolvedInCrashCarOwnerIsDriverAdress2')}</p>
                    )}
                    {!!watch('peopleInvolvedInCrashCarOwnerIsDriverPhone2') && (
                      <p>{`${watch('peopleInvolvedInCrashCarOwnerIsDriverPhone2Code')}${watch(
                        'peopleInvolvedInCrashCarOwnerIsDriverPhone2'
                      )}`}</p>
                    )}
                  </div>
                )}

                {watch('peopleInvolvedInCrashWhatHasDamage.Fahrzeug2') === 'Fahrzeug' &&
                  (!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichenEmpty2') ||
                    !!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichen2') ||
                    !!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugHersteller2') ||
                    !!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugTyp2')) && (
                    <>
                      <div className={styles.information_block}>
                        <p>Fahrzeug wurde beschädigt</p>
                      </div>
                      <div className={styles.information_block}>
                        {!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichenEmpty2') &&
                          !!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichen2') && (
                            <p>{watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichen2')}</p>
                          )}
                        {!!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugHersteller2') && (
                          <p>{watch('peopleInvolvedInCrashWhatHasDamageFahrzeugHersteller2')}</p>
                        )}
                        {!!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugTyp2') && (
                          <p>{watch('peopleInvolvedInCrashWhatHasDamageFahrzeugTyp2')}</p>
                        )}
                      </div>
                    </>
                  )}

                {watch('peopleInvolvedInCrashWhatHasDamage.Sonstiges2') === 'Sonstiges' &&
                  !!watch('peopleInvolvedInCrashWhatHasDamageSonstigesDamageDescription2') && (
                    <>
                      <div className={styles.information_block}>
                        <p>Sonstiges wurde beschädigt</p>
                      </div>
                      <div className={styles.information_block}>
                        <p>{watch('peopleInvolvedInCrashWhatHasDamageSonstigesDamageDescription2')}</p>
                      </div>
                    </>
                  )}
              </>
            )}

            {!!watch('peopleInvolvedInCrash') &&
              !!watch('peopleInvolvedInCrashAnotherPeopleInvolved') &&
              !!watch('peopleInvolvedInCrashAnotherPeopleInvolved2') && (
                <>
                  <div className={styles.small_header}>Dritte Beteiligte:</div>
                  {!watch('peopleInvolvedInCrashDateEmpty3') && (
                    <div className={styles.information_block}>
                      <p>{watch('peopleInvolvedInCrashName3')}</p>
                      <p>{watch('peopleInvolvedInCrashAdress3')}</p>
                      {!!watch('peopleInvolvedInCrashPhone3') && (
                        <p>{`${watch('peopleInvolvedInCrashPhone3Code')} ${watch('peopleInvolvedInCrashPhone3')}`}</p>
                      )}
                    </div>
                  )}
                  {!!watch('peopleInvolvedInCrashDateEmpty3') && (
                    <div className={styles.information_block}>
                      <p>Daten über die beteiligte Person unbekannt</p>
                    </div>
                  )}

                  <div className={styles.information_block}>
                    {!!watch('peopleInvolvedInCrashPeopleDamage3') ? (
                      <p>Ja, diese Person wurde verletzt</p>
                    ) : (
                      <p>Nein, diese Person wurde nicht verletzt</p>
                    )}
                  </div>

                  {!!watch('peopleInvolvedInCrashPeopleInvolvedInsuranse3') && (
                    <div className={styles.information_block}>
                      <p>{watch('peopleInvolvedInCrashPeopleInvolvedInsuranseName3')}</p>
                      {!!watch('peopleInvolvedInCrashPeopleInvolvedInsuranseNumber3') && (
                        <p>{watch('peopleInvolvedInCrashPeopleInvolvedInsuranseNumber3')}</p>
                      )}
                    </div>
                  )}

                  {!!watch('peopleInvolvedInCrashCarOwnerIsDriver3') && (
                    <div className={styles.information_block}>
                      <p>{watch('peopleInvolvedInCrashCarOwnerIsDriverName3')}</p>
                      {!!watch('peopleInvolvedInCrashCarOwnerIsDriverAdress3') && (
                        <p>{watch('peopleInvolvedInCrashCarOwnerIsDriverAdress3')}</p>
                      )}
                      {!!watch('peopleInvolvedInCrashCarOwnerIsDriverPhone3') && (
                        <p>{`${watch('peopleInvolvedInCrashCarOwnerIsDriverPhone3Code')}${watch(
                          'peopleInvolvedInCrashCarOwnerIsDriverPhone3'
                        )}`}</p>
                      )}
                    </div>
                  )}

                  {watch('peopleInvolvedInCrashWhatHasDamage.Fahrzeug3') === 'Fahrzeug' &&
                    (!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichenEmpty3') ||
                      !!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichen3') ||
                      !!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugHersteller3') ||
                      !!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugTyp3')) && (
                      <>
                        <div className={styles.information_block}>
                          <p>Fahrzeug wurde beschädigt</p>
                        </div>
                        <div className={styles.information_block}>
                          {!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichenEmpty3') &&
                            !!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichen3') && (
                              <p>{watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichen3')}</p>
                            )}
                          {!!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugHersteller3') && (
                            <p>{watch('peopleInvolvedInCrashWhatHasDamageFahrzeugHersteller3')}</p>
                          )}
                          {!!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugTyp3') && (
                            <p>{watch('peopleInvolvedInCrashWhatHasDamageFahrzeugTyp3')}</p>
                          )}
                        </div>
                      </>
                    )}

                  {watch('peopleInvolvedInCrashWhatHasDamage.Sonstiges3') === 'Sonstiges' &&
                    !!watch('peopleInvolvedInCrashWhatHasDamageSonstigesDamageDescription3') && (
                      <>
                        <div className={styles.information_block}>
                          <p>Sonstiges wurde beschädigt</p>
                        </div>
                        <div className={styles.information_block}>
                          <p>{watch('peopleInvolvedInCrashWhatHasDamageSonstigesDamageDescription3')}</p>
                        </div>
                      </>
                    )}
                </>
              )}
          </div>
        )}
      </>
    );
  };

  const FireDetailBlockLeft = () => {
    return (
      <>
        <div className={styles.half_block} style={{ order: 1 }}>
          <div className={styles.block_name}>
            <h6>Daten zum Schaden</h6>
            <div id='returnToStepOneButton' className={styles.edit} onClick={() => returnToStep(1)}>
              <PencilIcon color={theme.colors.darkBlue} />
            </div>
          </div>

          <div className={styles.information_block}>
            <p>{watch('kennzeichen')}</p>
          </div>

          <div className={styles.information_block}>
            <span>
              <p className={styles.first_p}>{formatDate(watch('Schadendatum'))}</p>
              <p>{`${watch('Uhrzeit').hour}:${watch('Uhrzeit').minutes}`}</p>
            </span>
          </div>

          <div className={styles.information_block}>
            <p>{watch('Land')}</p>
            <span>
              <p className={styles.first_p}>{watch('Stadt')}</p>
              <p>{watch('strPLZ')}</p>
            </span>
            <p>{watch('Strasse')}</p>
          </div>

          {((watch('fuhrparkInfo') === null && watch('fahrerInfo') === null) || !!watch('schadenAlsAnderePerson')) && (
            <div className={styles.information_block}>
              {!!watch('anotherPersonFahrerName') && <p>{watch('anotherPersonFahrerName')}</p>}
              {!!watch('anotherPersonFahrerEmail') && <p>{watch('anotherPersonFahrerEmail')}</p>}
              {!!watch('anotherPersonFahrerTelefon') && (
                <p>{`${watch('anotherPersonFahrerTelefonCode')} ${watch('anotherPersonFahrerTelefon')}`}</p>
              )}
              {!!watch('anotherPersonFahrerTelefon2') && (
                <p>{`${watch('anotherPersonFahrerTelefon2Code')} ${watch('anotherPersonFahrerTelefon2')}`}</p>
              )}
            </div>
          )}

          {!!watch('schadenAlsFahrzeugnutzer') && (
            <div className={styles.information_block}>
              {!!watch('fahrerDataFullname') && <p>{watch('fahrerDataFullname')}</p>}
              {!!watch('fahrerDataEmailaddress1') && <p>{watch('fahrerDataEmailaddress1')}</p>}
              {!!watch('fahrerDataMobilephone') && (
                <p>{`${watch('fahrerDataMobilephoneCode')} ${watch('fahrerDataMobilephone')}`}</p>
              )}
              {!!watch('fahrerDataMobilephone1') && (
                <p>{`${watch('fahrerDataMobilephone1Code')} ${watch('fahrerDataMobilephone1')}`}</p>
              )}
            </div>
          )}

          <div className={styles.information_block}>
            <p>{watch('Postleitzahl')}</p>
            {(!!watch('Wunschtermin') || !!watch('AlternativerTermin')) && (
              <span>
                {!!watch('Wunschtermin') && <p className={styles.first_p}>{formatDate(watch('Wunschtermin'))}</p>}
                {!!watch('AlternativerTermin') && <p>{formatDate(watch('AlternativerTermin'))}</p>}
              </span>
            )}
          </div>

          <div className={styles.damagetype_block}>
            <span>{watch('damageType')}</span>
            <div id='openModalChangeTypeOfDamageButton' className={styles.edit} onClick={openModalOnClick}>
              <PencilIcon color={theme.colors.darkBlue} />
            </div>
          </div>
        </div>

        {!!watch('needPolice') && (
          <div className={styles.half_block} style={{ order: 3 }}>
            <div className={styles.block_name}>
              <h6>Polizeiliche Unfalldaten</h6>
              <div id='stepFlowDamageThreeButton' className={styles.edit} onClick={() => stepFlowDamage(3)}>
                <PencilIcon color={theme.colors.darkBlue} />
              </div>
            </div>

            {!!watch('needPolice') && (
              <>
                <div className={styles.information_block}>
                  {!!watch('needPoliceDienststelle') && <p>{watch('needPoliceDienststelle')}</p>}
                  {!!watch('needPoliceAdressAndOffice') && <p>{watch('needPoliceAdressAndOffice')}</p>}
                </div>

                {!!watch('needPoliceGetCaseNumber') && (
                  <div className={styles.information_block}>
                    {!!watch('needPoliceGetCaseNumberAktenzeichen') && (
                      <p>{watch('needPoliceGetCaseNumberAktenzeichen')}</p>
                    )}
                  </div>
                )}

                {!!watch('needPoliceCriminalWarning') && (
                  <div className={styles.information_block}>
                    {!!watch('needPoliceCriminalWarningWho') && <p>{watch('needPoliceCriminalWarningWho')}</p>}
                  </div>
                )}

                {(!!watch('needPoliceDrunkDriverWasTested') || !!watch('needPoliceDrunkDrugsTest')) && (
                  <>
                    <div className={styles.information_block}>
                      {!!watch('needPoliceDrunkDriverWasTested') &&
                        !!watch('needPoliceCriminalWarningWhoFirstPersonName') && (
                          <p>{watch('needPoliceCriminalWarningWhoFirstPersonName')}</p>
                        )}
                      {!!watch('needPoliceDrunkDriverWasTested') &&
                        !!watch('needPoliceCriminalWarningWhoFirstPersonDrunkLevel') && (
                          <p>{watch('needPoliceCriminalWarningWhoFirstPersonDrunkLevel')}</p>
                        )}
                      {!!watch('showSecondDrunkPerson') &&
                        !!watch('needPoliceDrunkDriverWasTested') &&
                        !!watch('needPoliceCriminalWarningWhoSecondPersonName') && (
                          <p>{watch('needPoliceCriminalWarningWhoSecondPersonName')}</p>
                        )}
                      {!!watch('showSecondDrunkPerson') &&
                        !!watch('needPoliceDrunkDriverWasTested') &&
                        !!watch('needPoliceCriminalWarningWhoSecondPersonDrunkLevel') && (
                          <p>{watch('needPoliceCriminalWarningWhoSecondPersonDrunkLevel')}</p>
                        )}
                    </div>

                    <div className={styles.information_block}>
                      {!!watch('needPoliceDrunkDrugsTest') && !!watch('needPoliceDrunkDrugsTestWhoWasTestedFirst') && (
                        <p>{watch('needPoliceDrunkDrugsTestWhoWasTestedFirst')}</p>
                      )}
                      {!!watch('needPoliceDrunkDrugsTest') && !!watch('needPoliceDrunkDrugsTestResultFirst') && (
                        <p>{watch('needPoliceDrunkDrugsTestResultFirst')}</p>
                      )}
                      {!!watch('showSecondDrugPerson') &&
                        !!watch('needPoliceDrunkDrugsTest') &&
                        !!watch('needPoliceDrunkDrugsTestWhoWasTestedSecond') && (
                          <p>{watch('needPoliceDrunkDrugsTestWhoWasTestedSecond')}</p>
                        )}
                      {!!watch('showSecondDrugPerson') &&
                        !!watch('needPoliceDrunkDrugsTest') &&
                        !!watch('needPoliceDrunkDrugsTestResultSecond') && (
                          <p>{watch('needPoliceDrunkDrugsTestResultSecond')}</p>
                        )}
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        )}

        {!!watch('anyWitnesses') && (
          <div className={styles.half_block} style={{ order: 5 }}>
            <div className={styles.block_name}>
              <h6>Zeugen</h6>
              <div id='stepFlowDamageFourButton' className={styles.edit} onClick={() => stepFlowDamage(4)}>
                <PencilIcon color={theme.colors.darkBlue} />
              </div>
            </div>

            <div className={styles.small_header}>Erster Zeuge:</div>

            {!watch('anyWitnessesDateEmpty') && (
              <div className={styles.information_block}>
                <p>{watch('anyWitnessesName')}</p>
                <p>{watch('anyWitnessesAdress')}</p>
                <p>{watch('anyWitnessesEmail')}</p>
                <p>{`${watch('anyWitnessesPhoneCode')} ${watch('anyWitnessesPhone')}`}</p>
              </div>
            )}

            {!!watch('anyWitnessesDateEmpty') && (
              <div className={styles.information_block}>
                <p>Daten über die Zeuge unbekannt</p>
              </div>
            )}

            {!!watch('showSecondPersonAnyWitnesses') && (
              <>
                <div className={styles.small_header}>Zweiter Zeuge:</div>

                {!watch('anyWitnessesDateSecondEmpty') && (
                  <div className={styles.information_block}>
                    <p>{watch('anyWitnessesNameSecond')}</p>
                    <p>{watch('anyWitnessesAdressSecond')}</p>
                    <p>{watch('anyWitnessesEmailSecond')}</p>
                    <p>{`${watch('anyWitnessesPhoneSecondCode')} ${watch('anyWitnessesPhoneSecond')}`}</p>
                  </div>
                )}

                {!!watch('anyWitnessesDateSecondEmpty') && (
                  <div className={styles.information_block}>
                    <p>Daten über die Zeuge unbekannt</p>
                  </div>
                )}
              </>
            )}
          </div>
        )}

        {((!!watch('FotoSchaden')?.files && watch('FotoSchaden')?.files.length > 0) ||
          (!!watch('Skizze')?.files && watch('Skizze')?.files.length > 0) ||
          (!!watch('SonstigeUnterlagenzurSchadendokumentation')?.files &&
            watch('SonstigeUnterlagenzurSchadendokumentation')?.files.length > 0) ||
          (!!watch('polizei')?.files && watch('polizei')?.files.length > 0)) && (
          <div className={styles.half_block} style={{ order: 10 }}>
            <div className={styles.block_name}>
              <h6>Die Dateien</h6>
              <div id='stepFlowDamageFiveButton' className={styles.edit} onClick={() => stepFlowDamage(5)}>
                <PencilIcon color={theme.colors.darkBlue} />
              </div>
            </div>

            {!!watch('FotoSchaden')?.files &&
              Array.isArray(watch('FotoSchaden')?.files) &&
              watch('FotoSchaden')?.files.length > 0 &&
              watch('FotoSchaden')?.files.map((el: FileData) => (
                <div key={el?.path}>
                  <File file={el} />
                </div>
              ))}

            {!!watch('Skizze')?.files &&
              Array.isArray(watch('Skizze')?.files) &&
              watch('Skizze')?.files.length > 0 &&
              watch('Skizze')?.files.map((el: FileData) => (
                <div key={el?.path}>
                  <File file={el} />
                </div>
              ))}

            {!!watch('SonstigeUnterlagenzurSchadendokumentation')?.files &&
              Array.isArray(watch('SonstigeUnterlagenzurSchadendokumentation')?.files) &&
              watch('SonstigeUnterlagenzurSchadendokumentation')?.files.length > 0 &&
              watch('SonstigeUnterlagenzurSchadendokumentation')?.files.map((el: FileData) => (
                <div key={el?.path}>
                  <File file={el} />
                </div>
              ))}

            {!!watch('polizei')?.files &&
              Array.isArray(watch('polizei')?.files) &&
              watch('polizei')?.files.length > 0 &&
              watch('polizei')?.files.map((el: FileData) => (
                <div key={el?.path}>
                  <File file={el} />
                </div>
              ))}
          </div>
        )}
      </>
    );
  };

  const FireDetailBlockRight = () => {
    return (
      <>
        <div className={styles.half_block} style={{ order: 2 }}>
          <div className={styles.block_name}>
            <h6>Allgemeines zum Schaden</h6>
            <div id='stepFlowDamageTwoButton' className={styles.edit} onClick={() => stepFlowDamage(2)}>
              <PencilIcon color={theme.colors.darkBlue} />
            </div>
          </div>

          {!!watch('hasDamage') && (
            <div className={styles.information_block}>
              {!!watch('damageParts') &&
                Array.isArray(watch('damageParts')) &&
                watch('damageParts').length > 0 &&
                watch('damageParts').map((item: OtherDamagePartsItemProps, index: number) => (
                  <span key={index}>
                    <p className={styles.first_p}>{item[0]}</p>
                    <p>&#8212;</p>
                    <p className={styles.last_p}>{item[1]?.name}</p>
                  </span>
                ))}
            </div>
          )}

          {watch('damagePrivatOrBusinessTrip') !== undefined && (
            <div className={styles.information_block}>
              {!!watch('damagePrivatOrBusinessTrip') && <p>Der Schaden entstand bei einer Privatfahrt</p>}
              {!watch('damagePrivatOrBusinessTrip') && <p>Der Schaden entstand bei einer Dienstfahrt</p>}
            </div>
          )}

          <div className={styles.information_block}>
            {!!watch('autoIsNotOkayFirma') && <p>{watch('autoIsNotOkayFirma')}</p>}
            {!!watch('autoIsNotOkayAdress') && <p>{watch('autoIsNotOkayAdress')}</p>}
            {!!watch('autoIsNotOkayTelephone') && (
              <p>{`${watch('autoIsNotOkayTelephoneCode')} ${watch('autoIsNotOkayTelephone')}`}</p>
            )}
            {!!watch('autoIsNotOkayPLZ') && <p>{watch('autoIsNotOkayPLZ')}</p>}
            {!!watch('autoIsNotOkayStadt') && <p>{watch('autoIsNotOkayStadt')}</p>}
            {!!watch('autoIsNotOkayStreet') && <p>{watch('autoIsNotOkayStreet')}</p>}
          </div>
        </div>

        <div className={styles.half_block} style={{ order: 4 }}>
          <div className={styles.block_name}>
            <h6>Fahrerdaten und Schadenschilderung</h6>
            <div id='stepFlowDamageThreeButton' className={styles.edit} onClick={() => stepFlowDamage(3)}>
              <PencilIcon color={theme.colors.darkBlue} />
            </div>
          </div>

          <div className={styles.information_block}>
            {watch('whoDrive') === 'Fahrzeugnutzer' && <p>Fahrzeugnutzer war der Fahrer des Kundenfahrzeugs</p>}
            {watch('whoDrive') === 'Andere Person' && <p>Andere Person war der Fahrer des Kundenfahrzeugs</p>}
            {watch('whoDrive') === 'Niemand' && <p>Niemand war der Fahrer des Kundenfahrzeugs</p>}
          </div>

          {watch('whoDrive') === 'Fahrzeugnutzer' && (
            <div className={styles.information_block}>
              {!!watch('whoDriveName') && <p>{watch('whoDriveName')}</p>}
              {!!watch('whoDrivePhone') && (
                <p>{`${!!watch('whoDrivePhoneCode') ? watch('whoDrivePhoneCode') : ''} ${watch('whoDrivePhone')}`}</p>
              )}
              {!!watch('whoDriveEmail') && !watch('whoDriveEmailEmpty') && <p>{watch('whoDriveEmail')}</p>}
              {!!watch('whoDrivePLZ') && <p>{watch('whoDrivePLZ')}</p>}
              {!!watch('whoDriveStadt') && <p>{watch('whoDriveStadt')}</p>}
              {!!watch('whoDriveAdress') && <p>{watch('whoDriveAdress')}</p>}
            </div>
          )}

          {watch('whoDrive') === 'Andere Person' && (
            <div className={styles.information_block}>
              {!!watch('whoDriveNameAndere') && <p>{watch('whoDriveNameAndere')}</p>}
              {!!watch('whoDrivePhoneAndere') && (
                <p>{`${watch('whoDrivePhoneAndereCode')} ${watch('whoDrivePhoneAndere')}`}</p>
              )}
              {!!watch('whoDriveEmailAndere') && !watch('whoDriveEmailAndereEmpty') && (
                <p>{watch('whoDriveEmailAndere')}</p>
              )}
              {!!watch('whoDrivePLZAndere') && <p>{watch('whoDrivePLZAndere')}</p>}
              {!!watch('whoDriveStadtAndere') && <p>{watch('whoDriveStadtAndere')}</p>}
              {!!watch('whoDriveAdressAndere') && <p>{watch('whoDriveAdressAndere')}</p>}
            </div>
          )}

          {!!watch('doYouHaveLicense') && (
            <div className={styles.information_block}>
              {!!watch('doYouHaveLicenseIssuingAuthority') && <p>{watch('doYouHaveLicenseIssuingAuthority')}</p>}
              {!!watch('doYouHaveLicenseLicenseNumber') && <p>{watch('doYouHaveLicenseLicenseNumber')}</p>}
              {(!!watch('doYouHaveLicenseDateIssue') || !!watch('doYouHaveLicenseClass')) && (
                <span>
                  {!!watch('doYouHaveLicenseDateIssue') && (
                    <p className={styles.first_p}>{formatDate(watch('doYouHaveLicenseDateIssue'))}</p>
                  )}
                  {!!watch('doYouHaveLicenseClass') && <p>{watch('doYouHaveLicenseClass')}</p>}
                </span>
              )}
            </div>
          )}

          {!!watch('detailDamageComment') && (
            <div className={styles.information_block}>
              <p>{watch('detailDamageComment')}</p>
            </div>
          )}
        </div>

        {!!watch('peopleInvolvedInCrash') && (
          <div className={styles.half_block} style={{ order: 6 }}>
            <div className={styles.block_name}>
              <h6>Unfallbeteiligte</h6>
              <div id='stepFlowDamageFourButton' className={styles.edit} onClick={() => stepFlowDamage(4)}>
                <PencilIcon color={theme.colors.darkBlue} />
              </div>
            </div>
            <div className={styles.small_header}>Erste Beteiligte:</div>
            {!watch('peopleInvolvedInCrashDateEmpty') && (
              <div className={styles.information_block}>
                <p>{watch('peopleInvolvedInCrashName')}</p>
                <p>{watch('peopleInvolvedInCrashAdress')}</p>
                {!!watch('peopleInvolvedInCrashPhone') && (
                  <p>{`${watch('peopleInvolvedInCrashPhoneCode')} ${watch('peopleInvolvedInCrashPhone')}`}</p>
                )}
              </div>
            )}
            {!!watch('peopleInvolvedInCrashDateEmpty') && (
              <div className={styles.information_block}>
                <p>Daten über die beteiligte Person unbekannt</p>
              </div>
            )}
            <div className={styles.information_block}>
              {!!watch('peopleInvolvedInCrashPeopleDamage') ? (
                <p>Ja, diese Person wurde verletzt</p>
              ) : (
                <p>Nein, diese Person wurde nicht verletzt</p>
              )}
            </div>
            {!!watch('peopleInvolvedInCrashPeopleInvolvedInsuranse') && (
              <div className={styles.information_block}>
                <p>{watch('peopleInvolvedInCrashPeopleInvolvedInsuranseName')}</p>
                {!!watch('peopleInvolvedInCrashPeopleInvolvedInsuranseNumber') && (
                  <p>{watch('peopleInvolvedInCrashPeopleInvolvedInsuranseNumber')}</p>
                )}
              </div>
            )}
            {!!watch('peopleInvolvedInCrashCarOwnerIsDriver') && (
              <div className={styles.information_block}>
                <p>{watch('peopleInvolvedInCrashCarOwnerIsDriverName')}</p>
                {!!watch('peopleInvolvedInCrashCarOwnerIsDriverAdress') && (
                  <p>{watch('peopleInvolvedInCrashCarOwnerIsDriverAdress')}</p>
                )}
                {!!watch('peopleInvolvedInCrashCarOwnerIsDriverPhone') && (
                  <p>{`${watch('peopleInvolvedInCrashCarOwnerIsDriverPhoneCode')}${watch(
                    'peopleInvolvedInCrashCarOwnerIsDriverPhone'
                  )}`}</p>
                )}
              </div>
            )}
            {watch('peopleInvolvedInCrashWhatHasDamage.Fahrzeug') === 'Fahrzeug' &&
              (!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichenEmpty') ||
                !!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichen') ||
                !!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugHersteller') ||
                !!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugTyp')) && (
                <>
                  <div className={styles.information_block}>
                    <p>Fahrzeug wurde beschädigt</p>
                  </div>
                  <div className={styles.information_block}>
                    {!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichenEmpty') &&
                      !!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichen') && (
                        <p>{watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichen')}</p>
                      )}
                    {!!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugHersteller') && (
                      <p>{watch('peopleInvolvedInCrashWhatHasDamageFahrzeugHersteller')}</p>
                    )}
                    {!!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugTyp') && (
                      <p>{watch('peopleInvolvedInCrashWhatHasDamageFahrzeugTyp')}</p>
                    )}
                  </div>
                </>
              )}
            {watch('peopleInvolvedInCrashWhatHasDamage.Sonstiges') === 'Sonstiges' &&
              !!watch('peopleInvolvedInCrashWhatHasDamageSonstigesDamageDescription') && (
                <>
                  <div className={styles.information_block}>
                    <p>Sonstiges wurde beschädigt</p>
                  </div>
                  <div className={styles.information_block}>
                    <p>{watch('peopleInvolvedInCrashWhatHasDamageSonstigesDamageDescription')}</p>
                  </div>
                </>
              )}

            {!!watch('peopleInvolvedInCrash') && !!watch('peopleInvolvedInCrashAnotherPeopleInvolved') && (
              <>
                <div className={styles.small_header}>Zweite Beteiligte:</div>
                {!watch('peopleInvolvedInCrashDateEmpty2') && (
                  <div className={styles.information_block}>
                    <p>{watch('peopleInvolvedInCrashName2')}</p>
                    <p>{watch('peopleInvolvedInCrashAdress2')}</p>
                    {!!watch('peopleInvolvedInCrashPhone2') && (
                      <p>{`${watch('peopleInvolvedInCrashPhone2Code')} ${watch('peopleInvolvedInCrashPhone2')}`}</p>
                    )}
                  </div>
                )}
                {!!watch('peopleInvolvedInCrashDateEmpty2') && (
                  <div className={styles.information_block}>
                    <p>Daten über die beteiligte Person unbekannt</p>
                  </div>
                )}

                <div className={styles.information_block}>
                  {!!watch('peopleInvolvedInCrashPeopleDamage2') ? (
                    <p>Ja, diese Person wurde verletzt</p>
                  ) : (
                    <p>Nein, diese Person wurde nicht verletzt</p>
                  )}
                </div>

                {!!watch('peopleInvolvedInCrashPeopleInvolvedInsuranse2') && (
                  <div className={styles.information_block}>
                    <p>{watch('peopleInvolvedInCrashPeopleInvolvedInsuranseName2')}</p>
                    {!!watch('peopleInvolvedInCrashPeopleInvolvedInsuranseNumber2') && (
                      <p>{watch('peopleInvolvedInCrashPeopleInvolvedInsuranseNumber2')}</p>
                    )}
                  </div>
                )}

                {!!watch('peopleInvolvedInCrashCarOwnerIsDriver2') && (
                  <div className={styles.information_block}>
                    <p>{watch('peopleInvolvedInCrashCarOwnerIsDriverName2')}</p>
                    {!!watch('peopleInvolvedInCrashCarOwnerIsDriverAdress2') && (
                      <p>{watch('peopleInvolvedInCrashCarOwnerIsDriverAdress2')}</p>
                    )}
                    {!!watch('peopleInvolvedInCrashCarOwnerIsDriverPhone2') && (
                      <p>{`${watch('peopleInvolvedInCrashCarOwnerIsDriverPhone2Code')}${watch(
                        'peopleInvolvedInCrashCarOwnerIsDriverPhone2'
                      )}`}</p>
                    )}
                  </div>
                )}

                {watch('peopleInvolvedInCrashWhatHasDamage.Fahrzeug2') === 'Fahrzeug' &&
                  (!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichenEmpty2') ||
                    !!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichen2') ||
                    !!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugHersteller2') ||
                    !!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugTyp2')) && (
                    <>
                      <div className={styles.information_block}>
                        <p>Fahrzeug wurde beschädigt</p>
                      </div>
                      <div className={styles.information_block}>
                        {!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichenEmpty2') &&
                          !!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichen2') && (
                            <p>{watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichen2')}</p>
                          )}
                        {!!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugHersteller2') && (
                          <p>{watch('peopleInvolvedInCrashWhatHasDamageFahrzeugHersteller2')}</p>
                        )}
                        {!!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugTyp2') && (
                          <p>{watch('peopleInvolvedInCrashWhatHasDamageFahrzeugTyp2')}</p>
                        )}
                      </div>
                    </>
                  )}

                {watch('peopleInvolvedInCrashWhatHasDamage.Sonstiges2') === 'Sonstiges' &&
                  !!watch('peopleInvolvedInCrashWhatHasDamageSonstigesDamageDescription2') && (
                    <>
                      <div className={styles.information_block}>
                        <p>Sonstiges wurde beschädigt</p>
                      </div>
                      <div className={styles.information_block}>
                        <p>{watch('peopleInvolvedInCrashWhatHasDamageSonstigesDamageDescription2')}</p>
                      </div>
                    </>
                  )}
              </>
            )}

            {!!watch('peopleInvolvedInCrash') &&
              !!watch('peopleInvolvedInCrashAnotherPeopleInvolved') &&
              !!watch('peopleInvolvedInCrashAnotherPeopleInvolved2') && (
                <>
                  <div className={styles.small_header}>Dritte Beteiligte:</div>
                  {!watch('peopleInvolvedInCrashDateEmpty3') && (
                    <div className={styles.information_block}>
                      <p>{watch('peopleInvolvedInCrashName3')}</p>
                      <p>{watch('peopleInvolvedInCrashAdress3')}</p>
                      {!!watch('peopleInvolvedInCrashPhone3') && (
                        <p>{`${watch('peopleInvolvedInCrashPhone3Code')} ${watch('peopleInvolvedInCrashPhone3')}`}</p>
                      )}
                    </div>
                  )}
                  {!!watch('peopleInvolvedInCrashDateEmpty3') && (
                    <div className={styles.information_block}>
                      <p>Daten über die beteiligte Person unbekannt</p>
                    </div>
                  )}

                  <div className={styles.information_block}>
                    {!!watch('peopleInvolvedInCrashPeopleDamage3') ? (
                      <p>Ja, diese Person wurde verletzt</p>
                    ) : (
                      <p>Nein, diese Person wurde nicht verletzt</p>
                    )}
                  </div>

                  {!!watch('peopleInvolvedInCrashPeopleInvolvedInsuranse3') && (
                    <div className={styles.information_block}>
                      <p>{watch('peopleInvolvedInCrashPeopleInvolvedInsuranseName3')}</p>
                      {!!watch('peopleInvolvedInCrashPeopleInvolvedInsuranseNumber3') && (
                        <p>{watch('peopleInvolvedInCrashPeopleInvolvedInsuranseNumber3')}</p>
                      )}
                    </div>
                  )}

                  {!!watch('peopleInvolvedInCrashCarOwnerIsDriver3') && (
                    <div className={styles.information_block}>
                      <p>{watch('peopleInvolvedInCrashCarOwnerIsDriverName3')}</p>
                      {!!watch('peopleInvolvedInCrashCarOwnerIsDriverAdress3') && (
                        <p>{watch('peopleInvolvedInCrashCarOwnerIsDriverAdress3')}</p>
                      )}
                      {!!watch('peopleInvolvedInCrashCarOwnerIsDriverPhone3') && (
                        <p>{`${watch('peopleInvolvedInCrashCarOwnerIsDriverPhone3Code')}${watch(
                          'peopleInvolvedInCrashCarOwnerIsDriverPhone3'
                        )}`}</p>
                      )}
                    </div>
                  )}

                  {watch('peopleInvolvedInCrashWhatHasDamage.Fahrzeug3') === 'Fahrzeug' &&
                    (!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichenEmpty3') ||
                      !!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichen3') ||
                      !!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugHersteller3') ||
                      !!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugTyp3')) && (
                      <>
                        <div className={styles.information_block}>
                          <p>Fahrzeug wurde beschädigt</p>
                        </div>
                        <div className={styles.information_block}>
                          {!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichenEmpty3') &&
                            !!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichen3') && (
                              <p>{watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichen3')}</p>
                            )}
                          {!!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugHersteller3') && (
                            <p>{watch('peopleInvolvedInCrashWhatHasDamageFahrzeugHersteller3')}</p>
                          )}
                          {!!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugTyp3') && (
                            <p>{watch('peopleInvolvedInCrashWhatHasDamageFahrzeugTyp3')}</p>
                          )}
                        </div>
                      </>
                    )}

                  {watch('peopleInvolvedInCrashWhatHasDamage.Sonstiges3') === 'Sonstiges' &&
                    !!watch('peopleInvolvedInCrashWhatHasDamageSonstigesDamageDescription3') && (
                      <>
                        <div className={styles.information_block}>
                          <p>Sonstiges wurde beschädigt</p>
                        </div>
                        <div className={styles.information_block}>
                          <p>{watch('peopleInvolvedInCrashWhatHasDamageSonstigesDamageDescription3')}</p>
                        </div>
                      </>
                    )}
                </>
              )}
          </div>
        )}
      </>
    );
  };

  const HailDetailBlockLeft = () => {
    return (
      <>
        <div className={styles.half_block} style={{ order: 1 }}>
          <div className={styles.block_name}>
            <h6>Daten zum Schaden</h6>
            <div id='returnToStepOneButton' className={styles.edit} onClick={() => returnToStep(1)}>
              <PencilIcon color={theme.colors.darkBlue} />
            </div>
          </div>

          <div className={styles.information_block}>
            <p>{watch('kennzeichen')}</p>
          </div>

          <div className={styles.information_block}>
            <span>
              <p className={styles.first_p}>{formatDate(watch('Schadendatum'))}</p>
              <p>{`${watch('Uhrzeit').hour}:${watch('Uhrzeit').minutes}`}</p>
            </span>
          </div>

          <div className={styles.information_block}>
            <p>{watch('Land')}</p>
            <span>
              <p className={styles.first_p}>{watch('Stadt')}</p>
              <p>{watch('strPLZ')}</p>
            </span>
            <p>{watch('Strasse')}</p>
          </div>

          {((watch('fuhrparkInfo') === null && watch('fahrerInfo') === null) || !!watch('schadenAlsAnderePerson')) && (
            <div className={styles.information_block}>
              {!!watch('anotherPersonFahrerName') && <p>{watch('anotherPersonFahrerName')}</p>}
              {!!watch('anotherPersonFahrerEmail') && <p>{watch('anotherPersonFahrerEmail')}</p>}
              {!!watch('anotherPersonFahrerTelefon') && (
                <p>{`${watch('anotherPersonFahrerTelefonCode')} ${watch('anotherPersonFahrerTelefon')}`}</p>
              )}
              {!!watch('anotherPersonFahrerTelefon2') && (
                <p>{`${watch('anotherPersonFahrerTelefon2Code')} ${watch('anotherPersonFahrerTelefon2')}`}</p>
              )}
            </div>
          )}

          {!!watch('schadenAlsFahrzeugnutzer') && (
            <div className={styles.information_block}>
              {!!watch('fahrerDataFullname') && <p>{watch('fahrerDataFullname')}</p>}
              {!!watch('fahrerDataEmailaddress1') && <p>{watch('fahrerDataEmailaddress1')}</p>}
              {!!watch('fahrerDataMobilephone') && (
                <p>{`${watch('fahrerDataMobilephoneCode')} ${watch('fahrerDataMobilephone')}`}</p>
              )}
              {!!watch('fahrerDataMobilephone1') && (
                <p>{`${watch('fahrerDataMobilephone1Code')} ${watch('fahrerDataMobilephone1')}`}</p>
              )}
            </div>
          )}

          <div className={styles.information_block}>
            <p>{watch('Postleitzahl')}</p>
            {(!!watch('Wunschtermin') || !!watch('AlternativerTermin')) && (
              <span>
                {!!watch('Wunschtermin') && <p className={styles.first_p}>{formatDate(watch('Wunschtermin'))}</p>}
                {!!watch('AlternativerTermin') && <p>{formatDate(watch('AlternativerTermin'))}</p>}
              </span>
            )}
          </div>

          <div className={styles.damagetype_block}>
            <span>{watch('damageType')}</span>
            <div className={styles.edit} onClick={openModalOnClick}>
              <PencilIcon color={theme.colors.darkBlue} />
            </div>
          </div>
        </div>

        {!!watch('anyWitnesses') && (
          <div className={styles.half_block} style={{ order: 3 }}>
            <div className={styles.block_name}>
              <h6>Zeugen</h6>
              <div id='stepFlowDamageTwoButton' className={styles.edit} onClick={() => stepFlowDamage(2)}>
                <PencilIcon color={theme.colors.darkBlue} />
              </div>
            </div>

            <div className={styles.small_header}>Erster Zeuge:</div>

            {!watch('anyWitnessesDateEmpty') && (
              <div className={styles.information_block}>
                <p>{watch('anyWitnessesName')}</p>
                <p>{watch('anyWitnessesAdress')}</p>
                <p>{watch('anyWitnessesEmail')}</p>
                <p>{`${watch('anyWitnessesPhoneCode')} ${watch('anyWitnessesPhone')}`}</p>
              </div>
            )}

            {!!watch('anyWitnessesDateEmpty') && (
              <div className={styles.information_block}>
                <p>Daten über die Zeuge unbekannt</p>
              </div>
            )}

            {!!watch('showSecondPersonAnyWitnesses') && (
              <>
                <div className={styles.small_header}>Zweiter Zeuge:</div>

                {!watch('anyWitnessesDateSecondEmpty') && (
                  <div className={styles.information_block}>
                    <p>{watch('anyWitnessesNameSecond')}</p>
                    <p>{watch('anyWitnessesAdressSecond')}</p>
                    <p>{watch('anyWitnessesEmailSecond')}</p>
                    <p>{`${watch('anyWitnessesPhoneSecondCode')} ${watch('anyWitnessesPhoneSecond')}`}</p>
                  </div>
                )}

                {!!watch('anyWitnessesDateSecondEmpty') && (
                  <div className={styles.information_block}>
                    <p>Daten über die Zeuge unbekannt</p>
                  </div>
                )}
              </>
            )}
          </div>
        )}
      </>
    );
  };

  const HailDetailBlockRight = () => {
    return (
      <>
        <div className={styles.half_block} style={{ order: 2 }}>
          <div className={styles.block_name}>
            <h6>Allgemeines zum Schaden</h6>
            <div id='stepFlowDamageTwoButton' className={styles.edit} onClick={() => stepFlowDamage(2)}>
              <PencilIcon color={theme.colors.darkBlue} />
            </div>
          </div>

          {watch('damagePrivatOrBusinessTrip') !== undefined && (
            <div className={styles.information_block}>
              {!!watch('damagePrivatOrBusinessTrip') && <p>Der Schaden entstand bei einer Privatfahrt</p>}
              {!watch('damagePrivatOrBusinessTrip') && <p>Der Schaden entstand bei einer Dienstfahrt</p>}
            </div>
          )}

          <div className={styles.information_block}>
            {!!watch('autoIsNotOkayFirma') && <p>{watch('autoIsNotOkayFirma')}</p>}
            {!!watch('autoIsNotOkayAdress') && <p>{watch('autoIsNotOkayAdress')}</p>}
            {!!watch('autoIsNotOkayTelephone') && (
              <p>{`${watch('autoIsNotOkayTelephoneCode')} ${watch('autoIsNotOkayTelephone')}`}</p>
            )}
            {!!watch('autoIsNotOkayPLZ') && <p>{watch('autoIsNotOkayPLZ')}</p>}
            {!!watch('autoIsNotOkayStadt') && <p>{watch('autoIsNotOkayStadt')}</p>}
            {!!watch('autoIsNotOkayStreet') && <p>{watch('autoIsNotOkayStreet')}</p>}
          </div>

          {!!watch('DetailedDamageDescription') && (
            <div className={styles.information_block}>
              <p>{watch('DetailedDamageDescription')}</p>
            </div>
          )}
        </div>

        {((!!watch('FotoSchaden')?.files && watch('FotoSchaden')?.files.length > 0) ||
          (!!watch('Skizze')?.files && watch('Skizze')?.files.length > 0) ||
          (!!watch('SonstigeUnterlagenzurSchadendokumentation')?.files &&
            watch('SonstigeUnterlagenzurSchadendokumentation')?.files.length > 0) ||
          (!!watch('polizei')?.files && watch('polizei')?.files.length > 0)) && (
          <div className={styles.half_block} style={{ order: 10 }}>
            <div className={styles.block_name}>
              <h6>Die Dateien</h6>
              <div id='stepFlowDamageThreeButton' className={styles.edit} onClick={() => stepFlowDamage(3)}>
                <PencilIcon color={theme.colors.darkBlue} />
              </div>
            </div>

            {!!watch('FotoSchaden')?.files &&
              Array.isArray(watch('FotoSchaden')?.files) &&
              watch('FotoSchaden')?.files.length > 0 &&
              watch('FotoSchaden')?.files.map((el: FileData) => (
                <div key={el?.path}>
                  <File file={el} />
                </div>
              ))}

            {!!watch('Skizze')?.files &&
              Array.isArray(watch('Skizze')?.files) &&
              watch('Skizze')?.files.length > 0 &&
              watch('Skizze')?.files.map((el: FileData) => (
                <div key={el?.path}>
                  <File file={el} />
                </div>
              ))}

            {!!watch('SonstigeUnterlagenzurSchadendokumentation')?.files &&
              Array.isArray(watch('SonstigeUnterlagenzurSchadendokumentation')?.files) &&
              watch('SonstigeUnterlagenzurSchadendokumentation')?.files.length > 0 &&
              watch('SonstigeUnterlagenzurSchadendokumentation')?.files.map((el: FileData) => (
                <div key={el?.path}>
                  <File file={el} />
                </div>
              ))}

            {!!watch('polizei')?.files &&
              Array.isArray(watch('polizei')?.files) &&
              watch('polizei')?.files.length > 0 &&
              watch('polizei')?.files.map((el: FileData) => (
                <div key={el?.path}>
                  <File file={el} />
                </div>
              ))}
          </div>
        )}
      </>
    );
  };

  const StormDetailBlockLeft = () => {
    return (
      <>
        <div className={styles.half_block} style={{ order: 1 }}>
          <div className={styles.block_name}>
            <h6>Daten zum Schaden</h6>
            <div id='returnToStepOneButton' className={styles.edit} onClick={() => returnToStep(1)}>
              <PencilIcon color={theme.colors.darkBlue} />
            </div>
          </div>

          <div className={styles.information_block}>
            <p>{watch('kennzeichen')}</p>
          </div>

          <div className={styles.information_block}>
            <span>
              <p className={styles.first_p}>{formatDate(watch('Schadendatum'))}</p>
              <p>{`${watch('Uhrzeit').hour}:${watch('Uhrzeit').minutes}`}</p>
            </span>
          </div>

          <div className={styles.information_block}>
            <p>{watch('Land')}</p>
            <span>
              <p className={styles.first_p}>{watch('Stadt')}</p>
              <p>{watch('strPLZ')}</p>
            </span>
            <p>{watch('Strasse')}</p>
          </div>

          {((watch('fuhrparkInfo') === null && watch('fahrerInfo') === null) || !!watch('schadenAlsAnderePerson')) && (
            <div className={styles.information_block}>
              {!!watch('anotherPersonFahrerName') && <p>{watch('anotherPersonFahrerName')}</p>}
              {!!watch('anotherPersonFahrerEmail') && <p>{watch('anotherPersonFahrerEmail')}</p>}
              {!!watch('anotherPersonFahrerTelefon') && (
                <p>{`${watch('anotherPersonFahrerTelefonCode')} ${watch('anotherPersonFahrerTelefon')}`}</p>
              )}
              {!!watch('anotherPersonFahrerTelefon2') && (
                <p>{`${watch('anotherPersonFahrerTelefon2Code')} ${watch('anotherPersonFahrerTelefon2')}`}</p>
              )}
            </div>
          )}

          {!!watch('schadenAlsFahrzeugnutzer') && (
            <div className={styles.information_block}>
              {!!watch('fahrerDataFullname') && <p>{watch('fahrerDataFullname')}</p>}
              {!!watch('fahrerDataEmailaddress1') && <p>{watch('fahrerDataEmailaddress1')}</p>}
              {!!watch('fahrerDataMobilephone') && (
                <p>{`${watch('fahrerDataMobilephoneCode')} ${watch('fahrerDataMobilephone')}`}</p>
              )}
              {!!watch('fahrerDataMobilephone1') && (
                <p>{`${watch('fahrerDataMobilephone1Code')} ${watch('fahrerDataMobilephone1')}`}</p>
              )}
            </div>
          )}

          <div className={styles.information_block}>
            <p>{watch('Postleitzahl')}</p>
            {(!!watch('Wunschtermin') || !!watch('AlternativerTermin')) && (
              <span>
                {!!watch('Wunschtermin') && <p className={styles.first_p}>{formatDate(watch('Wunschtermin'))}</p>}
                {!!watch('AlternativerTermin') && <p>{formatDate(watch('AlternativerTermin'))}</p>}
              </span>
            )}
          </div>

          <div className={styles.damagetype_block}>
            <span>{watch('damageType')}</span>
            <div id='openModalChangeTypeOfDamageButton' className={styles.edit} onClick={openModalOnClick}>
              <PencilIcon color={theme.colors.darkBlue} />
            </div>
          </div>
        </div>

        {!!watch('needPolice') && (
          <div className={styles.half_block} style={{ order: 3 }}>
            <div className={styles.block_name}>
              <h6>Polizeiliche Unfalldaten</h6>
              <div id='stepFlowDamageTwoButton' className={styles.edit} onClick={() => stepFlowDamage(2)}>
                <PencilIcon color={theme.colors.darkBlue} />
              </div>
            </div>

            {!!watch('needPolice') && (
              <>
                <div className={styles.information_block}>
                  {!!watch('needPoliceDienststelle') && <p>{watch('needPoliceDienststelle')}</p>}
                  {!!watch('needPoliceAdressAndOffice') && <p>{watch('needPoliceAdressAndOffice')}</p>}
                </div>

                {!!watch('needPoliceGetCaseNumber') && (
                  <div className={styles.information_block}>
                    {!!watch('needPoliceGetCaseNumberAktenzeichen') && (
                      <p>{watch('needPoliceGetCaseNumberAktenzeichen')}</p>
                    )}
                  </div>
                )}

                {!!watch('needPoliceCriminalWarning') && (
                  <div className={styles.information_block}>
                    {!!watch('needPoliceCriminalWarningWho') && <p>{watch('needPoliceCriminalWarningWho')}</p>}
                  </div>
                )}

                {(!!watch('needPoliceDrunkDriverWasTested') || !!watch('needPoliceDrunkDrugsTest')) && (
                  <>
                    <div className={styles.information_block}>
                      {!!watch('needPoliceDrunkDriverWasTested') &&
                        !!watch('needPoliceCriminalWarningWhoFirstPersonName') && (
                          <p>{watch('needPoliceCriminalWarningWhoFirstPersonName')}</p>
                        )}
                      {!!watch('needPoliceDrunkDriverWasTested') &&
                        !!watch('needPoliceCriminalWarningWhoFirstPersonDrunkLevel') && (
                          <p>{watch('needPoliceCriminalWarningWhoFirstPersonDrunkLevel')}</p>
                        )}
                      {!!watch('showSecondDrunkPerson') &&
                        !!watch('needPoliceDrunkDriverWasTested') &&
                        !!watch('needPoliceCriminalWarningWhoSecondPersonName') && (
                          <p>{watch('needPoliceCriminalWarningWhoSecondPersonName')}</p>
                        )}
                      {!!watch('showSecondDrunkPerson') &&
                        !!watch('needPoliceDrunkDriverWasTested') &&
                        !!watch('needPoliceCriminalWarningWhoSecondPersonDrunkLevel') && (
                          <p>{watch('needPoliceCriminalWarningWhoSecondPersonDrunkLevel')}</p>
                        )}
                    </div>

                    <div className={styles.information_block}>
                      {!!watch('needPoliceDrunkDrugsTest') && !!watch('needPoliceDrunkDrugsTestWhoWasTestedFirst') && (
                        <p>{watch('needPoliceDrunkDrugsTestWhoWasTestedFirst')}</p>
                      )}
                      {!!watch('needPoliceDrunkDrugsTest') && !!watch('needPoliceDrunkDrugsTestResultFirst') && (
                        <p>{watch('needPoliceDrunkDrugsTestResultFirst')}</p>
                      )}
                      {!!watch('showSecondDrugPerson') &&
                        !!watch('needPoliceDrunkDrugsTest') &&
                        !!watch('needPoliceDrunkDrugsTestWhoWasTestedSecond') && (
                          <p>{watch('needPoliceDrunkDrugsTestWhoWasTestedSecond')}</p>
                        )}
                      {!!watch('showSecondDrugPerson') &&
                        !!watch('needPoliceDrunkDrugsTest') &&
                        !!watch('needPoliceDrunkDrugsTestResultSecond') && (
                          <p>{watch('needPoliceDrunkDrugsTestResultSecond')}</p>
                        )}
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        )}
      </>
    );
  };

  const StormDetailBlockRight = () => {
    return (
      <>
        <div className={styles.half_block} style={{ order: 2 }}>
          <div className={styles.block_name}>
            <h6>Allgemeines zum Schaden</h6>
            <div id='stepFlowDamageTwoButton' className={styles.edit} onClick={() => stepFlowDamage(2)}>
              <PencilIcon color={theme.colors.darkBlue} />
            </div>
          </div>

          {!!watch('hasDamage') && (
            <div className={styles.information_block}>
              {!!watch('damageParts') &&
                Array.isArray(watch('damageParts')) &&
                watch('damageParts').length > 0 &&
                watch('damageParts').map((item: OtherDamagePartsItemProps, index: number) => (
                  <span key={index}>
                    <p className={styles.first_p}>{item[0]}</p>
                    <p>&#8212;</p>
                    <p className={styles.last_p}>{item[1]?.name}</p>
                  </span>
                ))}
            </div>
          )}

          {watch('damagePrivatOrBusinessTrip') !== undefined && (
            <div className={styles.information_block}>
              {!!watch('damagePrivatOrBusinessTrip') && <p>Der Schaden entstand bei einer Privatfahrt</p>}
              {!watch('damagePrivatOrBusinessTrip') && <p>Der Schaden entstand bei einer Dienstfahrt</p>}
            </div>
          )}

          <div className={styles.information_block}>
            {!!watch('autoIsNotOkayFirma') && <p>{watch('autoIsNotOkayFirma')}</p>}
            {!!watch('autoIsNotOkayAdress') && <p>{watch('autoIsNotOkayAdress')}</p>}
            {!!watch('autoIsNotOkayTelephone') && (
              <p>{`${watch('autoIsNotOkayTelephoneCode')} ${watch('autoIsNotOkayTelephone')}`}</p>
            )}
          </div>

          {!!watch('detailDamageComment') && (
            <div className={styles.information_block}>
              <p>{watch('detailDamageComment')}</p>
            </div>
          )}
        </div>

        {((!!watch('FotoSchaden')?.files && watch('FotoSchaden')?.files.length > 0) ||
          (!!watch('Skizze')?.files && watch('Skizze')?.files.length > 0) ||
          (!!watch('SonstigeUnterlagenzurSchadendokumentation')?.files &&
            watch('SonstigeUnterlagenzurSchadendokumentation')?.files.length > 0) ||
          (!!watch('polizei')?.files && watch('polizei')?.files.length > 0)) && (
          <div className={styles.half_block} style={{ order: 10 }}>
            <div className={styles.block_name}>
              <h6>Die Dateien</h6>
              <div id='stepFlowDamageThreeButton' className={styles.edit} onClick={() => stepFlowDamage(3)}>
                <PencilIcon color={theme.colors.darkBlue} />
              </div>
            </div>

            {!!watch('FotoSchaden')?.files &&
              Array.isArray(watch('FotoSchaden')?.files) &&
              watch('FotoSchaden')?.files.length > 0 &&
              watch('FotoSchaden')?.files.map((el: FileData) => (
                <div key={el?.path}>
                  <File file={el} />
                </div>
              ))}

            {!!watch('Skizze')?.files &&
              Array.isArray(watch('Skizze')?.files) &&
              watch('Skizze')?.files.length > 0 &&
              watch('Skizze')?.files.map((el: FileData) => (
                <div key={el?.path}>
                  <File file={el} />
                </div>
              ))}

            {!!watch('SonstigeUnterlagenzurSchadendokumentation')?.files &&
              Array.isArray(watch('SonstigeUnterlagenzurSchadendokumentation')?.files) &&
              watch('SonstigeUnterlagenzurSchadendokumentation')?.files.length > 0 &&
              watch('SonstigeUnterlagenzurSchadendokumentation')?.files.map((el: FileData) => (
                <div key={el?.path}>
                  <File file={el} />
                </div>
              ))}

            {!!watch('polizei')?.files &&
              Array.isArray(watch('polizei')?.files) &&
              watch('polizei')?.files.length > 0 &&
              watch('polizei')?.files.map((el: FileData) => (
                <div key={el?.path}>
                  <File file={el} />
                </div>
              ))}
          </div>
        )}
      </>
    );
  };

  const AnimalDetailBlockLeft = () => {
    return (
      <>
        <div className={styles.half_block} style={{ order: 1 }}>
          <div className={styles.block_name}>
            <h6>Daten zum Schaden</h6>
            <div id='returnToStepOneButton' className={styles.edit} onClick={() => returnToStep(1)}>
              <PencilIcon color={theme.colors.darkBlue} />
            </div>
          </div>

          <div className={styles.information_block}>
            <p>{watch('kennzeichen')}</p>
          </div>

          <div className={styles.information_block}>
            <span>
              <p className={styles.first_p}>{formatDate(watch('Schadendatum'))}</p>
              <p>{`${watch('Uhrzeit').hour}:${watch('Uhrzeit').minutes}`}</p>
            </span>
          </div>

          <div className={styles.information_block}>
            <p>{watch('Land')}</p>
            <span>
              <p className={styles.first_p}>{watch('Stadt')}</p>
              <p>{watch('strPLZ')}</p>
            </span>
            <p>{watch('Strasse')}</p>
          </div>

          {((watch('fuhrparkInfo') === null && watch('fahrerInfo') === null) || !!watch('schadenAlsAnderePerson')) && (
            <div className={styles.information_block}>
              {!!watch('anotherPersonFahrerName') && <p>{watch('anotherPersonFahrerName')}</p>}
              {!!watch('anotherPersonFahrerEmail') && <p>{watch('anotherPersonFahrerEmail')}</p>}
              {!!watch('anotherPersonFahrerTelefon') && (
                <p>{`${watch('anotherPersonFahrerTelefonCode')} ${watch('anotherPersonFahrerTelefon')}`}</p>
              )}
              {!!watch('anotherPersonFahrerTelefon2') && (
                <p>{`${watch('anotherPersonFahrerTelefon2Code')} ${watch('anotherPersonFahrerTelefon2')}`}</p>
              )}
            </div>
          )}

          {!!watch('schadenAlsFahrzeugnutzer') && (
            <div className={styles.information_block}>
              {!!watch('fahrerDataFullname') && <p>{watch('fahrerDataFullname')}</p>}
              {!!watch('fahrerDataEmailaddress1') && <p>{watch('fahrerDataEmailaddress1')}</p>}
              {!!watch('fahrerDataMobilephone') && (
                <p>{`${watch('fahrerDataMobilephoneCode')} ${watch('fahrerDataMobilephone')}`}</p>
              )}
              {!!watch('fahrerDataMobilephone1') && (
                <p>{`${watch('fahrerDataMobilephone1Code')} ${watch('fahrerDataMobilephone1')}`}</p>
              )}
            </div>
          )}

          <div className={styles.information_block}>
            <p>{watch('Postleitzahl')}</p>
            {(!!watch('Wunschtermin') || !!watch('AlternativerTermin')) && (
              <span>
                {!!watch('Wunschtermin') && <p className={styles.first_p}>{formatDate(watch('Wunschtermin'))}</p>}
                {!!watch('AlternativerTermin') && <p>{formatDate(watch('AlternativerTermin'))}</p>}
              </span>
            )}
          </div>

          <div className={styles.damagetype_block}>
            <span>{watch('damageType')}</span>
            <div id='openModalChangeTypeOfDamageButton' className={styles.edit} onClick={openModalOnClick}>
              <PencilIcon color={theme.colors.darkBlue} />
            </div>
          </div>
        </div>

        {!!watch('needPolice') && (
          <div className={styles.half_block} style={{ order: 3 }}>
            <div className={styles.block_name}>
              <h6>Polizeiliche Unfalldaten</h6>
              <div id='stepFlowDamageThreeButton' className={styles.edit} onClick={() => stepFlowDamage(3)}>
                <PencilIcon color={theme.colors.darkBlue} />
              </div>
            </div>

            {!!watch('needPolice') && (
              <>
                <div className={styles.information_block}>
                  {!!watch('needPoliceDienststelle') && <p>{watch('needPoliceDienststelle')}</p>}
                  {!!watch('needPoliceAdressAndOffice') && <p>{watch('needPoliceAdressAndOffice')}</p>}
                </div>

                {!!watch('needPoliceGetCaseNumber') && (
                  <div className={styles.information_block}>
                    {!!watch('needPoliceGetCaseNumberAktenzeichen') && (
                      <p>{watch('needPoliceGetCaseNumberAktenzeichen')}</p>
                    )}
                  </div>
                )}

                {!!watch('needPoliceCriminalWarning') && (
                  <div className={styles.information_block}>
                    {!!watch('needPoliceCriminalWarningWho') && <p>{watch('needPoliceCriminalWarningWho')}</p>}
                  </div>
                )}

                {(!!watch('needPoliceDrunkDriverWasTested') || !!watch('needPoliceDrunkDrugsTest')) && (
                  <>
                    <div className={styles.information_block}>
                      {!!watch('needPoliceDrunkDriverWasTested') &&
                        !!watch('needPoliceCriminalWarningWhoFirstPersonName') && (
                          <p>{watch('needPoliceCriminalWarningWhoFirstPersonName')}</p>
                        )}
                      {!!watch('needPoliceDrunkDriverWasTested') &&
                        !!watch('needPoliceCriminalWarningWhoFirstPersonDrunkLevel') && (
                          <p>{watch('needPoliceCriminalWarningWhoFirstPersonDrunkLevel')}</p>
                        )}
                      {!!watch('showSecondDrunkPerson') &&
                        !!watch('needPoliceDrunkDriverWasTested') &&
                        !!watch('needPoliceCriminalWarningWhoSecondPersonName') && (
                          <p>{watch('needPoliceCriminalWarningWhoSecondPersonName')}</p>
                        )}
                      {!!watch('showSecondDrunkPerson') &&
                        !!watch('needPoliceDrunkDriverWasTested') &&
                        !!watch('needPoliceCriminalWarningWhoSecondPersonDrunkLevel') && (
                          <p>{watch('needPoliceCriminalWarningWhoSecondPersonDrunkLevel')}</p>
                        )}
                    </div>

                    <div className={styles.information_block}>
                      {!!watch('needPoliceDrunkDrugsTest') && !!watch('needPoliceDrunkDrugsTestWhoWasTestedFirst') && (
                        <p>{watch('needPoliceDrunkDrugsTestWhoWasTestedFirst')}</p>
                      )}
                      {!!watch('needPoliceDrunkDrugsTest') && !!watch('needPoliceDrunkDrugsTestResultFirst') && (
                        <p>{watch('needPoliceDrunkDrugsTestResultFirst')}</p>
                      )}
                      {!!watch('showSecondDrugPerson') &&
                        !!watch('needPoliceDrunkDrugsTest') &&
                        !!watch('needPoliceDrunkDrugsTestWhoWasTestedSecond') && (
                          <p>{watch('needPoliceDrunkDrugsTestWhoWasTestedSecond')}</p>
                        )}
                      {!!watch('showSecondDrugPerson') &&
                        !!watch('needPoliceDrunkDrugsTest') &&
                        !!watch('needPoliceDrunkDrugsTestResultSecond') && (
                          <p>{watch('needPoliceDrunkDrugsTestResultSecond')}</p>
                        )}
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        )}

        <div className={styles.half_block} style={{ order: 5 }}>
          <div className={styles.block_name}>
            <h6>Wild-/ Tierschaden</h6>
            <div id='stepFlowDamageThreeButton' className={styles.edit} onClick={() => stepFlowDamage(3)}>
              <PencilIcon color={theme.colors.darkBlue} />
            </div>
          </div>

          {watch('animalDamageReportAuthorities') !== undefined && (
            <div className={styles.information_block}>
              {!!watch('animalDamageReportAuthorities') && (
                <p>Der Wild-/Tierschaden wurde der zuständigen Behörde gemeldet</p>
              )}
              {!watch('animalDamageReportAuthorities') && (
                <p>Der Wild-/Tierschaden wurde keiner zuständigen Behörde gemeldet</p>
              )}
            </div>
          )}

          {!!watch('animalName') && (
            <div className={styles.information_block}>
              <p>{watch('animalName')}</p>
            </div>
          )}
        </div>

        <div className={styles.half_block} style={{ order: 7 }}>
          <div className={styles.block_name}>
            <h6>Schadenschilderung</h6>
            <div id='stepFlowDamageFourButton' className={styles.edit} onClick={() => stepFlowDamage(4)}>
              <PencilIcon color={theme.colors.darkBlue} />
            </div>
          </div>

          {!!watch('detailDamageComment') && (
            <div className={styles.information_block}>
              <p>{watch('detailDamageComment')}</p>
            </div>
          )}
        </div>

        {((!!watch('Wildunfallbescheinigung')?.files && watch('Wildunfallbescheinigung')?.files.length > 0) ||
          (!!watch('FotoSchaden')?.files && watch('FotoSchaden')?.files.length > 0) ||
          (!!watch('Skizze')?.files && watch('Skizze')?.files.length > 0) ||
          (!!watch('SonstigeUnterlagenzurSchadendokumentation')?.files &&
            watch('SonstigeUnterlagenzurSchadendokumentation')?.files.length > 0) ||
          (!!watch('polizei')?.files && watch('polizei')?.files.length > 0)) && (
          <div className={styles.half_block} style={{ order: 10 }}>
            <div className={styles.block_name}>
              <h6>Die Dateien</h6>
              <div id='stepFlowDamageFiveButton' className={styles.edit} onClick={() => stepFlowDamage(5)}>
                <PencilIcon color={theme.colors.darkBlue} />
              </div>
            </div>

            {!!watch('FotoSchaden')?.files &&
              Array.isArray(watch('FotoSchaden')?.files) &&
              watch('FotoSchaden')?.files.length > 0 &&
              watch('FotoSchaden')?.files.map((el: FileData) => (
                <div key={el?.path}>
                  <File file={el} />
                </div>
              ))}

            {!!watch('Skizze')?.files &&
              Array.isArray(watch('Skizze')?.files) &&
              watch('Skizze')?.files.length > 0 &&
              watch('Skizze')?.files.map((el: FileData) => (
                <div key={el?.path}>
                  <File file={el} />
                </div>
              ))}

            {!!watch('SonstigeUnterlagenzurSchadendokumentation')?.files &&
              Array.isArray(watch('SonstigeUnterlagenzurSchadendokumentation')?.files) &&
              watch('SonstigeUnterlagenzurSchadendokumentation')?.files.length > 0 &&
              watch('SonstigeUnterlagenzurSchadendokumentation')?.files.map((el: FileData) => (
                <div key={el?.path}>
                  <File file={el} />
                </div>
              ))}

            {!!watch('polizei')?.files &&
              Array.isArray(watch('polizei')?.files) &&
              watch('polizei')?.files.length > 0 &&
              watch('polizei')?.files.map((el: FileData) => (
                <div key={el?.path}>
                  <File file={el} />
                </div>
              ))}

            {!!watch('Wildunfallbescheinigung')?.files &&
              Array.isArray(watch('Wildunfallbescheinigung')?.files) &&
              watch('Wildunfallbescheinigung')?.files.length > 0 &&
              watch('Wildunfallbescheinigung')?.files.map((el: FileData) => (
                <div key={el?.path}>
                  <File file={el} />
                </div>
              ))}
          </div>
        )}
      </>
    );
  };

  const AnimalDetailBlockRight = () => {
    return (
      <>
        <div className={styles.half_block} style={{ order: 2 }}>
          <div className={styles.block_name}>
            <h6>Allgemeines zum Schaden</h6>
            <div id='stepFlowDamageTwoButton' className={styles.edit} onClick={() => stepFlowDamage(2)}>
              <PencilIcon color={theme.colors.darkBlue} />
            </div>
          </div>

          {!!watch('hasDamage') && (
            <div className={styles.information_block}>
              {!!watch('damageParts') &&
                Array.isArray(watch('damageParts')) &&
                watch('damageParts').length > 0 &&
                watch('damageParts').map((item: OtherDamagePartsItemProps, index: number) => (
                  <span key={index}>
                    <p className={styles.first_p}>{item[0]}</p>
                    <p>&#8212;</p>
                    <p className={styles.last_p}>{item[1]?.name}</p>
                  </span>
                ))}
            </div>
          )}

          {!!watch('damageSize') && (
            <div className={styles.information_block}>
              {watch('damageSize') === 'big' && <p>Schaden ist größer als 10x15 Zentimeter</p>}
              {watch('damageSize') === 'small' && <p>Schaden ist kleiner als 10x15 Zentimeter</p>}
            </div>
          )}

          {watch('damagePrivatOrBusinessTrip') !== undefined && (
            <div className={styles.information_block}>
              {!!watch('damagePrivatOrBusinessTrip') && <p>Der Schaden entstand bei einer Privatfahrt</p>}
              {!watch('damagePrivatOrBusinessTrip') && <p>Der Schaden entstand bei einer Dienstfahrt</p>}
            </div>
          )}

          <div className={styles.information_block}>
            {!!watch('autoIsNotOkayFirma') && <p>{watch('autoIsNotOkayFirma')}</p>}
            {!!watch('autoIsNotOkayAdress') && <p>{watch('autoIsNotOkayAdress')}</p>}
            {!!watch('autoIsNotOkayTelephone') && (
              <p>{`${watch('autoIsNotOkayTelephoneCode')} ${watch('autoIsNotOkayTelephone')}`}</p>
            )}
            {!!watch('autoIsNotOkayPLZ') && <p>{watch('autoIsNotOkayPLZ')}</p>}
            {!!watch('autoIsNotOkayStadt') && <p>{watch('autoIsNotOkayStadt')}</p>}
            {!!watch('autoIsNotOkayStreet') && <p>{watch('autoIsNotOkayStreet')}</p>}
          </div>
        </div>

        <div className={styles.half_block} style={{ order: 4 }}>
          <div className={styles.block_name}>
            <h6>Fahrerdaten</h6>
            <div id='stepFlowDamageThreeButton' className={styles.edit} onClick={() => stepFlowDamage(3)}>
              <PencilIcon color={theme.colors.darkBlue} />
            </div>
          </div>

          <div className={styles.information_block}>
            {watch('whoDrive') === 'Fahrzeugnutzer' && <p>Fahrzeugnutzer war der Fahrer des Kundenfahrzeugs</p>}
            {watch('whoDrive') === 'Andere Person' && <p>Andere Person war der Fahrer des Kundenfahrzeugs</p>}
            {watch('whoDrive') === 'Niemand' && <p>Niemand war der Fahrer des Kundenfahrzeugs</p>}
          </div>

          {watch('whoDrive') === 'Fahrzeugnutzer' && (
            <div className={styles.information_block}>
              {!!watch('whoDriveName') && <p>{watch('whoDriveName')}</p>}
              {!!watch('whoDrivePhone') && (
                <p>{`${!!watch('whoDrivePhoneCode') ? watch('whoDrivePhoneCode') : ''} ${watch('whoDrivePhone')}`}</p>
              )}
              {!!watch('whoDriveEmail') && !watch('whoDriveEmailEmpty') && <p>{watch('whoDriveEmail')}</p>}
              {!!watch('whoDrivePLZ') && <p>{watch('whoDrivePLZ')}</p>}
              {!!watch('whoDriveStadt') && <p>{watch('whoDriveStadt')}</p>}
              {!!watch('whoDriveAdress') && <p>{watch('whoDriveAdress')}</p>}
            </div>
          )}

          {watch('whoDrive') === 'Andere Person' && (
            <div className={styles.information_block}>
              {!!watch('whoDriveNameAndere') && <p>{watch('whoDriveNameAndere')}</p>}
              {!!watch('whoDrivePhoneAndere') && (
                <p>{`${watch('whoDrivePhoneAndereCode')} ${watch('whoDrivePhoneAndere')}`}</p>
              )}
              {!!watch('whoDriveEmailAndere') && !watch('whoDriveEmailAndereEmpty') && (
                <p>{watch('whoDriveEmailAndere')}</p>
              )}
              {!!watch('whoDrivePLZAndere') && <p>{watch('whoDrivePLZAndere')}</p>}
              {!!watch('whoDriveStadtAndere') && <p>{watch('whoDriveStadtAndere')}</p>}
              {!!watch('whoDriveAdressAndere') && <p>{watch('whoDriveAdressAndere')}</p>}
            </div>
          )}

          {!!watch('doYouHaveLicense') && (
            <div className={styles.information_block}>
              {!!watch('doYouHaveLicenseIssuingAuthority') && <p>{watch('doYouHaveLicenseIssuingAuthority')}</p>}
              {!!watch('doYouHaveLicenseLicenseNumber') && <p>{watch('doYouHaveLicenseLicenseNumber')}</p>}
              {(!!watch('doYouHaveLicenseDateIssue') || !!watch('doYouHaveLicenseClass')) && (
                <span>
                  {!!watch('doYouHaveLicenseDateIssue') && (
                    <p className={styles.first_p}>{formatDate(watch('doYouHaveLicenseDateIssue'))}</p>
                  )}
                  {!!watch('doYouHaveLicenseClass') && <p>{watch('doYouHaveLicenseClass')}</p>}
                </span>
              )}
            </div>
          )}
        </div>

        {!!watch('peopleInvolvedInCrash') && (
          <div className={styles.half_block} style={{ order: 6 }}>
            <div className={styles.block_name}>
              <h6>Unfallbeteiligte</h6>
              <div id='stepFlowDamageFourButton' className={styles.edit} onClick={() => stepFlowDamage(4)}>
                <PencilIcon color={theme.colors.darkBlue} />
              </div>
            </div>
            <div className={styles.small_header}>Erste Beteiligte:</div>
            {!watch('peopleInvolvedInCrashDateEmpty') && (
              <div className={styles.information_block}>
                <p>{watch('peopleInvolvedInCrashName')}</p>
                <p>{watch('peopleInvolvedInCrashAdress')}</p>
                {!!watch('peopleInvolvedInCrashPhone') && (
                  <p>{`${watch('peopleInvolvedInCrashPhoneCode')} ${watch('peopleInvolvedInCrashPhone')}`}</p>
                )}
              </div>
            )}
            {!!watch('peopleInvolvedInCrashDateEmpty') && (
              <div className={styles.information_block}>
                <p>Daten über die beteiligte Person unbekannt</p>
              </div>
            )}
            <div className={styles.information_block}>
              {!!watch('peopleInvolvedInCrashPeopleDamage') ? (
                <p>Ja, diese Person wurde verletzt</p>
              ) : (
                <p>Nein, diese Person wurde nicht verletzt</p>
              )}
            </div>
            {!!watch('peopleInvolvedInCrashPeopleInvolvedInsuranse') && (
              <div className={styles.information_block}>
                <p>{watch('peopleInvolvedInCrashPeopleInvolvedInsuranseName')}</p>
                {!!watch('peopleInvolvedInCrashPeopleInvolvedInsuranseNumber') && (
                  <p>{watch('peopleInvolvedInCrashPeopleInvolvedInsuranseNumber')}</p>
                )}
              </div>
            )}
            {!!watch('peopleInvolvedInCrashCarOwnerIsDriver') && (
              <div className={styles.information_block}>
                <p>{watch('peopleInvolvedInCrashCarOwnerIsDriverName')}</p>
                {!!watch('peopleInvolvedInCrashCarOwnerIsDriverAdress') && (
                  <p>{watch('peopleInvolvedInCrashCarOwnerIsDriverAdress')}</p>
                )}
                {!!watch('peopleInvolvedInCrashCarOwnerIsDriverPhone') && (
                  <p>{`${watch('peopleInvolvedInCrashCarOwnerIsDriverPhoneCode')}${watch(
                    'peopleInvolvedInCrashCarOwnerIsDriverPhone'
                  )}`}</p>
                )}
              </div>
            )}
            {watch('peopleInvolvedInCrashWhatHasDamage.Fahrzeug') === 'Fahrzeug' &&
              (!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichenEmpty') ||
                !!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichen') ||
                !!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugHersteller') ||
                !!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugTyp')) && (
                <>
                  <div className={styles.information_block}>
                    <p>Fahrzeug wurde beschädigt</p>
                  </div>
                  <div className={styles.information_block}>
                    {!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichenEmpty') &&
                      !!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichen') && (
                        <p>{watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichen')}</p>
                      )}
                    {!!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugHersteller') && (
                      <p>{watch('peopleInvolvedInCrashWhatHasDamageFahrzeugHersteller')}</p>
                    )}
                    {!!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugTyp') && (
                      <p>{watch('peopleInvolvedInCrashWhatHasDamageFahrzeugTyp')}</p>
                    )}
                  </div>
                </>
              )}
            {watch('peopleInvolvedInCrashWhatHasDamage.Sonstiges') === 'Sonstiges' &&
              !!watch('peopleInvolvedInCrashWhatHasDamageSonstigesDamageDescription') && (
                <>
                  <div className={styles.information_block}>
                    <p>Sonstiges wurde beschädigt</p>
                  </div>
                  <div className={styles.information_block}>
                    <p>{watch('peopleInvolvedInCrashWhatHasDamageSonstigesDamageDescription')}</p>
                  </div>
                </>
              )}

            {!!watch('peopleInvolvedInCrash') && !!watch('peopleInvolvedInCrashAnotherPeopleInvolved') && (
              <>
                <div className={styles.small_header}>Zweite Beteiligte:</div>
                {!watch('peopleInvolvedInCrashDateEmpty2') && (
                  <div className={styles.information_block}>
                    <p>{watch('peopleInvolvedInCrashName2')}</p>
                    <p>{watch('peopleInvolvedInCrashAdress2')}</p>
                    {!!watch('peopleInvolvedInCrashPhone2') && (
                      <p>{`${watch('peopleInvolvedInCrashPhone2Code')} ${watch('peopleInvolvedInCrashPhone2')}`}</p>
                    )}
                  </div>
                )}
                {!!watch('peopleInvolvedInCrashDateEmpty2') && (
                  <div className={styles.information_block}>
                    <p>Daten über die beteiligte Person unbekannt</p>
                  </div>
                )}
                <div className={styles.information_block}>
                  {!!watch('peopleInvolvedInCrashPeopleDamage2') ? (
                    <p>Ja, diese Person wurde verletzt</p>
                  ) : (
                    <p>Nein, diese Person wurde nicht verletzt</p>
                  )}
                </div>

                {!!watch('peopleInvolvedInCrashPeopleInvolvedInsuranse2') && (
                  <div className={styles.information_block}>
                    <p>{watch('peopleInvolvedInCrashPeopleInvolvedInsuranseName2')}</p>
                    {!!watch('peopleInvolvedInCrashPeopleInvolvedInsuranseNumber2') && (
                      <p>{watch('peopleInvolvedInCrashPeopleInvolvedInsuranseNumber2')}</p>
                    )}
                  </div>
                )}

                {!!watch('peopleInvolvedInCrashCarOwnerIsDriver2') && (
                  <div className={styles.information_block}>
                    <p>{watch('peopleInvolvedInCrashCarOwnerIsDriverName2')}</p>
                    {!!watch('peopleInvolvedInCrashCarOwnerIsDriverAdress2') && (
                      <p>{watch('peopleInvolvedInCrashCarOwnerIsDriverAdress2')}</p>
                    )}
                    {!!watch('peopleInvolvedInCrashCarOwnerIsDriverPhone2') && (
                      <p>{`${watch('peopleInvolvedInCrashCarOwnerIsDriverPhone2Code')}${watch(
                        'peopleInvolvedInCrashCarOwnerIsDriverPhone2'
                      )}`}</p>
                    )}
                  </div>
                )}

                {watch('peopleInvolvedInCrashWhatHasDamage.Fahrzeug2') === 'Fahrzeug' &&
                  (!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichenEmpty2') ||
                    !!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichen2') ||
                    !!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugHersteller2') ||
                    !!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugTyp2')) && (
                    <>
                      <div className={styles.information_block}>
                        <p>Fahrzeug wurde beschädigt</p>
                      </div>
                      <div className={styles.information_block}>
                        {!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichenEmpty2') &&
                          !!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichen2') && (
                            <p>{watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichen2')}</p>
                          )}
                        {!!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugHersteller2') && (
                          <p>{watch('peopleInvolvedInCrashWhatHasDamageFahrzeugHersteller2')}</p>
                        )}
                        {!!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugTyp2') && (
                          <p>{watch('peopleInvolvedInCrashWhatHasDamageFahrzeugTyp2')}</p>
                        )}
                      </div>
                    </>
                  )}

                {watch('peopleInvolvedInCrashWhatHasDamage.Sonstiges2') === 'Sonstiges' &&
                  !!watch('peopleInvolvedInCrashWhatHasDamageSonstigesDamageDescription2') && (
                    <>
                      <div className={styles.information_block}>
                        <p>Sonstiges wurde beschädigt</p>
                      </div>
                      <div className={styles.information_block}>
                        <p>{watch('peopleInvolvedInCrashWhatHasDamageSonstigesDamageDescription2')}</p>
                      </div>
                    </>
                  )}
              </>
            )}

            {!!watch('peopleInvolvedInCrash') &&
              !!watch('peopleInvolvedInCrashAnotherPeopleInvolved') &&
              !!watch('peopleInvolvedInCrashAnotherPeopleInvolved2') && (
                <>
                  <div className={styles.small_header}>Dritte Beteiligte:</div>
                  {!watch('peopleInvolvedInCrashDateEmpty3') && (
                    <div className={styles.information_block}>
                      <p>{watch('peopleInvolvedInCrashName3')}</p>
                      <p>{watch('peopleInvolvedInCrashAdress3')}</p>
                      {!!watch('peopleInvolvedInCrashPhone3') && (
                        <p>{`${watch('peopleInvolvedInCrashPhone3Code')} ${watch('peopleInvolvedInCrashPhone3')}`}</p>
                      )}
                    </div>
                  )}
                  {!!watch('peopleInvolvedInCrashDateEmpty3') && (
                    <div className={styles.information_block}>
                      <p>Daten über die beteiligte Person unbekannt</p>
                    </div>
                  )}

                  <div className={styles.information_block}>
                    {!!watch('peopleInvolvedInCrashPeopleDamage3') ? (
                      <p>Ja, diese Person wurde verletzt</p>
                    ) : (
                      <p>Nein, diese Person wurde nicht verletzt</p>
                    )}
                  </div>

                  {!!watch('peopleInvolvedInCrashPeopleInvolvedInsuranse3') && (
                    <div className={styles.information_block}>
                      <p>{watch('peopleInvolvedInCrashPeopleInvolvedInsuranseName3')}</p>
                      {!!watch('peopleInvolvedInCrashPeopleInvolvedInsuranseNumber3') && (
                        <p>{watch('peopleInvolvedInCrashPeopleInvolvedInsuranseNumber3')}</p>
                      )}
                    </div>
                  )}

                  {!!watch('peopleInvolvedInCrashCarOwnerIsDriver3') && (
                    <div className={styles.information_block}>
                      <p>{watch('peopleInvolvedInCrashCarOwnerIsDriverName3')}</p>
                      {!!watch('peopleInvolvedInCrashCarOwnerIsDriverAdress3') && (
                        <p>{watch('peopleInvolvedInCrashCarOwnerIsDriverAdress3')}</p>
                      )}
                      {!!watch('peopleInvolvedInCrashCarOwnerIsDriverPhone3') && (
                        <p>{`${watch('peopleInvolvedInCrashCarOwnerIsDriverPhone3Code')}${watch(
                          'peopleInvolvedInCrashCarOwnerIsDriverPhone3'
                        )}`}</p>
                      )}
                    </div>
                  )}

                  {watch('peopleInvolvedInCrashWhatHasDamage.Fahrzeug3') === 'Fahrzeug' &&
                    (!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichenEmpty3') ||
                      !!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichen3') ||
                      !!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugHersteller3') ||
                      !!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugTyp3')) && (
                      <>
                        <div className={styles.information_block}>
                          <p>Fahrzeug wurde beschädigt</p>
                        </div>
                        <div className={styles.information_block}>
                          {!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichenEmpty3') &&
                            !!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichen3') && (
                              <p>{watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichen3')}</p>
                            )}
                          {!!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugHersteller3') && (
                            <p>{watch('peopleInvolvedInCrashWhatHasDamageFahrzeugHersteller3')}</p>
                          )}
                          {!!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugTyp3') && (
                            <p>{watch('peopleInvolvedInCrashWhatHasDamageFahrzeugTyp3')}</p>
                          )}
                        </div>
                      </>
                    )}

                  {watch('peopleInvolvedInCrashWhatHasDamage.Sonstiges3') === 'Sonstiges' &&
                    !!watch('peopleInvolvedInCrashWhatHasDamageSonstigesDamageDescription3') && (
                      <>
                        <div className={styles.information_block}>
                          <p>Sonstiges wurde beschädigt</p>
                        </div>
                        <div className={styles.information_block}>
                          <p>{watch('peopleInvolvedInCrashWhatHasDamageSonstigesDamageDescription3')}</p>
                        </div>
                      </>
                    )}
                </>
              )}
          </div>
        )}
      </>
    );
  };

  const GlassDetailBlockLeft = () => {
    return (
      <>
        <div className={styles.half_block} style={{ order: 1 }}>
          <div className={styles.block_name}>
            <h6>Daten zum Schaden</h6>
            <div id='returnToStepOneButton' className={styles.edit} onClick={() => returnToStep(1)}>
              <PencilIcon color={theme.colors.darkBlue} />
            </div>
          </div>

          <div className={styles.information_block}>
            <p>{watch('kennzeichen')}</p>
          </div>

          <div className={styles.information_block}>
            <span>
              <p className={styles.first_p}>{formatDate(watch('Schadendatum'))}</p>
              <p>{`${watch('Uhrzeit').hour}:${watch('Uhrzeit').minutes}`}</p>
            </span>
          </div>

          <div className={styles.information_block}>
            <p>{watch('Land')}</p>
            <span>
              <p className={styles.first_p}>{watch('Stadt')}</p>
              <p>{watch('strPLZ')}</p>
            </span>
            <p>{watch('Strasse')}</p>
          </div>

          {((watch('fuhrparkInfo') === null && watch('fahrerInfo') === null) || !!watch('schadenAlsAnderePerson')) && (
            <div className={styles.information_block}>
              {!!watch('anotherPersonFahrerName') && <p>{watch('anotherPersonFahrerName')}</p>}
              {!!watch('anotherPersonFahrerEmail') && <p>{watch('anotherPersonFahrerEmail')}</p>}
              {!!watch('anotherPersonFahrerTelefon') && (
                <p>{`${watch('anotherPersonFahrerTelefonCode')} ${watch('anotherPersonFahrerTelefon')}`}</p>
              )}
              {!!watch('anotherPersonFahrerTelefon2') && (
                <p>{`${watch('anotherPersonFahrerTelefon2Code')} ${watch('anotherPersonFahrerTelefon2')}`}</p>
              )}
            </div>
          )}

          {!!watch('schadenAlsFahrzeugnutzer') && (
            <div className={styles.information_block}>
              {!!watch('fahrerDataFullname') && <p>{watch('fahrerDataFullname')}</p>}
              {!!watch('fahrerDataEmailaddress1') && <p>{watch('fahrerDataEmailaddress1')}</p>}
              {!!watch('fahrerDataMobilephone') && (
                <p>{`${watch('fahrerDataMobilephoneCode')} ${watch('fahrerDataMobilephone')}`}</p>
              )}
              {!!watch('fahrerDataMobilephone1') && (
                <p>{`${watch('fahrerDataMobilephone1Code')} ${watch('fahrerDataMobilephone1')}`}</p>
              )}
            </div>
          )}

          <div className={styles.information_block}>
            <p>{watch('Postleitzahl')}</p>
            {(!!watch('Wunschtermin') || !!watch('AlternativerTermin')) && (
              <span>
                {!!watch('Wunschtermin') && <p className={styles.first_p}>{formatDate(watch('Wunschtermin'))}</p>}
                {!!watch('AlternativerTermin') && <p>{formatDate(watch('AlternativerTermin'))}</p>}
              </span>
            )}
          </div>

          <div className={styles.damagetype_block}>
            <span>{watch('damageType')}</span>
            <div id='openModalChangeTypeOfDamageButton' className={styles.edit} onClick={openModalOnClick}>
              <PencilIcon color={theme.colors.darkBlue} />
            </div>
          </div>
        </div>

        {!!watch('needPolice') && (
          <div className={styles.half_block} style={{ order: 3 }}>
            <div className={styles.block_name}>
              <h6>Polizeiliche Unfalldaten</h6>
              <div id='stepFlowDamageTwoButton' className={styles.edit} onClick={() => stepFlowDamage(2)}>
                <PencilIcon color={theme.colors.darkBlue} />
              </div>
            </div>

            {!!watch('needPolice') && (
              <>
                <div className={styles.information_block}>
                  {!!watch('needPoliceDienststelle') && <p>{watch('needPoliceDienststelle')}</p>}
                  {!!watch('needPoliceAdressAndOffice') && <p>{watch('needPoliceAdressAndOffice')}</p>}
                </div>

                {!!watch('needPoliceGetCaseNumber') && (
                  <div className={styles.information_block}>
                    {!!watch('needPoliceGetCaseNumberAktenzeichen') && (
                      <p>{watch('needPoliceGetCaseNumberAktenzeichen')}</p>
                    )}
                  </div>
                )}

                {!!watch('needPoliceCriminalWarning') && (
                  <div className={styles.information_block}>
                    {!!watch('needPoliceCriminalWarningWho') && <p>{watch('needPoliceCriminalWarningWho')}</p>}
                  </div>
                )}

                {(!!watch('needPoliceDrunkDriverWasTested') || !!watch('needPoliceDrunkDrugsTest')) && (
                  <>
                    <div className={styles.information_block}>
                      {!!watch('needPoliceDrunkDriverWasTested') &&
                        !!watch('needPoliceCriminalWarningWhoFirstPersonName') && (
                          <p>{watch('needPoliceCriminalWarningWhoFirstPersonName')}</p>
                        )}
                      {!!watch('needPoliceDrunkDriverWasTested') &&
                        !!watch('needPoliceCriminalWarningWhoFirstPersonDrunkLevel') && (
                          <p>{watch('needPoliceCriminalWarningWhoFirstPersonDrunkLevel')}</p>
                        )}
                      {!!watch('showSecondDrunkPerson') &&
                        !!watch('needPoliceDrunkDriverWasTested') &&
                        !!watch('needPoliceCriminalWarningWhoSecondPersonName') && (
                          <p>{watch('needPoliceCriminalWarningWhoSecondPersonName')}</p>
                        )}
                      {!!watch('showSecondDrunkPerson') &&
                        !!watch('needPoliceDrunkDriverWasTested') &&
                        !!watch('needPoliceCriminalWarningWhoSecondPersonDrunkLevel') && (
                          <p>{watch('needPoliceCriminalWarningWhoSecondPersonDrunkLevel')}</p>
                        )}
                    </div>

                    <div className={styles.information_block}>
                      {!!watch('needPoliceDrunkDrugsTest') && !!watch('needPoliceDrunkDrugsTestWhoWasTestedFirst') && (
                        <p>{watch('needPoliceDrunkDrugsTestWhoWasTestedFirst')}</p>
                      )}
                      {!!watch('needPoliceDrunkDrugsTest') && !!watch('needPoliceDrunkDrugsTestResultFirst') && (
                        <p>{watch('needPoliceDrunkDrugsTestResultFirst')}</p>
                      )}
                      {!!watch('showSecondDrugPerson') &&
                        !!watch('needPoliceDrunkDrugsTest') &&
                        !!watch('needPoliceDrunkDrugsTestWhoWasTestedSecond') && (
                          <p>{watch('needPoliceDrunkDrugsTestWhoWasTestedSecond')}</p>
                        )}
                      {!!watch('showSecondDrugPerson') &&
                        !!watch('needPoliceDrunkDrugsTest') &&
                        !!watch('needPoliceDrunkDrugsTestResultSecond') && (
                          <p>{watch('needPoliceDrunkDrugsTestResultSecond')}</p>
                        )}
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        )}

        {((!!watch('FotoSchaden')?.files && watch('FotoSchaden')?.files.length > 0) ||
          (!!watch('Skizze')?.files && watch('Skizze')?.files.length > 0) ||
          (!!watch('SonstigeUnterlagenzurSchadendokumentation')?.files &&
            watch('SonstigeUnterlagenzurSchadendokumentation')?.files.length > 0) ||
          (!!watch('polizei')?.files && watch('polizei')?.files.length > 0)) && (
          <div className={styles.half_block} style={{ order: 10 }}>
            <div className={styles.block_name}>
              <h6>Die Dateien</h6>
              <div id='stepFlowDamageFourButton' className={styles.edit} onClick={() => stepFlowDamage(4)}>
                <PencilIcon color={theme.colors.darkBlue} />
              </div>
            </div>

            {!!watch('FotoSchaden')?.files &&
              Array.isArray(watch('FotoSchaden')?.files) &&
              watch('FotoSchaden')?.files.length > 0 &&
              watch('FotoSchaden')?.files.map((el: FileData) => (
                <div key={el?.path}>
                  <File file={el} />
                </div>
              ))}

            {!!watch('Skizze')?.files &&
              Array.isArray(watch('Skizze')?.files) &&
              watch('Skizze')?.files.length > 0 &&
              watch('Skizze')?.files.map((el: FileData) => (
                <div key={el?.path}>
                  <File file={el} />
                </div>
              ))}

            {!!watch('SonstigeUnterlagenzurSchadendokumentation')?.files &&
              Array.isArray(watch('SonstigeUnterlagenzurSchadendokumentation')?.files) &&
              watch('SonstigeUnterlagenzurSchadendokumentation')?.files.length > 0 &&
              watch('SonstigeUnterlagenzurSchadendokumentation')?.files.map((el: FileData) => (
                <div key={el?.path}>
                  <File file={el} />
                </div>
              ))}

            {!!watch('polizei')?.files &&
              Array.isArray(watch('polizei')?.files) &&
              watch('polizei')?.files.length > 0 &&
              watch('polizei')?.files.map((el: FileData) => (
                <div key={el?.path}>
                  <File file={el} />
                </div>
              ))}
          </div>
        )}
      </>
    );
  };

  const GlassDetailBlockRight = () => {
    return (
      <>
        <div className={styles.half_block} style={{ order: 2 }}>
          <div className={styles.block_name}>
            <h6>Allgemeines zum Schaden</h6>
            <div id='stepFlowDamageTwoButton' className={styles.edit} onClick={() => stepFlowDamage(2)}>
              <PencilIcon color={theme.colors.darkBlue} />
            </div>
          </div>

          {!!watch('hasDamage') && (
            <div className={styles.information_block}>
              {!!watch('damageParts') &&
                Array.isArray(watch('damageParts')) &&
                watch('damageParts').length > 0 &&
                watch('damageParts').map((item: OtherDamagePartsItemProps, index: number) => (
                  <span key={index}>
                    <p className={styles.first_p}>{item[0]}</p>
                    <p>&#8212;</p>
                    <p className={styles.last_p}>{item[1]?.name}</p>
                  </span>
                ))}
            </div>
          )}

          {watch('damagePrivatOrBusinessTrip') !== undefined && (
            <div className={styles.information_block}>
              {!!watch('damagePrivatOrBusinessTrip') && <p>Der Schaden entstand bei einer Privatfahrt</p>}
              {!watch('damagePrivatOrBusinessTrip') && <p>Der Schaden entstand bei einer Dienstfahrt</p>}
            </div>
          )}

          {watch('autoIsOkay') === false && (
            <div className={styles.information_block}>
              {!!watch('autoIsNotOkayFirma') && <p>{watch('autoIsNotOkayFirma')}</p>}
              {!!watch('autoIsNotOkayAdress') && <p>{watch('autoIsNotOkayAdress')}</p>}
              {!!watch('autoIsNotOkayTelephone') && (
                <p>{`${watch('autoIsNotOkayTelephoneCode')} ${watch('autoIsNotOkayTelephone')}`}</p>
              )}
              {!!watch('autoIsNotOkayPLZ') && <p>{watch('autoIsNotOkayPLZ')}</p>}
              {!!watch('autoIsNotOkayStadt') && <p>{watch('autoIsNotOkayStadt')}</p>}
              {!!watch('autoIsNotOkayStreet') && <p>{watch('autoIsNotOkayStreet')}</p>}
            </div>
          )}
        </div>

        <div className={styles.half_block} style={{ order: 4 }}>
          <div className={styles.block_name}>
            <h6>Fahrerdaten und Schadenschilderung</h6>
            <div id='stepFlowDamageThreeButton' className={styles.edit} onClick={() => stepFlowDamage(3)}>
              <PencilIcon color={theme.colors.darkBlue} />
            </div>
          </div>

          {!!watch('doYouHaveLicense') && (
            <div className={styles.information_block}>
              {!!watch('doYouHaveLicenseIssuingAuthority') && <p>{watch('doYouHaveLicenseIssuingAuthority')}</p>}
              {!!watch('doYouHaveLicenseLicenseNumber') && <p>{watch('doYouHaveLicenseLicenseNumber')}</p>}
              {(!!watch('doYouHaveLicenseDateIssue') || !!watch('doYouHaveLicenseClass')) && (
                <span>
                  {!!watch('doYouHaveLicenseDateIssue') && (
                    <p className={styles.first_p}>{formatDate(watch('doYouHaveLicenseDateIssue'))}</p>
                  )}
                  {!!watch('doYouHaveLicenseClass') && <p>{watch('doYouHaveLicenseClass')}</p>}
                </span>
              )}
            </div>
          )}

          {!!watch('detailDamageComment') && (
            <div className={styles.information_block}>
              <p>{watch('detailDamageComment')}</p>
            </div>
          )}
        </div>

        {!!watch('peopleInvolvedInCrash') && (
          <div className={styles.half_block} style={{ order: 6 }}>
            <div className={styles.block_name}>
              <h6>Unfallbeteiligte</h6>
              <div id='stepFlowDamageThreeButton' className={styles.edit} onClick={() => stepFlowDamage(3)}>
                <PencilIcon color={theme.colors.darkBlue} />
              </div>
            </div>
            <div className={styles.small_header}>Erste Beteiligte:</div>
            {!watch('peopleInvolvedInCrashDateEmpty') && (
              <div className={styles.information_block}>
                <p>{watch('peopleInvolvedInCrashName')}</p>
                <p>{watch('peopleInvolvedInCrashAdress')}</p>
                {!!watch('peopleInvolvedInCrashPhone') && (
                  <p>{`${watch('peopleInvolvedInCrashPhoneCode')} ${watch('peopleInvolvedInCrashPhone')}`}</p>
                )}
              </div>
            )}
            {!!watch('peopleInvolvedInCrashDateEmpty') && (
              <div className={styles.information_block}>
                <p>Daten über die beteiligte Person unbekannt</p>
              </div>
            )}
            <div className={styles.information_block}>
              {!!watch('peopleInvolvedInCrashPeopleDamage') ? (
                <p>Ja, diese Person wurde verletzt</p>
              ) : (
                <p>Nein, diese Person wurde nicht verletzt</p>
              )}
            </div>
            {!!watch('peopleInvolvedInCrashPeopleInvolvedInsuranse') && (
              <div className={styles.information_block}>
                <p>{watch('peopleInvolvedInCrashPeopleInvolvedInsuranseName')}</p>
                {!!watch('peopleInvolvedInCrashPeopleInvolvedInsuranseNumber') && (
                  <p>{watch('peopleInvolvedInCrashPeopleInvolvedInsuranseNumber')}</p>
                )}
              </div>
            )}
            {!!watch('peopleInvolvedInCrashCarOwnerIsDriver') && (
              <div className={styles.information_block}>
                <p>{watch('peopleInvolvedInCrashCarOwnerIsDriverName')}</p>
                {!!watch('peopleInvolvedInCrashCarOwnerIsDriverAdress') && (
                  <p>{watch('peopleInvolvedInCrashCarOwnerIsDriverAdress')}</p>
                )}
                {!!watch('peopleInvolvedInCrashCarOwnerIsDriverPhone') && (
                  <p>{`${watch('peopleInvolvedInCrashCarOwnerIsDriverPhoneCode')}${watch(
                    'peopleInvolvedInCrashCarOwnerIsDriverPhone'
                  )}`}</p>
                )}
              </div>
            )}
            {watch('peopleInvolvedInCrashWhatHasDamage.Fahrzeug') === 'Fahrzeug' &&
              (!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichenEmpty') ||
                !!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichen') ||
                !!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugHersteller') ||
                !!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugTyp')) && (
                <>
                  <div className={styles.information_block}>
                    <p>Fahrzeug wurde beschädigt</p>
                  </div>
                  <div className={styles.information_block}>
                    {!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichenEmpty') &&
                      !!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichen') && (
                        <p>{watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichen')}</p>
                      )}
                    {!!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugHersteller') && (
                      <p>{watch('peopleInvolvedInCrashWhatHasDamageFahrzeugHersteller')}</p>
                    )}
                    {!!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugTyp') && (
                      <p>{watch('peopleInvolvedInCrashWhatHasDamageFahrzeugTyp')}</p>
                    )}
                  </div>
                </>
              )}
            {watch('peopleInvolvedInCrashWhatHasDamage.Sonstiges') === 'Sonstiges' &&
              !!watch('peopleInvolvedInCrashWhatHasDamageSonstigesDamageDescription') && (
                <>
                  <div className={styles.information_block}>
                    <p>Sonstiges wurde beschädigt</p>
                  </div>
                  <div className={styles.information_block}>
                    <p>{watch('peopleInvolvedInCrashWhatHasDamageSonstigesDamageDescription')}</p>
                  </div>
                </>
              )}

            {!!watch('peopleInvolvedInCrash') && !!watch('peopleInvolvedInCrashAnotherPeopleInvolved') && (
              <>
                <div className={styles.small_header}>Zweite Beteiligte:</div>
                {!watch('peopleInvolvedInCrashDateEmpty2') && (
                  <div className={styles.information_block}>
                    <p>{watch('peopleInvolvedInCrashName2')}</p>
                    <p>{watch('peopleInvolvedInCrashAdress2')}</p>
                    {!!watch('peopleInvolvedInCrashPhone2') && (
                      <p>{`${watch('peopleInvolvedInCrashPhone2Code')} ${watch('peopleInvolvedInCrashPhone2')}`}</p>
                    )}
                  </div>
                )}
                {!!watch('peopleInvolvedInCrashDateEmpty2') && (
                  <div className={styles.information_block}>
                    <p>Daten über die beteiligte Person unbekannt</p>
                  </div>
                )}
                <div className={styles.information_block}>
                  {!!watch('peopleInvolvedInCrashPeopleDamage2') ? (
                    <p>Ja, diese Person wurde verletzt</p>
                  ) : (
                    <p>Nein, diese Person wurde nicht verletzt</p>
                  )}
                </div>

                {!!watch('peopleInvolvedInCrashPeopleInvolvedInsuranse2') && (
                  <div className={styles.information_block}>
                    <p>{watch('peopleInvolvedInCrashPeopleInvolvedInsuranseName2')}</p>
                    {!!watch('peopleInvolvedInCrashPeopleInvolvedInsuranseNumber2') && (
                      <p>{watch('peopleInvolvedInCrashPeopleInvolvedInsuranseNumber2')}</p>
                    )}
                  </div>
                )}

                {!!watch('peopleInvolvedInCrashCarOwnerIsDriver2') && (
                  <div className={styles.information_block}>
                    <p>{watch('peopleInvolvedInCrashCarOwnerIsDriverName2')}</p>
                    {!!watch('peopleInvolvedInCrashCarOwnerIsDriverAdress2') && (
                      <p>{watch('peopleInvolvedInCrashCarOwnerIsDriverAdress2')}</p>
                    )}
                    {!!watch('peopleInvolvedInCrashCarOwnerIsDriverPhone2') && (
                      <p>{`${watch('peopleInvolvedInCrashCarOwnerIsDriverPhone2Code')}${watch(
                        'peopleInvolvedInCrashCarOwnerIsDriverPhone2'
                      )}`}</p>
                    )}
                  </div>
                )}

                {watch('peopleInvolvedInCrashWhatHasDamage.Fahrzeug2') === 'Fahrzeug' &&
                  (!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichenEmpty2') ||
                    !!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichen2') ||
                    !!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugHersteller2') ||
                    !!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugTyp2')) && (
                    <>
                      <div className={styles.information_block}>
                        <p>Fahrzeug wurde beschädigt</p>
                      </div>
                      <div className={styles.information_block}>
                        {!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichenEmpty2') &&
                          !!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichen2') && (
                            <p>{watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichen2')}</p>
                          )}
                        {!!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugHersteller2') && (
                          <p>{watch('peopleInvolvedInCrashWhatHasDamageFahrzeugHersteller2')}</p>
                        )}
                        {!!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugTyp2') && (
                          <p>{watch('peopleInvolvedInCrashWhatHasDamageFahrzeugTyp2')}</p>
                        )}
                      </div>
                    </>
                  )}

                {watch('peopleInvolvedInCrashWhatHasDamage.Sonstiges2') === 'Sonstiges' &&
                  !!watch('peopleInvolvedInCrashWhatHasDamageSonstigesDamageDescription2') && (
                    <>
                      <div className={styles.information_block}>
                        <p>Sonstiges wurde beschädigt</p>
                      </div>
                      <div className={styles.information_block}>
                        <p>{watch('peopleInvolvedInCrashWhatHasDamageSonstigesDamageDescription2')}</p>
                      </div>
                    </>
                  )}
              </>
            )}

            {!!watch('peopleInvolvedInCrash') &&
              !!watch('peopleInvolvedInCrashAnotherPeopleInvolved') &&
              !!watch('peopleInvolvedInCrashAnotherPeopleInvolved2') && (
                <>
                  <div className={styles.small_header}>Dritte Beteiligte:</div>
                  {!watch('peopleInvolvedInCrashDateEmpty3') && (
                    <div className={styles.information_block}>
                      <p>{watch('peopleInvolvedInCrashName3')}</p>
                      <p>{watch('peopleInvolvedInCrashAdress3')}</p>
                      {!!watch('peopleInvolvedInCrashPhone3') && (
                        <p>{`${watch('peopleInvolvedInCrashPhone3Code')} ${watch('peopleInvolvedInCrashPhone3')}`}</p>
                      )}
                    </div>
                  )}
                  {!!watch('peopleInvolvedInCrashDateEmpty3') && (
                    <div className={styles.information_block}>
                      <p>Daten über die beteiligte Person unbekannt</p>
                    </div>
                  )}

                  <div className={styles.information_block}>
                    {!!watch('peopleInvolvedInCrashPeopleDamage3') ? (
                      <p>Ja, diese Person wurde verletzt</p>
                    ) : (
                      <p>Nein, diese Person wurde nicht verletzt</p>
                    )}
                  </div>

                  {!!watch('peopleInvolvedInCrashPeopleInvolvedInsuranse3') && (
                    <div className={styles.information_block}>
                      <p>{watch('peopleInvolvedInCrashPeopleInvolvedInsuranseName3')}</p>
                      {!!watch('peopleInvolvedInCrashPeopleInvolvedInsuranseNumber3') && (
                        <p>{watch('peopleInvolvedInCrashPeopleInvolvedInsuranseNumber3')}</p>
                      )}
                    </div>
                  )}

                  {!!watch('peopleInvolvedInCrashCarOwnerIsDriver3') && (
                    <div className={styles.information_block}>
                      <p>{watch('peopleInvolvedInCrashCarOwnerIsDriverName3')}</p>
                      {!!watch('peopleInvolvedInCrashCarOwnerIsDriverAdress3') && (
                        <p>{watch('peopleInvolvedInCrashCarOwnerIsDriverAdress3')}</p>
                      )}
                      {!!watch('peopleInvolvedInCrashCarOwnerIsDriverPhone3') && (
                        <p>{`${watch('peopleInvolvedInCrashCarOwnerIsDriverPhone3Code')}${watch(
                          'peopleInvolvedInCrashCarOwnerIsDriverPhone3'
                        )}`}</p>
                      )}
                    </div>
                  )}

                  {watch('peopleInvolvedInCrashWhatHasDamage.Fahrzeug3') === 'Fahrzeug' &&
                    (!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichenEmpty3') ||
                      !!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichen3') ||
                      !!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugHersteller3') ||
                      !!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugTyp3')) && (
                      <>
                        <div className={styles.information_block}>
                          <p>Fahrzeug wurde beschädigt</p>
                        </div>
                        <div className={styles.information_block}>
                          {!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichenEmpty3') &&
                            !!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichen3') && (
                              <p>{watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichen3')}</p>
                            )}
                          {!!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugHersteller3') && (
                            <p>{watch('peopleInvolvedInCrashWhatHasDamageFahrzeugHersteller3')}</p>
                          )}
                          {!!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugTyp3') && (
                            <p>{watch('peopleInvolvedInCrashWhatHasDamageFahrzeugTyp3')}</p>
                          )}
                        </div>
                      </>
                    )}

                  {watch('peopleInvolvedInCrashWhatHasDamage.Sonstiges3') === 'Sonstiges' &&
                    !!watch('peopleInvolvedInCrashWhatHasDamageSonstigesDamageDescription3') && (
                      <>
                        <div className={styles.information_block}>
                          <p>Sonstiges wurde beschädigt</p>
                        </div>
                        <div className={styles.information_block}>
                          <p>{watch('peopleInvolvedInCrashWhatHasDamageSonstigesDamageDescription3')}</p>
                        </div>
                      </>
                    )}
                </>
              )}
          </div>
        )}
      </>
    );
  };

  const MartenDetailBlockLeft = () => {
    return (
      <>
        <div className={styles.half_block} style={{ order: 1 }}>
          <div className={styles.block_name}>
            <h6>Daten zum Schaden</h6>
            <div id='stepFlowDamageFiveButton' className={styles.edit} onClick={() => returnToStep(1)}>
              <PencilIcon color={theme.colors.darkBlue} />
            </div>
          </div>

          <div className={styles.information_block}>
            <p>{watch('kennzeichen')}</p>
          </div>

          <div className={styles.information_block}>
            <span>
              <p className={styles.first_p}>{formatDate(watch('Schadendatum'))}</p>
              <p>{`${watch('Uhrzeit').hour}:${watch('Uhrzeit').minutes}`}</p>
            </span>
          </div>

          <div className={styles.information_block}>
            <p>{watch('Land')}</p>
            <span>
              <p className={styles.first_p}>{watch('Stadt')}</p>
              <p>{watch('strPLZ')}</p>
            </span>
            <p>{watch('Strasse')}</p>
          </div>

          {((watch('fuhrparkInfo') === null && watch('fahrerInfo') === null) || !!watch('schadenAlsAnderePerson')) && (
            <div className={styles.information_block}>
              {!!watch('anotherPersonFahrerName') && <p>{watch('anotherPersonFahrerName')}</p>}
              {!!watch('anotherPersonFahrerEmail') && <p>{watch('anotherPersonFahrerEmail')}</p>}
              {!!watch('anotherPersonFahrerTelefon') && (
                <p>{`${watch('anotherPersonFahrerTelefonCode')} ${watch('anotherPersonFahrerTelefon')}`}</p>
              )}
              {!!watch('anotherPersonFahrerTelefon2') && (
                <p>{`${watch('anotherPersonFahrerTelefon2Code')} ${watch('anotherPersonFahrerTelefon2')}`}</p>
              )}
            </div>
          )}

          {!!watch('schadenAlsFahrzeugnutzer') && (
            <div className={styles.information_block}>
              {!!watch('fahrerDataFullname') && <p>{watch('fahrerDataFullname')}</p>}
              {!!watch('fahrerDataEmailaddress1') && <p>{watch('fahrerDataEmailaddress1')}</p>}
              {!!watch('fahrerDataMobilephone') && (
                <p>{`${watch('fahrerDataMobilephoneCode')} ${watch('fahrerDataMobilephone')}`}</p>
              )}
              {!!watch('fahrerDataMobilephone1') && (
                <p>{`${watch('fahrerDataMobilephone1Code')} ${watch('fahrerDataMobilephone1')}`}</p>
              )}
            </div>
          )}

          <div className={styles.information_block}>
            <p>{watch('Postleitzahl')}</p>
            {(!!watch('Wunschtermin') || !!watch('AlternativerTermin')) && (
              <span>
                {!!watch('Wunschtermin') && <p className={styles.first_p}>{formatDate(watch('Wunschtermin'))}</p>}
                {!!watch('AlternativerTermin') && <p>{formatDate(watch('AlternativerTermin'))}</p>}
              </span>
            )}
          </div>

          <div className={styles.damagetype_block}>
            <span>{watch('damageType')}</span>
            <div id='openModalChangeTypeOfDamageButton' className={styles.edit} onClick={openModalOnClick}>
              <PencilIcon color={theme.colors.darkBlue} />
            </div>
          </div>
        </div>
      </>
    );
  };

  const MartenDetailBlockRight = () => {
    return (
      <>
        <div className={styles.half_block} style={{ order: 2 }}>
          <div className={styles.block_name}>
            <h6>Allgemeines zum Schaden</h6>
            <div id='stepFlowDamageTwoButton' className={styles.edit} onClick={() => stepFlowDamage(2)}>
              <PencilIcon color={theme.colors.darkBlue} />
            </div>
          </div>

          {watch('damagePrivatOrBusinessTrip') !== undefined && (
            <div className={styles.information_block}>
              {!!watch('damagePrivatOrBusinessTrip') && <p>Der Schaden entstand bei einer Privatfahrt</p>}
              {!watch('damagePrivatOrBusinessTrip') && <p>Der Schaden entstand bei einer Dienstfahrt</p>}
            </div>
          )}

          {watch('autoIsOkay') === false && (
            <div className={styles.information_block}>
              {!!watch('autoIsNotOkayFirma') && <p>{watch('autoIsNotOkayFirma')}</p>}
              {!!watch('autoIsNotOkayAdress') && <p>{watch('autoIsNotOkayAdress')}</p>}
              {!!watch('autoIsNotOkayTelephone') && (
                <p>{`${watch('autoIsNotOkayTelephoneCode')} ${watch('autoIsNotOkayTelephone')}`}</p>
              )}
              {!!watch('autoIsNotOkayPLZ') && <p>{watch('autoIsNotOkayPLZ')}</p>}
              {!!watch('autoIsNotOkayStadt') && <p>{watch('autoIsNotOkayStadt')}</p>}
              {!!watch('autoIsNotOkayStreet') && <p>{watch('autoIsNotOkayStreet')}</p>}
            </div>
          )}

          {!!watch('detailDamageComment') && (
            <div className={styles.information_block}>
              <p>{watch('detailDamageComment')}</p>
            </div>
          )}
        </div>

        {((!!watch('FotoSchaden')?.files && watch('FotoSchaden')?.files.length > 0) ||
          (!!watch('Skizze')?.files && watch('Skizze')?.files.length > 0) ||
          (!!watch('SonstigeUnterlagenzurSchadendokumentation')?.files &&
            watch('SonstigeUnterlagenzurSchadendokumentation')?.files.length > 0) ||
          (!!watch('polizei')?.files && watch('polizei')?.files.length > 0)) && (
          <div className={styles.half_block} style={{ order: 10 }}>
            <div className={styles.block_name}>
              <h6>Die Dateien</h6>
              <div id='stepFlowDamageThreeButton' className={styles.edit} onClick={() => stepFlowDamage(3)}>
                <PencilIcon color={theme.colors.darkBlue} />
              </div>
            </div>

            {!!watch('FotoSchaden')?.files &&
              Array.isArray(watch('FotoSchaden')?.files) &&
              watch('FotoSchaden')?.files.length > 0 &&
              watch('FotoSchaden')?.files.map((el: FileData) => (
                <div key={el?.path}>
                  <File file={el} />
                </div>
              ))}

            {!!watch('Skizze')?.files &&
              Array.isArray(watch('Skizze')?.files) &&
              watch('Skizze')?.files.length > 0 &&
              watch('Skizze')?.files.map((el: FileData) => (
                <div key={el?.path}>
                  <File file={el} />
                </div>
              ))}

            {!!watch('SonstigeUnterlagenzurSchadendokumentation')?.files &&
              Array.isArray(watch('SonstigeUnterlagenzurSchadendokumentation')?.files) &&
              watch('SonstigeUnterlagenzurSchadendokumentation')?.files.length > 0 &&
              watch('SonstigeUnterlagenzurSchadendokumentation')?.files.map((el: FileData) => (
                <div key={el?.path}>
                  <File file={el} />
                </div>
              ))}

            {!!watch('polizei')?.files &&
              Array.isArray(watch('polizei')?.files) &&
              watch('polizei')?.files.length > 0 &&
              watch('polizei')?.files.map((el: FileData) => (
                <div key={el?.path}>
                  <File file={el} />
                </div>
              ))}
          </div>
        )}
      </>
    );
  };

  const ThiefDetailBlockLeft = () => {
    return (
      <>
        <div className={styles.half_block} style={{ order: 1 }}>
          <div className={styles.block_name}>
            <h6>Daten zum Schaden</h6>
            <div id='returnToStepOneButton' className={styles.edit} onClick={() => returnToStep(1)}>
              <PencilIcon color={theme.colors.darkBlue} />
            </div>
          </div>

          <div className={styles.information_block}>
            <p>{watch('kennzeichen')}</p>
          </div>

          <div className={styles.information_block}>
            <span>
              <p className={styles.first_p}>{formatDate(watch('Schadendatum'))}</p>
              <p>{`${watch('Uhrzeit').hour}:${watch('Uhrzeit').minutes}`}</p>
            </span>
          </div>

          <div className={styles.information_block}>
            <p>{watch('Land')}</p>
            <span>
              <p className={styles.first_p}>{watch('Stadt')}</p>
              <p>{watch('strPLZ')}</p>
            </span>
            <p>{watch('Strasse')}</p>
          </div>

          {((watch('fuhrparkInfo') === null && watch('fahrerInfo') === null) || !!watch('schadenAlsAnderePerson')) && (
            <div className={styles.information_block}>
              {!!watch('anotherPersonFahrerName') && <p>{watch('anotherPersonFahrerName')}</p>}
              {!!watch('anotherPersonFahrerEmail') && <p>{watch('anotherPersonFahrerEmail')}</p>}
              {!!watch('anotherPersonFahrerTelefon') && (
                <p>{`${watch('anotherPersonFahrerTelefonCode')} ${watch('anotherPersonFahrerTelefon')}`}</p>
              )}
              {!!watch('anotherPersonFahrerTelefon2') && (
                <p>{`${watch('anotherPersonFahrerTelefon2Code')} ${watch('anotherPersonFahrerTelefon2')}`}</p>
              )}
            </div>
          )}

          {!!watch('schadenAlsFahrzeugnutzer') && (
            <div className={styles.information_block}>
              {!!watch('fahrerDataFullname') && <p>{watch('fahrerDataFullname')}</p>}
              {!!watch('fahrerDataEmailaddress1') && <p>{watch('fahrerDataEmailaddress1')}</p>}
              {!!watch('fahrerDataMobilephone') && (
                <p>{`${watch('fahrerDataMobilephoneCode')} ${watch('fahrerDataMobilephone')}`}</p>
              )}
              {!!watch('fahrerDataMobilephone1') && (
                <p>{`${watch('fahrerDataMobilephone1Code')} ${watch('fahrerDataMobilephone1')}`}</p>
              )}
            </div>
          )}

          <div className={styles.information_block}>
            <p>{watch('Postleitzahl')}</p>
            {(!!watch('Wunschtermin') || !!watch('AlternativerTermin')) && (
              <span>
                {!!watch('Wunschtermin') && <p className={styles.first_p}>{formatDate(watch('Wunschtermin'))}</p>}
                {!!watch('AlternativerTermin') && <p>{formatDate(watch('AlternativerTermin'))}</p>}
              </span>
            )}
          </div>

          <div className={styles.damagetype_block}>
            <span>{watch('damageType')}</span>
            <div id='openModalChangeTypeOfDamageButton' className={styles.edit} onClick={openModalOnClick}>
              <PencilIcon color={theme.colors.darkBlue} />
            </div>
          </div>
        </div>

        {!!watch('needPolice') && (
          <div className={styles.half_block} style={{ order: 3 }}>
            <div className={styles.block_name}>
              <h6>Polizeiliche Unfalldaten</h6>
              <div id='stepFlowDamageTwoButton' className={styles.edit} onClick={() => stepFlowDamage(2)}>
                <PencilIcon color={theme.colors.darkBlue} />
              </div>
            </div>

            {!!watch('needPolice') && (
              <>
                <div className={styles.information_block}>
                  {!!watch('needPoliceDienststelle') && <p>{watch('needPoliceDienststelle')}</p>}
                  {!!watch('needPoliceAdressAndOffice') && <p>{watch('needPoliceAdressAndOffice')}</p>}
                </div>

                {!!watch('needPoliceGetCaseNumber') && !!watch('needPoliceGetCaseNumberAktenzeichen') && (
                  <div className={styles.information_block}>
                    <p>{watch('needPoliceGetCaseNumberAktenzeichen')}</p>
                  </div>
                )}

                {!!watch('needPoliceCriminalWarning') && !!watch('needPoliceCriminalWarningWho') && (
                  <div className={styles.information_block}>
                    <p>{watch('needPoliceCriminalWarningWho')}</p>
                  </div>
                )}
              </>
            )}
          </div>
        )}

        {((!!watch('FotoSchaden')?.files && watch('FotoSchaden')?.files.length > 0) ||
          (!!watch('Skizze')?.files && watch('Skizze')?.files.length > 0) ||
          (!!watch('SonstigeUnterlagenzurSchadendokumentation')?.files &&
            watch('SonstigeUnterlagenzurSchadendokumentation')?.files.length > 0) ||
          (!!watch('polizei')?.files && watch('polizei')?.files.length > 0)) && (
          <div className={styles.half_block} style={{ order: 10 }}>
            <div className={styles.block_name}>
              <h6>Die Dateien</h6>
              <div id='stepFlowDamageThreeButton' className={styles.edit} onClick={() => stepFlowDamage(3)}>
                <PencilIcon color={theme.colors.darkBlue} />
              </div>
            </div>

            {!!watch('FotoSchaden')?.files &&
              Array.isArray(watch('FotoSchaden')?.files) &&
              watch('FotoSchaden')?.files.length > 0 &&
              watch('FotoSchaden')?.files.map((el: FileData) => (
                <div key={el?.path}>
                  <File file={el} />
                </div>
              ))}

            {!!watch('Skizze')?.files &&
              Array.isArray(watch('Skizze')?.files) &&
              watch('Skizze')?.files.length > 0 &&
              watch('Skizze')?.files.map((el: FileData) => (
                <div key={el?.path}>
                  <File file={el} />
                </div>
              ))}

            {!!watch('SonstigeUnterlagenzurSchadendokumentation')?.files &&
              Array.isArray(watch('SonstigeUnterlagenzurSchadendokumentation')?.files) &&
              watch('SonstigeUnterlagenzurSchadendokumentation')?.files.length > 0 &&
              watch('SonstigeUnterlagenzurSchadendokumentation')?.files.map((el: FileData) => (
                <div key={el?.path}>
                  <File file={el} />
                </div>
              ))}

            {!!watch('polizei')?.files &&
              Array.isArray(watch('polizei')?.files) &&
              watch('polizei')?.files.length > 0 &&
              watch('polizei')?.files.map((el: FileData) => (
                <div key={el?.path}>
                  <File file={el} />
                </div>
              ))}
          </div>
        )}
      </>
    );
  };

  const ThiefDetailBlockRight = () => {
    return (
      <>
        <div className={styles.half_block} style={{ order: 2 }}>
          <div className={styles.block_name}>
            <h6>Allgemeines zum Schaden</h6>
            <div id='stepFlowDamageTwoButton' className={styles.edit} onClick={() => stepFlowDamage(2)}>
              <PencilIcon color={theme.colors.darkBlue} />
            </div>
          </div>

          {!!watch('anyEvidence') && !!watch('anyEvidenceClues') && (
            <div className={styles.information_block}>
              <p>{watch('anyEvidenceClues')}</p>
            </div>
          )}

          {watch('damagePrivatOrBusinessTrip') !== undefined && (
            <div className={styles.information_block}>
              {!!watch('damagePrivatOrBusinessTrip') && <p>Der Schaden entstand bei einer Privatfahrt</p>}
              {!watch('damagePrivatOrBusinessTrip') && <p>Der Schaden entstand bei einer Dienstfahrt</p>}
            </div>
          )}

          {!!watch('howToVehicleWasSecured') && (
            <div className={styles.information_block}>
              <p>{watch('howToVehicleWasSecured')}</p>
            </div>
          )}

          {watch('youHaveAllKeys') === false && !!watch('whereIsKeys') && (
            <div className={styles.information_block}>
              <p>{watch('whereIsKeys')}</p>
            </div>
          )}

          {!!watch('detailDamageComment') && (
            <div className={styles.information_block}>
              <p>{watch('detailDamageComment')}</p>
            </div>
          )}
        </div>

        {!!watch('anyWitnesses') && (
          <div className={styles.half_block} style={{ order: 4 }}>
            <div className={styles.block_name}>
              <h6>Zeugen</h6>
              <div id='stepFlowDamageTwoButton' className={styles.edit} onClick={() => stepFlowDamage(2)}>
                <PencilIcon color={theme.colors.darkBlue} />
              </div>
            </div>

            <div className={styles.small_header}>Erster Zeuge:</div>

            {!watch('anyWitnessesDateEmpty') && (
              <div className={styles.information_block}>
                <p>{watch('anyWitnessesName')}</p>
                <p>{watch('anyWitnessesAdress')}</p>
                <p>{watch('anyWitnessesEmail')}</p>
                <p>{`${watch('anyWitnessesPhoneCode')} ${watch('anyWitnessesPhone')}`}</p>
              </div>
            )}

            {!!watch('anyWitnessesDateEmpty') && (
              <div className={styles.information_block}>
                <p>Daten über die Zeuge unbekannt</p>
              </div>
            )}

            {!!watch('showSecondPersonAnyWitnesses') && (
              <>
                <div className={styles.small_header}>Zweiter Zeuge:</div>

                {!watch('anyWitnessesDateSecondEmpty') && (
                  <div className={styles.information_block}>
                    <p>{watch('anyWitnessesNameSecond')}</p>
                    <p>{watch('anyWitnessesAdressSecond')}</p>
                    <p>{watch('anyWitnessesEmailSecond')}</p>
                    <p>{`${watch('anyWitnessesPhoneSecondCode')} ${watch('anyWitnessesPhoneSecond')}`}</p>
                  </div>
                )}

                {!!watch('anyWitnessesDateSecondEmpty') && (
                  <div className={styles.information_block}>
                    <p>Daten über die Zeuge unbekannt</p>
                  </div>
                )}
              </>
            )}
          </div>
        )}
      </>
    );
  };

  const BurglaryDetailBlockLeft = () => {
    return (
      <>
        <div className={styles.half_block} style={{ order: 1 }}>
          <div className={styles.block_name}>
            <h6>Daten zum Schaden</h6>
            <div id='returnToStepOneButton' className={styles.edit} onClick={() => returnToStep(1)}>
              <PencilIcon color={theme.colors.darkBlue} />
            </div>
          </div>

          <div className={styles.information_block}>
            <p>{watch('kennzeichen')}</p>
          </div>

          <div className={styles.information_block}>
            <span>
              <p className={styles.first_p}>{formatDate(watch('Schadendatum'))}</p>
              <p>{`${watch('Uhrzeit').hour}:${watch('Uhrzeit').minutes}`}</p>
            </span>
          </div>

          <div className={styles.information_block}>
            <p>{watch('Land')}</p>
            <span>
              <p className={styles.first_p}>{watch('Stadt')}</p>
              <p>{watch('strPLZ')}</p>
            </span>
            <p>{watch('Strasse')}</p>
          </div>

          {((watch('fuhrparkInfo') === null && watch('fahrerInfo') === null) || !!watch('schadenAlsAnderePerson')) && (
            <div className={styles.information_block}>
              {!!watch('anotherPersonFahrerName') && <p>{watch('anotherPersonFahrerName')}</p>}
              {!!watch('anotherPersonFahrerEmail') && <p>{watch('anotherPersonFahrerEmail')}</p>}
              {!!watch('anotherPersonFahrerTelefon') && (
                <p>{`${watch('anotherPersonFahrerTelefonCode')} ${watch('anotherPersonFahrerTelefon')}`}</p>
              )}
              {!!watch('anotherPersonFahrerTelefon2') && (
                <p>{`${watch('anotherPersonFahrerTelefon2Code')} ${watch('anotherPersonFahrerTelefon2')}`}</p>
              )}
            </div>
          )}

          {!!watch('schadenAlsFahrzeugnutzer') && (
            <div className={styles.information_block}>
              {!!watch('fahrerDataFullname') && <p>{watch('fahrerDataFullname')}</p>}
              {!!watch('fahrerDataEmailaddress1') && <p>{watch('fahrerDataEmailaddress1')}</p>}
              {!!watch('fahrerDataMobilephone') && (
                <p>{`${watch('fahrerDataMobilephoneCode')} ${watch('fahrerDataMobilephone')}`}</p>
              )}
              {!!watch('fahrerDataMobilephone1') && (
                <p>{`${watch('fahrerDataMobilephone1Code')} ${watch('fahrerDataMobilephone1')}`}</p>
              )}
            </div>
          )}

          <div className={styles.information_block}>
            <p>{watch('Postleitzahl')}</p>
            {(!!watch('Wunschtermin') || !!watch('AlternativerTermin')) && (
              <span>
                {!!watch('Wunschtermin') && <p className={styles.first_p}>{formatDate(watch('Wunschtermin'))}</p>}
                {!!watch('AlternativerTermin') && <p>{formatDate(watch('AlternativerTermin'))}</p>}
              </span>
            )}
          </div>

          <div className={styles.damagetype_block}>
            <span>{watch('damageType')}</span>
            <div id='openModalChangeTypeOfDamageButton' className={styles.edit} onClick={openModalOnClick}>
              <PencilIcon color={theme.colors.darkBlue} />
            </div>
          </div>
        </div>

        {!!watch('needPolice') && (
          <div className={styles.half_block} style={{ order: 3 }}>
            <div className={styles.block_name}>
              <h6>Polizeiliche Unfalldaten</h6>
              <div id='stepFlowDamageThreeButton' className={styles.edit} onClick={() => stepFlowDamage(3)}>
                <PencilIcon color={theme.colors.darkBlue} />
              </div>
            </div>

            {!!watch('needPolice') && (
              <>
                <div className={styles.information_block}>
                  {!!watch('needPoliceDienststelle') && <p>{watch('needPoliceDienststelle')}</p>}
                  {!!watch('needPoliceAdressAndOffice') && <p>{watch('needPoliceAdressAndOffice')}</p>}
                </div>

                {!!watch('needPoliceGetCaseNumber') && !!watch('needPoliceGetCaseNumberAktenzeichen') && (
                  <div className={styles.information_block}>
                    <p>{watch('needPoliceGetCaseNumberAktenzeichen')}</p>
                  </div>
                )}

                {!!watch('needPoliceCriminalWarning') && !!watch('needPoliceCriminalWarningWho') && (
                  <div className={styles.information_block}>
                    <p>{watch('needPoliceCriminalWarningWho')}</p>
                  </div>
                )}
              </>
            )}
          </div>
        )}

        {((!!watch('FotoSchaden')?.files && watch('FotoSchaden')?.files.length > 0) ||
          (!!watch('Skizze')?.files && watch('Skizze')?.files.length > 0) ||
          (!!watch('SonstigeUnterlagenzurSchadendokumentation')?.files &&
            watch('SonstigeUnterlagenzurSchadendokumentation')?.files.length > 0) ||
          (!!watch('polizei')?.files && watch('polizei')?.files.length > 0)) && (
          <div className={styles.half_block} style={{ order: 10 }}>
            <div className={styles.block_name}>
              <h6>Die Dateien</h6>
              <div id='stepFlowDamageFourButton' className={styles.edit} onClick={() => stepFlowDamage(4)}>
                <PencilIcon color={theme.colors.darkBlue} />
              </div>
            </div>

            {!!watch('FotoSchaden')?.files &&
              Array.isArray(watch('FotoSchaden')?.files) &&
              watch('FotoSchaden')?.files.length > 0 &&
              watch('FotoSchaden')?.files.map((el: FileData) => (
                <div key={el?.path}>
                  <File file={el} />
                </div>
              ))}

            {!!watch('Skizze')?.files &&
              Array.isArray(watch('Skizze')?.files) &&
              watch('Skizze')?.files.length > 0 &&
              watch('Skizze')?.files.map((el: FileData) => (
                <div key={el?.path}>
                  <File file={el} />
                </div>
              ))}

            {!!watch('SonstigeUnterlagenzurSchadendokumentation')?.files &&
              Array.isArray(watch('SonstigeUnterlagenzurSchadendokumentation')?.files) &&
              watch('SonstigeUnterlagenzurSchadendokumentation')?.files.length > 0 &&
              watch('SonstigeUnterlagenzurSchadendokumentation')?.files.map((el: FileData) => (
                <div key={el?.path}>
                  <File file={el} />
                </div>
              ))}

            {!!watch('polizei')?.files &&
              Array.isArray(watch('polizei')?.files) &&
              watch('polizei')?.files.length > 0 &&
              watch('polizei')?.files.map((el: FileData) => (
                <div key={el?.path}>
                  <File file={el} />
                </div>
              ))}
          </div>
        )}
      </>
    );
  };

  const BurglaryDetailBlockRight = () => {
    return (
      <>
        <div className={styles.half_block} style={{ order: 2 }}>
          <div className={styles.block_name}>
            <h6>Allgemeines zum Schaden</h6>
            <div id='stepFlowDamageTwoButton' className={styles.edit} onClick={() => stepFlowDamage(2)}>
              <PencilIcon color={theme.colors.darkBlue} />
            </div>
          </div>

          {!!watch('hasDamage') && (
            <div className={styles.information_block}>
              {!!watch('damageParts') &&
                Array.isArray(watch('damageParts')) &&
                watch('damageParts').length > 0 &&
                watch('damageParts').map((item: OtherDamagePartsItemProps, index: number) => (
                  <span key={index}>
                    <p className={styles.first_p}>{item[0]}</p>
                    <p>&#8212;</p>
                    <p className={styles.last_p}>{item[1]?.name}</p>
                  </span>
                ))}
            </div>
          )}

          {!!watch('anyEvidence') && !!watch('anyEvidenceClues') && (
            <div className={styles.information_block}>
              <p>{watch('anyEvidenceClues')}</p>
            </div>
          )}

          {watch('damagePrivatOrBusinessTrip') !== undefined && (
            <div className={styles.information_block}>
              {!!watch('damagePrivatOrBusinessTrip') && <p>Der Schaden entstand bei einer Privatfahrt</p>}
              {!watch('damagePrivatOrBusinessTrip') && <p>Der Schaden entstand bei einer Dienstfahrt</p>}
            </div>
          )}

          {watch('autoIsOkay') === false && (
            <div className={styles.information_block}>
              {!!watch('autoIsNotOkayFirma') && <p>{watch('autoIsNotOkayFirma')}</p>}
              {!!watch('autoIsNotOkayAdress') && <p>{watch('autoIsNotOkayAdress')}</p>}
              {!!watch('autoIsNotOkayTelephone') && (
                <p>{`${watch('autoIsNotOkayTelephoneCode')} ${watch('autoIsNotOkayTelephone')}`}</p>
              )}
              {!!watch('autoIsNotOkayPLZ') && <p>{watch('autoIsNotOkayPLZ')}</p>}
              {!!watch('autoIsNotOkayStadt') && <p>{watch('autoIsNotOkayStadt')}</p>}
              {!!watch('autoIsNotOkayStreet') && <p>{watch('autoIsNotOkayStreet')}</p>}
            </div>
          )}

          {!!watch('whatWasStolen') && (
            <div className={styles.information_block}>
              <p>{watch('whatWasStolen')}</p>
            </div>
          )}
        </div>

        <div className={styles.half_block} style={{ order: 4 }}>
          <div className={styles.block_name}>
            <h6>Schadenschilderung</h6>
            <div id='stepFlowDamageThreeButton' className={styles.edit} onClick={() => stepFlowDamage(3)}>
              <PencilIcon color={theme.colors.darkBlue} />
            </div>
          </div>

          {!!watch('howToVehicleWasSecured') && (
            <div className={styles.information_block}>
              <p>{watch('howToVehicleWasSecured')}</p>
            </div>
          )}

          {watch('youHaveAllKeys') === false && !!watch('whereIsKeys') && (
            <div className={styles.information_block}>
              <p>{watch('whereIsKeys')}</p>
            </div>
          )}

          {!!watch('detailDamageComment') && (
            <div className={styles.information_block}>
              <p>{watch('detailDamageComment')}</p>
            </div>
          )}
        </div>

        {!!watch('anyWitnesses') && (
          <div className={styles.half_block} style={{ order: 6 }}>
            <div className={styles.block_name}>
              <h6>Zeugen</h6>
              <div id='stepFlowDamageThreeButton' className={styles.edit} onClick={() => stepFlowDamage(3)}>
                <PencilIcon color={theme.colors.darkBlue} />
              </div>
            </div>

            <div className={styles.small_header}>Erster Zeuge:</div>

            {!watch('anyWitnessesDateEmpty') && (
              <div className={styles.information_block}>
                <p>{watch('anyWitnessesName')}</p>
                <p>{watch('anyWitnessesAdress')}</p>
                <p>{watch('anyWitnessesEmail')}</p>
                <p>{`${watch('anyWitnessesPhoneCode')} ${watch('anyWitnessesPhone')}`}</p>
              </div>
            )}

            {!!watch('anyWitnessesDateEmpty') && (
              <div className={styles.information_block}>
                <p>Daten über die Zeuge unbekannt</p>
              </div>
            )}

            {!!watch('showSecondPersonAnyWitnesses') && (
              <>
                <div className={styles.small_header}>Zweiter Zeuge:</div>

                {!watch('anyWitnessesDateSecondEmpty') && (
                  <div className={styles.information_block}>
                    <p>{watch('anyWitnessesNameSecond')}</p>
                    <p>{watch('anyWitnessesAdressSecond')}</p>
                    <p>{watch('anyWitnessesEmailSecond')}</p>
                    <p>{`${watch('anyWitnessesPhoneSecondCode')} ${watch('anyWitnessesPhoneSecond')}`}</p>
                  </div>
                )}

                {!!watch('anyWitnessesDateSecondEmpty') && (
                  <div className={styles.information_block}>
                    <p>Daten über die Zeuge unbekannt</p>
                  </div>
                )}
              </>
            )}
          </div>
        )}
      </>
    );
  };

  const OtherDetailBlockLeft = () => {
    return (
      <>
        <div className={styles.half_block} style={{ order: 1 }}>
          <div className={styles.block_name}>
            <h6>Daten zum Schaden</h6>
            <div id='returnToStepOneButton' className={styles.edit} onClick={() => returnToStep(1)}>
              <PencilIcon color={theme.colors.darkBlue} />
            </div>
          </div>

          <div className={styles.information_block}>
            <p>{watch('kennzeichen')}</p>
          </div>

          <div className={styles.information_block}>
            <span>
              <p className={styles.first_p}>{formatDate(watch('Schadendatum'))}</p>
              <p>{`${watch('Uhrzeit').hour}:${watch('Uhrzeit').minutes}`}</p>
            </span>
          </div>

          <div className={styles.information_block}>
            <p>{watch('Land')}</p>
            <span>
              <p className={styles.first_p}>{watch('Stadt')}</p>
              <p>{watch('strPLZ')}</p>
            </span>
            <p>{watch('Strasse')}</p>
          </div>

          {((watch('fuhrparkInfo') === null && watch('fahrerInfo') === null) || !!watch('schadenAlsAnderePerson')) && (
            <div className={styles.information_block}>
              {!!watch('anotherPersonFahrerName') && <p>{watch('anotherPersonFahrerName')}</p>}
              {!!watch('anotherPersonFahrerEmail') && <p>{watch('anotherPersonFahrerEmail')}</p>}
              {!!watch('anotherPersonFahrerTelefon') && (
                <p>{`${watch('anotherPersonFahrerTelefonCode')} ${watch('anotherPersonFahrerTelefon')}`}</p>
              )}
              {!!watch('anotherPersonFahrerTelefon2') && (
                <p>{`${watch('anotherPersonFahrerTelefon2Code')} ${watch('anotherPersonFahrerTelefon2')}`}</p>
              )}
            </div>
          )}

          {!!watch('schadenAlsFahrzeugnutzer') && (
            <div className={styles.information_block}>
              {!!watch('fahrerDataFullname') && <p>{watch('fahrerDataFullname')}</p>}
              {!!watch('fahrerDataEmailaddress1') && <p>{watch('fahrerDataEmailaddress1')}</p>}
              {!!watch('fahrerDataMobilephone') && (
                <p>{`${watch('fahrerDataMobilephoneCode')} ${watch('fahrerDataMobilephone')}`}</p>
              )}
              {!!watch('fahrerDataMobilephone1') && (
                <p>{`${watch('fahrerDataMobilephone1Code')} ${watch('fahrerDataMobilephone1')}`}</p>
              )}
            </div>
          )}

          <div className={styles.information_block}>
            <p>{watch('Postleitzahl')}</p>
            {(!!watch('Wunschtermin') || !!watch('AlternativerTermin')) && (
              <span>
                {!!watch('Wunschtermin') && <p className={styles.first_p}>{formatDate(watch('Wunschtermin'))}</p>}
                {!!watch('AlternativerTermin') && <p>{formatDate(watch('AlternativerTermin'))}</p>}
              </span>
            )}
          </div>

          <div className={styles.damagetype_block}>
            <span>{watch('damageType')}</span>
            <div id='openModalChangeTypeOfDamageButton' className={styles.edit} onClick={openModalOnClick}>
              <PencilIcon color={theme.colors.darkBlue} />
            </div>
          </div>
        </div>

        {!!watch('needPolice') && (
          <div className={styles.half_block} style={{ order: 3 }}>
            <div className={styles.block_name}>
              <h6>Polizeiliche Unfalldaten</h6>
              <div id='stepFlowDamageThreeButton' className={styles.edit} onClick={() => stepFlowDamage(3)}>
                <PencilIcon color={theme.colors.darkBlue} />
              </div>
            </div>

            {!!watch('needPolice') && (
              <>
                <div className={styles.information_block}>
                  {!!watch('needPoliceDienststelle') && <p>{watch('needPoliceDienststelle')}</p>}
                  {!!watch('needPoliceAdressAndOffice') && <p>{watch('needPoliceAdressAndOffice')}</p>}
                </div>

                {!!watch('needPoliceGetCaseNumber') && (
                  <div className={styles.information_block}>
                    {!!watch('needPoliceGetCaseNumberAktenzeichen') && (
                      <p>{watch('needPoliceGetCaseNumberAktenzeichen')}</p>
                    )}
                  </div>
                )}

                {!!watch('needPoliceCriminalWarning') && (
                  <div className={styles.information_block}>
                    {!!watch('needPoliceCriminalWarningWho') && <p>{watch('needPoliceCriminalWarningWho')}</p>}
                  </div>
                )}

                {(!!watch('needPoliceDrunkDriverWasTested') || !!watch('needPoliceDrunkDrugsTest')) && (
                  <>
                    <div className={styles.information_block}>
                      {!!watch('needPoliceDrunkDriverWasTested') &&
                        !!watch('needPoliceCriminalWarningWhoFirstPersonName') && (
                          <p>{watch('needPoliceCriminalWarningWhoFirstPersonName')}</p>
                        )}
                      {!!watch('needPoliceDrunkDriverWasTested') &&
                        !!watch('needPoliceCriminalWarningWhoFirstPersonDrunkLevel') && (
                          <p>{watch('needPoliceCriminalWarningWhoFirstPersonDrunkLevel')}</p>
                        )}
                      {!!watch('showSecondDrunkPerson') &&
                        !!watch('needPoliceDrunkDriverWasTested') &&
                        !!watch('needPoliceCriminalWarningWhoSecondPersonName') && (
                          <p>{watch('needPoliceCriminalWarningWhoSecondPersonName')}</p>
                        )}
                      {!!watch('showSecondDrunkPerson') &&
                        !!watch('needPoliceDrunkDriverWasTested') &&
                        !!watch('needPoliceCriminalWarningWhoSecondPersonDrunkLevel') && (
                          <p>{watch('needPoliceCriminalWarningWhoSecondPersonDrunkLevel')}</p>
                        )}
                    </div>

                    <div className={styles.information_block}>
                      {!!watch('needPoliceDrunkDrugsTest') && !!watch('needPoliceDrunkDrugsTestWhoWasTestedFirst') && (
                        <p>{watch('needPoliceDrunkDrugsTestWhoWasTestedFirst')}</p>
                      )}
                      {!!watch('needPoliceDrunkDrugsTest') && !!watch('needPoliceDrunkDrugsTestResultFirst') && (
                        <p>{watch('needPoliceDrunkDrugsTestResultFirst')}</p>
                      )}
                      {!!watch('showSecondDrugPerson') &&
                        !!watch('needPoliceDrunkDrugsTest') &&
                        !!watch('needPoliceDrunkDrugsTestWhoWasTestedSecond') && (
                          <p>{watch('needPoliceDrunkDrugsTestWhoWasTestedSecond')}</p>
                        )}
                      {!!watch('showSecondDrugPerson') &&
                        !!watch('needPoliceDrunkDrugsTest') &&
                        !!watch('needPoliceDrunkDrugsTestResultSecond') && (
                          <p>{watch('needPoliceDrunkDrugsTestResultSecond')}</p>
                        )}
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        )}

        {!!watch('anyWitnesses') && (
          <div className={styles.half_block} style={{ order: 5 }}>
            <div className={styles.block_name}>
              <h6>Zeugen</h6>
              <div id='stepFlowDamageFourButton' className={styles.edit} onClick={() => stepFlowDamage(4)}>
                <PencilIcon color={theme.colors.darkBlue} />
              </div>
            </div>

            <div className={styles.small_header}>Erster Zeuge:</div>

            {!watch('anyWitnessesDateEmpty') && (
              <div className={styles.information_block}>
                <p>{watch('anyWitnessesName')}</p>
                <p>{watch('anyWitnessesAdress')}</p>
                <p>{watch('anyWitnessesEmail')}</p>
                <p>{`${watch('anyWitnessesPhoneCode')} ${watch('anyWitnessesPhone')}`}</p>
              </div>
            )}

            {!!watch('anyWitnessesDateEmpty') && (
              <div className={styles.information_block}>
                <p>Daten über die Zeuge unbekannt</p>
              </div>
            )}

            {!!watch('showSecondPersonAnyWitnesses') && (
              <>
                <div className={styles.small_header}>Zweiter Zeuge:</div>

                {!watch('anyWitnessesDateSecondEmpty') && (
                  <div className={styles.information_block}>
                    <p>{watch('anyWitnessesNameSecond')}</p>
                    <p>{watch('anyWitnessesAdressSecond')}</p>
                    <p>{watch('anyWitnessesEmailSecond')}</p>
                    <p>{`${watch('anyWitnessesPhoneSecondCode')} ${watch('anyWitnessesPhoneSecond')}`}</p>
                  </div>
                )}

                {!!watch('anyWitnessesDateSecondEmpty') && (
                  <div className={styles.information_block}>
                    <p>Daten über die Zeuge unbekannt</p>
                  </div>
                )}
              </>
            )}
          </div>
        )}

        {((!!watch('FotoSchaden')?.files && watch('FotoSchaden')?.files.length > 0) ||
          (!!watch('Skizze')?.files && watch('Skizze')?.files.length > 0) ||
          (!!watch('SonstigeUnterlagenzurSchadendokumentation')?.files &&
            watch('SonstigeUnterlagenzurSchadendokumentation')?.files.length > 0) ||
          (!!watch('polizei')?.files && watch('polizei')?.files.length > 0)) && (
          <div className={styles.half_block} style={{ order: 10 }}>
            <div className={styles.block_name}>
              <h6>Die Dateien</h6>
              <div id='stepFlowDamageFiveButton' className={styles.edit} onClick={() => stepFlowDamage(5)}>
                <PencilIcon color={theme.colors.darkBlue} />
              </div>
            </div>

            {!!watch('FotoSchaden')?.files &&
              Array.isArray(watch('FotoSchaden')?.files) &&
              watch('FotoSchaden')?.files.length > 0 &&
              watch('FotoSchaden')?.files.map((el: FileData) => (
                <div key={el?.path}>
                  <File file={el} />
                </div>
              ))}

            {!!watch('Skizze')?.files &&
              Array.isArray(watch('Skizze')?.files) &&
              watch('Skizze')?.files.length > 0 &&
              watch('Skizze')?.files.map((el: FileData) => (
                <div key={el?.path}>
                  <File file={el} />
                </div>
              ))}

            {!!watch('SonstigeUnterlagenzurSchadendokumentation')?.files &&
              Array.isArray(watch('SonstigeUnterlagenzurSchadendokumentation')?.files) &&
              watch('SonstigeUnterlagenzurSchadendokumentation')?.files.length > 0 &&
              watch('SonstigeUnterlagenzurSchadendokumentation')?.files.map((el: FileData) => (
                <div key={el?.path}>
                  <File file={el} />
                </div>
              ))}

            {!!watch('polizei')?.files &&
              Array.isArray(watch('polizei')?.files) &&
              watch('polizei')?.files.length > 0 &&
              watch('polizei')?.files.map((el: FileData) => (
                <div key={el?.path}>
                  <File file={el} />
                </div>
              ))}
          </div>
        )}
      </>
    );
  };

  const OtherDetailBlockRight = () => {
    return (
      <>
        <div className={styles.half_block} style={{ order: 2 }}>
          <div className={styles.block_name}>
            <h6>Allgemeines zum Schaden</h6>
            <div id='stepFlowDamageTwoButton' className={styles.edit} onClick={() => stepFlowDamage(2)}>
              <PencilIcon color={theme.colors.darkBlue} />
            </div>
          </div>

          {!!watch('hasDamage') && (
            <div className={styles.information_block}>
              {!!watch('damageParts') &&
                Array.isArray(watch('damageParts')) &&
                watch('damageParts').length > 0 &&
                watch('damageParts').map((item: OtherDamagePartsItemProps, index: number) => (
                  <span key={index}>
                    <p className={styles.first_p}>{item[0]}</p>
                    <p>&#8212;</p>
                    <p className={styles.last_p}>{item[1]?.name}</p>
                  </span>
                ))}
            </div>
          )}

          {watch('damagePrivatOrBusinessTrip') !== undefined && (
            <div className={styles.information_block}>
              {!!watch('damagePrivatOrBusinessTrip') && <p>Der Schaden entstand bei einer Privatfahrt</p>}
              {!watch('damagePrivatOrBusinessTrip') && <p>Der Schaden entstand bei einer Dienstfahrt</p>}
            </div>
          )}

          {watch('autoIsOkay') === false && (
            <div className={styles.information_block}>
              {!!watch('autoIsNotOkayFirma') && <p>{watch('autoIsNotOkayFirma')}</p>}
              {!!watch('autoIsNotOkayAdress') && <p>{watch('autoIsNotOkayAdress')}</p>}
              {!!watch('autoIsNotOkayTelephone') && (
                <p>{`${watch('autoIsNotOkayTelephoneCode')} ${watch('autoIsNotOkayTelephone')}`}</p>
              )}
              {!!watch('autoIsNotOkayPLZ') && <p>{watch('autoIsNotOkayPLZ')}</p>}
              {!!watch('autoIsNotOkayStadt') && <p>{watch('autoIsNotOkayStadt')}</p>}
              {!!watch('autoIsNotOkayStreet') && <p>{watch('autoIsNotOkayStreet')}</p>}
            </div>
          )}
        </div>

        <div className={styles.half_block} style={{ order: 4 }}>
          <div className={styles.block_name}>
            <h6>Fahrerdaten und Schadenschilderung</h6>
            <div id='stepFlowDamageThreeButton' className={styles.edit} onClick={() => stepFlowDamage(3)}>
              <PencilIcon color={theme.colors.darkBlue} />
            </div>
          </div>

          <div className={styles.information_block}>
            {watch('whoDrive') === 'Fahrzeugnutzer' && <p>Fahrzeugnutzer war der Fahrer des Kundenfahrzeugs</p>}
            {watch('whoDrive') === 'Andere Person' && <p>Andere Person war der Fahrer des Kundenfahrzeugs</p>}
            {watch('whoDrive') === 'Niemand' && <p>Niemand war der Fahrer des Kundenfahrzeugs</p>}
          </div>

          {watch('whoDrive') === 'Fahrzeugnutzer' && (
            <div className={styles.information_block}>
              {!!watch('whoDriveName') && <p>{watch('whoDriveName')}</p>}
              {!!watch('whoDrivePhone') && (
                <p>{`${!!watch('whoDrivePhoneCode') ? watch('whoDrivePhoneCode') : ''} ${watch('whoDrivePhone')}`}</p>
              )}
              {!!watch('whoDriveEmail') && !watch('whoDriveEmailEmpty') && <p>{watch('whoDriveEmail')}</p>}
              {!!watch('whoDrivePLZ') && <p>{watch('whoDrivePLZ')}</p>}
              {!!watch('whoDriveStadt') && <p>{watch('whoDriveStadt')}</p>}
              {!!watch('whoDriveAdress') && <p>{watch('whoDriveAdress')}</p>}
            </div>
          )}

          {watch('whoDrive') === 'Andere Person' && (
            <div className={styles.information_block}>
              {!!watch('whoDriveNameAndere') && <p>{watch('whoDriveNameAndere')}</p>}
              {!!watch('whoDrivePhoneAndere') && (
                <p>{`${watch('whoDrivePhoneAndereCode')} ${watch('whoDrivePhoneAndere')}`}</p>
              )}
              {!!watch('whoDriveEmailAndere') && !watch('whoDriveEmailAndereEmpty') && (
                <p>{watch('whoDriveEmailAndere')}</p>
              )}
              {!!watch('whoDrivePLZAndere') && <p>{watch('whoDrivePLZAndere')}</p>}
              {!!watch('whoDriveStadtAndere') && <p>{watch('whoDriveStadtAndere')}</p>}
              {!!watch('whoDriveAdressAndere') && <p>{watch('whoDriveAdressAndere')}</p>}
            </div>
          )}

          {!!watch('doYouHaveLicense') && (
            <div className={styles.information_block}>
              {!!watch('doYouHaveLicenseIssuingAuthority') && <p>{watch('doYouHaveLicenseIssuingAuthority')}</p>}
              {!!watch('doYouHaveLicenseLicenseNumber') && <p>{watch('doYouHaveLicenseLicenseNumber')}</p>}
              {(!!watch('doYouHaveLicenseDateIssue') || !!watch('doYouHaveLicenseClass')) && (
                <span>
                  {!!watch('doYouHaveLicenseDateIssue') && (
                    <p className={styles.first_p}>{formatDate(watch('doYouHaveLicenseDateIssue'))}</p>
                  )}
                  {!!watch('doYouHaveLicenseClass') && <p>{watch('doYouHaveLicenseClass')}</p>}
                </span>
              )}
            </div>
          )}

          {!!watch('detailDamageComment') && (
            <div className={styles.information_block}>
              <p>{watch('detailDamageComment')}</p>
            </div>
          )}
        </div>

        {!!watch('peopleInvolvedInCrash') && (
          <div className={styles.half_block} style={{ order: 6 }}>
            <div className={styles.block_name}>
              <h6>Unfallbeteiligte</h6>
              <div id='stepFlowDamageFourButton' className={styles.edit} onClick={() => stepFlowDamage(4)}>
                <PencilIcon color={theme.colors.darkBlue} />
              </div>
            </div>
            <div className={styles.small_header}>Erste Beteiligte:</div>
            {!watch('peopleInvolvedInCrashDateEmpty') && (
              <div className={styles.information_block}>
                <p>{watch('peopleInvolvedInCrashName')}</p>
                <p>{watch('peopleInvolvedInCrashAdress')}</p>
                {!!watch('peopleInvolvedInCrashPhone') && (
                  <p>{`${watch('peopleInvolvedInCrashPhoneCode')} ${watch('peopleInvolvedInCrashPhone')}`}</p>
                )}
              </div>
            )}
            {!!watch('peopleInvolvedInCrashDateEmpty') && (
              <div className={styles.information_block}>
                <p>Daten über die beteiligte Person unbekannt</p>
              </div>
            )}
            <div className={styles.information_block}>
              {!!watch('peopleInvolvedInCrashPeopleDamage') ? (
                <p>Ja, diese Person wurde verletzt</p>
              ) : (
                <p>Nein, diese Person wurde nicht verletzt</p>
              )}
            </div>
            {!!watch('peopleInvolvedInCrashPeopleInvolvedInsuranse') && (
              <div className={styles.information_block}>
                <p>{watch('peopleInvolvedInCrashPeopleInvolvedInsuranseName')}</p>
                {!!watch('peopleInvolvedInCrashPeopleInvolvedInsuranseNumber') && (
                  <p>{watch('peopleInvolvedInCrashPeopleInvolvedInsuranseNumber')}</p>
                )}
              </div>
            )}
            {!!watch('peopleInvolvedInCrashCarOwnerIsDriver') && (
              <div className={styles.information_block}>
                <p>{watch('peopleInvolvedInCrashCarOwnerIsDriverName')}</p>
                {!!watch('peopleInvolvedInCrashCarOwnerIsDriverAdress') && (
                  <p>{watch('peopleInvolvedInCrashCarOwnerIsDriverAdress')}</p>
                )}
                {!!watch('peopleInvolvedInCrashCarOwnerIsDriverPhone') && (
                  <p>{`${watch('peopleInvolvedInCrashCarOwnerIsDriverPhoneCode')}${watch(
                    'peopleInvolvedInCrashCarOwnerIsDriverPhone'
                  )}`}</p>
                )}
              </div>
            )}
            {watch('peopleInvolvedInCrashWhatHasDamage.Fahrzeug') === 'Fahrzeug' &&
              (!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichenEmpty') ||
                !!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichen') ||
                !!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugHersteller') ||
                !!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugTyp')) && (
                <>
                  <div className={styles.information_block}>
                    <p>Fahrzeug wurde beschädigt</p>
                  </div>
                  <div className={styles.information_block}>
                    {!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichenEmpty') &&
                      !!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichen') && (
                        <p>{watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichen')}</p>
                      )}
                    {!!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugHersteller') && (
                      <p>{watch('peopleInvolvedInCrashWhatHasDamageFahrzeugHersteller')}</p>
                    )}
                    {!!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugTyp') && (
                      <p>{watch('peopleInvolvedInCrashWhatHasDamageFahrzeugTyp')}</p>
                    )}
                  </div>
                </>
              )}
            {watch('peopleInvolvedInCrashWhatHasDamage.Sonstiges') === 'Sonstiges' &&
              !!watch('peopleInvolvedInCrashWhatHasDamageSonstigesDamageDescription') && (
                <>
                  <div className={styles.information_block}>
                    <p>Sonstiges wurde beschädigt</p>
                  </div>
                  <div className={styles.information_block}>
                    <p>{watch('peopleInvolvedInCrashWhatHasDamageSonstigesDamageDescription')}</p>
                  </div>
                </>
              )}

            {!!watch('peopleInvolvedInCrash') && !!watch('peopleInvolvedInCrashAnotherPeopleInvolved') && (
              <>
                <div className={styles.small_header}>Zweite Beteiligte:</div>
                {!watch('peopleInvolvedInCrashDateEmpty2') && (
                  <div className={styles.information_block}>
                    <p>{watch('peopleInvolvedInCrashName2')}</p>
                    <p>{watch('peopleInvolvedInCrashAdress2')}</p>
                    {!!watch('peopleInvolvedInCrashPhone2') && (
                      <p>{`${watch('peopleInvolvedInCrashPhone2Code')} ${watch('peopleInvolvedInCrashPhone2')}`}</p>
                    )}
                  </div>
                )}
                {!!watch('peopleInvolvedInCrashDateEmpty2') && (
                  <div className={styles.information_block}>
                    <p>Daten über die beteiligte Person unbekannt</p>
                  </div>
                )}
                <div className={styles.information_block}>
                  {!!watch('peopleInvolvedInCrashPeopleDamage2') ? (
                    <p>Ja, diese Person wurde verletzt</p>
                  ) : (
                    <p>Nein, diese Person wurde nicht verletzt</p>
                  )}
                </div>

                {!!watch('peopleInvolvedInCrashPeopleInvolvedInsuranse2') && (
                  <div className={styles.information_block}>
                    <p>{watch('peopleInvolvedInCrashPeopleInvolvedInsuranseName2')}</p>
                    {!!watch('peopleInvolvedInCrashPeopleInvolvedInsuranseNumber2') && (
                      <p>{watch('peopleInvolvedInCrashPeopleInvolvedInsuranseNumber2')}</p>
                    )}
                  </div>
                )}

                {!!watch('peopleInvolvedInCrashCarOwnerIsDriver2') && (
                  <div className={styles.information_block}>
                    <p>{watch('peopleInvolvedInCrashCarOwnerIsDriverName2')}</p>
                    {!!watch('peopleInvolvedInCrashCarOwnerIsDriverAdress2') && (
                      <p>{watch('peopleInvolvedInCrashCarOwnerIsDriverAdress2')}</p>
                    )}
                    {!!watch('peopleInvolvedInCrashCarOwnerIsDriverPhone2') && (
                      <p>{`${watch('peopleInvolvedInCrashCarOwnerIsDriverPhone2Code')}${watch(
                        'peopleInvolvedInCrashCarOwnerIsDriverPhone2'
                      )}`}</p>
                    )}
                  </div>
                )}

                {watch('peopleInvolvedInCrashWhatHasDamage.Fahrzeug2') === 'Fahrzeug' &&
                  (!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichenEmpty2') ||
                    !!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichen2') ||
                    !!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugHersteller2') ||
                    !!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugTyp2')) && (
                    <>
                      <div className={styles.information_block}>
                        <p>Fahrzeug wurde beschädigt</p>
                      </div>
                      <div className={styles.information_block}>
                        {!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichenEmpty2') &&
                          !!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichen2') && (
                            <p>{watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichen2')}</p>
                          )}
                        {!!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugHersteller2') && (
                          <p>{watch('peopleInvolvedInCrashWhatHasDamageFahrzeugHersteller2')}</p>
                        )}
                        {!!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugTyp2') && (
                          <p>{watch('peopleInvolvedInCrashWhatHasDamageFahrzeugTyp2')}</p>
                        )}
                      </div>
                    </>
                  )}

                {watch('peopleInvolvedInCrashWhatHasDamage.Sonstiges2') === 'Sonstiges' &&
                  !!watch('peopleInvolvedInCrashWhatHasDamageSonstigesDamageDescription2') && (
                    <>
                      <div className={styles.information_block}>
                        <p>Sonstiges wurde beschädigt</p>
                      </div>
                      <div className={styles.information_block}>
                        <p>{watch('peopleInvolvedInCrashWhatHasDamageSonstigesDamageDescription2')}</p>
                      </div>
                    </>
                  )}
              </>
            )}

            {!!watch('peopleInvolvedInCrash') &&
              !!watch('peopleInvolvedInCrashAnotherPeopleInvolved') &&
              !!watch('peopleInvolvedInCrashAnotherPeopleInvolved2') && (
                <>
                  <div className={styles.small_header}>Dritte Beteiligte:</div>
                  {!watch('peopleInvolvedInCrashDateEmpty3') && (
                    <div className={styles.information_block}>
                      <p>{watch('peopleInvolvedInCrashName3')}</p>
                      <p>{watch('peopleInvolvedInCrashAdress3')}</p>
                      {!!watch('peopleInvolvedInCrashPhone3') && (
                        <p>{`${watch('peopleInvolvedInCrashPhone3Code')} ${watch('peopleInvolvedInCrashPhone3')}`}</p>
                      )}
                    </div>
                  )}
                  {!!watch('peopleInvolvedInCrashDateEmpty3') && (
                    <div className={styles.information_block}>
                      <p>Daten über die beteiligte Person unbekannt</p>
                    </div>
                  )}

                  <div className={styles.information_block}>
                    {!!watch('peopleInvolvedInCrashPeopleDamage3') ? (
                      <p>Ja, diese Person wurde verletzt</p>
                    ) : (
                      <p>Nein, diese Person wurde nicht verletzt</p>
                    )}
                  </div>

                  {!!watch('peopleInvolvedInCrashPeopleInvolvedInsuranse3') && (
                    <div className={styles.information_block}>
                      <p>{watch('peopleInvolvedInCrashPeopleInvolvedInsuranseName3')}</p>
                      {!!watch('peopleInvolvedInCrashPeopleInvolvedInsuranseNumber3') && (
                        <p>{watch('peopleInvolvedInCrashPeopleInvolvedInsuranseNumber3')}</p>
                      )}
                    </div>
                  )}

                  {!!watch('peopleInvolvedInCrashCarOwnerIsDriver3') && (
                    <div className={styles.information_block}>
                      <p>{watch('peopleInvolvedInCrashCarOwnerIsDriverName3')}</p>
                      {!!watch('peopleInvolvedInCrashCarOwnerIsDriverAdress3') && (
                        <p>{watch('peopleInvolvedInCrashCarOwnerIsDriverAdress3')}</p>
                      )}
                      {!!watch('peopleInvolvedInCrashCarOwnerIsDriverPhone3') && (
                        <p>{`${watch('peopleInvolvedInCrashCarOwnerIsDriverPhone3Code')}${watch(
                          'peopleInvolvedInCrashCarOwnerIsDriverPhone3'
                        )}`}</p>
                      )}
                    </div>
                  )}

                  {watch('peopleInvolvedInCrashWhatHasDamage.Fahrzeug3') === 'Fahrzeug' &&
                    (!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichenEmpty3') ||
                      !!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichen3') ||
                      !!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugHersteller3') ||
                      !!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugTyp3')) && (
                      <>
                        <div className={styles.information_block}>
                          <p>Fahrzeug wurde beschädigt</p>
                        </div>
                        <div className={styles.information_block}>
                          {!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichenEmpty3') &&
                            !!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichen3') && (
                              <p>{watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichen3')}</p>
                            )}
                          {!!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugHersteller3') && (
                            <p>{watch('peopleInvolvedInCrashWhatHasDamageFahrzeugHersteller3')}</p>
                          )}
                          {!!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugTyp3') && (
                            <p>{watch('peopleInvolvedInCrashWhatHasDamageFahrzeugTyp3')}</p>
                          )}
                        </div>
                      </>
                    )}

                  {watch('peopleInvolvedInCrashWhatHasDamage.Sonstiges3') === 'Sonstiges' &&
                    !!watch('peopleInvolvedInCrashWhatHasDamageSonstigesDamageDescription3') && (
                      <>
                        <div className={styles.information_block}>
                          <p>Sonstiges wurde beschädigt</p>
                        </div>
                        <div className={styles.information_block}>
                          <p>{watch('peopleInvolvedInCrashWhatHasDamageSonstigesDamageDescription3')}</p>
                        </div>
                      </>
                    )}
                </>
              )}
          </div>
        )}
      </>
    );
  };

  const VandalismDetailBlockLeft = () => {
    return (
      <>
        <div className={styles.half_block} style={{ order: 1 }}>
          <div className={styles.block_name}>
            <h6>Daten zum Schaden</h6>
            <div id='returnToStepOneButton' className={styles.edit} onClick={() => returnToStep(1)}>
              <PencilIcon color={theme.colors.darkBlue} />
            </div>
          </div>

          <div className={styles.information_block}>
            <p>{watch('kennzeichen')}</p>
          </div>

          <div className={styles.information_block}>
            <span>
              <p className={styles.first_p}>{formatDate(watch('Schadendatum'))}</p>
              <p>{`${watch('Uhrzeit').hour}:${watch('Uhrzeit').minutes}`}</p>
            </span>
          </div>

          <div className={styles.information_block}>
            <p>{watch('Land')}</p>
            <span>
              <p className={styles.first_p}>{watch('Stadt')}</p>
              <p>{watch('strPLZ')}</p>
            </span>
            <p>{watch('Strasse')}</p>
          </div>

          {((watch('fuhrparkInfo') === null && watch('fahrerInfo') === null) || !!watch('schadenAlsAnderePerson')) && (
            <div className={styles.information_block}>
              {!!watch('anotherPersonFahrerName') && <p>{watch('anotherPersonFahrerName')}</p>}
              {!!watch('anotherPersonFahrerEmail') && <p>{watch('anotherPersonFahrerEmail')}</p>}
              {!!watch('anotherPersonFahrerTelefon') && (
                <p>{`${watch('anotherPersonFahrerTelefonCode')} ${watch('anotherPersonFahrerTelefon')}`}</p>
              )}
              {!!watch('anotherPersonFahrerTelefon2') && (
                <p>{`${watch('anotherPersonFahrerTelefon2Code')} ${watch('anotherPersonFahrerTelefon2')}`}</p>
              )}
            </div>
          )}

          {!!watch('schadenAlsFahrzeugnutzer') && (
            <div className={styles.information_block}>
              {!!watch('fahrerDataFullname') && <p>{watch('fahrerDataFullname')}</p>}
              {!!watch('fahrerDataEmailaddress1') && <p>{watch('fahrerDataEmailaddress1')}</p>}
              {!!watch('fahrerDataMobilephone') && (
                <p>{`${watch('fahrerDataMobilephoneCode')} ${watch('fahrerDataMobilephone')}`}</p>
              )}
              {!!watch('fahrerDataMobilephone1') && (
                <p>{`${watch('fahrerDataMobilephone1Code')} ${watch('fahrerDataMobilephone1')}`}</p>
              )}
            </div>
          )}

          <div className={styles.information_block}>
            <p>{watch('Postleitzahl')}</p>
            {(!!watch('Wunschtermin') || !!watch('AlternativerTermin')) && (
              <span>
                {!!watch('Wunschtermin') && <p className={styles.first_p}>{formatDate(watch('Wunschtermin'))}</p>}
                {!!watch('AlternativerTermin') && <p>{formatDate(watch('AlternativerTermin'))}</p>}
              </span>
            )}
          </div>

          <div className={styles.damagetype_block}>
            <span>{watch('damageType')}</span>
            <div id='openModalChangeTypeOfDamageButton' className={styles.edit} onClick={openModalOnClick}>
              <PencilIcon color={theme.colors.darkBlue} />
            </div>
          </div>
        </div>

        {!!watch('needPolice') && (
          <div className={styles.half_block} style={{ order: 3 }}>
            <div className={styles.block_name}>
              <h6>Polizeiliche Unfalldaten</h6>
              <div id='stepFlowDamageTwoButton' className={styles.edit} onClick={() => stepFlowDamage(2)}>
                <PencilIcon color={theme.colors.darkBlue} />
              </div>
            </div>

            {!!watch('needPolice') && (
              <>
                <div className={styles.information_block}>
                  {!!watch('needPoliceDienststelle') && <p>{watch('needPoliceDienststelle')}</p>}
                  {!!watch('needPoliceAdressAndOffice') && <p>{watch('needPoliceAdressAndOffice')}</p>}
                </div>

                {!!watch('needPoliceGetCaseNumber') && (
                  <div className={styles.information_block}>
                    {!!watch('needPoliceGetCaseNumberAktenzeichen') && (
                      <p>{watch('needPoliceGetCaseNumberAktenzeichen')}</p>
                    )}
                  </div>
                )}

                {!!watch('needPoliceCriminalWarning') && (
                  <div className={styles.information_block}>
                    {!!watch('needPoliceCriminalWarningWho') && <p>{watch('needPoliceCriminalWarningWho')}</p>}
                  </div>
                )}

                {(!!watch('needPoliceDrunkDriverWasTested') || !!watch('needPoliceDrunkDrugsTest')) && (
                  <>
                    <div className={styles.information_block}>
                      {!!watch('needPoliceDrunkDriverWasTested') &&
                        !!watch('needPoliceCriminalWarningWhoFirstPersonName') && (
                          <p>{watch('needPoliceCriminalWarningWhoFirstPersonName')}</p>
                        )}
                      {!!watch('needPoliceDrunkDriverWasTested') &&
                        !!watch('needPoliceCriminalWarningWhoFirstPersonDrunkLevel') && (
                          <p>{watch('needPoliceCriminalWarningWhoFirstPersonDrunkLevel')}</p>
                        )}
                      {!!watch('showSecondDrunkPerson') &&
                        !!watch('needPoliceDrunkDriverWasTested') &&
                        !!watch('needPoliceCriminalWarningWhoSecondPersonName') && (
                          <p>{watch('needPoliceCriminalWarningWhoSecondPersonName')}</p>
                        )}
                      {!!watch('showSecondDrunkPerson') &&
                        !!watch('needPoliceDrunkDriverWasTested') &&
                        !!watch('needPoliceCriminalWarningWhoSecondPersonDrunkLevel') && (
                          <p>{watch('needPoliceCriminalWarningWhoSecondPersonDrunkLevel')}</p>
                        )}
                    </div>

                    <div className={styles.information_block}>
                      {!!watch('needPoliceDrunkDrugsTest') && !!watch('needPoliceDrunkDrugsTestWhoWasTestedFirst') && (
                        <p>{watch('needPoliceDrunkDrugsTestWhoWasTestedFirst')}</p>
                      )}
                      {!!watch('needPoliceDrunkDrugsTest') && !!watch('needPoliceDrunkDrugsTestResultFirst') && (
                        <p>{watch('needPoliceDrunkDrugsTestResultFirst')}</p>
                      )}
                      {!!watch('showSecondDrugPerson') &&
                        !!watch('needPoliceDrunkDrugsTest') &&
                        !!watch('needPoliceDrunkDrugsTestWhoWasTestedSecond') && (
                          <p>{watch('needPoliceDrunkDrugsTestWhoWasTestedSecond')}</p>
                        )}
                      {!!watch('showSecondDrugPerson') &&
                        !!watch('needPoliceDrunkDrugsTest') &&
                        !!watch('needPoliceDrunkDrugsTestResultSecond') && (
                          <p>{watch('needPoliceDrunkDrugsTestResultSecond')}</p>
                        )}
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        )}

        <div className={styles.half_block} style={{ order: 5 }}>
          <div className={styles.block_name}>
            <h6>Schadenschilderung</h6>
            <div id='stepFlowDamageThreeButton' className={styles.edit} onClick={() => stepFlowDamage(3)}>
              <PencilIcon color={theme.colors.darkBlue} />
            </div>
          </div>

          {!!watch('detailDamageComment') && (
            <div className={styles.information_block}>
              <p>{watch('detailDamageComment')}</p>
            </div>
          )}
        </div>

        {!!watch('anyWitnesses') && (
          <div className={styles.half_block} style={{ order: 7 }}>
            <div className={styles.block_name}>
              <h6>Zeugen</h6>
              <div id='stepFlowDamageThreeButton' className={styles.edit} onClick={() => stepFlowDamage(3)}>
                <PencilIcon color={theme.colors.darkBlue} />
              </div>
            </div>

            <div className={styles.small_header}>Erster Zeuge:</div>

            {!watch('anyWitnessesDateEmpty') && (
              <div className={styles.information_block}>
                <p>{watch('anyWitnessesName')}</p>
                <p>{watch('anyWitnessesAdress')}</p>
                <p>{watch('anyWitnessesEmail')}</p>
                <p>{`${watch('anyWitnessesPhoneCode')} ${watch('anyWitnessesPhone')}`}</p>
              </div>
            )}

            {!!watch('anyWitnessesDateEmpty') && (
              <div className={styles.information_block}>
                <p>Daten über die Zeuge unbekannt</p>
              </div>
            )}

            {!!watch('showSecondPersonAnyWitnesses') && (
              <>
                <div className={styles.small_header}>Zweiter Zeuge:</div>

                {!watch('anyWitnessesDateSecondEmpty') && (
                  <div className={styles.information_block}>
                    <p>{watch('anyWitnessesNameSecond')}</p>
                    <p>{watch('anyWitnessesAdressSecond')}</p>
                    <p>{watch('anyWitnessesEmailSecond')}</p>
                    <p>{`${watch('anyWitnessesPhoneSecondCode')} ${watch('anyWitnessesPhoneSecond')}`}</p>
                  </div>
                )}

                {!!watch('anyWitnessesDateSecondEmpty') && (
                  <div className={styles.information_block}>
                    <p>Daten über die Zeuge unbekannt</p>
                  </div>
                )}
              </>
            )}
          </div>
        )}

        {((!!watch('FotoSchaden')?.files && watch('FotoSchaden')?.files.length > 0) ||
          (!!watch('Skizze')?.files && watch('Skizze')?.files.length > 0) ||
          (!!watch('SonstigeUnterlagenzurSchadendokumentation')?.files &&
            watch('SonstigeUnterlagenzurSchadendokumentation')?.files.length > 0) ||
          (!!watch('polizei')?.files && watch('polizei')?.files.length > 0)) && (
          <div className={styles.half_block} style={{ order: 10 }}>
            <div className={styles.block_name}>
              <h6>Die Dateien</h6>
              <div id='stepFlowDamageFourButton' className={styles.edit} onClick={() => stepFlowDamage(4)}>
                <PencilIcon color={theme.colors.darkBlue} />
              </div>
            </div>

            {!!watch('FotoSchaden')?.files &&
              Array.isArray(watch('FotoSchaden')?.files) &&
              watch('FotoSchaden')?.files.length > 0 &&
              watch('FotoSchaden')?.files.map((el: FileData) => (
                <div key={el?.path}>
                  <File file={el} />
                </div>
              ))}

            {!!watch('Skizze')?.files &&
              Array.isArray(watch('Skizze')?.files) &&
              watch('Skizze')?.files.length > 0 &&
              watch('Skizze')?.files.map((el: FileData) => (
                <div key={el?.path}>
                  <File file={el} />
                </div>
              ))}

            {!!watch('SonstigeUnterlagenzurSchadendokumentation')?.files &&
              Array.isArray(watch('SonstigeUnterlagenzurSchadendokumentation')?.files) &&
              watch('SonstigeUnterlagenzurSchadendokumentation')?.files.length > 0 &&
              watch('SonstigeUnterlagenzurSchadendokumentation')?.files.map((el: FileData) => (
                <div key={el?.path}>
                  <File file={el} />
                </div>
              ))}

            {!!watch('polizei')?.files &&
              Array.isArray(watch('polizei')?.files) &&
              watch('polizei')?.files.length > 0 &&
              watch('polizei')?.files.map((el: FileData) => (
                <div key={el?.path}>
                  <File file={el} />
                </div>
              ))}
          </div>
        )}
      </>
    );
  };

  const VandalismDetailBlockRight = () => {
    return (
      <>
        <div className={styles.half_block} style={{ order: 2 }}>
          <div className={styles.block_name}>
            <h6>Allgemeines zum Schaden</h6>
            <div id='stepFlowDamageTwoButton' className={styles.edit} onClick={() => stepFlowDamage(2)}>
              <PencilIcon color={theme.colors.darkBlue} />
            </div>
          </div>

          {!!watch('hasDamage') && (
            <div className={styles.information_block}>
              {!!watch('damageParts') &&
                Array.isArray(watch('damageParts')) &&
                watch('damageParts').length > 0 &&
                watch('damageParts').map((item: OtherDamagePartsItemProps, index: number) => (
                  <span key={index}>
                    <p className={styles.first_p}>{item[0]}</p>
                    <p>&#8212;</p>
                    <p className={styles.last_p}>{item[1]?.name}</p>
                  </span>
                ))}
            </div>
          )}

          {watch('damagePrivatOrBusinessTrip') !== undefined && (
            <div className={styles.information_block}>
              {!!watch('damagePrivatOrBusinessTrip') && <p>Der Schaden entstand bei einer Privatfahrt</p>}
              {!watch('damagePrivatOrBusinessTrip') && <p>Der Schaden entstand bei einer Dienstfahrt</p>}
            </div>
          )}

          {watch('autoIsOkay') === false && (
            <div className={styles.information_block}>
              {!!watch('autoIsNotOkayFirma') && <p>{watch('autoIsNotOkayFirma')}</p>}
              {!!watch('autoIsNotOkayAdress') && <p>{watch('autoIsNotOkayAdress')}</p>}
              {!!watch('autoIsNotOkayTelephone') && (
                <p>{`${watch('autoIsNotOkayTelephoneCode')} ${watch('autoIsNotOkayTelephone')}`}</p>
              )}
              {!!watch('autoIsNotOkayPLZ') && <p>{watch('autoIsNotOkayPLZ')}</p>}
              {!!watch('autoIsNotOkayStadt') && <p>{watch('autoIsNotOkayStadt')}</p>}
              {!!watch('autoIsNotOkayStreet') && <p>{watch('autoIsNotOkayStreet')}</p>}
            </div>
          )}
        </div>

        {!!watch('peopleInvolvedInCrash') && (
          <div className={styles.half_block} style={{ order: 4 }}>
            <div className={styles.block_name}>
              <h6>Unfallbeteiligte</h6>
              <div id='stepFlowDamageTreeButton' className={styles.edit} onClick={() => stepFlowDamage(3)}>
                <PencilIcon color={theme.colors.darkBlue} />
              </div>
            </div>
            <div className={styles.small_header}>Erste Beteiligte:</div>
            {!watch('peopleInvolvedInCrashDateEmpty') && (
              <div className={styles.information_block}>
                <p>{watch('peopleInvolvedInCrashName')}</p>
                <p>{watch('peopleInvolvedInCrashAdress')}</p>
                {!!watch('peopleInvolvedInCrashPhone') && (
                  <p>{`${watch('peopleInvolvedInCrashPhoneCode')} ${watch('peopleInvolvedInCrashPhone')}`}</p>
                )}
              </div>
            )}
            {!!watch('peopleInvolvedInCrashDateEmpty') && (
              <div className={styles.information_block}>
                <p>Daten über die beteiligte Person unbekannt</p>
              </div>
            )}
            <div className={styles.information_block}>
              {!!watch('peopleInvolvedInCrashPeopleDamage') ? (
                <p>Ja, diese Person wurde verletzt</p>
              ) : (
                <p>Nein, diese Person wurde nicht verletzt</p>
              )}
            </div>
            {!!watch('peopleInvolvedInCrashPeopleInvolvedInsuranse') && (
              <div className={styles.information_block}>
                <p>{watch('peopleInvolvedInCrashPeopleInvolvedInsuranseName')}</p>
                {!!watch('peopleInvolvedInCrashPeopleInvolvedInsuranseNumber') && (
                  <p>{watch('peopleInvolvedInCrashPeopleInvolvedInsuranseNumber')}</p>
                )}
              </div>
            )}
            {!!watch('peopleInvolvedInCrashCarOwnerIsDriver') && (
              <div className={styles.information_block}>
                <p>{watch('peopleInvolvedInCrashCarOwnerIsDriverName')}</p>
                {!!watch('peopleInvolvedInCrashCarOwnerIsDriverAdress') && (
                  <p>{watch('peopleInvolvedInCrashCarOwnerIsDriverAdress')}</p>
                )}
                {!!watch('peopleInvolvedInCrashCarOwnerIsDriverPhone') && (
                  <p>{`${watch('peopleInvolvedInCrashCarOwnerIsDriverPhoneCode')}${watch(
                    'peopleInvolvedInCrashCarOwnerIsDriverPhone'
                  )}`}</p>
                )}
              </div>
            )}
            {watch('peopleInvolvedInCrashWhatHasDamage.Fahrzeug') === 'Fahrzeug' &&
              (!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichenEmpty') ||
                !!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichen') ||
                !!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugHersteller') ||
                !!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugTyp')) && (
                <>
                  <div className={styles.information_block}>
                    <p>Fahrzeug wurde beschädigt</p>
                  </div>
                  <div className={styles.information_block}>
                    {!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichenEmpty') &&
                      !!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichen') && (
                        <p>{watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichen')}</p>
                      )}
                    {!!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugHersteller') && (
                      <p>{watch('peopleInvolvedInCrashWhatHasDamageFahrzeugHersteller')}</p>
                    )}
                    {!!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugTyp') && (
                      <p>{watch('peopleInvolvedInCrashWhatHasDamageFahrzeugTyp')}</p>
                    )}
                  </div>
                </>
              )}
            {watch('peopleInvolvedInCrashWhatHasDamage.Sonstiges') === 'Sonstiges' &&
              !!watch('peopleInvolvedInCrashWhatHasDamageSonstigesDamageDescription') && (
                <>
                  <div className={styles.information_block}>
                    <p>Sonstiges wurde beschädigt</p>
                  </div>
                  <div className={styles.information_block}>
                    <p>{watch('peopleInvolvedInCrashWhatHasDamageSonstigesDamageDescription')}</p>
                  </div>
                </>
              )}

            {!!watch('peopleInvolvedInCrash') && !!watch('peopleInvolvedInCrashAnotherPeopleInvolved') && (
              <>
                <div className={styles.small_header}>Zweite Beteiligte:</div>
                {!watch('peopleInvolvedInCrashDateEmpty2') && (
                  <div className={styles.information_block}>
                    <p>{watch('peopleInvolvedInCrashName2')}</p>
                    <p>{watch('peopleInvolvedInCrashAdress2')}</p>
                    {!!watch('peopleInvolvedInCrashPhone2') && (
                      <p>{`${watch('peopleInvolvedInCrashPhone2Code')} ${watch('peopleInvolvedInCrashPhone2')}`}</p>
                    )}
                  </div>
                )}
                {!!watch('peopleInvolvedInCrashDateEmpty2') && (
                  <div className={styles.information_block}>
                    <p>Daten über die beteiligte Person unbekannt</p>
                  </div>
                )}
                <div className={styles.information_block}>
                  {!!watch('peopleInvolvedInCrashPeopleDamage2') ? (
                    <p>Ja, diese Person wurde verletzt</p>
                  ) : (
                    <p>Nein, diese Person wurde nicht verletzt</p>
                  )}
                </div>

                {!!watch('peopleInvolvedInCrashPeopleInvolvedInsuranse2') && (
                  <div className={styles.information_block}>
                    <p>{watch('peopleInvolvedInCrashPeopleInvolvedInsuranseName2')}</p>
                    {!!watch('peopleInvolvedInCrashPeopleInvolvedInsuranseNumber2') && (
                      <p>{watch('peopleInvolvedInCrashPeopleInvolvedInsuranseNumber2')}</p>
                    )}
                  </div>
                )}

                {!!watch('peopleInvolvedInCrashCarOwnerIsDriver2') && (
                  <div className={styles.information_block}>
                    <p>{watch('peopleInvolvedInCrashCarOwnerIsDriverName2')}</p>
                    {!!watch('peopleInvolvedInCrashCarOwnerIsDriverAdress2') && (
                      <p>{watch('peopleInvolvedInCrashCarOwnerIsDriverAdress2')}</p>
                    )}
                    {!!watch('peopleInvolvedInCrashCarOwnerIsDriverPhone2') && (
                      <p>{`${watch('peopleInvolvedInCrashCarOwnerIsDriverPhone2Code')}${watch(
                        'peopleInvolvedInCrashCarOwnerIsDriverPhone2'
                      )}`}</p>
                    )}
                  </div>
                )}

                {watch('peopleInvolvedInCrashWhatHasDamage.Fahrzeug2') === 'Fahrzeug' &&
                  (!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichenEmpty2') ||
                    !!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichen2') ||
                    !!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugHersteller2') ||
                    !!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugTyp2')) && (
                    <>
                      <div className={styles.information_block}>
                        <p>Fahrzeug wurde beschädigt</p>
                      </div>
                      <div className={styles.information_block}>
                        {!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichenEmpty2') &&
                          !!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichen2') && (
                            <p>{watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichen2')}</p>
                          )}
                        {!!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugHersteller2') && (
                          <p>{watch('peopleInvolvedInCrashWhatHasDamageFahrzeugHersteller2')}</p>
                        )}
                        {!!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugTyp2') && (
                          <p>{watch('peopleInvolvedInCrashWhatHasDamageFahrzeugTyp2')}</p>
                        )}
                      </div>
                    </>
                  )}

                {watch('peopleInvolvedInCrashWhatHasDamage.Sonstiges2') === 'Sonstiges' &&
                  !!watch('peopleInvolvedInCrashWhatHasDamageSonstigesDamageDescription2') && (
                    <>
                      <div className={styles.information_block}>
                        <p>Sonstiges wurde beschädigt</p>
                      </div>
                      <div className={styles.information_block}>
                        <p>{watch('peopleInvolvedInCrashWhatHasDamageSonstigesDamageDescription2')}</p>
                      </div>
                    </>
                  )}
              </>
            )}

            {!!watch('peopleInvolvedInCrash') &&
              !!watch('peopleInvolvedInCrashAnotherPeopleInvolved') &&
              !!watch('peopleInvolvedInCrashAnotherPeopleInvolved2') && (
                <>
                  <div className={styles.small_header}>Dritte Beteiligte:</div>
                  {!watch('peopleInvolvedInCrashDateEmpty3') && (
                    <div className={styles.information_block}>
                      <p>{watch('peopleInvolvedInCrashName3')}</p>
                      <p>{watch('peopleInvolvedInCrashAdress3')}</p>
                      {!!watch('peopleInvolvedInCrashPhone3') && (
                        <p>{`${watch('peopleInvolvedInCrashPhone3Code')} ${watch('peopleInvolvedInCrashPhone3')}`}</p>
                      )}
                    </div>
                  )}
                  {!!watch('peopleInvolvedInCrashDateEmpty3') && (
                    <div className={styles.information_block}>
                      <p>Daten über die beteiligte Person unbekannt</p>
                    </div>
                  )}

                  <div className={styles.information_block}>
                    {!!watch('peopleInvolvedInCrashPeopleDamage3') ? (
                      <p>Ja, diese Person wurde verletzt</p>
                    ) : (
                      <p>Nein, diese Person wurde nicht verletzt</p>
                    )}
                  </div>

                  {!!watch('peopleInvolvedInCrashPeopleInvolvedInsuranse3') && (
                    <div className={styles.information_block}>
                      <p>{watch('peopleInvolvedInCrashPeopleInvolvedInsuranseName3')}</p>
                      {!!watch('peopleInvolvedInCrashPeopleInvolvedInsuranseNumber3') && (
                        <p>{watch('peopleInvolvedInCrashPeopleInvolvedInsuranseNumber3')}</p>
                      )}
                    </div>
                  )}

                  {!!watch('peopleInvolvedInCrashCarOwnerIsDriver3') && (
                    <div className={styles.information_block}>
                      <p>{watch('peopleInvolvedInCrashCarOwnerIsDriverName3')}</p>
                      {!!watch('peopleInvolvedInCrashCarOwnerIsDriverAdress3') && (
                        <p>{watch('peopleInvolvedInCrashCarOwnerIsDriverAdress3')}</p>
                      )}
                      {!!watch('peopleInvolvedInCrashCarOwnerIsDriverPhone3') && (
                        <p>{`${watch('peopleInvolvedInCrashCarOwnerIsDriverPhone3Code')}${watch(
                          'peopleInvolvedInCrashCarOwnerIsDriverPhone3'
                        )}`}</p>
                      )}
                    </div>
                  )}

                  {watch('peopleInvolvedInCrashWhatHasDamage.Fahrzeug3') === 'Fahrzeug' &&
                    (!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichenEmpty3') ||
                      !!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichen3') ||
                      !!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugHersteller3') ||
                      !!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugTyp3')) && (
                      <>
                        <div className={styles.information_block}>
                          <p>Fahrzeug wurde beschädigt</p>
                        </div>
                        <div className={styles.information_block}>
                          {!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichenEmpty3') &&
                            !!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichen3') && (
                              <p>{watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichen3')}</p>
                            )}
                          {!!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugHersteller3') && (
                            <p>{watch('peopleInvolvedInCrashWhatHasDamageFahrzeugHersteller3')}</p>
                          )}
                          {!!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugTyp3') && (
                            <p>{watch('peopleInvolvedInCrashWhatHasDamageFahrzeugTyp3')}</p>
                          )}
                        </div>
                      </>
                    )}

                  {watch('peopleInvolvedInCrashWhatHasDamage.Sonstiges3') === 'Sonstiges' &&
                    !!watch('peopleInvolvedInCrashWhatHasDamageSonstigesDamageDescription3') && (
                      <>
                        <div className={styles.information_block}>
                          <p>Sonstiges wurde beschädigt</p>
                        </div>
                        <div className={styles.information_block}>
                          <p>{watch('peopleInvolvedInCrashWhatHasDamageSonstigesDamageDescription3')}</p>
                        </div>
                      </>
                    )}
                </>
              )}
          </div>
        )}
      </>
    );
  };

  return (
    <div className={styles.details_block}>
      <CSSTransition in={showBlock} timeout={800} classNames={slide} mountOnEnter unmountOnExit>
        <section className={styles.container}>
          <div className={styles.full_header}>Bitte prüfe die Daten auf Richtigkeit.</div>

          {(watch('damageType') === 'Auffahrschaden' ||
            watch('damageType') === 'Fahrspurwechsel' ||
            watch('damageType') === 'Vorfahrt verletzt' ||
            watch('damageType') === 'Parkschaden') && (
            <>
              {windowWidth > 900 ? (
                <>
                  <div className={styles.left_column}>
                    <CollisionParkingDetailBlockLeft />
                  </div>
                  <div className={styles.right_column}>
                    <CollisionParkingDetailBlockRight />
                  </div>
                </>
              ) : (
                <div className={styles.mobile_column}>
                  <CollisionParkingDetailBlockLeft />
                  <CollisionParkingDetailBlockRight />
                </div>
              )}
            </>
          )}

          {watch('damageType') === 'Brandschaden' && (
            <>
              {windowWidth > 900 ? (
                <>
                  <div className={styles.left_column}>
                    <FireDetailBlockLeft />
                  </div>
                  <div className={styles.Right_column}>
                    <FireDetailBlockRight />
                  </div>
                </>
              ) : (
                <div className={styles.mobile_column}>
                  <FireDetailBlockLeft />
                  <FireDetailBlockRight />
                </div>
              )}
            </>
          )}

          {watch('damageType') === 'Hagelschaden' && (
            <>
              {windowWidth > 900 ? (
                <>
                  <div className={styles.left_column}>
                    <HailDetailBlockLeft />
                  </div>
                  <div className={styles.Right_column}>
                    <HailDetailBlockRight />
                  </div>
                </>
              ) : (
                <div className={styles.mobile_column}>
                  <HailDetailBlockLeft />
                  <HailDetailBlockRight />
                </div>
              )}
            </>
          )}

          {watch('damageType') === 'Sturmschaden' && (
            <>
              {windowWidth > 900 ? (
                <>
                  <div className={styles.left_column}>
                    <StormDetailBlockLeft />
                  </div>
                  <div className={styles.right_column}>
                    <StormDetailBlockRight />
                  </div>
                </>
              ) : (
                <div className={styles.mobile_column}>
                  <StormDetailBlockLeft />
                  <StormDetailBlockRight />
                </div>
              )}
            </>
          )}

          {watch('damageType') === 'Wild-/ Tierschaden' && (
            <>
              {windowWidth > 900 ? (
                <>
                  <div className={styles.left_column}>
                    <AnimalDetailBlockLeft />
                  </div>
                  <div className={styles.right_column}>
                    <AnimalDetailBlockRight />
                  </div>
                </>
              ) : (
                <div className={styles.mobile_column}>
                  <AnimalDetailBlockLeft />
                  <AnimalDetailBlockRight />
                </div>
              )}
            </>
          )}

          {watch('damageType') === 'Glasschaden' && (
            <>
              {windowWidth > 900 ? (
                <>
                  <div className={styles.left_column}>
                    <GlassDetailBlockLeft />
                  </div>
                  <div className={styles.right_column}>
                    <GlassDetailBlockRight />
                  </div>
                </>
              ) : (
                <div className={styles.mobile_column}>
                  <GlassDetailBlockLeft />
                  <GlassDetailBlockRight />
                </div>
              )}
            </>
          )}

          {watch('damageType') === 'Marderschaden' && (
            <>
              {windowWidth > 900 ? (
                <>
                  <div className={styles.left_column}>
                    <MartenDetailBlockLeft />
                  </div>
                  <div className={styles.right_column}>
                    <MartenDetailBlockRight />
                  </div>
                </>
              ) : (
                <div className={styles.mobile_column}>
                  <MartenDetailBlockLeft />
                  <MartenDetailBlockRight />
                </div>
              )}
            </>
          )}

          {watch('damageType') === 'Totaldiebstahl' && (
            <>
              {windowWidth > 900 ? (
                <>
                  <div className={styles.left_column}>
                    <ThiefDetailBlockLeft />
                  </div>
                  <div className={styles.right_column}>
                    <ThiefDetailBlockRight />
                  </div>
                </>
              ) : (
                <div className={styles.mobile_column}>
                  <ThiefDetailBlockLeft />
                  <ThiefDetailBlockRight />
                </div>
              )}
            </>
          )}

          {watch('damageType') === 'Einbruch / Verlust / Teildiebstahl' && (
            <>
              {windowWidth > 900 ? (
                <>
                  <div className={styles.left_column}>
                    <BurglaryDetailBlockLeft />
                  </div>
                  <div className={styles.right_column}>
                    <BurglaryDetailBlockRight />
                  </div>
                </>
              ) : (
                <div className={styles.mobile_column}>
                  <BurglaryDetailBlockLeft />
                  <BurglaryDetailBlockRight />
                </div>
              )}
            </>
          )}

          {watch('damageType') === 'Sonstiges' && (
            <>
              {windowWidth > 900 ? (
                <>
                  <div className={styles.left_column}>
                    <OtherDetailBlockLeft />
                  </div>
                  <div className={styles.right_column}>
                    <OtherDetailBlockRight />
                  </div>
                </>
              ) : (
                <div className={styles.mobile_column}>
                  <OtherDetailBlockLeft />
                  <OtherDetailBlockRight />
                </div>
              )}
            </>
          )}

          {watch('damageType') === 'Sachbesch./ Vandalismus' && (
            <>
              {windowWidth > 900 ? (
                <>
                  <div className={styles.left_column}>
                    <VandalismDetailBlockLeft />
                  </div>
                  <div className={styles.right_column}>
                    <VandalismDetailBlockRight />
                  </div>
                </>
              ) : (
                <div className={styles.mobile_column}>
                  <VandalismDetailBlockLeft />
                  <VandalismDetailBlockRight />
                </div>
              )}
            </>
          )}

          <div className={styles.buttons_block}>
            <RequestComponent
              reactHookFormData={reactHookFormData}
              moveToPreviousStep={moveToPreviousStep}
              errorMessage={(type !== 'success' && !!message)  ? message : ''}
            />
          </div>
        </section>
      </CSSTransition>
    </div>
  );
};

export default DetailPage;
