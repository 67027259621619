import { Flex, Text } from 'common/common-components.styled';
import FormHook from 'components/form-hook/form-hook';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useConditionStep } from './use-condition-step';
import InfoHint from 'components/info-hint';
import AddIcon from 'components/icons/add-icon';
import DamagePartSection from './components';

const ConditionStep = () => {
  const reactHookFormData = useFormContext();
  const {
    interiorConditionField,
    suspensionConditionField,
    upholsteryConditionField,
    carDamageField,
    damagedPartsSections,
    removeDamagedPart,
    addAdditionalSection
  } = useConditionStep(reactHookFormData);

  return (
    <Flex width='100%' direction='column'>
      <div>
        <Text size='16' color='darkBlue'>
          Zustand
        </Text>
      </div>

      <Flex width='100%' top='30' direction='column'>
        <Flex gap='10px' width='100%'>
          <FormHook
            reactHookFormData={reactHookFormData}
            formFields={interiorConditionField}
            distanceBetweenFields='24'
          />
          <FormHook
            reactHookFormData={reactHookFormData}
            formFields={suspensionConditionField}
            distanceBetweenFields='24'
          />
        </Flex>

        <Flex>
          <FormHook
            reactHookFormData={reactHookFormData}
            formFields={upholsteryConditionField}
            distanceBetweenFields='0'
          />
        </Flex>

        <Flex margin='24px 0'>
          <InfoHint>
            Sind am Fahrzeug offensichtliche Schäden an der Karosserie, dem Lack oder an der Verglasung vorhanden?
            Schäden auf der Abbildung nummerieren und erklären. Größe der Schäden in cm angeben. Bei größen Schäden
            Fotos anfügen. Klicke auf die beschädigten Teile, um Fotos und eine Beschreibung hinzuzufügen.
          </InfoHint>
        </Flex>

        <Flex>
          <FormHook reactHookFormData={reactHookFormData} formFields={carDamageField} distanceBetweenFields='0' />
        </Flex>

        <Flex direction='column'>
          {Boolean(damagedPartsSections.length) &&
            damagedPartsSections.map((form, sectionIndex) => (
              <Flex key={sectionIndex} direction='column' bottom='16'>
                {form.map((item, index) => (
                  <DamagePartSection
                    key={index}
                    form={item}
                    removeDamagedPart={() => removeDamagedPart(item.value, index, sectionIndex)}
                  />
                ))}

                <Flex>
                  <Flex gap='10px' pointer onClick={() => addAdditionalSection(sectionIndex)}>
                    <AddIcon />

                    <Text color='darkBlue'>Eine weitere Schadensart zu diesem Teil hinzufügen </Text>
                  </Flex>
                </Flex>
              </Flex>
            ))}
        </Flex>

        <Flex>
          <InfoHint>
            Bitte beachten Sie, dass Sie nach dem Klicken auf „Weiter“ keine Änderungen mehr vornehmen können.
            Überprüfen Sie bitte noch einmal, ob Sie alle Angaben gemacht, bevor Sie das Formular absenden.
          </InfoHint>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ConditionStep;
