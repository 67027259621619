import { useEffect, useState } from 'react';
import useReleaseData from './use-release-data';
import useVehicleApprovalPage from './use-vehicle-approval-page';
import { ApproveComponentPriceProps } from '../approve-component/approve-component-price/approve-component-price.types';
import { getExpectedReferenceRate } from 'pages/car-configurator/hooks/use-info-containers-data';
import { CONFIGURATION_TYPES } from '../../../constants';

const useBudgetDetailsFields = () => {
  const [budgetDetailsFields, setBudgetDetailsFields] = useState<ApproveComponentPriceProps[]>([]);

  const { vehicleOfferData, globalVehicleOfferData } = useReleaseData();
  const { vehicleApprovalPageData } = useVehicleApprovalPage();

  useEffect(() => {
    if (vehicleApprovalPageData?.uds_leasingangeboteid && vehicleOfferData) {
      const leaseRate = getExpectedReferenceRate({
        netListPrice: vehicleApprovalPageData.uds_listenpreis_netto?.value || 0,
        leasingFactor: vehicleApprovalPageData.uds_leasingfaktor || 0,
        leaseServices: vehicleApprovalPageData.lease_services || 0
      });
      let driverSupplement =
        (vehicleApprovalPageData.uds_monthly_fuel_costs?.value || 0) + leaseRate - vehicleApprovalPageData.budget;
      driverSupplement = driverSupplement > 0 ? driverSupplement : 0;

      const isBulkOrder =
        globalVehicleOfferData?.uds_configurationtypecode?.value === CONFIGURATION_TYPES.bulkOrder.value;
      let newBudgetDetailsFields: ApproveComponentPriceProps[] = [];
      if (isBulkOrder) {
        const leaseRateForBulkOrder = vehicleApprovalPageData.uds_gesamtkosten_monatlich_vertragsabschluss?.value || 0;
        const budgetForBulkOrder = vehicleApprovalPageData.budget || 0;
        // const subsidy = vehicleApprovalPageData.uds_subvention?.value || 0;
        const subsidy = budgetForBulkOrder - leaseRateForBulkOrder;
        const subsidyToShow = subsidy < 0 ? subsidy : 0;
        newBudgetDetailsFields = [
          { label: 'Budget:', price: budgetForBulkOrder },
          {
            label: 'Leasingrate:',
            price: leaseRateForBulkOrder
          },
          {
            label: 'Subvention:',
            price: subsidyToShow,
            bottom: '15'
          },
          {
            label: 'Gesamtkosten monatlich:',
            price: leaseRateForBulkOrder + subsidyToShow
          }
        ];
      } else {
        newBudgetDetailsFields = [
          { label: 'Bruttolistenpreis inkl. Sonderausstattung:', price: vehicleOfferData.uds_listenpreis?.value || 0 },
          { label: 'Budget:', price: vehicleApprovalPageData.budget },
          { label: 'Zulässiger Eigenanteil:', price: vehicleApprovalPageData.absoluteDownPayment, bottom: '15' },
          {
            label: 'Voraussichtliche Leasingrate:',
            price: leaseRate
          },
          {
            label: 'Kalkulatorische Kraftstoffkosten:',
            price: vehicleApprovalPageData.uds_monthly_fuel_costs?.value || 0
          },
          {
            label: 'Gesamtkosten monatlich:',
            price: (vehicleApprovalPageData.uds_monthly_fuel_costs?.value || 0) + leaseRate,
            bottom: '15'
          },
          {
            label: 'Finaler Eigenanteil:',
            price: driverSupplement || 0
          }
        ];
      }

      setBudgetDetailsFields(newBudgetDetailsFields);
    }
  }, [vehicleOfferData, vehicleApprovalPageData, globalVehicleOfferData]);

  return { budgetDetailsFields };
};

export default useBudgetDetailsFields;
