import React from 'react';
import { Text, Flex, Button } from 'common/common-components.styled';
import {
  StyledSettingsWrapper,
  StyledItemsContainer,
  StyledDivider,
  ButtonsContainer
} from './columns-settings.styled';
import ColumnItem from './column-item/column-item';
import { RefreshArrow } from 'components/icons/refresh-arrow';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useColumnSettings } from './use-column-settings';
import { ColumnSettingsProps } from './column-settings.types';
import { useDispatch } from 'react-redux';
import { toggleModal } from 'actions/app_action';
import { MODALS } from '../../constants';

const ColumnsSettings: React.FC<ColumnSettingsProps> = ({ defaultColumns, customColumns, saveSettings }) => {
  const { columns, checkboxDisabled, toggleItem, onDragEnd, reset } = useColumnSettings(defaultColumns, customColumns);
  const dispatch = useDispatch();

  const closeAlert = () => {
    dispatch(toggleModal(MODALS.alert, null));
  };

  return (
    <StyledSettingsWrapper>
      <Text color='black' bottom='20'>
        Wähle aus, welche Spalten du angezeigt bekommen möchtest. Ziehe die Spalten, um die Reihenfolge zu ändern.
      </Text>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId='list'>
          {provided => (
            <StyledItemsContainer ref={provided.innerRef} {...provided.droppableProps}>
              <PerfectScrollbar id='columnSettings'>
                {columns.map((column, index) => (
                  <>
                    {column.title && (
                      <ColumnItem
                        key={column.title}
                        column={column}
                        onChange={() => toggleItem(column)}
                        index={index}
                        disableCheckbox={checkboxDisabled}
                      />
                    )}
                  </>
                ))}
                {provided.placeholder}
              </PerfectScrollbar>
            </StyledItemsContainer>
          )}
        </Droppable>
      </DragDropContext>
      <StyledDivider />
      <ButtonsContainer>
        <Flex onClick={reset} pointer>
          <RefreshArrow />
          <Text color='blue' fontWeight={500} left='10'>
            Zurücksetzen
          </Text>
        </Flex>
        <Flex justify='flex-end'>
          <Button secondary right='10' onClick={closeAlert}>
            Abbrechen
          </Button>
          <Button onClick={() => saveSettings(columns)}>Speichern</Button>
        </Flex>
      </ButtonsContainer>
    </StyledSettingsWrapper>
  );
};

export default ColumnsSettings;
