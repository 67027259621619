import React from 'react';
import LeasingWidget from '../components/leasing-widget';
import { CarContractIcon } from 'components/icons-new/car-contract-icon';
import { useTheme } from 'styled-components';
import WidgetWrapper from '../components/widget-wrapper/widget-wrapper';
import { useChecklist } from './use-checklist';
import ChecklistButtons from './components/checklist-buttons';
import { DescriptionContainer } from '../components/leasing-widget/leasing-widget.styled';
import ChecklistModal from 'components/checklist-modal';
import { useQuery } from 'hooks';

const Checklist = ({}) => {
  const theme = useTheme();
  const { statusColor, statusLabel, description, showChecklistModal, setShowChecklistModal } = useChecklist();
  const query = useQuery();

  return (
    <LeasingWidget
      image={<CarContractIcon color={theme.colors.midBlue} width={60} height={40} />}
      statusColor={statusColor}
      statusLabel={statusLabel}
      buttons={<ChecklistButtons setShowChecklistModal={setShowChecklistModal} />}
      id={'checklist'}
    >
      <>
        <WidgetWrapper>
          <DescriptionContainer>{description}</DescriptionContainer>
        </WidgetWrapper>

        {showChecklistModal && (
          <ChecklistModal
            open={showChecklistModal}
            onClose={() => setShowChecklistModal(false)}
            carId={query.id as string}
          />
        )}
      </>
    </LeasingWidget>
  );
};

export default Checklist;
