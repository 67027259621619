/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from 'react';
import classes from './file.module.scss';
import { DropzoneOptions, DropzoneRootProps, FileError, FileRejection, useDropzone } from 'react-dropzone';
import classNames from 'classnames';
import { FileInfo } from 'components/file-info/file-info';
import { formatDate } from 'helpers';
import { PrefixForId } from 'common/enums';
import { removeSpaces } from 'utils/remove-spaces';
import { FileProps } from './file.props';
import { formFieldProvider } from '../../form-field';
import { FileAcceptInfo } from './components/accept-info';

export const File = ({ value, onChange, name, error, placeholder, dropzoneOptions }: FileProps) => {
  const files = Array.isArray(value) ? value : [];

  const { reactHookFormData, field } = useContext(formFieldProvider);

  const { setError } = reactHookFormData;
  const isFormMultiple = (dropzoneOptions || DEFAULT_DROPZONE_OPTIONS).multiple;
  const rootProps: DropzoneRootProps = {
    className: classNames(classes.upload_file, error && classes.error)
  };

  const getLastModifiedFile = (date: number) => formatDate(new Date(date).toISOString()) || '';

  const setMaxFileSizeError = (errors: FileError[]) => {
    const fileMaxSize = field.dropzoneOptions?.maxSize;

    if (fileMaxSize && errors.find(error => error.code === 'file-too-large')) {
      setError(name, {
        type: 'manual',
        message: `Die Datei überschreitet die zulässige Größe von ${Math.round(fileMaxSize / 1024 / 1024)} MB.`
      });
    }
  };

  const onDrop = (acceptedFiles: File[]) => {
    const newFiles = [...files, ...acceptedFiles];
    if (!isFormMultiple) {
      newFiles.length = 1;
    }

    if (dropzoneOptions?.maxFiles && newFiles.length > dropzoneOptions?.maxFiles) {
      newFiles.length = dropzoneOptions?.maxFiles;
    }

    if ((value.length !== 0 || !!newFiles.length) && value.length !== newFiles.length) {
      onChange(newFiles);
    }
  };

  const onDropRejected = (rejectedFiles: FileRejection[]) => {
    rejectedFiles.map(rejectFiles => {
      setMaxFileSizeError(rejectFiles.errors);
    });
  };

  const handleRemoveFile = (deletedFileIndex: number) => {
    let newFiles = [...files];

    newFiles = newFiles.filter((_, index) => index !== deletedFileIndex);

    onChange(newFiles);
  };

  const { getRootProps, getInputProps } = useDropzone({
    ...(dropzoneOptions || DEFAULT_DROPZONE_OPTIONS),
    onDrop,
    onDropRejected
  });

  return (
    <div>
      <div
        id={removeSpaces(name) + PrefixForId.File + PrefixForId.Field}
        {...getRootProps(rootProps)}
        ref={e => {
          if (error) {
            e?.scrollIntoView({ behavior: 'smooth', block: 'center' });
          }
        }}
      >
        <img
          src={files.length ? '/assets/images/uploaded_icon.svg' : '/assets/images/upload_icon.svg'}
          draggable='false'
          alt='upload icon'
        />
        <span>{placeholder}</span>
        <span className={classes.upload_link}>Datei hochladen</span>
        <input id='file' {...getInputProps()} />
      </div>

      <FileAcceptInfo dropzoneOptions={dropzoneOptions} />

      <div className={classes.files}>
        {files?.map((file, index) => (
          <FileInfo
            id={removeSpaces(name) + PrefixForId.File + PrefixForId.Field + PrefixForId.Items + index}
            key={index}
            fileName={file.name}
            fileSize={file.size}
            lastModified={getLastModifiedFile(file.lastModified)}
            file={file as any}
            hideCheckBox
            onDelete={() => handleRemoveFile(index)}
            withoutFileViewer
          />
        ))}
      </div>
    </div>
  );
};

const DEFAULT_DROPZONE_OPTIONS: DropzoneOptions = {
  multiple: true,
  maxSize: 10485760
};
