import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import RootPage from './list-view';
import { setListId, setStartLoading, toggleSidebar as toggleSidebarAction } from '../../actions/app_action';

const mapStateToProps = state => ({
  sidebarOpen: state.app.listSidebarOpen,
});

const mapDispatchToState = dispatch => ({
  setList(id) {
    dispatch(setListId(id));
  },
  showOverlay() {
    dispatch(setStartLoading());
  },
  toggleSidebar(flag) {
    dispatch(toggleSidebarAction(flag));
  }
});

export default connect(mapStateToProps, mapDispatchToState)(withRouter(RootPage));
