import React, { useState, useCallback, useMemo, MouseEvent } from 'react';
import {
  CONFIGURATION_TYPES,
  defaultCarImageUrl,
  OFFER_STATUSES,
  Transmission,
  VEHICLE_OFFER_ASSIGNMENT,
  VEHICLE_OFFER_EVENT_DESCRIPTIONS
} from '../../constants';
import { deactiveteVehicle } from '../../services/vehicle-service';
import toMoney from '../../utils/toMoney';
import ConsumptionSmallText from '../consumption-small-text';
import More, { MoreMenuItem } from '../more';
import Spinner from 'components/spinner';
import AllInclusive from './all-inclusive';
import Price from './price';
import classes from './product.module.scss';
import './product.scss';
import { CircleQuestionMarkIcon } from '../icons/circle-question-mark';
import TooltipHint from '../tooltip-hint/tooltip-hint';
import DownArrow from '../icons/down_arrow';
import { OrderIcon } from '../icons/order-icon';
import { CalendarIcon } from '../icons/calendar-icon';
import { UsersIcon } from '../icons/users-icon';
import { getDateString } from '../../utils/convert-UTC-date-to-local-date';
import { encodeBase64 } from '../../utils/decode-encode-base64';
import { Flex, Text } from '../../common/common-components.styled';
import { formFields } from '../monatsrechnungen/formFields/formFields';
import Form from 'components/form/form-redux';
import { createNewTaskHandler } from 'utils/create-new-task-handler';
import Snackbar from '../snackbar';
import { useLocation, useHistory } from 'react-router-dom';
import { useTypedSelector } from 'hooks/use-typed-selector';
import { useOverlay, useQuery } from 'hooks';
import { useDispatch } from 'react-redux';
import { addToComparison, deleteFromComparison, toggleComparisonPanel } from '../../actions/comparison_action';
import { setForm, toggleModal } from '../../actions/app_action';
import { MODALS, MODAL_NAMES } from '../../constants';
import {
  DateInfoProps,
  TriangleProps,
  InfoItemProps,
  ProductInfoProps,
  ProductProps,
  ItemProps
} from './product-view.props';
import { useTheme } from 'styled-components';
import { CarWithEuroIcon } from '../icons/offer-statuses/car-with-euro-icon';
import { GasStationIcon } from '../icons';
import { useVehileLogo } from 'hooks/react-query/vehile-logo';
import useLatestValue from 'hooks/use-latest-value';
import classNames from 'classnames';
import { PAGES } from 'common/enums';
import { AskQuestionIcon } from 'components/icons/ask-qustion-icon';
import ButtonWithIcon from 'components/button-with-icon';
import {
  SUBMIT_GRV_BUTTON_TEXT_DRIVER,
  SUBMIT_GRV_BUTTON_TEXT_FLEET_MANAGER
} from 'pages/details/order-widget/order-widget';
import { SettingsIconOutlined } from 'components/icons/settings-icon-outlined';
import { removeSpaces } from 'utils/remove-spaces';
import { getTileNumber } from 'components/product/utils';
import { openInNewTabHandler } from 'utils/open-page-in-new-tab';
import { getStatusSectionEventDescriptions } from 'components/order-status-section/utils';
import { useAllAppPages } from 'components/order-status-section/use-all-app-pages';
import { getDataForTable } from 'utils/get-response-data';
import { VehicleOfferEventData } from 'components/order-status-section/order-status-section.props';
import useVehicleOffersEventDescriptions from 'hooks/react-query/use-vehicle-offers-event-descriptions';
import useOfferStatus from 'hooks/react-query/use-offer-status';
import useLatestEvents from 'hooks/use-latest-events';
import { VehicleOfferEventDescription } from 'common/interfaces';
import useOldestEvents from 'hooks/use-oldest-events';
import useDeactivateDialog from './hooks/use-deactivate-dialog';
import { Configuration } from 'components/vehicle-offers-list-component/hooks/react-query/use-drivers-personal-configurations/use-drivers-personal-configurations.types';
import { StyledCarPolicy } from 'pages/release-external-page/release-external-page.styled';

export const DateInfo = ({ icon, title, value }: DateInfoProps) => {
  return (
    <div className={classes.date_info}>
      <>
        {icon}
        {title && (
          <span id={removeSpaces(title) + 'Title'} className={classes.title}>
            {title}
          </span>
        )}
        <span id={removeSpaces(title) + 'Value'} className={classes.date}>
          {value}
        </span>
      </>
    </div>
  );
};

const Triangle = ({ width, height, color, rotate }: TriangleProps) => {
  return (
    <div
      style={{
        width: '0',
        height: '0',
        borderStyle: 'solid',
        borderWidth: `0 0 ${height}px ${width}px`,
        borderColor: `transparent transparent ${color} transparent`,
        transform: `rotate(${rotate}deg)`
      }}
    />
  );
};

const InfoItem = ({ children }: InfoItemProps) => {
  return (
    <div className='d-flex'>
      <Triangle width={4} height={24} color={'#102F3F'} />
      <div style={{ width: '5px', height: '24px', backgroundColor: '#102F3F' }} />
      <Triangle width={4} height={24} color={'#102F3F'} rotate={180} />
      <div className='d-flex' style={{ marginLeft: '-4px' }}>
        <Triangle width={4} height={24} color={'#009383'} />
        <div className={classes.new_uds_info_item}>{children}</div>
        <Triangle width={4} height={24} color={'#009383'} rotate={180} />
      </div>
    </div>
  );
};

const ProductInfo = ({ label, value }: ProductInfoProps) => {
  return (
    <div className={classes.new_uds_product_info}>
      <p id={removeSpaces(label) + 'Label'} className={classes.new_uds_label}>
        {label}
      </p>
      <p id={removeSpaces(label) + 'Value'} className={classes.new_uds_value}>
        {value}
      </p>
    </div>
  );
};

const CONFIRM_BUTTON_TEXT = {
  [OFFER_STATUSES.configurationCreated]: 'Leasingangebot anfragen',
  [OFFER_STATUSES.requirementApproved]: 'Leasingfahrzeug anfordern',
  [OFFER_STATUSES.vehicleConfigurationBasedOnGRVCreated]: 'Leasingfahrzeug anfordern',
  [OFFER_STATUSES.purchaseTriggered]: 'Leasinganforderung freigeben',
  [OFFER_STATUSES.globalReferenceVehicleGeneratedViaAPI]: 'Konfigurieren'
};

const spinnerStyle = {
  width: '50px',
  height: '50px',
  position: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%,-50%)'
};

const detailsPagesMapping = {
  [PAGES.CONFIGURATIONS]: PAGES.CONFIGURATION_DETAILS,
  [PAGES.EMPLOYEE_CONFIGURATIONS]: PAGES.EMPLOYEE_CONFIGURATION_DETAILS
};

const Product = ({ reloadList, item, setUseQueryKey }: ProductProps) => {
  const query = useQuery();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const theme = useTheme();
  const user = useTypedSelector(state => state.app.user);
  const comparisonCars = useTypedSelector(state => state.comparison.cars);
  const { vehicleOffersEventDescriptions } = useVehicleOffersEventDescriptions();
  const forms = useTypedSelector(state => state.app.forms);
  const formsLatest = useLatestValue(forms);
  const [showOverlay, hideOverlay] = useOverlay();

  const isEmployeeConfigurationsPage = location.pathname.includes(PAGES.EMPLOYEE_CONFIGURATIONS);
  const isNewEmployeeConfigurationsPage = location.pathname.includes(PAGES.NEW_REFERENCE_CONFIGURATIONS);
  const isDriverConfigurationPage =
    location.pathname.includes(PAGES.CONFIGURATIONS) && !location.pathname.includes(PAGES.NEW_CONFIGURATIONS);

  const isCarCompared = !!comparisonCars.find(
    (car: ItemProps) => car.uds_fahrzeugangeboteid === item.uds_fahrzeugangeboteid
  );
  const isPoolcar = item.uds_zuordnung_value === VEHICLE_OFFER_ASSIGNMENT.poolCar.value;
  const isFahrzeugeDerCarPolicyPage =
    location.pathname.includes(PAGES.REFERENCE_VEHICLES) &&
    !location.pathname.includes(PAGES.NEW_REFERENCE_CONFIGURATIONS);
  const isNewDriverConfigurationPage = location.pathname.includes(PAGES.NEW_CONFIGURATIONS);
  const isPersonalConfiguration =
    item.uds_configurationtypecode_value === CONFIGURATION_TYPES.personalConfiguration.value;

  const {
    offerRequestedEvent,
    vehicleRequestedEvent,
    approvedByFleetManagerEvent,
    orderApprovedEvent,
    approvalRejectedEvent,
    globalReferenceVehicleGeneratedViaAPIEvent,
    latestEvent
  } = useLatestEvents(item.events);
  const {
    configurationCreatedEvent,
    vehicleConfigurationBasedOnGRVCreatedEvent,
    offerPublishedForUsersEvent,
    vehicleBasedOnBulkOrderCreatedEvent
  } = useOldestEvents(item.events);
  const configuration: Configuration = {
    ...item,
    uds_remove_request_date: { value: item.uds_remove_request_date, label: '' }
  };
  const { openDeactivateDialog } = useDeactivateDialog({ configuration, refreshList: () => reloadList(true) });

  const queryData = query.data || null;

  const [isShowAllesInklusive, setIsShowAllesInklusive] = useState(false);
  const configurationUploaded =
    configurationCreatedEvent?.uds_eventdescription.attributeValue.id ===
    VEHICLE_OFFER_EVENT_DESCRIPTIONS.configurationUploaded.id;
  const isBasedOnGlobalReferenceVehicle = !!vehicleConfigurationBasedOnGRVCreatedEvent;
  const isGlobalReferenceVehicle = !!globalReferenceVehicleGeneratedViaAPIEvent;
  const isBulkOrderVehicle =
    !!offerPublishedForUsersEvent && item.uds_configurationtypecode_value === CONFIGURATION_TYPES.bulkOrder.value;
  const isBasedOnBulkOrderVehicle = !!vehicleBasedOnBulkOrderCreatedEvent;

  const { data: img, isFetched: imgIsFetched } = useVehileLogo(item.sharepointLocation, defaultCarImageUrl);

  const { offerStatus: vehicleOfferStatus } = useOfferStatus(
    item.events,
    isEmployeeConfigurationsPage || isNewEmployeeConfigurationsPage,
    isNewDriverConfigurationPage
  );

  const isOrderCompleted = [OFFER_STATUSES.orderCompleted].includes(vehicleOfferStatus);

  const fuelCost = item.includeFuelCosts
    ? isGlobalReferenceVehicle
      ? item.kraftstoffcosten_value
      : item.kraftstoffcosten
    : 0;
  const priceWithoutFuelCost = isGlobalReferenceVehicle
    ? item.uds_gesamtkosten_monatlich_vertragsabschluss_value
    : isOrderCompleted
    ? item.kraftstoffcostenPlusGesamtrate - fuelCost
    : item.uds_mobexo_gesamtrate_value;

  const [snackbar, setSnackbar] = useState({
    message: '',
    type: ''
  });

  const getButtonText = () => {
    if (
      [
        OFFER_STATUSES.configurationCreated,
        OFFER_STATUSES.requirementApproved,
        OFFER_STATUSES.purchaseTriggered,
        OFFER_STATUSES.globalReferenceVehicleGeneratedViaAPI,
        OFFER_STATUSES.vehicleConfigurationBasedOnGRVCreated
      ].includes(vehicleOfferStatus) &&
      !isFahrzeugeDerCarPolicyPage &&
      !isBasedOnGlobalReferenceVehicle &&
      !isBulkOrderVehicle
    ) {
      return CONFIRM_BUTTON_TEXT[vehicleOfferStatus];
    }

    if (
      [
        OFFER_STATUSES.vehicleConfigurationBasedOnGRVCreated,
        OFFER_STATUSES.vehicleRequested,
        OFFER_STATUSES.buildAbilityConfirmed
      ].includes(vehicleOfferStatus) &&
      !(
        [OFFER_STATUSES.vehicleRequested, OFFER_STATUSES.buildAbilityConfirmed].includes(vehicleOfferStatus) &&
        isDriverConfigurationPage
      ) &&
      !isFahrzeugeDerCarPolicyPage &&
      isBasedOnGlobalReferenceVehicle &&
      !isBulkOrderVehicle
    ) {
      return isEmployeeConfigurationsPage
        ? SUBMIT_GRV_BUTTON_TEXT_FLEET_MANAGER[vehicleOfferStatus]
        : SUBMIT_GRV_BUTTON_TEXT_DRIVER[vehicleOfferStatus];
    }

    if (isBulkOrderVehicle) {
      return 'Fahrzeug anfordern';
    }

    if (
      isBasedOnBulkOrderVehicle &&
      isEmployeeConfigurationsPage &&
      [OFFER_STATUSES.vehicleBasedOnBulkOrderCreated].includes(vehicleOfferStatus)
    ) {
      return 'Freigabe anfordern';
    }

    return 'Zu den Details';
  };

  const openPage = async (ctrlKeyPressed?:boolean) => {
    !ctrlKeyPressed && showOverlay();
    !ctrlKeyPressed && dispatch(toggleComparisonPanel(false));
    try {
      const id = encodeBase64(item.uds_fahrzeugangeboteid as string);

      let link = `${
        isEmployeeConfigurationsPage
          ? PAGES.EMPLOYEE_CONFIGURATION_DETAILS
          : isNewEmployeeConfigurationsPage
          ? PAGES.REFERENCE_VEHICLES_DETAILS
          : PAGES.CONFIGURATION_DETAILS
      }?id=${id}`;
      if (queryData) {
        link += `&data=${queryData}`;
      }
      ctrlKeyPressed ?
      openInNewTabHandler(
         `${window.location.origin}${link}`,
         500
      ) :
      history.push(link);
    } catch (error) {
      //TODO: Add error handling
      console.log('Error while retrieving data for page redirect');
    } finally {
      !ctrlKeyPressed && hideOverlay();
    }
  };

  const showAllesInklusive = useCallback(() => {
    setIsShowAllesInklusive(true);
  }, []);

  const hideAllesInklusive = useCallback(() => {
    setIsShowAllesInklusive(false);
  }, []);

  const addCarToComparison = useCallback(
    (event: any) => {
      event.stopPropagation();
      if (comparisonCars.length < 4) {
        dispatch(addToComparison({ ...item, image: img }));
        dispatch(toggleComparisonPanel(true));
      }
    },
    [comparisonCars, dispatch, img, item]
  );

  const deleteCarFromComparison = useCallback(
    (event: any) => {
      event.stopPropagation();
      dispatch(deleteFromComparison(item.uds_fahrzeugangeboteid));
    },
    [dispatch, item]
  );

  const snackbarSetup = useCallback((message: any, type: any) => {
    setSnackbar({
      message: message,
      type: type
    });
  }, []);

  const closeAlert = useCallback(() => {
    dispatch(toggleModal(MODALS.alert, null));
  }, [dispatch]);

  const openCreateNewTaskModal = useCallback(
    (e: any) => {
      e.stopPropagation();
      const alertData = {
        title: 'Neue Frage',
        children: <Form name='frage' formFields={formFields} />,
        buttons: [
          {
            type: 'cancel',
            title: 'Abbrechen',
            action: closeAlert
          },
          {
            type: 'submit',
            title: 'Speichern',
            action: () =>
              createNewTaskHandler({
                itemId: item.uds_fahrzeugangeboteid as string,
                userId: user.id,
                closeAlert: closeAlert,
                dispatchForm: form => {
                  dispatch(setForm(form));
                },
                formsLatest: formsLatest.current,
                hideOverlay: hideOverlay,
                showOverlay: showOverlay,
                snackbarSetup: snackbarSetup,
                logicalName: 'uds_fahrzeugangebote'
              })
          }
        ]
      };
      dispatch(toggleModal(MODALS.alert, MODAL_NAMES.alert, alertData));
    },
    [dispatch, formsLatest.current, hideOverlay, item, showOverlay, snackbarSetup, user, closeAlert]
  );

  const productOnClick = (e:MouseEvent) => {
    if (!isFahrzeugeDerCarPolicyPage) {
      const ctrlKeyPressed = e?.ctrlKey;
      if(ctrlKeyPressed){
        return openPage(ctrlKeyPressed);
      }
      openPage();
      setUseQueryKey();
    }
  };

  const isNewUdsOrderedClassName =
    [OFFER_STATUSES.vehicleRequested, OFFER_STATUSES.purchaseTriggered, OFFER_STATUSES.orderApproved].includes(
      vehicleOfferStatus
    ) &&
    !(vehicleOfferStatus === OFFER_STATUSES.vehicleRequested && isEmployeeConfigurationsPage) &&
    !(vehicleOfferStatus === OFFER_STATUSES.orderApproved && isEmployeeConfigurationsPage) &&
    !isNewEmployeeConfigurationsPage &&
    !isFahrzeugeDerCarPolicyPage;

  const containerClassName = classNames(classes.new_uds_product, {
    [classes.new_uds_ordered]: isNewUdsOrderedClassName,
    [classes.new_uds_canceled]: !isNewUdsOrderedClassName && vehicleOfferStatus === OFFER_STATUSES.approvalRejected,
    table: !isFahrzeugeDerCarPolicyPage
  });

  const events: VehicleOfferEventData[] = useMemo(() => getDataForTable(item.events || []), [item.events]);

  const { appPages } = useAllAppPages();
  const pageUrl = location.pathname.replace(/\/$/, '') as keyof typeof detailsPagesMapping;
  const mainRoleId = user.mainRole?.id;
  const appPage = useMemo(
    () => appPages?.find(appPage => appPage.uds_page_url === detailsPagesMapping[pageUrl]),
    [appPages]
  );

  const eventDescriptions = useMemo(
    () => getStatusSectionEventDescriptions(vehicleOffersEventDescriptions || [], events, mainRoleId, appPage),
    [vehicleOffersEventDescriptions, events, appPage]
  );

  const eventDescription = useMemo(
    () =>
      eventDescriptions.filter(eventDescription =>
        events.some(
          event => event.uds_eventdescription_id === eventDescription.uds_fahrzeugbestellungeneventdescriptionid
        )
      )[0] as VehicleOfferEventDescription | undefined,
    [eventDescriptions, events]
  );
  const currentEventDescriptionWebRole = eventDescription?.webroles.find(
    webrole => webrole.uds_apppageid_id === appPage?.uds_appageid && webrole.uds_b2c_webrollid_id === mainRoleId
  );
  const eventDescriptionAppText = currentEventDescriptionWebRole?.uds_app_status_text_when_done
    ? currentEventDescriptionWebRole.uds_app_status_text_when_done
    : currentEventDescriptionWebRole?.uds_app_status_text || '';

  const targetEvent = useMemo(
    () =>
      events.find(
        event => event.uds_eventdescription_id === eventDescription?.uds_fahrzeugbestellungeneventdescriptionid
      ),
    [events, eventDescription]
  );

  return (
    <section
      id={item.uds_name + 'Tile'}
      style={{ cursor: isFahrzeugeDerCarPolicyPage ? 'default' : 'pointer' }}
      onClick={productOnClick}
      className={containerClassName}
    >
      {(isNewDriverConfigurationPage || isNewEmployeeConfigurationsPage) && item.isEco ? (
        <img className={classes.eco_badge} src='/assets/images/eco-badge.svg' alt='eco-badge' />
      ) : null}

      {isEmployeeConfigurationsPage ||
        (isDriverConfigurationPage && (
          <div className={'hidden-md hidden-lg'}>
            <More>
              <MoreMenuItem
                label='Konfiguration archivieren'
                minWidth={'235px'}
                onClick={openDeactivateDialog}
                img={<img src='/assets/images/delete.svg' />}
              />
            </More>
          </div>
        ))}

      {!!item.carPolicyList?.length && (
        <Flex bottom='10' wrap='wrap' align='center'>
          {item.carPolicyList.map(carPolicy => (
            <StyledCarPolicy key={carPolicy.uds_carpolicyid.attributeValue}>
              {carPolicy.uds_name.attributeValue}
            </StyledCarPolicy>
          ))}
        </Flex>
      )}

      <div className={classes.new_uds_content}>
        <div className={classes.new_uds_image_container}>
          {imgIsFetched ? null : <Spinner style={spinnerStyle} />}
          <img src={img} alt={`Foto - ${item.uds_title}`} />
          <ConsumptionSmallText item={item} styles={{ fontSize: '9px', marginTop: '0px' }} showFANumber={false} />
          {user.isTestUser && (isGlobalReferenceVehicle || isBasedOnGlobalReferenceVehicle) && (
            <Text top='10' color='grey400' size='12'>
              {getTileNumber(
                vehicleOfferStatus,
                isNewDriverConfigurationPage,
                isDriverConfigurationPage,
                isNewEmployeeConfigurationsPage,
                isEmployeeConfigurationsPage
              )}
            </Text>
          )}
        </div>

        <div className={classes.new_uds_description}>
          <Flex direction='column'>
            <h4 id={item.uds_name + 'VehicleTitle'}>{item.uds_title as string}</h4>
            <ProductInfo
              label={
                isNewDriverConfigurationPage || isNewEmployeeConfigurationsPage || isFahrzeugeDerCarPolicyPage
                  ? 'Referenzfahrzeug:'
                  : 'Konfiguration:'
              }
              value={item.uds_name}
            />
            <ProductInfo
              label='Bruttolistenpreis:'
              value={item.uds_listenpreis ? toMoney(item.uds_listenpreis) : '-'}
            />
            {configurationUploaded ? (
              <div className='d-flex mt-30'>
                <TooltipHint
                  position='top'
                  text='Da diese Konfiguration nicht über die App erstellt wurde,
                      stehen diese Informationen nicht zur Verfügung.'
                >
                  <CircleQuestionMarkIcon color='#102F3F' className={classes.question_icon} />
                </TooltipHint>
                <div className='mr-1' />
                <span>Warum sehe ich hier keine Informationen zum Fahrzeug?</span>
              </div>
            ) : (
              <>
                <ProductInfo label={'Treibstoff:'} value={item.engineType.name || '-'} />
                <ProductInfo label={'Leistung kW:'} value={String(item.uds_leistungkw || '-')} />
                <ProductInfo
                  label={'Getriebe:'}
                  value={Transmission[item.uds_getriebe_value as unknown as keyof typeof Transmission] || '-'}
                />
              </>
            )}
          </Flex>

          <Flex>
            <ButtonWithIcon
              tooltip='Frage stellen'
              icon={<AskQuestionIcon />}
              onClick={e => openCreateNewTaskModal(e)}
            />
          </Flex>
        </div>

        <div className={classes.new_uds_price_container}>
          <div>
            {isEmployeeConfigurationsPage || isDriverConfigurationPage ? (
              <div className={'hidden-xs hidden-sm mb-1'}>
                <More>
                  <MoreMenuItem
                    label='Konfiguration archivieren'
                    minWidth={'235px'}
                    onClick={openDeactivateDialog}
                    img={<img src='/assets/images/delete.svg' />}
                    isLeftPositionImg={true}
                  />
                </More>
              </div>
            ) : null}

            {[
              OFFER_STATUSES.requirementApproved,
              OFFER_STATUSES.vehicleRequested,
              OFFER_STATUSES.purchaseTriggered,
              OFFER_STATUSES.approvedByFleetManager,
              OFFER_STATUSES.orderApproved,
              OFFER_STATUSES.approvalRejected,
              OFFER_STATUSES.globalReferenceVehicleGeneratedViaAPI,
              OFFER_STATUSES.vehicleConfigurationBasedOnGRVCreated,
              OFFER_STATUSES.buildAbilityConfirmed,
              OFFER_STATUSES.orderCompleted,
              OFFER_STATUSES.dealershipSelected,
              OFFER_STATUSES.approvalFlowStarted
            ].includes(vehicleOfferStatus) ||
            (isEmployeeConfigurationsPage && priceWithoutFuelCost) ? (
              <div className={classes.new_uds_price_section}>
                <div className={classes.new_uds_netto_price}>
                  {(isNewDriverConfigurationPage ||
                    isDriverConfigurationPage ||
                    (isNewEmployeeConfigurationsPage && isGlobalReferenceVehicle) ||
                    (isEmployeeConfigurationsPage && isBasedOnGlobalReferenceVehicle)) &&
                  priceWithoutFuelCost &&
                  ![OFFER_STATUSES.orderApproved, OFFER_STATUSES.orderCompleted].includes(vehicleOfferStatus)
                    ? 'ab '
                    : ''}

                  <Price
                    // @ts-ignore
                    item={item}
                    type='/ Monat'
                    fuelCost={fuelCost}
                    priceWithoutFuelCost={priceWithoutFuelCost}
                  />
                </div>
                <div className={classes.new_uds_brutto_price}>
                  {(isNewDriverConfigurationPage ||
                    isDriverConfigurationPage ||
                    (isNewEmployeeConfigurationsPage && isGlobalReferenceVehicle) ||
                    (isEmployeeConfigurationsPage && isBasedOnGlobalReferenceVehicle)) &&
                  priceWithoutFuelCost &&
                  ![OFFER_STATUSES.orderApproved, OFFER_STATUSES.orderCompleted].includes(vehicleOfferStatus)
                    ? 'ab '
                    : ''}

                  <Price
                    // @ts-ignore
                    item={item}
                    type='(inkl. MwSt.)'
                    fuelCost={fuelCost}
                    priceWithoutFuelCost={priceWithoutFuelCost}
                  />
                </div>
              </div>
            ) : null}

            {fuelCost > 0 && (
              <>
                <Flex direction='column' top='25' bottom='10'>
                  <Flex align='center' justify='flex-end'>
                    <CarWithEuroIcon color={theme.colors.darkBlue} />
                    <Text bold color='darkBlue' size='18' left='10'>
                      {toMoney(priceWithoutFuelCost)}
                    </Text>
                  </Flex>

                  <Flex top='10' align='center' justify='flex-end'>
                    <GasStationIcon color={theme.colors.darkBlue} />
                    <Text bold color='darkBlue' size='18' left='10'>
                      {toMoney(fuelCost)}
                    </Text>
                  </Flex>
                </Flex>
              </>
            )}
          </div>

          {![OFFER_STATUSES.approvalRejected, OFFER_STATUSES.globalReferenceVehicleGeneratedViaAPI].includes(
            vehicleOfferStatus
          ) &&
            !isEmployeeConfigurationsPage &&
            !isBasedOnGlobalReferenceVehicle &&
            !isFahrzeugeDerCarPolicyPage && (
              <div className={classes.new_uds_info_section}>
                {!isNewEmployeeConfigurationsPage && (
                  <>
                    <InfoItem>{'Fahrzeug Anfordern'}</InfoItem>
                    <div style={{ marginBottom: '5px' }} />
                    <InfoItem>{'Freigabe bekommen'}</InfoItem>
                    <div style={{ marginBottom: '5px' }} />
                    <InfoItem>{'Neues Auto Fahren'}</InfoItem>
                  </>
                )}
              </div>
            )}

          <div className={classes.new_uds_buttons_wrapper}>
            {/*{!(*/}
            {/*  isNewEmployeeConfigurationsPage ||*/}
            {/*  isNewDriverConfigurationPage ||*/}
            {/*  isFahrzeugeDerCarPolicyPage ||*/}
            {/*  isBasedOnGlobalReferenceVehicle*/}
            {/*) && <OfferStatusSection status={vehicleOfferStatus} />}*/}

            {(isNewEmployeeConfigurationsPage || isFahrzeugeDerCarPolicyPage) &&
              !isBasedOnGlobalReferenceVehicle &&
              !isGlobalReferenceVehicle && (
                <div className='alles-inklusive'>
                  {isShowAllesInklusive && <AllInclusive />}
                  <Flex bottom='20' align='center' onMouseEnter={showAllesInklusive} onMouseLeave={hideAllesInklusive}>
                    <Text as='span' right='5' color='grey600' size='12'>
                      Alles inklusive - nur noch tanken!
                    </Text>
                    <CircleQuestionMarkIcon color={'#777777'} width='13' height='13' />
                  </Flex>
                </div>
              )}

            {!(
              isNewEmployeeConfigurationsPage ||
              isNewDriverConfigurationPage ||
              isFahrzeugeDerCarPolicyPage ||
              isBasedOnGlobalReferenceVehicle
            ) && (
              <div className={classes.new_uds_dates}>
                {isEmployeeConfigurationsPage && configurationCreatedEvent?.uds_erstelltfurid.attributeValue && (
                  <div style={{ marginBottom: '-2px' }}>
                    <DateInfo
                      icon={(<UsersIcon />) as unknown as Element}
                      title={isPoolcar ? '' : 'Fahrer:'}
                      value={
                        isPoolcar ? 'Poolcar' : configurationCreatedEvent.uds_erstelltfurid.attributeValue.name || ''
                      }
                    />
                  </div>
                )}
                {/*{[*/}
                {/*  OFFER_STATUSES.vehicleRequested,*/}
                {/*  OFFER_STATUSES.purchaseTriggered,*/}
                {/*  OFFER_STATUSES.approvedByFleetManager,*/}
                {/*  OFFER_STATUSES.orderApproved,*/}
                {/*  OFFER_STATUSES.approvalRejected*/}
                {/*].includes(vehicleOfferStatus) &&*/}
                {/*  vehicleRequestedEvent && (*/}
                {/*    <DateInfo*/}
                {/*      icon={<OrderIcon width='12' height='14' />}*/}
                {/*      title='Angefordert am:'*/}
                {/*      value={getDateString(vehicleRequestedEvent.createdon.attributeValue)}*/}
                {/*    />*/}
                {/*  )}*/}
                {/*{[OFFER_STATUSES.approvedByFleetManager].includes(vehicleOfferStatus) && (*/}
                {/*  <DateInfo*/}
                {/*    icon={<OrderIcon width='12' height='14' />}*/}
                {/*    title='Freigabe Fuhrparkleiter am:'*/}
                {/*    value={getDateString(approvedByFleetManagerEvent?.createdon.attributeValue)}*/}
                {/*  />*/}
                {/*)}*/}
                {/*{[OFFER_STATUSES.orderApproved].includes(vehicleOfferStatus) && (*/}
                {/*  <DateInfo*/}
                {/*    icon={<CalendarIcon width='12' height='14' />}*/}
                {/*    title='Vollständig freigegeben am:'*/}
                {/*    value={getDateString(orderApprovedEvent?.createdon.attributeValue)}*/}
                {/*  />*/}
                {/*)}*/}
                {/*{[OFFER_STATUSES.approvalRejected].includes(vehicleOfferStatus) && (*/}
                {/*  <DateInfo*/}
                {/*    icon={<CalendarIcon width='12' height='14' />}*/}
                {/*    title='Abgelehnt am:'*/}
                {/*    value={getDateString(approvalRejectedEvent?.createdon.attributeValue)}*/}
                {/*  />*/}
                {/*)}*/}
                {/*{[*/}
                {/*  OFFER_STATUSES.offerRequested,*/}
                {/*  OFFER_STATUSES.priceRequested,*/}
                {/*  OFFER_STATUSES.requirementApproved*/}
                {/*].includes(vehicleOfferStatus) &&*/}
                {/*  offerRequestedEvent && (*/}
                {/*    <DateInfo*/}
                {/*      icon={<OrderIcon width='12' height='14' />}*/}
                {/*      title='Leasingangebot angefragt am:'*/}
                {/*      value={getDateString(offerRequestedEvent.createdon.attributeValue)}*/}
                {/*    />*/}
                {/*  )}*/}
                {[
                  OFFER_STATUSES.offerRequested,
                  OFFER_STATUSES.priceRequested,
                  OFFER_STATUSES.requirementApproved,
                  OFFER_STATUSES.vehicleRequested,
                  OFFER_STATUSES.purchaseTriggered,
                  OFFER_STATUSES.approvedByFleetManager,
                  OFFER_STATUSES.orderApproved
                ].includes(vehicleOfferStatus) &&
                  item.uds_wunschlieferdatum && (
                    <DateInfo
                      icon={<CalendarIcon width='12' height='14' />}
                      title='Wunschlieferdatum:'
                      value={getDateString(item.uds_wunschlieferdatum)}
                    />
                  )}
              </div>
            )}

            {isBasedOnGlobalReferenceVehicle && (
              <div className={classes.new_uds_dates}>
                {/*{[*/}
                {/*  OFFER_STATUSES.vehicleConfigurationBasedOnGRVCreated,*/}
                {/*  OFFER_STATUSES.vehicleRequested,*/}
                {/*  OFFER_STATUSES.dealershipSelected,*/}
                {/*  OFFER_STATUSES.buildAbilityConfirmed,*/}
                {/*  OFFER_STATUSES.approvedByFleetManager*/}
                {/*].includes(vehicleOfferStatus) &&*/}
                {/*  !(*/}
                {/*    [*/}
                {/*      OFFER_STATUSES.vehicleRequested,*/}
                {/*      OFFER_STATUSES.dealershipSelected,*/}
                {/*      OFFER_STATUSES.buildAbilityConfirmed,*/}
                {/*      OFFER_STATUSES.approvedByFleetManager*/}
                {/*    ].includes(vehicleOfferStatus) && isDriverConfigurationPage*/}
                {/*  ) && (*/}
                {/*    <DateInfo*/}
                {/*      icon={<OrderIcon width='12' height='14' />}*/}
                {/*      title='Konfiguration erstellt am:'*/}
                {/*      value={getDateString(vehicleConfigurationBasedOnGRVCreatedEvent.createdon.attributeValue)}*/}
                {/*    />*/}
                {/*  )}*/}
                {/*{[*/}
                {/*  OFFER_STATUSES.vehicleRequested,*/}
                {/*  OFFER_STATUSES.orderApproved,*/}
                {/*  OFFER_STATUSES.dealershipSelected,*/}
                {/*  OFFER_STATUSES.buildAbilityConfirmed*/}
                {/*].includes(vehicleOfferStatus) &&*/}
                {/*  !isEmployeeConfigurationsPage && (*/}
                {/*    <DateInfo*/}
                {/*      icon={<OrderIcon width='12' height='14' />}*/}
                {/*      title='Freigabe angefordert am:'*/}
                {/*      value={getDateString(vehicleRequestedEvent?.createdon.attributeValue)}*/}
                {/*    />*/}
                {/*  )}*/}
                {/*{[*/}
                {/*  OFFER_STATUSES.vehicleRequested,*/}
                {/*  OFFER_STATUSES.purchaseTriggered,*/}
                {/*  OFFER_STATUSES.dealershipSelected,*/}
                {/*  OFFER_STATUSES.orderApproved,*/}
                {/*  OFFER_STATUSES.buildAbilityConfirmed*/}
                {/*].includes(vehicleOfferStatus) && (*/}
                {/*  <DateInfo*/}
                {/*    icon={<SettingsIconOutlined width='14' height='14' color={theme.colors.grey600} />}*/}
                {/*    title='Status zuletzt geändert am:'*/}
                {/*    value={getDateString(latestEvent?.createdon.attributeValue)}*/}
                {/*  />*/}
                {/*)}*/}
                {isEmployeeConfigurationsPage &&
                  vehicleConfigurationBasedOnGRVCreatedEvent?.uds_erstelltfurid.attributeValue && (
                    <DateInfo
                      icon={<UsersIcon />}
                      title='Fahrer:'
                      value={vehicleConfigurationBasedOnGRVCreatedEvent.uds_erstelltfurid.attributeValue.name || ''}
                    />
                  )}
              </div>
            )}

            {isBasedOnBulkOrderVehicle &&
              isEmployeeConfigurationsPage &&
              vehicleBasedOnBulkOrderCreatedEvent?.uds_erstelltfurid.attributeValue && (
                <div className={classes.new_uds_dates}>
                  <DateInfo
                    icon={<UsersIcon />}
                    title='Fahrer:'
                    value={vehicleBasedOnBulkOrderCreatedEvent.uds_erstelltfurid.attributeValue.name || ''}
                  />
                </div>
              )}

            {eventDescriptionAppText && targetEvent && (
              <div className={classes.new_uds_dates}>
                <DateInfo
                  icon={<OrderIcon width='12' height='14' />}
                  title={`${eventDescriptionAppText}:`}
                  value={getDateString(targetEvent.createdon)}
                />
              </div>
            )}

            <div className={classes.new_uds_buttons_container}>
              <div
                style={{ marginTop: '5px' }}
                className={`button-mobexo button-mobexo__main ${classes.new_uds_offer_offer}`}
              >
                {getButtonText()}
                <DownArrow className={classes.down_arrow} width={'10'} height={'14'} />
              </div>

              {!isPersonalConfiguration && (
                <>
                  <div className='mb-1' />
                  <div
                    onClick={isCarCompared ? deleteCarFromComparison : addCarToComparison}
                    className={`button-mobexo button-mobexo__secondary ${classes.new_uds_offer_offer}`}
                  >
                    {isCarCompared ? 'Vom Vergleich entfernen' : 'Zum Vergleich hinzufügen'}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={!!snackbar.message}
        type={snackbar.type}
        time={6000}
        onClose={() => {
          setSnackbar({ ...snackbar, message: '' });
        }}
        message={snackbar.message}
      />
    </section>
  );
};

export default Product;
