import React, { useContext } from 'react';
import { Flex, Text } from 'common/common-components.styled';
import { PlusLargeIcon } from 'components/icons/plus-large-icon';
import { useTheme } from 'styled-components';
import { formFieldProvider } from 'components/form-hook/form-field/form-field';
import { cloneDeep } from 'lodash';
import { damage } from '../car-damage-icon/utils';
import { cardDamageOptions } from '../car-damage-option-set/form-field';
import { OthersPartButtonProps } from './others-part-button.types';

export const OthersPartButton: React.FC<OthersPartButtonProps> = ({ field }) => {
  const { reactHookFormData } = useContext(formFieldProvider);
  const theme = useTheme();

  const { setValue, watch } = reactHookFormData;
  const damageListValue: Partial<typeof damage> = watch('damageList') || {};
  const isButtonDisable =
    watch('damageList')?.other?.length === cardDamageOptions.length ||
    field?.damageIconSettings?.disableOtherPartsButton;

  const addOtherPartHandler = () => {
    if (isButtonDisable) {
      return;
    }

    const cloneDamageList = cloneDeep(damageListValue) as typeof damage;
    const damageOtherListLength = watch('damageList')?.other?.length > 0 ? watch('damageList').other?.length - 1 : 0;
    const damageOtherListLastItem = (watch('damageList')?.other || [])[damageOtherListLength];

    return setValue('damageList', {
      ...cloneDamageList,
      other: [
        ...(watch('damageList')?.other || []),
        {
          ...damage.other,
          index: typeof damageOtherListLastItem?.index === 'number' ? damageOtherListLastItem?.index + 1 : 0
        }
      ]
    });
  };

  return (
    <Flex width='100%' justify='center' bottom='30' top='10'>
      <Flex align='center' cursor={isButtonDisable ? 'not-allowed' : 'pointer'} onClick={addOtherPartHandler}>
        <PlusLargeIcon color={theme.colors.blue} />
        <Text color='blue' left='10'>
          Sonstige Teile
        </Text>
      </Flex>
    </Flex>
  );
};
