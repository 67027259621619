import React from 'react';
import NumberFormat, { NumberFormatProps } from 'react-number-format';

export interface CustomPropsOnChangeEvent {
  target: { value: number | undefined };
}

export interface CustomProps extends Omit<NumberFormatProps, 'onChange'> {
  onChange: (event: CustomPropsOnChangeEvent) => void;
  name: string;
  decimalScale?: number;
  hideSuffix?: boolean;
}

export const NumberFormatCustom = React.forwardRef<NumberFormat<any>, CustomProps>((props, ref) => {
  const { onChange, hideSuffix, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={values => {
        onChange({ target: { value: values.floatValue } });
      }}
      thousandSeparator='.'
      decimalSeparator=','
      fixedDecimalScale={true}
      decimalScale={2}
      suffix={hideSuffix ? '' : ' €'}
    />
  );
});

export const NumberFormatPercentages = React.forwardRef<NumberFormat<any>, CustomProps>((props, ref) => {
  const { onChange, decimalScale, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={values => {
        onChange({ target: { value: values.floatValue } });
      }}
      thousandSeparator='.'
      decimalSeparator=','
      isAllowed={values => {
        const { formattedValue, floatValue } = values;
        return formattedValue === '' || (floatValue as number) <= 100;
      }}
      decimalScale={decimalScale}
      suffix=' %'
    />
  );
});

export const NumberFormatPhoneNumber = React.forwardRef<NumberFormat<any>, CustomProps>((props, ref) => {
  const { onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={values => {
        onChange({ target: { value: values.floatValue } });
      }}
      prefix='+49'
      style={{ marginBottom: '20px' }}
    />
  );
});

export const NumberFormatMonths = React.forwardRef<NumberFormat<any>, CustomProps>((props, ref) => {
  const { onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={values => {
        onChange({ target: { value: values.floatValue } });
      }}
      thousandSeparator='.'
      decimalSeparator=','
      suffix=' Monate'
      placeholder='XX Monate'
      decimalScale={0}
    />
  );
});

export const NumberFormatKilometers = React.forwardRef<NumberFormat<any>, CustomProps>((props, ref) => {
  const { onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={values => {
        onChange({ target: { value: values.floatValue } });
      }}
      thousandSeparator='.'
      decimalSeparator=','
      suffix=' km'
      decimalScale={0}
      placeholder='XXX.XXX km'
    />
  );
});

export const NumberFormatMillimeters = React.forwardRef<NumberFormat<any>, CustomProps>((props, ref) => {
  const { onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={values => {
        onChange({ target: { value: values.floatValue } });
      }}
      thousandSeparator='.'
      decimalSeparator=','
      suffix=' mm'
      decimalScale={1}
      placeholder='XX.X mm'
    />
  );
});

export const InputNumberFormats = React.forwardRef<NumberFormat<any>, CustomProps>((props, ref) => {
  const { onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={values => {
        onChange({ target: { value: values.floatValue } });
      }}
      thousandSeparator='.'
      decimalSeparator=','
      fixedDecimalScale={true}
    />
  );
});
