import { useFetch } from 'hooks/use-fetch/use-fetch';
import { ResponseModel, User } from 'common/interfaces';
import { useQuery, useQueryClient } from 'react-query';
import { PAGES, ReactQueryKeys } from 'common/enums';
import { accountRequestConfig } from 'request-config/account/account.request-config';
import { UserInfoResponse } from './use-user-info.types';
import { AxiosResponse } from 'axios';
import { setRoleSwitched, setUser } from 'actions/app_action';
import { defaultGuid } from '../../../constants';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from 'hooks/use-typed-selector';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const useUserInfo = (enabled: boolean = true) => {
  const dispatch = useDispatch();
  const user = useTypedSelector<User>(state => state['app'].user);
  const roleSwitched = useTypedSelector<User>(state => state['app'].roleSwitched);

  const history = useHistory();

  const { fetch } = useFetch<ResponseModel<UserInfoResponse>>({
    loadingKey: 'useUserInfo',
    isShowErrorSnackbar: false,
    ...accountRequestConfig.getUserInfo()
  });

  const modifyResponse = (data: AxiosResponse<UserInfoResponse>) => {
    if (data?.status === 200) {
      return data.data;
    }

    return undefined;
  };

  const { data: userInfo, isFetched } = useQuery<
    AxiosResponse<UserInfoResponse>,
    unknown,
    UserInfoResponse | undefined
  >(ReactQueryKeys.GetUserInfo, fetch, {
    select: modifyResponse,
    enabled
  });

  const queryClient = useQueryClient();

  const clearCacheExceptOne = (queryKeyToKeep: string) => {
    const allQueries = queryClient.getQueryCache().findAll();

    allQueries.forEach(query => {
      if (query.queryKey !== queryKeyToKeep) {
        queryClient.cancelQueries(query.queryKey);
        queryClient.removeQueries(query.queryKey);
      }
    });
  };

  useEffect(() => {
    if (isFetched && !userInfo?.mainB2CWebrolleId) {
      dispatch(
        setUser({
          ...user,
          companyId: defaultGuid
        })
      );
    }
  }, [userInfo, isFetched]);

  useEffect(() => {
    if (userInfo) {
      dispatch(setUser({ ...user, ...getUserData(userInfo) }));
      window.clarity && window.clarity('set', 'email', userInfo.email);

      if (roleSwitched) {
        dispatch(setRoleSwitched(false));
        history.push(PAGES.DASHBOARD);
        setTimeout(() => clearCacheExceptOne(ReactQueryKeys.GetUserInfo), 0);
      }
    }
  }, [userInfo?.mainB2CWebrolleId]);

  return { userInfo, isFetched };
};

export default useUserInfo;

const getUserData = (userResponse: UserInfoResponse) => {
  const userRoles = userResponse.roles.map(role => ({ id: role.uds_b2c_webroleid, name: role.uds_name }));
  return {
    ...userResponse,
    id: userResponse.id,
    name: userResponse.fullName,
    email: userResponse.email,
    companyId: userResponse.account?.id,
    isTestUser: userResponse.isTestUser,
    companyName: userResponse.account?.name,
    roles: userRoles,
    mainRole: userResponse.mainB2CWebrolleId
      ? userRoles.find(role => role.id === userResponse.mainB2CWebrolleId) || userRoles[0]
      : userRoles[0],
    childCompanies: userResponse.childCompanies,
    appPages: userResponse.appPages.pages || [],
    driversCheckFunction: {
      isManager: userResponse.isManager,
      isController: userResponse.isController
    }
  };
};
