/* eslint-disable max-len */
/* eslint-disable linebreak-style */
/* eslint-disable no-extra-boolean-cast */
import React from 'react';
import styles from './request-component.module.scss';
import { crmDataTypes } from '../../../constants';
import { RequestComponentProps } from './request-component.props';
import {
  CreateDamageReport,
  CreateNewCarProps,
  DamagePartsEntityArray,
  DamagePartsItemProps,
  QueryProps
} from '../report-damage.props';

const RequestComponent = ({ reactHookFormData, moveToPreviousStep, errorMessage }: RequestComponentProps) => {
  const { watch, setValue } = reactHookFormData;
  const moveToNextStep = () => {
    setTimeout(() => setValue('previousStep', 8), 500);
    setValue('lastStep', true);
    sendRequest();
  };

  const isDamageType: boolean =
    watch('damageType') === 'Auffahrschaden' ||
    watch('damageType') === 'Fahrspurwechsel' ||
    watch('damageType') === 'Vorfahrt verletzt' ||
    watch('damageType') === 'Parkschaden' ||
    watch('damageType') === 'Brandschaden' ||
    watch('damageType') === 'Sturmschaden' ||
    watch('damageType') === 'Wild-/ Tierschaden' ||
    watch('damageType') === 'Glasschaden' ||
    watch('damageType') === 'Einbruch/ Teildiebstahl' ||
    watch('damageType') === 'Sonstiges' ||
    watch('damageType') === 'Sachbesch./ Vandalismus';

  let damageTypeValue: boolean | number = false;

  if (!!watch('damageType')) {
    switch (watch('damageType')) {
      case 'Auffahrschaden':
        damageTypeValue = 11;
        break;

      case 'Vorfahrt verletzt':
        damageTypeValue = 13;
        break;

      case 'Fahrspurwechsel':
        damageTypeValue = 12;
        break;

      case 'Parkschaden':
        damageTypeValue = 14;
        break;

      case 'Brandschaden':
        damageTypeValue = 21;
        break;

      case 'Hagelschaden':
        damageTypeValue = 22;
        break;

      case 'Sturmschaden':
        damageTypeValue = 23;
        break;

      case 'Wild-/ Tierschaden':
        damageTypeValue = 24;
        break;

      case 'Glasschaden':
        damageTypeValue = 25;
        break;

      case 'Marderschaden':
        damageTypeValue = 26;
        break;

      case 'Totaldiebstahl':
        damageTypeValue = 31;
        break;

      case 'Einbruch/ Teildiebstahl':
        damageTypeValue = 32;
        break;

      case 'Sachbesch./ Vandalismus':
        damageTypeValue = 33;
        break;

      case 'Sonstiges':
        damageTypeValue = 4;
        break;

      default:
        break;
    }
  }

  const sendRequest = () => {
    if (
      !watch('new_fuhrparkid') &&
      (!!watch('kennzeichen') ||
        !!watch('new_hersteller') ||
        !!watch('new_model') ||
        !!watch('new_fin') ||
        !!watch('new_ezl') ||
        !!watch('new_ezl_value'))
    ) {
      const createFuhrpark: CreateNewCarProps = {
        entityName: 'new_fuhrpark',
        id: null,
        attributes: {}
      };

      if (!!watch('kennzeichen')) {
        createFuhrpark.attributes.new_name = {
          attributeValue: watch('kennzeichen'),
          attributeTypeCode: crmDataTypes.String
        };
      }
      if (!!watch('new_hersteller')) {
        createFuhrpark.attributes.new_hersteller = {
          attributeValue: watch('new_hersteller'),
          attributeTypeCode: crmDataTypes.String
        };
      }
      if (!!watch('new_model')) {
        createFuhrpark.attributes.new_model = {
          attributeValue: watch('new_model'),
          attributeTypeCode: crmDataTypes.String
        };
      }
      if (!!watch('new_fin')) {
        createFuhrpark.attributes.new_fin = {
          attributeValue: watch('new_fin'),
          attributeTypeCode: crmDataTypes.String
        };
      }
      if (!!watch('new_ezl') || !!watch('new_ezl_value')) {
        createFuhrpark.attributes.new_ezl = {
          attributeValue: !!watch('new_ezl') ? watch('new_ezl') : watch('new_ezl_value'),
          attributeTypeCode: crmDataTypes.DateTime
        };
      }

      setValue('createFuhrpark', createFuhrpark);
    }

    const createSchadenAufnahme: CreateDamageReport = {
      entityName: 'uds_schadenaufnahme',
      attributes: {}
    };

    createSchadenAufnahme.attributes.uds_classification_schadenortcode = {
      attributeValue: watch('uds_classification_schadenortcode'),
      attributeTypeCode: crmDataTypes.Picklist
    };

    if (
      !!watch('new_fuhrparkid') &&
      (!!watch('new_hersteller') ||
        !!watch('new_model') ||
        !!watch('new_fin') ||
        !!watch('new_ezl') ||
        !!watch('new_ezl_value'))
    ) {
      if (!!watch('new_hersteller')) {
        createSchadenAufnahme.attributes.uds_hersteller = {
          attributeValue: watch('new_hersteller'),
          attributeTypeCode: crmDataTypes.String
        };
      }
      if (!!watch('new_model')) {
        createSchadenAufnahme.attributes.uds_modell = {
          attributeValue: watch('new_model'),
          attributeTypeCode: crmDataTypes.String
        };
      }
      if (!!watch('new_fin')) {
        createSchadenAufnahme.attributes.uds_fahrgestellnummer = {
          attributeValue: watch('new_fin'),
          attributeTypeCode: crmDataTypes.String
        };
      }
      if (!!watch('new_ezl') || !!watch('new_ezl_value')) {
        createSchadenAufnahme.attributes.uds_erstzulassung = {
          attributeValue: !!watch('new_ezl') ? watch('new_ezl') : watch('new_ezl_value'),
          attributeTypeCode: crmDataTypes.DateTime
        };
      }
    }

    if (
      (!!watch('firma') && watch('firma')?.accountid?.attributeValue) ||
      (!!watch('fahrerInsurance') && !!watch('fahrerInsurance')?.accountid?.attributeValue)
    ) {
      createSchadenAufnahme.attributes.uds_versichererid = {
        attributeValue: {
          id: !!watch('fahrerInsurance')?.accountid?.attributeValue
            ? watch('fahrerInsurance')?.accountid?.attributeValue
            : watch('firma')?.accountid?.attributeValue,
          logicalName: 'account',
          name: null
        },
        attributeTypeCode: crmDataTypes.Lookup
      };
    }
    if (
      (!!watch('firma') && watch('firma')?.name?.attributeValue) ||
      !!watch('newFirmName') ||
      (!!watch('fahrerInsurance') && !!watch('fahrerInsurance')?.name?.attributeValue)
    ) {
      createSchadenAufnahme.attributes.uds_versicherername = {
        attributeValue: !!watch('fahrerInsurance')?.name?.attributeValue
          ? watch('fahrerInsurance')?.name?.attributeValue
          : !!watch('firma')?.name?.attributeValue
          ? watch('firma')?.name?.attributeValue
          : watch('newFirmName'),
        attributeTypeCode: crmDataTypes.String
      };
    }
    if (
      (!!watch('firma') && watch('firma')?.uds_versicherunsnr?.attributeValue) ||
      !!watch('newFirmNumber') ||
      (!!watch('fahrerInsurance') && !!watch('fahrerInsurance')?.uds_versicherunsnr?.attributeValue)
    ) {
      createSchadenAufnahme.attributes.uds_versicherernum = {
        attributeValue: !!watch('fahrerInsurance')?.uds_versicherunsnr?.attributeValue
          ? watch('fahrerInsurance')?.uds_versicherunsnr?.attributeValue
          : !!watch('firma')?.uds_versicherunsnr?.attributeValue
          ? watch('firma')?.uds_versicherunsnr?.attributeValue
          : watch('newFirmNumber'),
        attributeTypeCode: crmDataTypes.String
      };
    }

    if (!!watch('new_fuhrparkid') && watch('new_fuhrparkid') !== null) {
      createSchadenAufnahme.attributes.uds_fuhrparkid = {
        attributeValue: {
          id: watch('new_fuhrparkid'),
          logicalName: 'new_fuhrpark'
        },
        attributeTypeCode: crmDataTypes.Lookup
      };
    }
    if (!!watch('Schadendatum') && !!watch('Uhrzeit')) {
      let date = new Date(watch('Schadendatum'));
      let year = date.getFullYear();
      let month = date.getMonth() + 1; // month is zero-based
      let day = date.getDate();
      createSchadenAufnahme.attributes.uds_schadenuhrzeit = {
        attributeValue: `${year}-${month}-${day}T${watch('Uhrzeit').hour}:${watch('Uhrzeit').minutes}:00.000Z`,
        attributeTypeCode: crmDataTypes.DateTime
      };
    }
    if (!!watch('Land')) {
      createSchadenAufnahme.attributes.uds_schadenort_land = {
        attributeValue: watch('Land'),
        attributeTypeCode: crmDataTypes.String
      };
    }
    if (!!watch('Stadt')) {
      createSchadenAufnahme.attributes.uds_schadenort_city = {
        attributeValue: watch('Stadt'),
        attributeTypeCode: crmDataTypes.String
      };
    }
    if (!!watch('strPLZ') && watch('strPLZ').length > 0) {
      createSchadenAufnahme.attributes.uds_schadenort_postalcode = {
        attributeValue: watch('strPLZ'),
        attributeTypeCode: crmDataTypes.String
      };
    }
    if (!!watch('Strasse')) {
      createSchadenAufnahme.attributes.uds_schadenort_street = {
        attributeValue: watch('Strasse'),
        attributeTypeCode: crmDataTypes.String
      };
    }
    if (!!watch('Postleitzahl')) {
      createSchadenAufnahme.attributes.uds_repairpartnerpostalcode = {
        attributeValue: watch('Postleitzahl'),
        attributeTypeCode: crmDataTypes.String
      };
    }
    if (!!watch('Wunschtermin')) {
      createSchadenAufnahme.attributes.uds_wunschtermin = {
        attributeValue: watch('Wunschtermin'),
        attributeTypeCode: crmDataTypes.DateTime
      };
    }
    if (!!watch('AlternativerTermin')) {
      createSchadenAufnahme.attributes.uds_alternativertermin = {
        attributeValue: watch('AlternativerTermin'),
        attributeTypeCode: crmDataTypes.DateTime
      };
    }
    if (watch('isContactPerson') !== undefined) {
      createSchadenAufnahme.attributes.uds_is_person_contact_value = {
        attributeValue: {
          label: null,
          value: !!watch('isContactPerson') ? 100000000 : 100000001
        },
        attributeTypeCode: crmDataTypes.Picklist
      };
      if (watch('isContactPerson') === false) {
        if (!!watch('isContactPersonName')) {
          createSchadenAufnahme.attributes.uds_ansprechpartner_name = {
            attributeValue: watch('isContactPersonName'),
            attributeTypeCode: crmDataTypes.String
          };
        }
        if (!!watch('isContactPersonEmail')) {
          createSchadenAufnahme.attributes.uds_ansprechpartner_email = {
            attributeValue: watch('isContactPersonEmail'),
            attributeTypeCode: crmDataTypes.String
          };
        }
        if (!!watch('isContactPersonPhone')) {
          createSchadenAufnahme.attributes.uds_ansprechpartner_phone = {
            attributeValue: `${watch('isContactPersonPhoneCode')}${watch('isContactPersonPhone')}`,
            attributeTypeCode: crmDataTypes.String
          };
        }
        if (!!watch('isContactPersonPhoneSecond')) {
          createSchadenAufnahme.attributes.uds_ansprechpartner_adittional_phone = {
            attributeValue: `${watch('isContactPersonPhoneSecondCode')}${watch('isContactPersonPhoneSecond')}`,
            attributeTypeCode: crmDataTypes.String
          };
        }
      }
    }

    if (!!watch('schadenAlsAnderePerson') || (watch('fuhrparkInfo') === null && watch('fahrerInfo') === null)) {
      if (!!watch('anotherPersonFahrerName')) {
        createSchadenAufnahme.attributes.uds_schaden_gemeldet_contact_name = {
          attributeValue: watch('anotherPersonFahrerName'),
          attributeTypeCode: crmDataTypes.String
        };
      }
      if (!!watch('anotherPersonFahrerEmail')) {
        createSchadenAufnahme.attributes.uds_schaden_gemeldet_contact_email = {
          attributeValue: watch('anotherPersonFahrerEmail'),
          attributeTypeCode: crmDataTypes.String
        };
      }
      if (!!watch('anotherPersonFahrerTelefon')) {
        createSchadenAufnahme.attributes.uds_schaden_gemeldet_contact_phone = {
          attributeValue: `${watch('anotherPersonFahrerTelefonCode')}${watch('anotherPersonFahrerTelefon')}`,
          attributeTypeCode: crmDataTypes.String
        };
      }
      if (!!watch('anotherPersonFahrerTelefon2')) {
        createSchadenAufnahme.attributes.uds_schaden_gemeldet_contact_other_phone = {
          attributeValue: `${watch('anotherPersonFahrerTelefon2Code')}${watch('anotherPersonFahrerTelefon2')}`,
          attributeTypeCode: crmDataTypes.String
        };
      }
    }

    if (!!watch('schadenAlsFahrzeugnutzer') || !!watch('schadenAlsAnderePerson')) {
      createSchadenAufnahme.attributes.uds_schaden_gemeldet_von_value = {
        attributeValue: {
          label: null,
          value: !!watch('schadenAlsFahrzeugnutzer') ? 100000000 : 100000001
        },
        attributeTypeCode: crmDataTypes.Picklist
      };
    }

    if (!!watch('schadenAlsFahrzeugnutzer')) {
      if (!!watch('fahrerDataFullname') && watch('fahrerDataFullname')?.length) {
        createSchadenAufnahme.attributes.uds_schaden_gemeldet_contact_name = {
          attributeValue: watch('fahrerDataFullname'),
          attributeTypeCode: crmDataTypes.String
        };
      }
      if (!!watch('fahrerDataEmailaddress1') && watch('fahrerDataEmailaddress1')?.length) {
        createSchadenAufnahme.attributes.uds_schaden_gemeldet_contact_email = {
          attributeValue: watch('fahrerDataEmailaddress1'),
          attributeTypeCode: crmDataTypes.String
        };
      }
      if (!!watch('fahrerDataMobilephone') && watch('fahrerDataMobilephone')?.length) {
        createSchadenAufnahme.attributes.uds_schaden_gemeldet_contact_phone = {
          attributeValue: `${watch('fahrerDataMobilephoneCode')}${watch('fahrerDataMobilephone')}`,
          attributeTypeCode: crmDataTypes.String
        };
      }
      if (!!watch('fahrerDataMobilephone1') && watch('fahrerDataMobilephone1')?.length) {
        createSchadenAufnahme.attributes.uds_schaden_gemeldet_contact_other_phone = {
          attributeValue: `${watch('fahrerDataMobilephone1Code')}${watch('fahrerDataMobilephone1')}`,
          attributeTypeCode: crmDataTypes.String
        };
      }
    }

    if (!!damageTypeValue) {
      createSchadenAufnahme.attributes.uds_artdesschadens = {
        attributeValue: {
          label: null,
          value: damageTypeValue
        },
        attributeTypeCode: crmDataTypes.Picklist
      };
    }
    if (!!watch('accidentScatch') || watch('accidentScatch') === false) {
      createSchadenAufnahme.attributes.uds_unfallskizzevorhanden = {
        attributeValue: watch('accidentScatch'),
        attributeTypeCode: crmDataTypes.Boolean
      };
    }
    if (!!watch('hasDamage') || watch('hasDamage') === false) {
      createSchadenAufnahme.attributes.uds_ass_fahrzeug_schaden = {
        attributeValue: watch('hasDamage'),
        attributeTypeCode: crmDataTypes.Boolean
      };
    }

    // Beschädigte Teile auf Kundenfahrzeug	[uds_teile1] //add field

    if (watch('damageSize') === 'big' || watch('damageSize') === 'small') {
      createSchadenAufnahme.attributes.uds_isschadengroser = {
        attributeValue: watch('damageSize') === 'big', // need info about type in CRM
        attributeTypeCode: crmDataTypes.Boolean // need info about type in CRM
      };
    }
    if (!!watch('damageDealer')) {
      let damageDealerValue: number = 0;
      if (watch('damageDealer') === 'Ich/Fahrzeugnutzer') {
        damageDealerValue = 1;
      }
      if (watch('damageDealer') === 'Unfallgegner') {
        damageDealerValue = 2;
      }
      if (watch('damageDealer') === 'Unsicher') {
        damageDealerValue = 3;
      }
      createSchadenAufnahme.attributes.uds_unfallverursacher = {
        attributeValue: {
          label: null,
          value: damageDealerValue
        },
        attributeTypeCode: crmDataTypes.Picklist
      };
    }
    if (!!watch('damagePrivatOrBusinessTrip')) {
      createSchadenAufnahme.attributes.uds_typfahrt = {
        attributeValue: watch('damagePrivatOrBusinessTrip'),
        attributeTypeCode: crmDataTypes.Boolean
      };
    }
    if (!!watch('autoIsOkay') || watch('autoIsOkay') === false) {
      createSchadenAufnahme.attributes.uds_fahrbereit = {
        attributeValue: watch('autoIsOkay'),
        attributeTypeCode: crmDataTypes.Boolean
      };
    }

    if (
      watch('autoIsOkay') === false ||
      watch('damageType') === 'Brandschaden' ||
      watch('damageType') === 'Sturmschaden' ||
      watch('damageType') === 'Wild-/ Tierschaden' ||
      watch('damageType') === 'Hagelschaden'
    ) {
      if (!!watch('autoIsNotOkayFirma') && watch('autoIsNotOkayFirma').length > 0) {
        createSchadenAufnahme.attributes.uds_ass_besichtigungsort_unfallfahrzeug_firma = {
          attributeValue: watch('autoIsNotOkayFirma'),
          attributeTypeCode: crmDataTypes.String
        };
      }
      if (!!watch('autoIsNotOkayAdress') && watch('autoIsNotOkayAdress').length > 0) {
        createSchadenAufnahme.attributes.uds_ass_besichtigungsort_strasse = {
          attributeValue: watch('autoIsNotOkayAdress'),
          attributeTypeCode: crmDataTypes.String
        };
      }
      if (!!watch('autoIsNotOkayTelephone') && watch('autoIsNotOkayTelephone').length > 0) {
        createSchadenAufnahme.attributes.uds_ass_besichtigungsort_telefon_ansprechpart = {
          attributeValue: `${watch('autoIsNotOkayTelephoneCode')}${watch('autoIsNotOkayTelephone')}`,
          attributeTypeCode: crmDataTypes.String
        };
      }
      if (
        !!watch('autoIsNotOkayPLZ') &&
        watch('autoIsNotOkayPLZ').length > 4 &&
        !!watch('autoIsNotOkayStadt') &&
        watch('autoIsNotOkayStadt').length > 0 &&
        !!watch('autoIsNotOkayStreet') &&
        watch('autoIsNotOkayStreet').length > 0
      ) {
        createSchadenAufnahme.attributes.uds_ass_besichtigungsort_strasse = {
          attributeValue: `${watch('autoIsNotOkayPLZ')}, ${watch('autoIsNotOkayStadt')}, ${watch(
            'autoIsNotOkayStreet'
          )}`,
          attributeTypeCode: crmDataTypes.String
        };
      }
    }

    if (!!watch('needRent') || watch('needRent') === false) {
      createSchadenAufnahme.attributes.uds_reparaturpartner_mietwagen = {
        attributeValue: watch('needRent'),
        attributeTypeCode: crmDataTypes.Boolean
      };
    }

    // need repair quest field not here. need to add

    // need to add Sind dir Zeugen bekannt? question field

    if (!!watch('needPolice') || watch('needPolice') === false) {
      createSchadenAufnahme.attributes.uds_polizauf = {
        attributeValue: watch('needPolice'),
        attributeTypeCode: crmDataTypes.Boolean
      };
    }

    if (!!watch('needPolice')) {
      if (!!watch('needPoliceDienststelle') && watch('needPoliceDienststelle').length > 0) {
        createSchadenAufnahme.attributes.uds_polizeidienststelle = {
          attributeValue: watch('needPoliceDienststelle'),
          attributeTypeCode: crmDataTypes.String
        };
      }
      if (!!watch('needPoliceAdressAndOffice') && watch('needPoliceAdressAndOffice').length > 0) {
        createSchadenAufnahme.attributes.uds_dienststelle_adresse = {
          attributeValue: watch('needPoliceAdressAndOffice'),
          attributeTypeCode: crmDataTypes.String
        };
      }
      if (!!watch('needPoliceGetCaseNumber') || watch('needPoliceGetCaseNumber') === false) {
        createSchadenAufnahme.attributes.uds_polizeibericht_vorhanden = {
          attributeValue: watch('needPoliceGetCaseNumber'),
          attributeTypeCode: crmDataTypes.Boolean
        };
      }
      if (!!watch('needPoliceGetCaseNumber')) {
        if (!!watch('needPoliceGetCaseNumberAktenzeichen') && watch('needPoliceGetCaseNumberAktenzeichen').length > 0) {
          createSchadenAufnahme.attributes.uds_polizeilichesaktenzeichen = {
            attributeValue: watch('needPoliceGetCaseNumberAktenzeichen'),
            attributeTypeCode: crmDataTypes.String
          };
        }
      }
      if (!!watch('needPoliceCriminalWarning') || watch('needPoliceCriminalWarning') === false) {
        createSchadenAufnahme.attributes.uds_warning_saidcode = {
          attributeValue: {
            label: null,
            value: !!watch('needPoliceCriminalWarning') ? 100000001 : 100000000
          },
          attributeTypeCode: crmDataTypes.Picklist
        };
      }
      if (watch('needPoliceComplaintFiled') !== undefined) {
        createSchadenAufnahme.attributes.uds_anzeige_erstattet_option = {
          attributeValue: {
            label: null,
            value: !!watch('needPoliceComplaintFiled') ? 100000000 : 100000001
          },
          attributeTypeCode: crmDataTypes.Picklist
        };
      }

      if (!!watch('needPoliceCriminalWarning')) {
        if (!!watch('needPoliceCriminalWarningWho') && watch('needPoliceCriminalWarningWho').length > 0) {
          createSchadenAufnahme.attributes.uds_verwarnungan = {
            attributeValue: watch('needPoliceCriminalWarningWho'),
            attributeTypeCode: crmDataTypes.String
          };
        }
      }

      if (!!watch('needPoliceDrunkDriverWasTested') || watch('needPoliceDrunkDriverWasTested') === false) {
        createSchadenAufnahme.attributes.uds_alkoholtest = {
          attributeValue: watch('needPoliceDrunkDriverWasTested'),
          attributeTypeCode: crmDataTypes.Boolean
        };
      }
      if (!!watch('needPoliceDrunkDriverWasTested')) {
        if (
          !!watch('needPoliceCriminalWarningWhoFirstPersonName') &&
          watch('needPoliceCriminalWarningWhoFirstPersonName').length > 0
        ) {
          createSchadenAufnahme.attributes.uds_alkoholtest_bei_wem = {
            attributeValue: watch('needPoliceCriminalWarningWhoFirstPersonName'),
            attributeTypeCode: crmDataTypes.String
          };
        }
        if (
          !!watch('needPoliceCriminalWarningWhoFirstPersonDrunkLevel') &&
          watch('needPoliceCriminalWarningWhoFirstPersonDrunkLevel').length > 0
        ) {
          createSchadenAufnahme.attributes.uds_wert_alkoholtest = {
            attributeValue: watch('needPoliceCriminalWarningWhoFirstPersonDrunkLevel'),
            attributeTypeCode: crmDataTypes.String
          };
        }
        if (!!watch('showSecondDrunkPerson')) {
          if (
            !!watch('needPoliceCriminalWarningWhoSecondPersonName') &&
            watch('needPoliceCriminalWarningWhoSecondPersonName').length > 0
          ) {
            createSchadenAufnahme.attributes.uds_alkoholtest_bei_wem2 = {
              attributeValue: watch('needPoliceCriminalWarningWhoSecondPersonName'),
              attributeTypeCode: crmDataTypes.String
            };
          }
          if (
            !!watch('needPoliceCriminalWarningWhoSecondPersonDrunkLevel') &&
            watch('needPoliceCriminalWarningWhoSecondPersonDrunkLevel').length > 0
          ) {
            createSchadenAufnahme.attributes.uds_wert_alkoholtest2 = {
              attributeValue: watch('needPoliceCriminalWarningWhoSecondPersonDrunkLevel'),
              attributeTypeCode: crmDataTypes.String
            };
          }
        }
      }

      if (!!watch('needPoliceDrunkDrugsTest') || watch('needPoliceDrunkDrugsTest') === false) {
        createSchadenAufnahme.attributes.uds_drogentest = {
          attributeValue: watch('needPoliceDrunkDrugsTest'),
          attributeTypeCode: crmDataTypes.Boolean
        };
      }
      if (!!watch('needPoliceDrunkDrugsTest')) {
        if (
          !!watch('needPoliceDrunkDrugsTestWhoWasTestedFirst') &&
          watch('needPoliceDrunkDrugsTestWhoWasTestedFirst').length > 0
        ) {
          createSchadenAufnahme.attributes.uds_drogentest_bei_wem = {
            attributeValue: watch('needPoliceDrunkDrugsTestWhoWasTestedFirst'),
            attributeTypeCode: crmDataTypes.String
          };
        }
        if (!!watch('needPoliceDrunkDrugsTestResultFirst')) {
          createSchadenAufnahme.attributes.uds_wert_drogentestoption = {
            attributeValue: {
              label: null,
              value: watch('needPoliceDrunkDrugsTestResultFirst') === 'Positiv' ? 100000000 : 100000001
            },
            attributeTypeCode: crmDataTypes.Picklist
          };
        }
        if (
          !!watch('needPoliceDrunkDrugsTestWhoWasTestedSecond') &&
          watch('needPoliceDrunkDrugsTestWhoWasTestedSecond').length > 0
        ) {
          createSchadenAufnahme.attributes.uds_drogentest_bei_wem2 = {
            attributeValue: watch('needPoliceDrunkDrugsTestWhoWasTestedSecond'),
            attributeTypeCode: crmDataTypes.String
          };
        }
        if (!!watch('needPoliceDrunkDrugsTestResultSecond')) {
          createSchadenAufnahme.attributes.uds_wert_drogentestoption2 = {
            attributeValue: {
              label: null,
              value: watch('needPoliceDrunkDrugsTestResultSecond') === 'Positiv' ? 100000000 : 100000001
            },
            attributeTypeCode: crmDataTypes.Picklist
          };
        }
      }
    }
    if (!!watch('fahrerInfo')?.contactid?.attributeValue) {
      createSchadenAufnahme.attributes.uds_fahrerid = {
        attributeValue: {
          id: watch('fahrerInfo')?.contactid?.attributeValue,
          logicalName: 'contact'
        },
        attributeTypeCode: crmDataTypes.Lookup
      };
    }

    if (!!watch('whoDrive')) {
      let whoDriveValue: number = 100000001;
      if (watch('whoDrive') === 'Fahrzeugnutzer') {
        whoDriveValue = 100000002;
      }
      if (watch('whoDrive') === 'Andere Person') {
        whoDriveValue = 100000003;
      }
      if (watch('whoDrive') === 'Niemand') {
        whoDriveValue = 100000004;
      }
      createSchadenAufnahme.attributes.uds_fahrer_des_kundenfahrzeugs_option = {
        attributeValue: {
          label: null,
          value: whoDriveValue
        },
        attributeTypeCode: crmDataTypes.Picklist
      };
      if (watch('whoDrive') === 'Fahrzeugnutzer') {
        if (!!watch('fahrerInfo')?.contactid?.attributeValue) {
          createSchadenAufnahme.attributes.new_caruser = {
            attributeValue: {
              id: watch('fahrerInfo')?.contactid?.attributeValue,
              logicalName: 'contact'
            },
            attributeTypeCode: crmDataTypes.Lookup
          };
        }
        if (
          (!!watch('whoDriveName') && watch('whoDriveName').length > 0) ||
          !!watch('fahrerInfo')?.fullname?.attributeValue
        ) {
          createSchadenAufnahme.attributes.uds_driver_name = {
            attributeValue: !!watch('whoDriveName')
              ? watch('whoDriveName')
              : watch('fahrerInfo')?.fullname?.attributeValue,
            attributeTypeCode: crmDataTypes.String
          };
        }
        if (
          (!!watch('whoDrivePhone') && watch('whoDrivePhone').length > 0) ||
          !!watch('fahrerInfo')?.mobilephone?.attributeValue
        ) {
          createSchadenAufnahme.attributes.uds_driver_mobile = {
            attributeValue: !!watch('whoDrivePhone')
              ? `${!!watch('whoDrivePhoneCode') ? watch('whoDrivePhoneCode') : '+49'}${watch('whoDrivePhone')}`
              : watch('fahrerInfo')?.mobilephone?.attributeValue,
            attributeTypeCode: crmDataTypes.String
          };
        }
        if (
          !!watch('fahrerInfo')?.emailaddress1?.attributeValue ||
          (!watch('whoDriveEmailEmpty') && !!watch('whoDriveEmail') && watch('whoDriveEmail').length > 0)
        ) {
          createSchadenAufnahme.attributes.uds_driver_emailaddress = {
            attributeValue: !!watch('whoDriveEmail')
              ? watch('whoDriveEmail')
              : watch('fahrerInfo')?.emailaddress1?.attributeValue,
            attributeTypeCode: crmDataTypes.String
          };
        }
        if (
          (!!watch('whoDrivePLZ') && watch('whoDrivePLZ').length > 0) ||
          !!watch('fahrerInfo')?.address1_postalcode?.attributeValue
        ) {
          createSchadenAufnahme.attributes.uds_driver_postcode = {
            attributeValue: !!watch('whoDrivePLZ')
              ? watch('whoDrivePLZ')
              : watch('fahrerInfo')?.address1_postalcode?.attributeValue,
            attributeTypeCode: crmDataTypes.String
          };
        }
        if (
          (!!watch('whoDriveStadt') && watch('whoDriveStadt').length > 0) ||
          !!watch('fahrerInfo')?.address1_city?.attributeValue
        ) {
          createSchadenAufnahme.attributes.uds_driver_city = {
            attributeValue: !!watch('whoDriveStadt')
              ? watch('whoDriveStadt')
              : watch('fahrerInfo')?.address1_city?.attributeValue,
            attributeTypeCode: crmDataTypes.String
          };
        }
        if (
          (!!watch('whoDriveAdress') && watch('whoDriveAdress').length > 0) ||
          !!watch('fahrerInfo')?.address1_line1?.attributeValue
        ) {
          createSchadenAufnahme.attributes.uds_driver_street = {
            attributeValue: !!watch('whoDriveAdress')
              ? watch('whoDriveAdress')
              : watch('fahrerInfo')?.address1_line1?.attributeValue,
            attributeTypeCode: crmDataTypes.String
          };
        }
        // probably we don't need this field for Fahrer
        // if (!!watch('whoDriveEmailEmpty')) {
        //   createSchadenAufnahme.attributes.uds_is_driver_mail_unknown = {
        //     attributeValue: watch('whoDriveEmailEmpty'),
        //     attributeTypeCode: crmDataTypes.Boolean
        //   };
        // }
      }
      if (watch('whoDrive') === 'Andere Person') {
        if (!!watch('whoDriveNameAndere') && watch('whoDriveNameAndere').length > 0) {
          createSchadenAufnahme.attributes.uds_driver_name = {
            attributeValue: watch('whoDriveNameAndere'),
            attributeTypeCode: crmDataTypes.String
          };
        }
        if (!!watch('whoDrivePhoneAndere') && watch('whoDrivePhoneAndere').length > 0) {
          createSchadenAufnahme.attributes.uds_driver_mobile = {
            attributeValue: `${!!watch('whoDrivePhoneAndereCode') ? watch('whoDrivePhoneAndereCode') : '+49'}${watch(
              'whoDrivePhoneAndere'
            )}`,
            attributeTypeCode: crmDataTypes.String
          };
        }
        if (
          !watch('whoDriveEmailAndereEmpty') &&
          !!watch('whoDriveEmailAndere') &&
          watch('whoDriveEmailAndere').length > 0
        ) {
          createSchadenAufnahme.attributes.uds_driver_emailaddress = {
            attributeValue: watch('whoDriveEmailAndere'),
            attributeTypeCode: crmDataTypes.String
          };
        }
        if (!!watch('whoDriveEmailAndereEmpty')) {
          createSchadenAufnahme.attributes.uds_is_driver_mail_unknown = {
            attributeValue: watch('whoDriveEmailAndereEmpty'),
            attributeTypeCode: crmDataTypes.Boolean
          };
        }
        if (!!watch('whoDrivePLZAndere') && watch('whoDrivePLZAndere').length > 0) {
          createSchadenAufnahme.attributes.uds_driver_postcode = {
            attributeValue: watch('whoDrivePLZAndere'),
            attributeTypeCode: crmDataTypes.String
          };
        }
        if (!!watch('whoDriveStadtAndere') && watch('whoDriveStadtAndere').length > 0) {
          createSchadenAufnahme.attributes.uds_driver_city = {
            attributeValue: watch('whoDriveStadtAndere'),
            attributeTypeCode: crmDataTypes.String
          };
        }
        if (!!watch('whoDriveAdressAndere') && watch('whoDriveAdressAndere').length > 0) {
          createSchadenAufnahme.attributes.uds_driver_street = {
            attributeValue: watch('whoDriveAdressAndere'),
            attributeTypeCode: crmDataTypes.String
          };
        }
      }
    }

    if (!!watch('doYouHaveLicense') || watch('doYouHaveLicense') === false) {
      createSchadenAufnahme.attributes.uds_driving_license_was_valid = {
        attributeValue: watch('doYouHaveLicense'),
        attributeTypeCode: crmDataTypes.Boolean
      };
      if (!!watch('doYouHaveLicense')) {
        if (!!watch('doYouHaveLicenseIssuingAuthority') && watch('doYouHaveLicenseIssuingAuthority').length > 0) {
          createSchadenAufnahme.attributes.uds_driving_license_place_of_issue = {
            attributeValue: watch('doYouHaveLicenseIssuingAuthority'),
            attributeTypeCode: crmDataTypes.String
          };
        }
        if (!!watch('doYouHaveLicenseLicenseNumber') && watch('doYouHaveLicenseLicenseNumber').length > 0) {
          createSchadenAufnahme.attributes.uds_driving_license_number = {
            attributeValue: watch('doYouHaveLicenseLicenseNumber'),
            attributeTypeCode: crmDataTypes.String
          };
        }
        if (!!watch('doYouHaveLicenseDateIssue')) {
          createSchadenAufnahme.attributes.uds_driving_license_date = {
            attributeValue: watch('doYouHaveLicenseDateIssue'),
            attributeTypeCode: crmDataTypes.DateTime
          };
        }
        if (!!watch('doYouHaveLicenseClass') && watch('doYouHaveLicenseClass').length > 0) {
          createSchadenAufnahme.attributes.uds_driving_license_class = {
            attributeValue: watch('doYouHaveLicenseClass'),
            attributeTypeCode: crmDataTypes.String
          };
        }
      }
    }

    if (!!watch('needRepair') || watch('needRepair') === false) {
      createSchadenAufnahme.attributes.uds_isreparaturnotwendigkeit = {
        attributeValue: watch('needRepair'),
        attributeTypeCode: crmDataTypes.Boolean
      };
    }

    if (!!watch('detailDamageComment') && watch('detailDamageComment').length > 0) {
      createSchadenAufnahme.attributes.uds_description = {
        attributeValue: watch('detailDamageComment'),
        attributeTypeCode: crmDataTypes.String
      };
    }

    if (!!watch('DetailedDamageDescription') && watch('DetailedDamageDescription').length > 0) {
      createSchadenAufnahme.attributes.uds_description = {
        attributeValue: watch('DetailedDamageDescription'),
        attributeTypeCode: crmDataTypes.String
      };
    }

    if (!!watch('anyWitnesses') || watch('anyWitnesses') === false) {
      createSchadenAufnahme.attributes.uds_uz1_schaden_unfallzeugen = {
        attributeValue: watch('anyWitnesses'),
        attributeTypeCode: crmDataTypes.Boolean
      };
      if (!!watch('anyWitnesses')) {
        if (!!watch('anyWitnessesDateEmpty')) {
          createSchadenAufnahme.attributes.uds_uzdatenunbekannt1 = {
            attributeValue: watch('anyWitnessesDateEmpty'),
            attributeTypeCode: crmDataTypes.Boolean
          };
        }
        if (!watch('anyWitnessesDateEmpty')) {
          if (!!watch('anyWitnessesName') && watch('anyWitnessesName').length > 0) {
            createSchadenAufnahme.attributes.uds_uzname1 = {
              attributeValue: watch('anyWitnessesName'),
              attributeTypeCode: crmDataTypes.String
            };
          }
          if (!!watch('anyWitnessesAdress') && watch('anyWitnessesAdress').length > 0) {
            createSchadenAufnahme.attributes.uds_uzanschrift1 = {
              attributeValue: watch('anyWitnessesAdress'),
              attributeTypeCode: crmDataTypes.String
            };
          }
          if (!!watch('anyWitnessesEmail') && watch('anyWitnessesEmail').length > 0) {
            createSchadenAufnahme.attributes.uds_uzemail1 = {
              attributeValue: watch('anyWitnessesEmail'),
              attributeTypeCode: crmDataTypes.String
            };
          }
          if (!!watch('anyWitnessesPhone') && watch('anyWitnessesPhone').length > 0) {
            createSchadenAufnahme.attributes.uds_uztelefon1 = {
              attributeValue: `${watch('anyWitnessesPhoneCode')}${watch('anyWitnessesPhone')}`,
              attributeTypeCode: crmDataTypes.String
            };
          }
        }
        if (!!watch('showSecondPersonAnyWitnesses')) {
          if (!!watch('anyWitnessesDateSecondEmpty')) {
            createSchadenAufnahme.attributes.uds_uzdatenunbekannt2 = {
              attributeValue: watch('anyWitnessesDateSecondEmpty'),
              attributeTypeCode: crmDataTypes.Boolean
            };
          }
          if (!watch('anyWitnessesDateSecondEmpty')) {
            if (!!watch('anyWitnessesNameSecond') && watch('anyWitnessesNameSecond').length > 0) {
              createSchadenAufnahme.attributes.uds_uzname2 = {
                attributeValue: watch('anyWitnessesNameSecond'),
                attributeTypeCode: crmDataTypes.String
              };
            }
            if (!!watch('anyWitnessesAdressSecond') && watch('anyWitnessesAdressSecond').length > 0) {
              createSchadenAufnahme.attributes.uds_uzanschrift2 = {
                attributeValue: watch('anyWitnessesAdressSecond'),
                attributeTypeCode: crmDataTypes.String
              };
            }
            if (!!watch('anyWitnessesEmailSecond') && watch('anyWitnessesEmailSecond').length > 0) {
              createSchadenAufnahme.attributes.uds_uzemail2 = {
                attributeValue: watch('anyWitnessesEmailSecond'),
                attributeTypeCode: crmDataTypes.String
              };
            }
            if (!!watch('anyWitnessesPhoneSecond') && watch('anyWitnessesPhoneSecond').length > 0) {
              createSchadenAufnahme.attributes.uds_uztelefon2 = {
                attributeValue: `${watch('anyWitnessesPhoneSecondCode')}${watch('anyWitnessesPhoneSecond')}`,
                attributeTypeCode: crmDataTypes.String
              };
            }
          }
        }
      }
    }

    if (!!watch('peopleInvolvedInCrash') || watch('peopleInvolvedInCrash') === false) {
      createSchadenAufnahme.attributes.uds_schaden_unfallbeteiligte = {
        attributeValue: watch('peopleInvolvedInCrash'),
        attributeTypeCode: crmDataTypes.Boolean
      };

      if (!!watch('peopleInvolvedInCrash')) {
        if (!!watch('peopleInvolvedInCrashDateEmpty')) {
          createSchadenAufnahme.attributes.uds_unfallgegner1_personliche_daten = {
            attributeValue: watch('peopleInvolvedInCrashDateEmpty'),
            attributeTypeCode: crmDataTypes.Boolean
          };
        }

        if (!watch('peopleInvolvedInCrashDateEmpty')) {
          if (!!watch('peopleInvolvedInCrashName') && watch('peopleInvolvedInCrashName').length > 0) {
            createSchadenAufnahme.attributes.uds_bp1_name = {
              attributeValue: watch('peopleInvolvedInCrashName'),
              attributeTypeCode: crmDataTypes.String
            };
          }
          if (!!watch('peopleInvolvedInCrashAdress') && watch('peopleInvolvedInCrashAdress').length > 0) {
            createSchadenAufnahme.attributes.uds_bp1_adresse = {
              attributeValue: watch('peopleInvolvedInCrashAdress'),
              attributeTypeCode: crmDataTypes.String
            };
          }
          if (!!watch('peopleInvolvedInCrashPhone') && watch('peopleInvolvedInCrashPhone').length > 0) {
            createSchadenAufnahme.attributes.uds_ubtelefon2 = {
              attributeValue: `${watch('peopleInvolvedInCrashPhoneCode')}${watch('peopleInvolvedInCrashPhone')}`,
              attributeTypeCode: crmDataTypes.String
            };
          }
        }

        if (!!watch('peopleInvolvedInCrashPeopleDamage') || watch('peopleInvolvedInCrashPeopleDamage') === false) {
          createSchadenAufnahme.attributes.uds_schaden_personenschaeden = {
            attributeValue: watch('peopleInvolvedInCrashPeopleDamage'),
            attributeTypeCode: crmDataTypes.Boolean
          };
        }

        if (
          !!watch('peopleInvolvedInCrashPeopleInvolvedInsuranse') ||
          watch('peopleInvolvedInCrashPeopleInvolvedInsuranse') === false
        ) {
          createSchadenAufnahme.attributes.uds_ubversicherung1_unbekannt = {
            attributeValue: watch('peopleInvolvedInCrashPeopleInvolvedInsuranse'),
            attributeTypeCode: crmDataTypes.Boolean
          };
        }

        if (!!watch('peopleInvolvedInCrashPeopleInvolvedInsuranse')) {
          if (
            !!watch('peopleInvolvedInCrashPeopleInvolvedInsuranseName') &&
            watch('peopleInvolvedInCrashPeopleInvolvedInsuranseName').length > 0
          ) {
            createSchadenAufnahme.attributes.uds_ubversicherung1 = {
              attributeValue: watch('peopleInvolvedInCrashPeopleInvolvedInsuranseName'),
              attributeTypeCode: crmDataTypes.String
            };
          }
          if (
            !!watch('peopleInvolvedInCrashPeopleInvolvedInsuranseNumber') &&
            watch('peopleInvolvedInCrashPeopleInvolvedInsuranseNumber').length > 0
          ) {
            createSchadenAufnahme.attributes.uds_ubversichennum1 = {
              attributeValue: watch('peopleInvolvedInCrashPeopleInvolvedInsuranseNumber'),
              attributeTypeCode: crmDataTypes.String
            };
          }
        }

        if (
          !!watch('peopleInvolvedInCrashCarOwnerIsDriver') ||
          watch('peopleInvolvedInCrashCarOwnerIsDriver') === false
        ) {
          createSchadenAufnahme.attributes.uds_ubname1_schaediger = {
            attributeValue: watch('peopleInvolvedInCrashCarOwnerIsDriver'),
            attributeTypeCode: crmDataTypes.Boolean
          };

          if (!!watch('peopleInvolvedInCrashCarOwnerIsDriver')) {
            if (
              !!watch('peopleInvolvedInCrashCarOwnerIsDriverName') &&
              watch('peopleInvolvedInCrashCarOwnerIsDriverName').length > 0
            ) {
              createSchadenAufnahme.attributes.uds_fh_name1 = {
                attributeValue: watch('peopleInvolvedInCrashCarOwnerIsDriverName'),
                attributeTypeCode: crmDataTypes.String
              };
            }
            if (
              !!watch('peopleInvolvedInCrashCarOwnerIsDriverAdress') &&
              watch('peopleInvolvedInCrashCarOwnerIsDriverAdress').length > 0
            ) {
              createSchadenAufnahme.attributes.uds_fh_anschrift1 = {
                attributeValue: watch('peopleInvolvedInCrashCarOwnerIsDriverAdress'),
                attributeTypeCode: crmDataTypes.String
              };
            }
            if (
              !!watch('peopleInvolvedInCrashCarOwnerIsDriverPhone') &&
              watch('peopleInvolvedInCrashCarOwnerIsDriverPhone').length > 0
            ) {
              createSchadenAufnahme.attributes.uds_ubtelefon1 = {
                attributeValue: `${watch('peopleInvolvedInCrashCarOwnerIsDriverPhoneCode')}${watch(
                  'peopleInvolvedInCrashCarOwnerIsDriverPhone'
                )}`,
                attributeTypeCode: crmDataTypes.String
              };
            }
          }
        }

        if (
          watch('peopleInvolvedInCrashWhatHasDamage.Fahrzeug') === 'Fahrzeug' ||
          watch('peopleInvolvedInCrashWhatHasDamage.Fahrzeug') === false
        ) {
          createSchadenAufnahme.attributes.uds_bp1_wurde_fahrzeug_beschadigt = {
            attributeValue: watch('peopleInvolvedInCrashWhatHasDamage.Fahrzeug') === 'Fahrzeug',
            attributeTypeCode: crmDataTypes.Boolean
          };
        }

        if (watch('peopleInvolvedInCrashWhatHasDamage.Fahrzeug') === 'Fahrzeug') {
          if (
            !!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichenEmpty') ||
            watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichenEmpty') === false
          ) {
            createSchadenAufnahme.attributes.uds_kennzeichenunbekannt = {
              attributeValue: watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichenEmpty'),
              attributeTypeCode: crmDataTypes.Boolean
            };
          }
          if (
            !watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichenEmpty') &&
            !!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichen') &&
            watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichen').length > 0
          ) {
            createSchadenAufnahme.attributes.uds_ubkennzeichen2 = {
              attributeValue: watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichen'),
              attributeTypeCode: crmDataTypes.String
            };
          }
          if (
            !!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugHersteller') &&
            watch('peopleInvolvedInCrashWhatHasDamageFahrzeugHersteller').length > 0
          ) {
            createSchadenAufnahme.attributes.uds_bp1_fahrzeug_hersteller = {
              attributeValue: watch('peopleInvolvedInCrashWhatHasDamageFahrzeugHersteller'),
              attributeTypeCode: crmDataTypes.String
            };
          }
          if (
            !!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugTyp') &&
            watch('peopleInvolvedInCrashWhatHasDamageFahrzeugTyp').length > 0
          ) {
            createSchadenAufnahme.attributes.uds_bp1_fahrzeug_typ = {
              attributeValue: watch('peopleInvolvedInCrashWhatHasDamageFahrzeugTyp'),
              attributeTypeCode: crmDataTypes.String
            };
          }
        }

        if (
          watch('peopleInvolvedInCrashWhatHasDamage.Sonstiges') === 'Sonstiges' ||
          watch('peopleInvolvedInCrashWhatHasDamage.Sonstiges') === false
        ) {
          createSchadenAufnahme.attributes.uds_ub1_sachschaden = {
            attributeValue: watch('peopleInvolvedInCrashWhatHasDamage.Sonstiges') === 'Sonstiges',
            attributeTypeCode: crmDataTypes.Boolean
          };

          if (
            watch('peopleInvolvedInCrashWhatHasDamage.Sonstiges') === 'Sonstiges' &&
            !!watch('peopleInvolvedInCrashWhatHasDamageSonstigesDamageDescription') &&
            watch('peopleInvolvedInCrashWhatHasDamageSonstigesDamageDescription').length > 0
          ) {
            createSchadenAufnahme.attributes.uds_ub1_was_beschaedigt_sachschaden = {
              attributeValue: watch('peopleInvolvedInCrashWhatHasDamageSonstigesDamageDescription'),
              attributeTypeCode: crmDataTypes.String
            };
          }
        }

        if (
          !!watch('peopleInvolvedInCrashAnotherPeopleInvolved') ||
          watch('peopleInvolvedInCrashAnotherPeopleInvolved') === false
        ) {
          createSchadenAufnahme.attributes.uds_schaden_unfallbeteiligte2 = {
            attributeValue: watch('peopleInvolvedInCrashAnotherPeopleInvolved'),
            attributeTypeCode: crmDataTypes.Boolean
          };
        }

        //////////////////////////////////////////////////////////////////////////////////////////
        // SECOND BLOCK FOR  INVOLVED IN CRASH PERSON !!!!!!
        //////////////////////////////////////////////////////////////////////////////////////////

        if (!!watch('peopleInvolvedInCrashAnotherPeopleInvolved')) {
          if (!!watch('peopleInvolvedInCrashDateEmpty2')) {
            createSchadenAufnahme.attributes.uds_unfallgegner2_personliche_daten = {
              attributeValue: watch('peopleInvolvedInCrashDateEmpty2'),
              attributeTypeCode: crmDataTypes.Boolean
            };
          }

          if (!watch('peopleInvolvedInCrashDateEmpty')) {
            if (!!watch('peopleInvolvedInCrashName2') && watch('peopleInvolvedInCrashName2').length > 0) {
              createSchadenAufnahme.attributes.uds_bp2_name = {
                attributeValue: watch('peopleInvolvedInCrashName2'),
                attributeTypeCode: crmDataTypes.String
              };
            }
            if (!!watch('peopleInvolvedInCrashAdress2') && watch('peopleInvolvedInCrashAdress2').length > 0) {
              createSchadenAufnahme.attributes.uds_bp2_adresse = {
                attributeValue: watch('peopleInvolvedInCrashAdress2'),
                attributeTypeCode: crmDataTypes.String
              };
            }
            if (!!watch('peopleInvolvedInCrashPhone2') && watch('peopleInvolvedInCrashPhone2').length > 0) {
              createSchadenAufnahme.attributes.uds_ubtelefon4 = {
                attributeValue: `${watch('peopleInvolvedInCrashPhone2Code')}${watch('peopleInvolvedInCrashPhone2')}`,
                attributeTypeCode: crmDataTypes.String
              };
            }
          }

          if (!!watch('peopleInvolvedInCrashPeopleDamage2') || watch('peopleInvolvedInCrashPeopleDamage2') === false) {
            createSchadenAufnahme.attributes.uds_bp2_schaden_personenschaeden = {
              attributeValue: watch('peopleInvolvedInCrashPeopleDamage2'),
              attributeTypeCode: crmDataTypes.Boolean
            };
          }

          if (
            !!watch('peopleInvolvedInCrashPeopleInvolvedInsuranse2') ||
            watch('peopleInvolvedInCrashPeopleInvolvedInsuranse2') === false
          ) {
            createSchadenAufnahme.attributes.uds_ubversicherung3_unbekannt = {
              attributeValue: watch('peopleInvolvedInCrashPeopleInvolvedInsuranse2'),
              attributeTypeCode: crmDataTypes.Boolean
            };
          }

          if (!!watch('peopleInvolvedInCrashPeopleInvolvedInsuranse2')) {
            if (
              !!watch('peopleInvolvedInCrashPeopleInvolvedInsuranseName2') &&
              watch('peopleInvolvedInCrashPeopleInvolvedInsuranseName2').length > 0
            ) {
              createSchadenAufnahme.attributes.uds_ubversicherung3 = {
                attributeValue: watch('peopleInvolvedInCrashPeopleInvolvedInsuranseName2'),
                attributeTypeCode: crmDataTypes.String
              };
            }
            if (
              !!watch('peopleInvolvedInCrashPeopleInvolvedInsuranseNumber2') &&
              watch('peopleInvolvedInCrashPeopleInvolvedInsuranseNumber2').length > 0
            ) {
              createSchadenAufnahme.attributes.uds_ubversichennum3 = {
                attributeValue: watch('peopleInvolvedInCrashPeopleInvolvedInsuranseNumber2'),
                attributeTypeCode: crmDataTypes.String
              };
            }
          }

          if (
            !!watch('peopleInvolvedInCrashCarOwnerIsDriver2') ||
            watch('peopleInvolvedInCrashCarOwnerIsDriver2') === false
          ) {
            createSchadenAufnahme.attributes.uds_ubname3_schaediger = {
              attributeValue: watch('peopleInvolvedInCrashCarOwnerIsDriver2'),
              attributeTypeCode: crmDataTypes.Boolean
            };

            if (!!watch('peopleInvolvedInCrashCarOwnerIsDriver2')) {
              if (
                !!watch('peopleInvolvedInCrashCarOwnerIsDriverName2') &&
                watch('peopleInvolvedInCrashCarOwnerIsDriverName2').length > 0
              ) {
                createSchadenAufnahme.attributes.uds_fh_name2 = {
                  attributeValue: watch('peopleInvolvedInCrashCarOwnerIsDriverName2'),
                  attributeTypeCode: crmDataTypes.String
                };
              }
              if (
                !!watch('peopleInvolvedInCrashCarOwnerIsDriverAdress2') &&
                watch('peopleInvolvedInCrashCarOwnerIsDriverAdress2').length > 0
              ) {
                createSchadenAufnahme.attributes.uds_fh_anschrift2 = {
                  attributeValue: watch('peopleInvolvedInCrashCarOwnerIsDriverAdress2'),
                  attributeTypeCode: crmDataTypes.String
                };
              }
              if (
                !!watch('peopleInvolvedInCrashCarOwnerIsDriverPhone2') &&
                watch('peopleInvolvedInCrashCarOwnerIsDriverPhone2').length > 0
              ) {
                createSchadenAufnahme.attributes.uds_ubtelefon3 = {
                  attributeValue: `${watch('peopleInvolvedInCrashCarOwnerIsDriverPhone2Code')}${watch(
                    'peopleInvolvedInCrashCarOwnerIsDriverPhone2'
                  )}`,
                  attributeTypeCode: crmDataTypes.String
                };
              }
            }
          }

          if (
            watch('peopleInvolvedInCrashWhatHasDamage.Fahrzeug2') === 'Fahrzeug' ||
            watch('peopleInvolvedInCrashWhatHasDamage.Fahrzeug2') === false
          ) {
            createSchadenAufnahme.attributes.uds_bp2_wurde_fahrzeug_beschadigt = {
              attributeValue: watch('peopleInvolvedInCrashWhatHasDamage.Fahrzeug2') === 'Fahrzeug',
              attributeTypeCode: crmDataTypes.Boolean
            };
          }

          if (watch('peopleInvolvedInCrashWhatHasDamage.Fahrzeug2') === 'Fahrzeug') {
            if (
              !!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichenEmpty2') ||
              watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichenEmpty2') === false
            ) {
              createSchadenAufnahme.attributes.uds_bp2_kennzeichenunbekannt = {
                attributeValue: watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichenEmpty2'),
                attributeTypeCode: crmDataTypes.Boolean
              };
            }
            if (
              !watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichenEmpty2') &&
              !!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichen2') &&
              watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichen2').length > 0
            ) {
              createSchadenAufnahme.attributes.uds_ubkennzeichen4 = {
                attributeValue: watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichen2'),
                attributeTypeCode: crmDataTypes.String
              };
            }
            if (
              !!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugHersteller2') &&
              watch('peopleInvolvedInCrashWhatHasDamageFahrzeugHersteller2').length > 0
            ) {
              createSchadenAufnahme.attributes.uds_bp2_fahrzeug_hersteller = {
                attributeValue: watch('peopleInvolvedInCrashWhatHasDamageFahrzeugHersteller2'),
                attributeTypeCode: crmDataTypes.String
              };
            }
            if (
              !!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugTyp2') &&
              watch('peopleInvolvedInCrashWhatHasDamageFahrzeugTyp2').length > 0
            ) {
              createSchadenAufnahme.attributes.uds_bp2_fahrzeug_typ = {
                attributeValue: watch('peopleInvolvedInCrashWhatHasDamageFahrzeugTyp2'),
                attributeTypeCode: crmDataTypes.String
              };
            }
          }

          if (
            watch('peopleInvolvedInCrashWhatHasDamage.Sonstiges2') === 'Sonstiges' ||
            watch('peopleInvolvedInCrashWhatHasDamage.Sonstiges2') === false
          ) {
            createSchadenAufnahme.attributes.uds_ub3_sachschaden = {
              attributeValue: watch('peopleInvolvedInCrashWhatHasDamage.Sonstiges2') === 'Sonstiges',
              attributeTypeCode: crmDataTypes.Boolean
            };

            if (
              watch('peopleInvolvedInCrashWhatHasDamage.Sonstiges2') === 'Sonstiges' &&
              !!watch('peopleInvolvedInCrashWhatHasDamageSonstigesDamageDescription2') &&
              watch('peopleInvolvedInCrashWhatHasDamageSonstigesDamageDescription2').length > 0
            ) {
              createSchadenAufnahme.attributes.uds_ub3_was_beschaedigt_sachschaden = {
                attributeValue: watch('peopleInvolvedInCrashWhatHasDamageSonstigesDamageDescription2'),
                attributeTypeCode: crmDataTypes.String
              };
            }
          }

          if (
            !!watch('peopleInvolvedInCrashAnotherPeopleInvolved2') ||
            watch('peopleInvolvedInCrashAnotherPeopleInvolved2') === false
          ) {
            createSchadenAufnahme.attributes.uds_schaden_unfallbeteiligte3 = {
              attributeValue: watch('peopleInvolvedInCrashAnotherPeopleInvolved2'),
              attributeTypeCode: crmDataTypes.Boolean
            };

            //////////////////////////////////////////////////////////////////////////////////////////
            // THIRD BLOCK FOR  INVOLVED IN CRASH PERSON !!!!!!
            //////////////////////////////////////////////////////////////////////////////////////////

            if (!!watch('peopleInvolvedInCrashAnotherPeopleInvolved2')) {
              if (!!watch('peopleInvolvedInCrashDateEmpty3')) {
                createSchadenAufnahme.attributes.uds_bp3_unfallgegner_personliche_daten = {
                  attributeValue: watch('peopleInvolvedInCrashDateEmpty3'),
                  attributeTypeCode: crmDataTypes.Boolean
                };
              }

              if (!watch('peopleInvolvedInCrashDateEmpty')) {
                if (!!watch('peopleInvolvedInCrashName3') && watch('peopleInvolvedInCrashName3').length > 0) {
                  createSchadenAufnahme.attributes.uds_bp3_name = {
                    attributeValue: watch('peopleInvolvedInCrashName3'),
                    attributeTypeCode: crmDataTypes.String
                  };
                }
                if (!!watch('peopleInvolvedInCrashAdress3') && watch('peopleInvolvedInCrashAdress3').length > 0) {
                  createSchadenAufnahme.attributes.uds_bp3_adresse = {
                    attributeValue: watch('peopleInvolvedInCrashAdress3'),
                    attributeTypeCode: crmDataTypes.String
                  };
                }
                if (!!watch('peopleInvolvedInCrashPhone3') && watch('peopleInvolvedInCrashPhone3').length > 0) {
                  createSchadenAufnahme.attributes.uds_bp3_telefon = {
                    attributeValue: `${watch('peopleInvolvedInCrashPhone3Code')}${watch(
                      'peopleInvolvedInCrashPhone3'
                    )}`,
                    attributeTypeCode: crmDataTypes.String
                  };
                }
              }

              if (
                !!watch('peopleInvolvedInCrashPeopleDamage3') ||
                watch('peopleInvolvedInCrashPeopleDamage3') === false
              ) {
                createSchadenAufnahme.attributes.uds_bp3_schaden_personenschaeden = {
                  attributeValue: watch('peopleInvolvedInCrashPeopleDamage3'),
                  attributeTypeCode: crmDataTypes.Boolean
                };
              }

              if (
                !!watch('peopleInvolvedInCrashPeopleInvolvedInsuranse3') ||
                watch('peopleInvolvedInCrashPeopleInvolvedInsuranse3') === false
              ) {
                createSchadenAufnahme.attributes.uds_bp3_ubversicherung_unbekannt = {
                  attributeValue: watch('peopleInvolvedInCrashPeopleInvolvedInsuranse3'),
                  attributeTypeCode: crmDataTypes.Boolean
                };
              }

              if (!!watch('peopleInvolvedInCrashPeopleInvolvedInsuranse3')) {
                if (
                  !!watch('peopleInvolvedInCrashPeopleInvolvedInsuranseName3') &&
                  watch('peopleInvolvedInCrashPeopleInvolvedInsuranseName3').length > 0
                ) {
                  createSchadenAufnahme.attributes.uds_bp3_ubversicherung = {
                    attributeValue: watch('peopleInvolvedInCrashPeopleInvolvedInsuranseName3'),
                    attributeTypeCode: crmDataTypes.String
                  };
                }
                if (
                  !!watch('peopleInvolvedInCrashPeopleInvolvedInsuranseNumber3') &&
                  watch('peopleInvolvedInCrashPeopleInvolvedInsuranseNumber3').length > 0
                ) {
                  createSchadenAufnahme.attributes.uds_bp3_ubversichennum = {
                    attributeValue: watch('peopleInvolvedInCrashPeopleInvolvedInsuranseNumber3'),
                    attributeTypeCode: crmDataTypes.String
                  };
                }
              }

              if (
                !!watch('peopleInvolvedInCrashCarOwnerIsDriver3') ||
                watch('peopleInvolvedInCrashCarOwnerIsDriver3') === false
              ) {
                createSchadenAufnahme.attributes.uds_is_ubname4_schaediger = {
                  attributeValue: watch('peopleInvolvedInCrashCarOwnerIsDriver3'),
                  attributeTypeCode: crmDataTypes.Boolean
                };

                if (!!watch('peopleInvolvedInCrashCarOwnerIsDriver3')) {
                  if (
                    !!watch('peopleInvolvedInCrashCarOwnerIsDriverName3') &&
                    watch('peopleInvolvedInCrashCarOwnerIsDriverName3').length > 0
                  ) {
                    createSchadenAufnahme.attributes.uds_fh_name3 = {
                      attributeValue: watch('peopleInvolvedInCrashCarOwnerIsDriverName3'),
                      attributeTypeCode: crmDataTypes.String
                    };
                  }
                  if (
                    !!watch('peopleInvolvedInCrashCarOwnerIsDriverAdress3') &&
                    watch('peopleInvolvedInCrashCarOwnerIsDriverAdress3').length > 0
                  ) {
                    createSchadenAufnahme.attributes.uds_fh_anschrift3 = {
                      attributeValue: watch('peopleInvolvedInCrashCarOwnerIsDriverAdress3'),
                      attributeTypeCode: crmDataTypes.String
                    };
                  }
                  if (
                    !!watch('peopleInvolvedInCrashCarOwnerIsDriverPhone3') &&
                    watch('peopleInvolvedInCrashCarOwnerIsDriverPhone3').length > 0
                  ) {
                    createSchadenAufnahme.attributes.uds_fh_telefon3 = {
                      attributeValue: `${watch('peopleInvolvedInCrashCarOwnerIsDriverPhone3Code')}${watch(
                        'peopleInvolvedInCrashCarOwnerIsDriverPhone2'
                      )}`,
                      attributeTypeCode: crmDataTypes.String
                    };
                  }
                }
              }

              if (
                watch('peopleInvolvedInCrashWhatHasDamage.Fahrzeug3') === 'Fahrzeug' ||
                watch('peopleInvolvedInCrashWhatHasDamage.Fahrzeug3') === false
              ) {
                createSchadenAufnahme.attributes.uds_bp3_wurde_fahrzeug_beschadigt = {
                  attributeValue: watch('peopleInvolvedInCrashWhatHasDamage.Fahrzeug3') === 'Fahrzeug',
                  attributeTypeCode: crmDataTypes.Boolean
                };
              }

              if (watch('peopleInvolvedInCrashWhatHasDamage.Fahrzeug3') === 'Fahrzeug') {
                if (
                  !!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichenEmpty3') ||
                  watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichenEmpty3') === false
                ) {
                  createSchadenAufnahme.attributes.uds_bp3_kennzeichenunbekannt = {
                    attributeValue: watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichenEmpty3'),
                    attributeTypeCode: crmDataTypes.Boolean
                  };
                }
                if (
                  !watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichenEmpty3') &&
                  !!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichen3') &&
                  watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichen3').length > 0
                ) {
                  createSchadenAufnahme.attributes.uds_ubkennzeichen5 = {
                    attributeValue: watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichen3'),
                    attributeTypeCode: crmDataTypes.String
                  };
                }
                if (
                  !!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugHersteller3') &&
                  watch('peopleInvolvedInCrashWhatHasDamageFahrzeugHersteller3').length > 0
                ) {
                  createSchadenAufnahme.attributes.uds_bp3_fahrzeug_hersteller = {
                    attributeValue: watch('peopleInvolvedInCrashWhatHasDamageFahrzeugHersteller3'),
                    attributeTypeCode: crmDataTypes.String
                  };
                }
                if (
                  !!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugTyp3') &&
                  watch('peopleInvolvedInCrashWhatHasDamageFahrzeugTyp3').length > 0
                ) {
                  createSchadenAufnahme.attributes.uds_bp3_fahrzeug_typ = {
                    attributeValue: watch('peopleInvolvedInCrashWhatHasDamageFahrzeugTyp3'),
                    attributeTypeCode: crmDataTypes.String
                  };
                }
              }

              if (
                watch('peopleInvolvedInCrashWhatHasDamage.Sonstiges3') === 'Sonstiges' ||
                watch('peopleInvolvedInCrashWhatHasDamage.Sonstiges3') === false
              ) {
                createSchadenAufnahme.attributes.uds_bp3_sachschaden = {
                  attributeValue: watch('peopleInvolvedInCrashWhatHasDamage.Sonstiges3') === 'Sonstiges',
                  attributeTypeCode: crmDataTypes.Boolean
                };

                if (
                  watch('peopleInvolvedInCrashWhatHasDamage.Sonstiges3') === 'Sonstiges' &&
                  !!watch('peopleInvolvedInCrashWhatHasDamageSonstigesDamageDescription3') &&
                  watch('peopleInvolvedInCrashWhatHasDamageSonstigesDamageDescription3').length > 0
                ) {
                  createSchadenAufnahme.attributes.uds_bp3_was_beschaedigt_sachschaden = {
                    attributeValue: watch('peopleInvolvedInCrashWhatHasDamageSonstigesDamageDescription3'),
                    attributeTypeCode: crmDataTypes.String
                  };
                }
              }
            }
          }
        }
      }
    }

    if (watch('animalDamageReportAuthorities') !== undefined) {
      createSchadenAufnahme.attributes.uds_tierschaden_meldung_behoerde = {
        attributeValue: watch('animalDamageReportAuthorities'),
        attributeTypeCode: crmDataTypes.Boolean
      };

      if (!!watch('animalDamageReportAuthorities') && watch('animalDamageReportAuthoritiesCertificate') !== undefined) {
        createSchadenAufnahme.attributes.uds_tierschaden_meldung_behoerde_bescheinigun = {
          attributeValue: watch('animalDamageReportAuthoritiesCertificate'),
          attributeTypeCode: crmDataTypes.Boolean
        };
      }
    }

    if (!!watch('animalName') && watch('animalName').length > 0) {
      createSchadenAufnahme.attributes.uds_tierart = {
        attributeValue: watch('animalName'),
        attributeTypeCode: crmDataTypes.String
      };
    }

    if (watch('anyEvidence') !== undefined) {
      createSchadenAufnahme.attributes.uds_gibt_hinweise_auf_taeter_option = {
        attributeValue: {
          label: null,
          value: !!watch('anyEvidence') ? 100000000 : 100000001
        },
        attributeTypeCode: crmDataTypes.Picklist
      };

      if (!!watch('anyEvidence') && !!watch('anyEvidenceClues') && watch('anyEvidenceClues').length > 0) {
        createSchadenAufnahme.attributes.uds_hinweise_auf_taeter = {
          attributeValue: watch('anyEvidenceClues'),
          attributeTypeCode: crmDataTypes.String
        };
      }
    }

    if (watch('youHaveAllKeys') !== undefined) {
      createSchadenAufnahme.attributes.uds_liegen_alle_schluessel_vor = {
        attributeValue: watch('youHaveAllKeys'),
        attributeTypeCode: crmDataTypes.Boolean
      };

      if (!!watch('youHaveAllKeys') && !!watch('whereIsKeys') && watch('whereIsKeys').length > 0) {
        createSchadenAufnahme.attributes.uds_wo_sind_die_fahrzeugschluessel = {
          attributeValue: watch('whereIsKeys'),
          attributeTypeCode: crmDataTypes.String
        };
      }
    }

    if (!!watch('howToVehicleWasSecured') && watch('howToVehicleWasSecured').length > 0) {
      createSchadenAufnahme.attributes.uds_absicherung_diebstahl = {
        attributeValue: watch('howToVehicleWasSecured'),
        attributeTypeCode: crmDataTypes.String
      };
    }

    if (!!watch('whatWasStolen') && watch('whatWasStolen').length > 0) {
      createSchadenAufnahme.attributes.uds_was_wurde_gestohlen = {
        attributeValue: watch('whatWasStolen'),
        attributeTypeCode: crmDataTypes.String
      };
    }

    const createDamageParts: DamagePartsEntityArray = [];
    if (isDamageType && Array.isArray(watch('damageParts')) && watch('damageParts').length > 0) {
      watch('damageParts').map((el: DamagePartsItemProps) => {
        let elObject = {
          entityName: 'uds_damagedpart',
          attributes: {
            uds_parttypecode: {
              attributeValue: {
                label: null,
                value: el[1].value
              },
              attributeTypeCode: 11
            },
            uds_damagetypecode: {
              attributeValue: {
                label: null,
                value: el[1].type
              },
              attributeTypeCode: 11
            }
          }
        };
        createDamageParts.push(elObject);
      });
    }

    const files: QueryProps['files'] = [];
    if (Array.isArray(watch('FotoSchaden')?.files) && watch('FotoSchaden')?.files.length > 0) {
      watch('FotoSchaden').files.forEach((el: File) => {
        const fileObject = {
          el,
          fileSection: 0
        };
        files.push(fileObject);
      });
    }
    if (Array.isArray(watch('Skizze')?.files) && watch('Skizze')?.files.length > 0) {
      watch('Skizze').files.forEach((el: File) => {
        const fileObject = {
          el,
          fileSection: 2
        };
        files.push(fileObject);
      });
    }
    if (Array.isArray(watch('polizei')?.files) && watch('polizei')?.files.length > 0) {
      watch('polizei').files.forEach((el: File) => {
        const fileObject = {
          el,
          fileSection: 1
        };
        files.push(fileObject);
      });
    }
    if (
      Array.isArray(watch('SonstigeUnterlagenzurSchadendokumentation')?.files) &&
      watch('SonstigeUnterlagenzurSchadendokumentation')?.files.length > 0
    ) {
      watch('SonstigeUnterlagenzurSchadendokumentation').files.forEach((el: File) => {
        const fileObject = {
          el,
          fileSection: 3
        };
        files.push(fileObject);
      });
    }
    if (Array.isArray(watch('Wildunfallbescheinigung')?.files) && watch('Wildunfallbescheinigung')?.files.length > 0) {
      watch('Wildunfallbescheinigung').files.forEach((el: File) => {
        const fileObject = {
          el,
          fileSection: 4
        };
        files.push(fileObject);
      });
    }

    setValue('createSchadenAufnahme', createSchadenAufnahme);
    setValue('createDamageParts', createDamageParts);
    setValue('files', files);
  };

  return (
    <>
      <div className={styles.row_columns}>
        <div className={styles.left_block}>
          <button
            onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
              e.preventDefault();
              moveToPreviousStep();
            }}
            className={styles.button_back}
          >
            Zurück
          </button>
        </div>
        <div className={styles.right_block}>
          <button onClick={moveToNextStep} className={styles.button_active}>
            Formular abschicken
          </button>
        </div>
      </div>
      {!!errorMessage && <label className={styles.error_message}>{errorMessage}</label>}
    </>
  );
};

export default RequestComponent;
