import React from 'react';
import WidgetButton from '../../../components/widget-button';
import { useChecklistButtons } from './use-checklist-buttons';
import { Flex } from 'common/common-components.styled';
import { ChecklistButtonsProps } from './checklist-buttons.types';

const ChecklistButtons: React.FC<ChecklistButtonsProps> = ({ setShowChecklistModal }) => {
  const { showButton } = useChecklistButtons();

  if (!showButton) {
    return null;
  }

  return (
    <Flex justify='flex-end'>
      <WidgetButton
        title='Checkliste ausfüllen'
        handleClick={() => {
          setShowChecklistModal(true);
        }}
      />
    </Flex>
  );
};

export default ChecklistButtons;
