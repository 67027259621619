import { BaseOptionStatuses } from '../use-base-options/use-base-options.types';

export interface OptionResponse {
  basePrice: number;
  location: string;
  manufName: string;
  optionCode: string;
  optionId: number;
  optionType: OptionTypes;
  recordId: number;
  retailPrice: number;
  schemaId: number;
  uniqId: string;
  sumBasePrice: number;
  sumRetailPrice: number;
  categoryName: CategoryNames;
  equipmentCode: string;
  carPolicyStatus: BaseOptionStatuses;
  retailPriceDiscount: number;
  basePriceDiscount: number;
  conflictOptions?: OptionResponse[];
}

export enum OptionTypes {
  Color = 'C',
  Option = 'O',
  Package = 'P'
}

export enum ConfiguratorLanguages {
  German = 3,
  English = 19
}

export enum CategoryNames {
  Paint = 'Paint',
  Wheels = 'Wheels',
  Interior = 'Interior',
  OtherOptions = 'OtherOptions'
}
