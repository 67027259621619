import React, { useEffect, useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import FormHook from 'components/form-hook/form-hook';
import { Button, Flex } from 'common/common-components.styled';
import isEmpty from 'lodash/isEmpty';
import {
  editDueDateFormFields,
  editDueDateCalendarFormFields,
  taskEmployeeFormFields,
  taskVehicleFormFields,
  taskTitleFormFields,
  taskDescriptionFormFields,
  taskFilesFormField
} from './edit-task-form-fields';
import { CrmResponse, CrmLookupResponse, AnyObject } from 'common/interfaces';
import { CrmName } from 'components/aufgaben-component/task.prop';
import moment from 'moment';
import { useVehicleForCreateTaskQuery } from 'hooks/react-query/tasks/vehicle-for-create-task';
import { useEmployeesQuery } from '../create-new-task/use-employees-query';
import { cloneDeep } from 'lodash';
import { editTask } from 'components/aufgaben-component/utils';
import { getRequestFormData } from 'utils/get-response-data';
import { allFieldsCreateTask } from '../create-new-task/formFields';
import { useOverlay } from 'hooks';
import { useDispatch } from 'react-redux';
import { setSnakbar } from 'actions/app_action';
import { FileProps } from 'pages/vehicle-return-page/vehicle-return-page.props';
import { blobToBase64 } from 'utils/decode-encode-base64';

interface TaskDataProps {
  taskId: CrmResponse;
  title: CrmResponse;
  description: CrmResponse;
  executorId: CrmLookupResponse;
  vehicleId: CrmLookupResponse;
  dueDate: CrmResponse;
  files: FileProps[];
}

interface EditTaskProps {
  onCancel: () => void;
  taskData: TaskDataProps;
  refresh: () => void;
}

const convertFilesForField = (files: FileProps[]) => {
  return files.map(file => {
    return {
      path: file.path,
      lastModified: moment(file.modifiedOn).valueOf(),
      lastModifiedDate: moment(file.modifiedOn).toISOString(),
      name: file.fileName,
      size: Number(file.size),
      id: file.fileId
    };
  });
};

const EditTask = ({ onCancel, taskData, refresh }: EditTaskProps) => {
  const reactHookFormData = useForm();
  const {
    handleSubmit,
    formState: { errors, dirtyFields },
    watch,
    setValue,
    clearErrors
  } = reactHookFormData;

  const dispatch = useDispatch();
  const [showOverlay, hideOverlay] = useOverlay();
  const currentDueDateOption = watch('dateOptions');
  const isCalendarVisible = currentDueDateOption === 'choose';
  const currentDueDateCalendar = watch('dateChosen');
  const currentVehicle = watch(CrmName.Vehicle);
  const { vehicleForCreateTaskData, isVehicleForCreateTaskFetching } = useVehicleForCreateTaskQuery();
  const { employeesOptions } = useEmployeesQuery(false);

  const [currentEmployeeFields, setCurrentEmployeeFields] = useState(taskEmployeeFormFields);
  const [currentVehicleFields, setCurrentVehicleFields] = useState(taskVehicleFormFields);

  const setDriverForVehicle = useCallback(
    (vehicleId: string) => {
      const findDataByVehicle = vehicleForCreateTaskData?.find(
        dataItem => dataItem.new_fuhrparkid.attributeValue === vehicleId
      );

      const isDriverInEmployees = employeesOptions.some(
        employee => employee.value === findDataByVehicle?.new_fahrerid?.attributeValue?.id
      );

      if (isDriverInEmployees) {
        return findDataByVehicle?.new_fahrerid?.attributeValue?.id;
      }
    },
    [employeesOptions, vehicleForCreateTaskData]
  );

  const vehicleFormData = useCallback(() => {
    const cloneVehicleData = cloneDeep(vehicleForCreateTaskData);

    return cloneVehicleData?.map(dataItem => ({
      value: dataItem.new_fuhrparkid.attributeValue || '',
      label: dataItem.new_name.attributeValue || ''
    }));
  }, [JSON.stringify(vehicleForCreateTaskData)]);

  const submit = () => {
    handleSubmit(async data => {
      showOverlay();
      const newTaskData: AnyObject = getRequestFormData(data, allFieldsCreateTask);
      if (data[CrmName.Vehicle]) {
        newTaskData.regardingobjectid.attributeValue.logicalName = 'new_fuhrpark';
      }
      newTaskData.uds_executor_contactid.attributeValue.logicalName = 'contact';

      let formattedFiles = [];

      if (data.files?.length) {
        if (data.files && data.files.length) {
          const files = await Promise.all(
            data.files.map(async (file: AnyObject) => {
              if (file.id) {
                return {
                  fileId: file.id,
                  fileName: null,
                  body: null
                };
              } else {
                const base64Body = (await blobToBase64(file as File)) as string;

                return {
                  fileName: file.name,
                  body: base64Body?.split(',')[1]
                };
              }
            })
          );
          formattedFiles = files;
        }
      }
      editTask({
        Id: taskData.taskId.attributeValue,
        Attributes: newTaskData,
        Files: formattedFiles
      })
        .then(response => {
          refresh();
          onCancel();
          dispatch(
            setSnakbar({
              isOpen: true,
              message: response.data.isSuccess
                ? 'Die Aufgabe wurde erfolgreich aktualisiert.'
                : 'Fehler, die Aufgabe wurde nicht geändert.',
              type: response.data.level.toLowerCase()
            })
          );
        })
        .catch(e => {
          console.log(e);
        })
        .finally(() => {
          hideOverlay();
        });
    })();
  };

  useEffect(() => {
    if (isVehicleForCreateTaskFetching) {
      return;
    }

    const newVehicleFields = cloneDeep(taskVehicleFormFields);
    newVehicleFields[0].options = vehicleFormData();
    setCurrentVehicleFields(newVehicleFields);
  }, [isVehicleForCreateTaskFetching, vehicleFormData]);

  useEffect(() => {
    const newEmployeeFormFields = cloneDeep(taskEmployeeFormFields);

    newEmployeeFormFields[0].options = employeesOptions || [];
    setCurrentEmployeeFields(newEmployeeFormFields);
  }, [employeesOptions]);

  useEffect(() => {
    setValue(CrmName.Title, taskData.title.attributeValue, { shouldDirty: false });
    setValue(CrmName.Description, taskData.description.attributeValue, { shouldDirty: false });
    setValue(CrmName.Vehicle, taskData.vehicleId.attributeValue?.id, { shouldDirty: false });
    setValue(CrmName.Employee, taskData.executorId.attributeValue?.id, { shouldDirty: false });
    setValue(CrmName.DateOptions, 'choose');
    setValue(CrmName.DateChosen, moment(taskData.dueDate.attributeValue), { shouldDirty: false });

    setValue(CrmName.Files, convertFilesForField(taskData.files), { shouldDirty: false });
  }, []);

  useEffect(() => {
    if (currentVehicle && dirtyFields[CrmName.Vehicle]) {
      const newDriver = setDriverForVehicle(currentVehicle);
      setValue(CrmName.Employee, newDriver);
      if (newDriver) {
        clearErrors(CrmName.Employee);
      }
    }
  }, [clearErrors, currentVehicle, dirtyFields, setDriverForVehicle, setValue]);

  useEffect(() => {
    if (isCalendarVisible) {
      setValue(CrmName.DueDate, moment.utc(currentDueDateCalendar).format('YYYY-MM-DD'));
    } else {
      if (currentDueDateOption === 'today') {
        setValue(CrmName.DueDate, moment().format('YYYY-MM-DD'));
      } else {
        setValue(CrmName.DueDate, moment().add(currentDueDateOption, 'days').format('YYYY-MM-DD'));
      }
    }
  }, [currentDueDateCalendar, currentDueDateOption, isCalendarVisible, setValue]);

  return (
    <Flex top='30' direction='column'>
      <FormHook reactHookFormData={reactHookFormData} formFields={taskTitleFormFields} />
      <Flex>
        <FormHook reactHookFormData={reactHookFormData} formFields={currentVehicleFields} />
        <FormHook reactHookFormData={reactHookFormData} formFields={currentEmployeeFields} marginLeft='10' />
        <FormHook reactHookFormData={reactHookFormData} formFields={editDueDateFormFields} marginLeft='10' />
      </Flex>
      {isCalendarVisible && (
        <FormHook reactHookFormData={reactHookFormData} formFields={editDueDateCalendarFormFields} />
      )}
      <FormHook reactHookFormData={reactHookFormData} formFields={taskDescriptionFormFields} />

      <FormHook reactHookFormData={reactHookFormData} formFields={taskFilesFormField} />

      <Flex justify='flex-end'>
        <Button secondary onClick={onCancel}>
          Abbrechen
        </Button>
        <Button left='10' disabled={!isEmpty(errors)} onClick={submit}>
          Speichern
        </Button>
      </Flex>
    </Flex>
  );
};

export default EditTask;
