import React, { useEffect, useMemo, useState } from 'react';
import PersonalDataStep from './components/personal-data-step';
import MileageStep from './components/mileage-step';
import ReturnStep from './components/return-step';
import ServiceInspectionsStep from './components/service-inspections-step';
import ConditionStep from './components/condition-step';
import { useFetch } from 'hooks/use-fetch/use-fetch';
import { checklistModalRequestConfig } from 'request-config/checklist-modal/checklist-modal.request-config';
import { useQuery } from 'react-query';
import { PAGES, ReactQueryKeys } from 'common/enums';
import { ApiResponse } from 'common/interfaces';
import { ChecklistInfo } from 'request-config/checklist-modal/checklist-modal.types';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { isEmpty, isNumber } from 'lodash';
import { DamageFormSection } from './components/condition-step/use-condition-step.types';
import {
  getChecklistFields,
  getDamagedSectionsValues,
  getDamageSectionsWithFiles,
  initialSteps,
  modifyResponse
} from './utils';
import { queryClient } from 'index';
import { uploadDamageFile } from 'components/aufgaben-component/utils';

export const useChecklistModal = (
  carId: string,
  reactHookFormData: UseFormReturn<FieldValues, any>,
  onClose: () => void
) => {
  const {
    handleSubmit,
    formState: { dirtyFields }
  } = reactHookFormData;
  const [activeStep, setActiveStep] = useState<number | null>(null);
  const [steps, setSteps] = useState(initialSteps);
  const [isUpdating, setIsUpdating] = useState(false);
  const [damagedSections, setDamagedSections] = useState<DamageFormSection[][]>([]);
  const [isFormSubmited, setIsFormmSubmited] = useState(false);

  const { fetch: getChecklistInfo } = useFetch({
    loadingKey: 'checklistlInfo',
    isShowErrorSnackbar: false,
    isShowLoading: false,
    ...checklistModalRequestConfig.getChecklistInfo(carId as string)
  });

  const { fetch: updateChecklistForm } = useFetch({
    loadingKey: 'updateCheklistInfo',
    isShowErrorSnackbar: false,
    isShowLoading: false
  });

  const {
    data: checklistInfo,
    isLoading,
    isFetched,
    refetch
  } = useQuery<ApiResponse<ChecklistInfo>, unknown, ChecklistInfo>(
    [ReactQueryKeys.ChecklistModal, carId],
    getChecklistInfo,
    {
      enabled: Boolean(carId),
      select: modifyResponse
    }
  );

  const handleStepChange = (stepTitle: string) => {
    const newSteps = steps.map((step, index) => {
      if (step.title === stepTitle) {
        setActiveStep(index);
        return {
          title: step.title,
          active: true
        };
      }
      return {
        title: step.title,
        active: false
      };
    });

    setSteps(newSteps);
  };

  const handleNextStep = () => {
    const isLastStep = steps.length - 1 === activeStep;

    if (isNumber(activeStep) && !isLastStep) {
      handleStepChange(steps[activeStep + 1].title);
      return;
    }

    if (isLastStep) {
      setIsFormmSubmited(true);
      const newSteps = steps.map((step, index) => {
        if (index === steps.length - 1) {
          return {
            title: step.title,
            active: true
          };
        }
        return {
          title: step.title,
          active: false
        };
      });

      setSteps(newSteps);
    }
  };

  const handlePrevStep = () => {
    if (isNumber(activeStep)) {
      handleStepChange(steps[activeStep - 1].title);
    }
  };

  const stepsComponents = useMemo(() => {
    return [<PersonalDataStep />, <MileageStep />, <ReturnStep />, <ServiceInspectionsStep />, <ConditionStep />];
  }, []);

  const onSubmit = () => {
    handleSubmit(async data => {
      setIsUpdating(true);
      const stepFromResponse = checklistInfo?.checklistData.attributes.uds_form_step?.value;
      const isSubmitRequired =
        stepFromResponse && (stepFromResponse - 1 === 0 || stepFromResponse - 1 > Number(activeStep))
          ? !isEmpty(dirtyFields)
          : true;
      const isLastStep = activeStep === steps.length - 1;
      const checklistFields = getChecklistFields(data, activeStep!, checklistInfo!);

      const checklistParts = isLastStep ? getDamagedSectionsValues(damagedSections, data) : [];
      const checklistFiles = isLastStep ? getDamageSectionsWithFiles(damagedSections, data) : [];

      try {
        if (isSubmitRequired) {
          for (const checklistFile of checklistFiles) {
            const formData = new FormData();
            formData.append('ChecklistId', checklistInfo?.checklistData.id!);
            formData.append('PartCode', checklistFile.partType.toString());
            formData.append('DamageCode', checklistFile.damageType.toString());
            formData.append('File', checklistFile.file);

            await uploadDamageFile(formData);
          }
          const response = await updateChecklistForm({
            requestConfig: checklistModalRequestConfig.updateChecklistForm({
              FuhrparkId: carId,
              ChecklistStep: Number(activeStep) + 1,
              ChecklistFields: checklistFields,
              ChecklistPartsFields: checklistParts
            })
          });

          if (response) {
            refetch();
            handleNextStep();
          }
        } else {
          handleNextStep();
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsUpdating(false);
      }
    })();
  };

  const handleFinishProcess = () => {
    onClose();
    queryClient.resetQueries([PAGES.TASKS]);
    queryClient.resetQueries([PAGES.MY_VEHICLES_DETAILS]);
  };

  useEffect(() => {
    if (isFetched) {
      const actualStep = checklistInfo?.checklistData.attributes.uds_form_step?.value
        ? checklistInfo?.checklistData.attributes.uds_form_step?.value - 1
        : 0;

      const formattedSteps = steps.map((step, index) => {
        return {
          ...step,
          active: actualStep === index
        };
      });

      setSteps(formattedSteps);
      setActiveStep(actualStep);
    }
  }, [isFetched]);

  return {
    steps,
    isFirstStep: activeStep === 0,
    activeStepComponent: stepsComponents[activeStep || 0],
    checklistInfo,
    isLoading: isLoading || !isNumber(activeStep) || isUpdating,
    damagedSections,
    isFormSubmited,
    setDamagedSections,
    handleStepChange,
    handlePrevStep,
    onSubmit,
    handleFinishProcess
  };
};
