import { ReleaseStatuses, StepperData } from './use-release-data/use-release-data.types';
import React, { useEffect, useMemo, useState } from 'react';
import DoneIcon from 'components/icons/done-icon';
import SigningExternalPageStepperIcon from 'components/icons/signing_external_page_stepper_icon';
import { useTheme } from 'styled-components';
import { Step } from '../release-external-page.types';
import useReleaseData from './use-release-data';
import { ApproveStatuses } from '../approve-component/approve-component';

const getStepLabel = (isCurrentUser: boolean, isSigned: boolean, initials: string, status: ApproveStatuses) => {
  if (isCurrentUser) {
    if (isSigned) {
      return 'Freigabe erteilt';
    } else {
      return status === ApproveStatuses.Declined ? 'Freigabe abgelehnt' : 'Freigabe erteilen';
    }
  } else {
    if (isSigned) {
      return `Anfrage von ${initials} akzeptiert`;
    } else {
      return `Anfrage an ${initials}`;
    }
  }
};

const useSteps = (status: ApproveStatuses, recordStatus?: ReleaseStatuses) => {
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);

  const { stepperData } = useReleaseData();

  const steps = useMemo(() => {
    if (stepperData) {
      const userSteps: Step[] = stepperData.map((participant: StepperData, index: number) => {
        const { isSigned, isCurrentUser, initials } = participant;
        const signed = [ApproveStatuses.Approved].includes(status) ? activeStep === index || isSigned : isSigned;
        const isFill =
          [ApproveStatuses.Approved].includes(status) || recordStatus === ReleaseStatuses.Signed
            ? activeStep === index || activeStep === index - 1
            : activeStep === index;

        return {
          label: getStepLabel(isCurrentUser, signed, initials, status),
          icon: signed ? (
            <DoneIcon fill={theme.colors.darkBlue} />
          ) : (
            <SigningExternalPageStepperIcon
              step={index}
              fill={isFill ? theme.colors.darkBlue : theme.colors.lightBlue}
            />
          ),
          isSigned: signed
        };
      });

      const isLastApproverSigned = userSteps[userSteps.length - 1].isSigned;
      const lastStep: Step = {
        label: 'Bestellabschluss',
        icon: isLastApproverSigned ? (
          <DoneIcon fill={theme.colors.darkBlue} />
        ) : (
          <SigningExternalPageStepperIcon step={stepperData.length} fill={theme.colors.lightBlue} />
        ),
        isSigned: isLastApproverSigned
      };

      return [...userSteps, lastStep];
    }

    return [];
  }, [JSON.stringify(stepperData), status, activeStep]);

  useEffect(() => {
    if (stepperData) {
      setActiveStep(stepperData.findIndex((participant: StepperData) => participant.isCurrentUser));
    }
  }, [JSON.stringify(stepperData)]);

  return { steps, activeStep };
};

export default useSteps;
