import styled from 'styled-components';
import { Flex } from 'common/common-components.styled';
import Chip from '@mui/material/Chip';

interface StyledChipsFieldProps {
  error?: boolean;
}

export const StyledChipsField = styled(Flex)<StyledChipsFieldProps>`
  border: 1px solid ${({ theme, error }) => (error ? theme.colors.pink : theme.colors.grey500)};
  border-radius: 2px;
  padding: 4px 15px;
`;

export const StyledInput = styled.input`
  font-size: 14px !important;
  color: ${({ theme }) => theme.colors.black};
  height: 32px;
  border: none;
  ::placeholder {
    color: ${({ theme }) => theme.colors.grey400};
  }
`;

export const StyledChip = styled(Chip)`
  & .MuiChip-root {
  }
  font-size: 14px !important;
  color: ${({ theme }) => theme.colors.white} !important;
  background-color: ${({ theme }) => theme.colors.blue} !important;
`;
