import React, {FunctionComponent, ReactElement, ReactSVGElement} from 'react';
import { IconProps } from 'common/interfaces';

export const EmailContactIcon:FunctionComponent<IconProps> = ({ width = 33, height = 22, color: colorProp, fill: fillProp, hovered }: IconProps)
   :ReactElement<ReactSVGElement> => {
    const strokeColor = colorProp ? colorProp : "#335566";
    return (
      <svg width={width} height={height} viewBox="0 0 33 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.5 5.24463L15.2294 11.8849C16.0278 12.2719 16.9592 12.2719 17.7576 11.8849L31.4991 5.24463" stroke={strokeColor} stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M1.89746 20.1991L12.7317 10.7994M20.2934 10.7994L31.1276 20.1991" stroke={strokeColor} stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M3.4362 21.0896C2.37172 21.0896 1.5 20.2187 1.5 19.1543V9.47817L1.50063 2.84563C1.50063 1.78126 2.37157 0.9104 3.43606 0.9104H29.5638C30.6283 0.9104 31.4992 1.78126 31.4992 2.84563L31.4999 9.47817L31.5 12.5218L31.4994 19.1543C31.4994 20.2187 30.6284 21.0896 29.5639 21.0896H3.4362ZM3.4362 21.0896H17.6187" stroke={strokeColor} stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    );
};
