import { AxiosRequestConfig } from 'axios';
import { UpdateChecklistPayload } from './checklist-modal.types';

class ChecklistModalREquestConfig {
  getChecklistInfo = (id: string): AxiosRequestConfig => ({
    method: 'POST',
    url: 'FuhrparkLeaseEnd/ChecklistGetForm',
    data: {
      fuhrparkid: id
    }
  });
  updateChecklistForm = (data: Partial<UpdateChecklistPayload>): AxiosRequestConfig => ({
    method: 'POST',
    url: 'FuhrparkLeaseEnd/ChecklistUpdateForm',
    data
  });
}

export const checklistModalRequestConfig = new ChecklistModalREquestConfig();
