import React from 'react';
import { ChecklistModalProps } from './checklist-modal.types';
import { Dialog } from '@mui/material';
import {
  StepperContainer,
  StyledDialogActions,
  StyledDialogContent,
  StyledDialogTitle
} from './checklist-modal.styled';
import { Button, Flex, Text } from 'common/common-components.styled';
import ProgressComponent from 'components/progress-component/progress-component';
import { useChecklistModal } from './use-checklist-modal';
import SpinnerContainer from 'components/spinner-container';
import { ChecklistModalContext } from './checklist-modal-context';
import { ChecklistInfo } from 'request-config/checklist-modal/checklist-modal.types';
import { FormProvider, useForm } from 'react-hook-form';
import { CloseIcon } from 'components/icons-new/close';
import SuccessScreen from './components/suceess-screen';

const ChecklistModal: React.FC<ChecklistModalProps> = ({ open, carId, onClose }) => {
  const reactHookFormData = useForm();

  const {
    steps,
    activeStepComponent,
    checklistInfo,
    isLoading,
    isFirstStep,
    damagedSections,
    isFormSubmited,
    setDamagedSections,
    handleStepChange,
    handlePrevStep,
    onSubmit,
    handleFinishProcess
  } = useChecklistModal(carId, reactHookFormData, onClose);

  return (
    <Dialog open={open} maxWidth='md' id={'checklistModal'}>
      <StyledDialogTitle>
        <Flex direction='column' width='100%'>
          {!isFormSubmited && (
            <Flex justify='flex-end'>
              <Flex onClick={onClose} pointer>
                <CloseIcon />
              </Flex>
            </Flex>
          )}
          <Flex justify='center'>
            <Text size='24'>Checkliste Fahrzeugrückgabe</Text>
          </Flex>
        </Flex>

        {!isLoading && (
          <StepperContainer>
            <ProgressComponent isAllStepsCompleted={isFormSubmited} steps={steps} onClick={handleStepChange} />
          </StepperContainer>
        )}
      </StyledDialogTitle>
      <StyledDialogContent>
        {isLoading && <SpinnerContainer />}

        {!isLoading && (
          <>
            {isFormSubmited ? (
              <SuccessScreen />
            ) : (
              <Flex width='100%' maxWidth='630px'>
                <ChecklistModalContext.Provider
                  value={{
                    checklistInfo: checklistInfo || ({} as ChecklistInfo),
                    handlePrevStep,
                    damagedSections,
                    setDamagedSections
                  }}
                >
                  <FormProvider {...reactHookFormData}>{activeStepComponent}</FormProvider>
                </ChecklistModalContext.Provider>
              </Flex>
            )}
          </>
        )}
      </StyledDialogContent>
      {!isLoading && (
        <StyledDialogActions isOneButtonExist={isFirstStep || isFormSubmited}>
          {!isFirstStep && !isFormSubmited && (
            <Flex>
              <Button secondary onClick={handlePrevStep}>
                Zurück
              </Button>
            </Flex>
          )}

          <Flex>
            <Button onClick={isFormSubmited ? handleFinishProcess : onSubmit}>Weiter</Button>
          </Flex>
        </StyledDialogActions>
      )}
    </Dialog>
  );
};

export default ChecklistModal;
