import { createContext , useContext} from 'react';
import { DamageSubmitContext } from '../report-damage.interfaces';

export const DamageReportSavingContext = createContext<DamageSubmitContext>({
    message: '',
    loading:false,
    step: 0,
    loadingMessage:'',
    type:''
});

export const getDamageReportSavingContext = ()=> useContext<DamageSubmitContext>(DamageReportSavingContext);