/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState , useCallback} from 'react';
import { UnauthorizedPageContainer } from 'components/unauthorized-page-container';
import classes from './report-damage-component.module.scss';
import { useForm, SubmitHandler } from 'react-hook-form';
import { MODALS, MODAL_NAMES, RequestStatuses } from '../../constants';
import { createDamageCase, finishCreateDamageCase , uploadFilesOfDamage} from '../../services/report-damage-service';
import { documentTypeId } from './utils';
import SMHeader from './custom-header';
import CloseIcon from './icons/close';
import StartCarInfo from './car-info-component/car-info';
import DetailCarInfo from './detail-car-info-component/detail-car-info';
import DamageTypeChoose from './damage-choose-component/damage-type-choose';
import ChooseElementFlow from './choose-element-component/choose-element-component';
import SuccessPage from './success-page/success-page';
import DetailPage from './detail-page-component/detail-page';
import { useDispatch } from 'react-redux';
import { toggleModal } from 'actions/app_action';
import { useOverlay } from 'hooks';
import { AnyObject } from 'common/interfaces';
import SaveFieldsAlert from './save-fields-alert';
import HelmetComponent from 'components/helmet-component/helmet-component';
import { UNAUTHORIZED_PAGES } from 'common/enums';
import useWindowSize from 'hooks/use-window-size';
import { QueryProps } from './report-damage.props';
import Snackbar from 'components/snackbar';
import FileUploadingProcess from './file-uploading-process/file-uploading-process';
import {DamageFormModal} from './damage-form-loading-status-modal/damage-form-modal';
import {DamageReportSavingContext} from './damage-report-saving-context/damage-report-saving-context';
import { DamageSubmitContext , FilesArray, CurrentDamageFormState , FileSectionIndex} from './report-damage.interfaces';
import { ErrorsList , SuccessLabel , LoadingMessagesList} from './labels';
import { isEmpty } from 'lodash';


const ReportDamageComponent = () => {
  const dispatch = useDispatch();
  const [showOverlay, hideOverlay] = useOverlay();
  const [showSideBlock, setShowSideBlock] = useState<boolean>(false);
  const [formLoadingState , setFormLoadingState] = useState<DamageSubmitContext & CurrentDamageFormState>(
    {loading:false, loadingMessage:'', message:'', step:0, damageRecordId:'', type:''}
  );
  const reactHookFormData = useForm();
  const { windowWidth } = useWindowSize();
  const { watch, setValue, handleSubmit } = reactHookFormData;

  useEffect(() => {
    let SMdata = localStorage.getItem('SMdata');
    if (SMdata) {
      const savedData = JSON.parse(SMdata);
      if (Array.isArray(savedData)) {
        savedData.forEach((el: AnyObject) => {
          if (typeof el[1] === 'string' && el[1].match('000Z')) {
            let date = new Date(el[1]);
            setValue(el[0], date);
          } else {
            setValue(el[0], el[1]);
          }
        });
      }
    } else {
      setValue('step', 0);
    }
    if (windowWidth <= 900) {
      const meta = document.querySelector('meta[name="viewport"]');
      meta?.setAttribute('user-scalable', 'no');
    }
  }, []);

  useEffect(() => {
    window?.scrollTo(0, 0);
    document.getElementById('unauthorizedPageContainer')?.scrollTo(0, 0);
    document.getElementById('SMform')?.scrollTo(0, 0);
    document.getElementById('UPcontentConatiner')?.scrollTo(0, 0);
    document.getElementById('detailBlockView')?.scrollTo(0, 0);
    watch('step') !== 'success' && windowWidth > 900
      ? (document.body.style.overflow = 'hidden')
      : (document.body.style.overflow = 'auto');
  }, [watch('step'), watch('activeStep')]);

  const onSubmit: SubmitHandler<AnyObject> = () => {
    if (watch('lastStep') === true) {
      const createSchadenAufnahme = watch('createSchadenAufnahme') ? watch('createSchadenAufnahme') : null;
      const createDamageParts = Array.isArray(watch('createDamageParts')) ? watch('createDamageParts') : [];
      const createFuhrpark = watch('createFuhrpark') ? watch('createFuhrpark') : null;
      const files = Array.isArray(watch('files')) ? watch('files') : [];

      const query: QueryProps = {
        createSchadenAufnahme,
        createDamageParts,
        createFuhrpark,
        files
      };

      createCaseHandler(query);
    }
  };

  async function stepHandler<Type, Data>(callFunction:Function, data:Data, message:string, step:number):Promise<Type>{
    return new Promise( async (resolve, reject)=>{
       try{
         const response = await callFunction(data);
         resolve(response);
       }catch(error){
         reject({
          message,
          step
        });
       }
    });
  };

  const createDamageHandler = async (requestData: QueryProps):Promise<string>=>{
     try{
      const response = await createDamageCase(requestData, false);
      const { schadenAufnahmeId: caseId } = response?.data?.data ?? '';
      return caseId;
     }catch(error){
       throw error;
     }
  }

  const uploadFilesHandler = async ({files, id}:{files:FilesArray, id:string}):Promise<void>=>{
    try{
      await Promise.all(files.map( ({el ,fileSection}) =>
        uploadFilesOfDamage(
          [el] as File[],
          id,
          documentTypeId[fileSection as FileSectionIndex]
        )
      ));
    }catch(error){
      throw error;
    }
  }

  const finishCreateDamageHandler = async (recordId:string):Promise<void>=>{
    try{
      const response = await finishCreateDamageCase(recordId);
      if (response?.data.level === RequestStatuses.Success) {
        localStorage.removeItem('SMdata');
        setValue('step', 'success');
      }
    }catch(error){
      throw error;
    }
  }

  const setLoadingMessageHandler = (message:string)=>{
    setFormLoadingState((prevState) => ({...prevState, loadingMessage:message}));
  }

  const createCaseHandler = useCallback(async (requestData: QueryProps) => {
    const {step, damageRecordId} = formLoadingState;
    setFormLoadingState((prevState) => ({...prevState, loading:true}));

    let id = damageRecordId;
    try{
     if(!id){
      setLoadingMessageHandler(`${LoadingMessagesList.Creation} ...`);
      const recordId = await stepHandler<string , QueryProps>(createDamageHandler, requestData, ErrorsList.Creation, 1);
      if(recordId){
        id = recordId;
        setFormLoadingState((prevState) => ({...prevState, damageRecordId:id}));
      }
     }
     if(!step || (step > 1 && step < 3)){
        if(requestData.files && !isEmpty(requestData.files)){
          setLoadingMessageHandler(`${LoadingMessagesList.FilesUploading} ...`);
          await stepHandler<void , {files:FilesArray, id:string}>(uploadFilesHandler, {files:requestData.files, id},ErrorsList.FilesUploading, 2);
        }
     }
     if(!step || (step > 2)){
        setLoadingMessageHandler(`${LoadingMessagesList.Completion} ...`);
        await stepHandler<void , string>(finishCreateDamageHandler, id, ErrorsList.Completion, 3);
     }
      setFormLoadingState((prevState) => ({
        ...prevState,
        message:SuccessLabel,
        step:0,
        loading:false,
        loadingMessage: '',
        damageRecordId:'',
        type:'success'
      }));
    }catch(error:any){
       console.error(error);
       const errObject = error as {message:string, step:number};
       setFormLoadingState((prevState) => ({
        ...prevState,
        loading:false,
        loadingMessage: '',
        ...errObject,
        type:'error'
      }));
    }
  }, [formLoadingState]);

  const saveWithoutFilesModal = (savedFields: Array<[string, AnyObject | undefined]>) => {
    let alertData = {
      title: 'Dateienspeicherung',
      children: <SaveFieldsAlert saveToLocalStorage={saveToLocalStorage} savedFields={savedFields} />,
      buttons: [
        {
          type: 'cancel',
          action: () => dispatch(toggleModal(MODALS.alert, null))
        }
      ],
      allButtonsHidden: true
    };

    dispatch(toggleModal(MODALS.alert, MODAL_NAMES.alert, alertData));
  };

  const saveToLocalStorage = (savedFields: Array<[string, AnyObject | undefined]>) => {
    showOverlay('saveToLocalStorage', true);
    localStorage.setItem('SMdata', JSON.stringify(savedFields));
    setTimeout(() => hideOverlay('saveToLocalStorage'), 1000);
    dispatch(toggleModal(MODALS.alert, null));
  };

  const checkFields = () => {
    const savedFields = Object.entries(watch());

    if (
      watch('SonstigeUnterlagenzurSchadendokumentation') &&
      Array.isArray(watch('SonstigeUnterlagenzurSchadendokumentation').files) &&
      watch('SonstigeUnterlagenzurSchadendokumentation').files.length > 0
    ) {
      savedFields.map(el => {
        if (el[0] === 'SonstigeUnterlagenzurSchadendokumentation') {
          el[1] = { files: [], b64file: [] };
        }
      });
    }
    if (watch('polizei') && Array.isArray(watch('polizei').files) && watch('polizei').files.length > 0) {
      savedFields.map(el => {
        if (el[0] === 'polizei') {
          el[1] = { files: [], b64file: [] };
        }
      });
    }
    if (watch('Skizze') && Array.isArray(watch('Skizze').files) && watch('Skizze').files.length > 0) {
      savedFields.map(el => {
        if (el[0] === 'Skizze') {
          el[1] = { files: [], b64file: [] };
        }
      });
    }
    if (watch('FotoSchaden') && Array.isArray(watch('FotoSchaden').files) && watch('FotoSchaden').files.length > 0) {
      savedFields.map(el => {
        if (el[0] === 'FotoSchaden') {
          el[1] = { files: [], b64file: [] };
        }
      });
    }
    if (
      watch('Wildunfallbescheinigung') &&
      Array.isArray(watch('Wildunfallbescheinigung').files) &&
      watch('Wildunfallbescheinigung').files.length > 0
    ) {
      savedFields.map(el => {
        if (el[0] === 'Wildunfallbescheinigung') {
          el[1] = { files: [], b64file: [] };
        }
      });
    }

    if (
      (watch('Wildunfallbescheinigung') &&
        Array.isArray(watch('Wildunfallbescheinigung').files) &&
        watch('Wildunfallbescheinigung').files.length > 0) ||
      (watch('FotoSchaden') && Array.isArray(watch('FotoSchaden').files) && watch('FotoSchaden').files.length > 0) ||
      (watch('Skizze') && Array.isArray(watch('Skizze').files) && watch('Skizze').files.length > 0) ||
      (watch('polizei') && Array.isArray(watch('polizei').files) && watch('polizei').files.length > 0) ||
      (watch('SonstigeUnterlagenzurSchadendokumentation') &&
        Array.isArray(watch('SonstigeUnterlagenzurSchadendokumentation').files) &&
        watch('SonstigeUnterlagenzurSchadendokumentation').files.length > 0)
    ) {
      saveWithoutFilesModal(savedFields);
    } else {
      saveToLocalStorage(savedFields);
    }
  };

  return (
    <UnauthorizedPageContainer
      className={classes.full_content}
      containerClassName={watch('step') !== 'success' ? classes.container_style : classes.container_success}
      customHeader={
        <SMHeader
          reactHookFormData={reactHookFormData}
          setShowSideBlock={setShowSideBlock}
          showSideBlock={showSideBlock}
        />
      }
    >
      <HelmetComponent title={UNAUTHORIZED_PAGES.REPORT_DAMAGE} />

      <form
        id='SMform'
        onKeyDown={e => e.keyCode == 13 && e.preventDefault()}
        onSubmit={handleSubmit(onSubmit)}
        className={classes.form}
      >
        {watch('step') === 0 && <StartCarInfo reactHookFormData={reactHookFormData} />}
        {watch('step') === 1 && <DetailCarInfo reactHookFormData={reactHookFormData} />}
        {watch('step') === 2 && <DamageTypeChoose reactHookFormData={reactHookFormData} />}

        {!watch('step') && !!watch('damageType') && <ChooseElementFlow reactHookFormData={reactHookFormData} />}

        {watch('step') === 'detail' &&
         <DamageReportSavingContext.Provider value={{...formLoadingState}}>
            <DetailPage reactHookFormData={reactHookFormData} />
         </DamageReportSavingContext.Provider>
        }
      </form>

      {/* SAVE block */}

      {watch('step') !== 'success' && (
        <div className={classes.side_block}>
          <div className={showSideBlock ? classes.side_save_block_show : classes.side_save_block_hide}>
            <div className={classes.save_block}>
              <div className={classes.text_block}>
                <div className={classes.close_block} onClick={() => setShowSideBlock(false)}>
                  <CloseIcon />
                </div>
                <div className={classes.info_text}>
                  <h6>Datenspeicherung.</h6>
                  <p>
                    Deine bisher eingegebenen Daten werden in deinem Browser gespeichert. Lösche daher keine
                    Browserdaten, sonst kannst du nicht mehr auf die gespeicherten Daten zugreifen.
                  </p>
                </div>
              </div>
              <div className={classes.save_buttons}>
                <button
                  className={classes.close_button}
                  onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                    e.preventDefault();
                    setShowSideBlock(false);
                  }}
                >
                  Zurück
                </button>
                <button
                  className={classes.save_data}
                  onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                    e.preventDefault();
                    checkFields();
                    setShowSideBlock(false);
                  }}
                >
                  Speichern
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {watch('step') === 'success' && <SuccessPage />}
      {
        <DamageReportSavingContext.Provider value={{...formLoadingState}}>
            <DamageFormModal />
        </DamageReportSavingContext.Provider>
      }
      <Snackbar
        open={!!formLoadingState.type}
        type={formLoadingState.type}
        onClose={() => setFormLoadingState((prevState) => ({...prevState, type:'' }))}
        message={formLoadingState.message}
      />
    </UnauthorizedPageContainer>
  );
};


const ReportDamagePage = () => {
  return <ReportDamageComponent />;
};


export default ReportDamagePage;
