import React , {FunctionComponent , ReactElement} from 'react';
import { CallButton } from './components/call-button';
import { EmailButton } from './components/email-button';
import {TelephoneContactIcon} from '../../components/icons-new/telephone-contact';
import {EmailContactIcon} from '../../components/icons-new/email-contact';
import classes from './menu-buttons.module.scss';

const MenuButtons:FunctionComponent = ():ReactElement=>{

    return (
        <div className={classes.buttons_menu}>
           <CallButton>
              <TelephoneContactIcon color='#FFFFFF' width={24} height={24}/>
           </CallButton>
           <EmailButton>
             <EmailContactIcon color='#FFFFFF' width={24} height={24}/>
           </EmailButton>
        </div>
    )
}

export default MenuButtons;