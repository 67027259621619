import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import classes from './driver-vehicle.module.scss';
import { CarRepairIcon } from 'components/icons-new/car-repair';
import UploadCarPhotoModal from './upload-modal';
import { toggleModal } from 'actions/app_action';
import { DeviceTypes, MODALS, MODAL_NAMES, PORTAL_ENDPOINT, RequestStatuses } from '../../../constants';
import { useDispatch } from 'react-redux';
import { AnyObject } from 'common/interfaces';
import sharepointService from 'services/sharepoint-service';
import dashboardService from 'services/dashboard-service';
import { cloneDeep } from 'lodash';
import LoadingMessageAccidentRepair from 'pages/details/loading-message/loading-message-unfallschaden';
import { useOverlay } from 'hooks';
import { getDeviceType } from 'utils/get-device-type';
import { Distance, Flex } from 'common/common-components.styled';
import { addTooltipToString } from 'components/aufgaben-component/components/aufgaben-item/aufgaben-item';
import { PAGES, UNAUTHORIZED_PAGES } from 'common/enums';
import { DriverVehicleProps } from '../dashboard-driver.interface';
import Spinner from 'components/spinner';
import { useTheme } from 'styled-components';
import { StarIcon } from 'components/icons-new/star';
import { updateHauptauto } from 'services/fuhrpark-service';
import { useHistory } from 'react-router';
import TooltipHint from 'components/tooltip-hint/tooltip-hint';
import ChangeMainCarModal from './change-main-car-modal';
import { NotLeasingServicesDetails } from './components/not-leasing-services-details/not-leasing-services-details';
import { useReportDamageCustomLink } from 'hooks/custom-links/use-report-damage';
import { useComponent } from 'hooks/use-component/use-component';

const deviceType: string = getDeviceType();

export const DriverVehicle = ({ vehicleData, cancelToken, isLoading }: DriverVehicleProps) => {
  const [showOverlay, hideOverlay] = useOverlay();
  const theme = useTheme();
  const [showAdditionalFields, setShowAdditionalFields] = useState<boolean>(true);
  const [activeVehicle, setActiveVehicle] = useState<AnyObject>(vehicleData[0]);
  const [allVehicles, setAllVehicles] = useState<AnyObject>(vehicleData);
  const [activeVehicleImg, setActiveVehicleImg] = useState<any>('');
  const [carServiceCart, setCarServiceCart] = useState<AnyObject | null>(null);
  const blockRef = useRef<HTMLDivElement>(null);
  const isMobile = deviceType === DeviceTypes.Mobile;
  const dispatch = useDispatch();
  const [visible, setVisible] = useState<boolean>(false);
  const dropdownRef = useRef<any>(null);
  const history = useHistory();

  const isMainCar = activeVehicle?.fuhrpark[0]?.uds_hauptauto?.attributeValue;
  const activeCarNumber = activeVehicle?.fuhrpark[0]?.new_name?.attributeValue;
  const activeCarId = activeVehicle?.fuhrpark[0]?.new_fuhrparkid?.attributeValue;

  const { reportDamageCustomLink } = useReportDamageCustomLink();
  const { getIsDisplayComponent } = useComponent();

  useEffect(() => {
    vehicleData[0] && setActiveVehicle(vehicleData[0]);
    setAllVehicles(vehicleData);
    if (vehicleData[0]?.serviceKarte) {
      setShowAdditionalFields(true);
      setCarServiceCart(vehicleData[0]?.serviceKarte[0]);
    }
    if (vehicleData?.length && vehicleData[0].fuhrpark[0]?.new_fuhrparkid?.attributeValue) {
      getCarImages(vehicleData[0].fuhrpark[0]?.new_fuhrparkid?.attributeValue);
    }
    if (vehicleData[0]?.serviceKarte) {
      setShowAdditionalFields(true);
      setCarServiceCart(vehicleData[0]?.serviceKarte[0]);
    }
  }, [vehicleData]);

  useEffect(() => {
    getCarImages(activeCarId);
  }, [activeVehicle]);

  const closeModal = () => {
    dispatch(toggleModal(MODALS.alert, null));
  };

  const goToReportDamagePage = () => {
    window.open(PORTAL_ENDPOINT + reportDamageCustomLink, '_blank');
  };

  const setNewCarImage = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    const alertData = {
      title: 'Foto hochladen',
      children: <UploadCarPhotoModal uploadNewLogo={uploadNewLogo} />,
      allButtonsHidden: true,
      buttons: [
        {
          type: 'cancel',
          action: closeModal,
          hide: true
        }
      ]
    };
    dispatch(toggleModal(MODALS.alert, MODAL_NAMES.alert, alertData));
  };

  const setNewMainCar = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();

    const mainCarNumber =
      allVehicles[0]?.fuhrpark?.find((car: any) => car?.uds_hauptauto?.attributeValue === true)?.new_name
        ?.attributeValue || null;

    const closeModalButtonText = isMainCar
      ? 'Nein, abbrechen'
      : mainCarNumber
      ? 'Nein, Hauptauto gleich lassen'
      : 'Nein, abbrechen';
    const updateMainCarButtonText = isMainCar
      ? 'Ja, fortsetzen'
      : mainCarNumber
      ? 'Ja, Hauptauto ändern'
      : 'Ja, Hauptauto definieren';

    const alertData = {
      title: 'Änderung Hauptauto',
      children: (
        <ChangeMainCarModal isMainCar={isMainCar} mainCarNumber={mainCarNumber} activeCarNumber={activeCarNumber} />
      ),
      buttons: [
        {
          type: 'cancel',
          title: closeModalButtonText,
          action: closeModal
        },
        {
          type: 'submit',
          title: updateMainCarButtonText,
          action: updateMainCar
        }
      ]
    };
    dispatch(toggleModal(MODALS.alert, MODAL_NAMES.alert, alertData));
  };

  const getCarImages = async (carId: string) => {
    let res: any;
    try {
      res = await sharepointService.getSharepointLogo(carId, 'Foto(s) Fahrzeug', cancelToken.token);
    } catch (err) {
      console.error(err);
      res = '';
    } finally {
      setActiveVehicleImg(res?.data);
      if (!allVehicles.length) {
        return;
      }
      const newAllVehicles = cloneDeep(allVehicles);
      newAllVehicles[0].imgSrc = res?.data || '';
      setAllVehicles(newAllVehicles);
    }
  };

  const filesUploadingModal = {
    children: <LoadingMessageAccidentRepair />,
    className: classes.loading_message,
    buttons: []
  };

  const uploadNewLogo = async (data: AnyObject) => {
    showOverlay();
    dispatch(toggleModal(MODALS.alert, MODAL_NAMES.alert, filesUploadingModal));
    let res;
    let slicedFile = data.slice(0, data.size, data.type);
    let formData = new FormData();
    formData.append('file', slicedFile, data.name);
    formData.append('fuhrparkId', activeCarId as string);
    try {
      res = await dashboardService.uploadCarLogo(formData);
    } catch (err) {
      console.error(err);
    } finally {
      if (res?.status === 200 && res?.data) {
        const imageUrl = URL.createObjectURL(res.data);
        setActiveVehicleImg(imageUrl);
      }
      closeModal();
      hideOverlay();
    }
  };

  const getCarList = async () => {
    showOverlay();
    let res;
    try {
      res = await dashboardService.getMeinFahrzeug();
    } catch (err) {
      console.error(err);
    } finally {
      if (res?.data?.level === RequestStatuses.Success && res?.data?.data?.fuhrparks) {
        setAllVehicles(res.data?.data?.fuhrparks);
        const newActiveCar = res.data?.data?.fuhrparks.find(
          (car: AnyObject) => car?.fuhrpark[0]?.new_fuhrparkid?.attributeValue === activeCarId
        );
        if (newActiveCar) {
          setActiveVehicle(newActiveCar);
        }
      }
      hideOverlay();
    }
  };

  const updateMainCar = async () => {
    showOverlay();
    let res;
    let updatedCar = {
      fuhrparkId: activeCarId,
      isMainCar: !isMainCar
    };
    try {
      res = await updateHauptauto(updatedCar);
    } catch (err) {
      console.error(err);
    } finally {
      if (res?.data?.level === RequestStatuses.Success) {
        getCarList();
      }
      closeModal();
      hideOverlay();
    }
  };

  const blockStyle = !showAdditionalFields
    ? isMobile
      ? classes.container_mobile
      : classes.container
    : isMobile
    ? classes.full_container_mobile
    : classes.full_container;

  const carModelName =
    !activeVehicle?.fuhrpark[0]?.new_hersteller?.attributeValue &&
    !activeVehicle?.fuhrpark[0]?.new_model?.attributeValue
      ? '-'
      : addTooltipToString(
          `${activeVehicle?.fuhrpark[0]?.new_hersteller?.attributeValue || '-'} ${
            activeVehicle?.fuhrpark[0]?.new_model?.attributeValue || '-'
          }`,
          30,
          classes.tooltip_string_long
        );

  const mainBlockStyle: CSSProperties = {
    borderTopRightRadius: showAdditionalFields ? 0 : 2,
    borderBottomRightRadius: showAdditionalFields ? 0 : 2
  };

  const expandImgStyle = !visible ? classes.expand_bottom : classes.expand_top;

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (dropdownRef?.current && !dropdownRef.current.contains(event.target)) {
        setVisible(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const selectItem = (item: any) => {
    setActiveVehicle(item);
    setCarServiceCart(item?.serviceKarte?.length ? item?.serviceKarte[0] : {});
    setShowAdditionalFields(!!item?.serviceKarte?.length);
    setVisible(false);
  };

  const getStarIconColor = isMainCar ? theme.colors.green : theme.colors.blue;

  return (
    <section ref={blockRef} className={blockStyle}>
      {isLoading ? (
        <div className={classes.spinner_block}>
          <Spinner style={spinnerStyle} />
        </div>
      ) : (
        <>
          <div className={classes.main_block} style={isMobile ? {} : mainBlockStyle}>
            <div className={classes.image_block}>
              {!activeVehicleImg ? (
                <>
                  <img src='/assets/images/default-vehicle-upload.svg' alt='vehicle' className={classes.upload_img} />
                  <div className={classes.upload_photo_img}>
                    <img
                      src='/assets/images/camera24px-icon.svg'
                      alt='vehicle'
                      onClick={e => {
                        setNewCarImage(e);
                      }}
                    />
                  </div>
                </>
              ) : (
                <img src={activeVehicleImg} alt='vehicle' className={classes.upload_img} />
              )}
            </div>
            <div className={classes.car_name}>
              <div>
                <div className={classes.show_cars}>
                  <h6 onClick={() => history.push(`${PAGES.MY_VEHICLES_DETAILS}/?id=${activeCarId}`)}>
                    {activeVehicle?.fuhrpark[0]?.new_name?.attributeValue || '-'}
                  </h6>
                  {allVehicles && allVehicles?.length > 0 && (
                    <div ref={dropdownRef}>
                      <img
                        src='/assets/images/dashboard-expand.svg'
                        alt='dashboard-expand'
                        className={expandImgStyle}
                        onClick={() => setVisible(!visible)}
                      />
                      <div className={`${classes.dropdown} ${visible ? classes.v : ''}`}>
                        {visible && allVehicles && allVehicles?.length > 0 && (
                          <ul>
                            {allVehicles.map((el: any) => (
                              <li
                                key={el?.fuhrpark[0]?.new_fuhrparkid?.attributeValue}
                                onClick={() => selectItem(el)}
                                className={classes.dropdown_li}
                              >
                                <div className={classes.item_text1}>{el?.fuhrpark[0]?.new_name?.attributeValue}</div>
                              </li>
                            ))}
                          </ul>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className={classes.star_tooltip} onClick={setNewMainCar}>
                <TooltipHint text={isMainCar ? 'Hauptfahrzeug' : 'Nebenfahrzeug'} position='bottom'>
                  <StarIcon
                    height={24}
                    width={24}
                    color={getStarIconColor}
                    fill={getStarIconColor}
                    hovered={isMainCar}
                  />
                </TooltipHint>
              </div>
            </div>
            <div className={classes.car_model}>{carModelName}</div>
            <div className={classes.car_number}>{activeVehicle?.fuhrpark[0]?.new_fin?.attributeValue || '-'}</div>
          </div>
          {isMobile && <div className={classes.empty_block} />}
          {showAdditionalFields && isMobile && (
            <div className={classes.additional_block_mobile}>
              <div className={classes.additional_header}>
                <h6>MobexoCard</h6>
                <span> im Servicefall</span>
              </div>

              <div className={classes.additional_info}>
                <p>Wartung / Verschleiß</p>
                <span>
                  {(carServiceCart &&
                    addTooltipToString(
                      carServiceCart?.uds_wartung_verschleiss?.attributeValue,
                      20,
                      classes.tooltip_string
                    )) ||
                    '-'}
                </span>
              </div>
              <div className={classes.additional_info}>
                <p>HU / UVV</p>
                <span>
                  {(carServiceCart &&
                    addTooltipToString(carServiceCart?.uds_versicherung?.attributeValue, 20, classes.tooltip_string)) ||
                    '-'}
                </span>
              </div>
              <div className={classes.additional_info_blue}>
                <p>Rechnungsempfänger</p>
                <span>
                  {(carServiceCart &&
                    addTooltipToString(
                      carServiceCart?.uds_rechnungsemfanger_name?.attributeValue,
                      20,
                      classes.tooltip_string
                    )) ||
                    '-'}
                </span>
              </div>
              <Distance top='20' />
              <div className={classes.additional_info}>
                <p>Räder / Reifen</p>
                <span>
                  {(carServiceCart &&
                    addTooltipToString(carServiceCart?.uds_winterrader?.attributeValue, 20, classes.tooltip_string)) ||
                    '-'}
                </span>
              </div>
              <div className={classes.additional_info}>
                <p>Unfall / Panne / Glas</p>
                <span>
                  {(carServiceCart &&
                    addTooltipToString(carServiceCart?.uds_reifenersatz?.attributeValue, 15, classes.tooltip_string)) ||
                    '-'}{' '}
                  <br />
                  {getIsDisplayComponent('createDamageButton', { showByDefault: false }) && (
                    <span className={classes.link} onClick={() => history.push(PAGES.CREATE_DAMAGE)}>
                      Schaden anlegen
                    </span>
                  )}
                  {getIsDisplayComponent('reportDamageButton') && (
                    <span className={classes.link} onClick={goToReportDamagePage}>
                      Schaden melden
                    </span>
                  )}
                </span>
              </div>

              <NotLeasingServicesDetails top='15' />
            </div>
          )}
          {showAdditionalFields && !isMobile && (
            <div className={classes.additional_block}>
              <div className={classes.additional_header}>
                <CarRepairIcon color={theme.colors.white} />
                <h6>Im Servicefall</h6>
              </div>
              <Flex>
                <Flex direction='column' right='15'>
                  <div className={classes.additional_info}>
                    <span>
                      <p>Wartung / Verschleiß</p>
                      {(carServiceCart &&
                        addTooltipToString(
                          carServiceCart?.uds_wartung_verschleiss?.attributeValue,
                          30,
                          classes.tooltip_string
                        )) ||
                        '-'}
                    </span>
                  </div>
                  <div className={classes.additional_info}>
                    <span>
                      <p>HU / UVV</p>
                      {(carServiceCart &&
                        addTooltipToString(
                          carServiceCart?.uds_versicherung?.attributeValue,
                          30,
                          classes.tooltip_string
                        )) ||
                        '-'}
                    </span>
                  </div>
                  <div className={classes.additional_info}>
                    <span>
                      <p>Rechnungsempfänger</p>
                      {(carServiceCart &&
                        addTooltipToString(
                          carServiceCart?.uds_rechnungsemfanger_name?.attributeValue,
                          30,
                          classes.tooltip_string
                        )) ||
                        '-'}
                    </span>
                  </div>
                  <div className={classes.additional_info}>
                    <p>Räder / Reifen</p>
                    <span>
                      {(carServiceCart &&
                        addTooltipToString(
                          carServiceCart?.uds_winterrader?.attributeValue,
                          30,
                          classes.tooltip_string
                        )) ||
                        '-'}
                    </span>
                  </div>
                  <div className={classes.additional_info}>
                    <p>Unfall / Panne / Glas</p>
                    <span>
                      {(carServiceCart &&
                        addTooltipToString(
                          carServiceCart?.uds_reifenersatz?.attributeValue,
                          20,
                          classes.tooltip_string
                        )) ||
                        '-'}{' '}
                      {getIsDisplayComponent('createDamageButton', { showByDefault: false }) && (
                        <span className={classes.link} onClick={() => history.push(PAGES.CREATE_DAMAGE)}>
                          Schaden anlegen
                        </span>
                      )}
                      {getIsDisplayComponent('reportDamageButton') && (
                        <span className={classes.link} onClick={goToReportDamagePage}>
                          Schaden melden
                        </span>
                      )}
                    </span>
                  </div>
                </Flex>
                <NotLeasingServicesDetails width='177px' isColumnDetailItem />
              </Flex>
            </div>
          )}
        </>
      )}
    </section>
  );
};

const spinnerStyle = {
  width: '50px',
  zIndex: 97
};
