import { TASK_STATUS_CODES } from '../../../../../../../../constants';
import { LeasingWidgetsContext } from 'pages/existing-vehicle-page/components/layout/leasing-widgets-layout/leasing-widgets-context';
import { useContext, useMemo } from 'react';

export const useChecklistButtons = () => {
  const { car } = useContext(LeasingWidgetsContext);
  const { checklist } = car;
  const status = checklist.widgetState.attributeValue.value;

  const showButton = useMemo(() => {
    return [TASK_STATUS_CODES.open, TASK_STATUS_CODES.postponed].includes(status);
  }, [status]);

  return { showButton };
};
