import { AxiosResponse } from 'axios';
import { FormFieldOption, ResponseModel } from 'common/interfaces';
import { ChecklistData, ChecklistFile, ChecklistInfo } from 'request-config/checklist-modal/checklist-modal.types';
import { DamageFormSection } from './components/condition-step/use-condition-step.types';
import { FieldValues } from 'react-hook-form';

export const modifyResponse = ({ data }: AxiosResponse<ResponseModel>): ChecklistInfo => {
  return data.data as ChecklistInfo;
};

export const initialSteps = [
  {
    title: 'Persönliche Daten',
    active: true
  },
  {
    title: 'Laufleistung',
    active: false
  },
  {
    title: 'Rückgabe',
    active: false
  },
  {
    title: 'Service/Inspektionen',
    active: false
  },
  {
    title: 'Zustand',
    active: false
  }
];

export const getChecklistFields = (
  data: FieldValues,
  activeStep: number,
  checklistInfo: ChecklistInfo
): Partial<ChecklistData> => {
  switch (activeStep) {
    case 0:
      delete data.uds_persona_firma;
      return {
        attributes: {
          ...data,
          uds_fuhrparkid: {
            id: data.uds_fuhrparkid,
            logicalName: 'new_fuhrpark',
            name: checklistInfo.fuhrparkData.name
          }
        }
      } as Partial<ChecklistData>;
    case 1:
    case 3:
      return {
        attributes: data
      } as Partial<ChecklistData>;
    case 2:
      const convertCheckboxSection = (items: FormFieldOption[] | null) => {
        if (!items) {
          return [];
        }

        return items.filter(item => item.isChecked).map(({ value }) => value);
      };
      const leftSection = convertCheckboxSection(data.leftSection);
      const rightSection = convertCheckboxSection(data.rightSection);

      return {
        attributes: {
          uds_returnoptions: [...leftSection, ...rightSection]
        }
      } as Partial<ChecklistData>;

    case 4:
      const { uds_condition_condition, uds_condition_fittings, uds_condition_upholstery } = data;

      return {
        attributes: {
          uds_condition_condition,
          uds_condition_fittings,
          uds_condition_upholstery
        }
      } as Partial<ChecklistData>;

    default:
      return {} as Partial<ChecklistData>;
  }
};

export const getDamagedSectionsValues = (damagedPartsSections: DamageFormSection[][], data: FieldValues) => {
  return damagedPartsSections.flat().map(section => {
    return {
      attributes: {
        uds_comment: data[section.commentField[0].name],
        uds_damagetypecode: data[section.damageTypeField[0].name],
        uds_parttypecode: section.value
      }
    };
  });
};

export const getDamageSectionsWithFiles = (
  damagedPartsSections: DamageFormSection[][],
  data: FieldValues
): ChecklistFile[] => {
  const damageSectionsWithFiles = damagedPartsSections
    .flat()
    .map(section => {
      return {
        file: data[section.fileField[0].name],
        damageType: data[section.damageTypeField[0].name],
        partType: section.value
      };
    })
    .filter(section => section.file.length);

  return damageSectionsWithFiles
    .map(section => {
      return section.file.map((file: File) => {
        return {
          file,
          damageType: section.damageType,
          partType: section.partType
        };
      });
    })
    .flat();
};
