import { useFetch } from 'hooks/use-fetch/use-fetch';
import { unfoldedTaskRequestConfig } from '../../../../../request-config/unfolded-task/unfolded-task.request-config';
import { useQuery } from 'react-query';
import { ApiResponse } from 'common/interfaces';
import { ReactQueryKeys } from 'common/enums';
import { RequestStatuses } from '../../../../../constants';
import { AnyObject } from 'chart.js/dist/types/basic';
import { SpecificPhaseInfoProps, VehicleInfoProps } from 'components/aufgaben-component/task.prop';
import { FileProps } from 'pages/vehicle-return-page/vehicle-return-page.props';

interface TaskAssets {
  upperText?: string;
  lowerText?: string;
  isDescriptionRender: boolean;
  rvo_phaseinfo: AnyObject;
  rvo_state: number;
  vir_phaseinfo: {
    phaseNo: number;
    phaseInfo: {
      person: string;
      phone: string;
      address: string;
    };
  };
  vr_phaseinfo: {
    fuhrparkId: string;
    lafId: string | null;
  };
  vir_state: number;
  vr_state: number;
  lva_phaseinfo: {
    files: {
      Verlängerungangebot: FileProps[];
    };
    fuhrparkId: VehicleInfoProps;
    lvaId: string;
    specificPhaseInfo: SpecificPhaseInfoProps;
  };
  lva_state: number;
  files: FileProps[];
}

const modifyResponse = ({ data }: ApiResponse<AnyObject>) => {
  const { data: taskAssets } = data;

  if (data.level === RequestStatuses.Success) {
    return {
      files: taskAssets.files.map((file: FileProps) => {
        return {
          ...file,
          name: file.fileName
        };
      }),
      ...taskAssets.taskInfo,
      ...taskAssets.leaseEndTaskInfo
    };
  }

  return undefined;
};

export const useUnfoldedTask = (taskId: string) => {
  const { fetch } = useFetch({
    loadingKey: 'unfoldedTask',
    isShowErrorSnackbar: false,
    isShowLoading: false,
    ...unfoldedTaskRequestConfig.getUnfoldedTaskInfo(taskId)
  });

  const { data: taskAssets, isLoading } = useQuery<ApiResponse<AnyObject>, unknown, TaskAssets | undefined>(
    [ReactQueryKeys.UnfoldedTask, taskId],
    () => fetch(),
    {
      enabled: Boolean(taskId),
      select: modifyResponse
    }
  );

  return { taskAssets: taskAssets || ({} as TaskAssets), isLoading };
};
