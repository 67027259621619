import React from 'react';
import PropTypes from 'prop-types';
import classes from './unauthorized-page-container.module.scss';
import Overlay from '../overlay';
import KontaktSupportMenuButtons from '../../components/kontakt-support-menu-buttons';
import classNames from 'classnames';
import UnauthorisedLogoMobexo from 'components/icons/unauthorised-logo-mobexo';

export const UnauthorizedPageContainer = ({ children, customHeader, className, containerClassName }) => (
  <div id='UnauthorizedPageScrollContainer' className={classes.page}>
    {customHeader ? (
      customHeader
    ) : (
      <header className={classes.header}>
        <div className={classes.container}>
          <a className={classes.logo_container} href='https://www.mobexo.de/' target='_blank' rel='noopener noreferrer'>
            <UnauthorisedLogoMobexo />
          </a>
        </div>
      </header>
    )}
    <main id='unauthorizedPageContainer' className={classNames(classes.content, className)}>
      <Overlay />
      <div
        id='UPcontentConatiner'
        className={containerClassName ? containerClassName : `${classes.container} ${classes.items_container}`}
      >
        {children}
      </div>
      {< KontaktSupportMenuButtons />}
    </main>
  </div>
);

UnauthorizedPageContainer.propTypes = {
  children: PropTypes.any.isRequired,
  customHeader: PropTypes.any,
  className: PropTypes.string,
  isHideChatBot: PropTypes.boolean,
  containerClassName: PropTypes.string
};
