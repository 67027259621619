import React, { memo } from 'react';
import { useTheme } from 'styled-components';
import { Flex } from 'common/common-components.styled';
import { RightArrowIcon } from 'components/icons/right-arrow-icon';
import ProgressStep from 'components/progress-component/progress-step/progress-step';

export interface ProgressComponentStep {
  title: string;
  active: boolean;
}

interface ProgressComponentProps {
  steps: ProgressComponentStep[];
  isAllStepsCompleted?: boolean;
  onClick?: (stepTitle: string) => void;
}

const ProgressComponent = ({ steps, isAllStepsCompleted, onClick }: ProgressComponentProps) => {
  const theme = useTheme();

  const activeStepIndex = steps.findIndex(step => step.active);

  return (
    <Flex>
      {steps.map((step, i) => {
        return (
          <Flex align='center' key={step.title}>
            <ProgressStep
              title={step.title}
              active={!isAllStepsCompleted && step.active}
              done={isAllStepsCompleted || activeStepIndex === -1 || i < activeStepIndex}
              onClick={onClick}
              isAllStepsCompleted={isAllStepsCompleted}
            />
            {steps[i + 1] && <RightArrowIcon color={theme.colors.grey100} />}
          </Flex>
        );
      })}
    </Flex>
  );
};

export default memo(ProgressComponent);
