import { useContext, useMemo } from 'react';
import { FormFieldTypes } from 'common/enums';
import { ChecklistModalContext } from 'components/checklist-modal/checklist-modal-context';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { errorMessages } from 'utils/error-messages';

enum FieldsNames {
  Mileage = 'uds_levels_mileage',
  TreadHeight = 'uds_levels_treadheight'
}

export const useFillLevelsStep = () => {
  const { checklistInfo } = useContext(ChecklistModalContext);
  const { checklistData } = checklistInfo;

  const mileageField = useMemo(() => {
    return [
      {
        name: FieldsNames.Mileage,
        type: FormFieldTypes.Kilometers,
        placeholder: 'Bitte eintragen',
        label: 'Kilometerstand',
        defaultValue: checklistData.attributes.uds_levels_mileage,
        validation: {
          required: errorMessages.required,
          max: { value: 1000000, message: errorMessages.getMaxNumberValue(1000000) }
        }
      }
    ];
  }, [checklistData]);

  const treadDepthField = useMemo(() => {
    return [
      {
        name: FieldsNames.TreadHeight,
        type: FormFieldTypes.Millimeters,
        label: 'Profiltiefe',
        defaultValue: checklistData.attributes.uds_levels_treadheight,
        validation: {
          required: errorMessages.required,
          max: { value: 20, message: errorMessages.getMaxNumberValue(20) }
        }
      }
    ];
  }, [checklistData]);

  return { mileageField, treadDepthField };
};
