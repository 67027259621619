import React from 'react';
import { useHistory } from 'react-router-dom';
import { MyVehicleMenuItem } from './my-vehicles-menu-item';
import { MenuTabListProps } from '../my-vehicles-menu.props';
import { useInactiveCarModal } from 'hooks/modals/car/use-inactive-car/use-inactive-car';
import { PushParamsType } from 'common/interfaces';
import { VehicleStateCodeLabel } from 'pages/my-vehicles/my-vehicles.props';

export const MenuTabList = ({ className, menuList, getLink, carId, carStatus }: MenuTabListProps) => {
  const { openInactiveCarModal } = useInactiveCarModal();
  const history = useHistory();

  const openPage = (isShowModal: boolean, historyConfig?: PushParamsType) => {
    if (!historyConfig) {
      return;
    }

    if (isShowModal) {
      openInactiveCarModal({
        carId,
        isCarInactive: carStatus === VehicleStateCodeLabel.Inactive,
        callback: () => {
          history.push(historyConfig);
        }
      });

      return;
    }

    history.push(historyConfig);
  };

  return (
    <div className={className}>
      <ul>
        {menuList.map(menuSetup => {
          const link = getLink(menuSetup);

          return (
            <>
              {!link ? (
                <div onClick={menuSetup.handleClick}>
                  <MyVehicleMenuItem key={menuSetup.name} {...menuSetup} />
                </div>
              ) : menuSetup.openInNewTab ? (
                <a href={menuSetup.href as string} target='_blank' rel='noreferrer'>
                  <MyVehicleMenuItem key={menuSetup.name} {...menuSetup} />
                </a>
              ) : (
                <div
                  key={menuSetup.name}
                  onClick={() => {
                    if (menuSetup.isEnabled) {
                      menuSetup?.handleClick ? menuSetup?.handleClick() : openPage(!!menuSetup.isShowModal, link);
                    }
                  }}
                >
                  <MyVehicleMenuItem key={menuSetup.name} {...menuSetup} />
                </div>
              )}
            </>
          );
        })}
      </ul>
    </div>
  );
};
