import React from 'react';
import { Flex, Text } from 'common/common-components.styled';
import { useFormContext } from 'react-hook-form';
import { useReturnStep } from './use-return-step';
import FormHook from 'components/form-hook/form-hook';
import InfoHint from 'components/info-hint';

const ReturnStep = () => {
  const reactHookFormData = useFormContext();
  const { returnFieldsLeftSection, returnFieldsRightSection } = useReturnStep(reactHookFormData);

  return (
    <Flex width='100%' direction='column'>
      <div>
        <Text size='16' color='darkBlue'>
          Rückgabe
        </Text>
      </div>

      <Flex gap='20px' width='100%' top='30' bottom='24'>
        <Flex width='50%' direction='column'>
          <FormHook
            reactHookFormData={reactHookFormData}
            formFields={returnFieldsLeftSection}
            distanceBetweenFields='0'
          />
        </Flex>

        <Flex direction='column' width='50%'>
          <FormHook
            reactHookFormData={reactHookFormData}
            formFields={returnFieldsRightSection}
            distanceBetweenFields='0'
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ReturnStep;
