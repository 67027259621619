import React, {FunctionComponent, ReactElement, ReactSVGElement} from 'react';
import { IconProps } from 'common/interfaces';
import useDefaultIconsColor from 'hooks/use-default-icons-color';

export const DownloadsAndFaqIcon:FunctionComponent = ({ width = 26, height = 26, color: colorProp, fill: fillProp, hovered, strokeWidth = 1.2 }: IconProps)
   :ReactElement<ReactSVGElement> => {
    const { defaultColor, defaultFill } = useDefaultIconsColor();
    const color = colorProp || defaultColor;
    const fill = fillProp || defaultFill;

    return (
      <svg width={width} height={height} viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
          { hovered &&
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M19.3184 6.96163V12.3809V15.5209C18.86 15.2524 18.3264 15.0985 17.7568 15.0985C16.0479 15.0985 14.6625 16.4839 14.6625 18.1928C14.6625 19.9017 16.0479 21.2871 17.7568 21.2871C18.3264 21.2871 18.86 21.1332 19.3184 20.8647V25.1549H1.51222C1.08642 25.1549 0.738037 24.8065 0.738037 24.3807V1.92945C0.738037 1.50366 1.08642 1.15527 1.51222 1.15527H13.8991V7.09617L19.3184 6.96163Z"
              fill={fill}
            />
          }
          <path
            d="M19.304 6.97623H14.2758C14.0631 6.97623 13.889 6.80218 13.889 6.58945V1.40527"
            fill="white"
          />
          <path
            d="M19.304 8.91001V6.97609L13.889 1.17432H1.51185C1.08639 1.17432 0.738281 1.52242 0.738281 1.94789V24.3814C0.738281 24.8069 1.08639 25.155 1.51185 25.155H10.7947" 
            stroke={color}
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
            strokeWidth={strokeWidth}
          />
          <path
            d="M19.3041 6.97605H14.2759C14.0632 6.97605 13.8892 6.802 13.8892 6.58926V4.26855"
            stroke={color}
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
            strokeWidth={strokeWidth}
          />
          <path
            d="M23.7768 21.6524C24.5133 20.3818 24.8618 18.8639 24.6538 17.2504C24.2378 14.0683 21.6179 11.5384 18.4246 11.2404C14.0394 10.8244 10.3963 14.4731 10.8068 18.8583C11.1103 22.0572 13.6346 24.6714 16.8167 25.0931C18.4358 25.3067 19.9538 24.9525 21.2244 24.216L24.0916 24.9863C24.3671 25.0594 24.6257 24.8064 24.547 24.5309L23.7768 21.6524Z"
            fill="white"
          />
          <path
            d="M17.7442 21.0776C17.3775 21.0776 17.0775 21.3776 17.0775 21.7443C17.0775 22.111 17.3775 22.411 17.7442 22.411C18.1108 22.411 18.4108 22.111 18.4108 21.7443C18.4108 21.3776 18.1108 21.0776 17.7442 21.0776Z"
            fill="#335566"
          />
          <path
            d="M15.7202 16.8286C15.7202 15.7098 16.6254 14.8047 17.7441 14.8047C18.8629 14.8047 19.7681 15.7098 19.7681 16.8286C19.7681 17.9474 18.8629 18.8525 17.7441 18.8525V19.977"
            stroke={color}
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
            strokeWidth={strokeWidth}
          />
          <path
            d="M21.2244 24.2161L24.0916 24.9863C24.3671 25.0594 24.6257 24.8064 24.547 24.5309L23.7768 21.6524C24.5133 20.3818 24.8618 18.8639 24.6538 17.2504C24.2378 14.0683 21.6179 11.5384 18.4246 11.2404C14.0394 10.8244 10.3963 14.4731 10.8068 18.8583C11.1103 22.0572 13.6346 24.6714 16.8167 25.0931C17.6678 25.2054 18.4909 25.1608 19.2648 24.9863"
            stroke={color}
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
            strokeWidth={strokeWidth}
          />
      </svg>
    );
};

