import React, { useEffect, useMemo, useState } from 'react';
import { FormFieldTypes, PAGES, ReactQueryKeys } from 'common/enums';
import { CrmName, NewTaskDataProps, TaskTypeId, TaskTypeName } from 'components/aufgaben-component/task.prop';
import moment from 'moment';
import { cloneDeep, isArray, isEmpty } from 'lodash';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { useIsUserHaveRole } from 'hooks/use-is-user-have-role';
import { MODALS, RequestStatuses, USER_ROLES } from '../../../../constants';
import { useVehicleForCreateTaskQuery } from 'hooks/react-query/tasks/vehicle-for-create-task';
import { useEmployeesQuery } from './use-employees-query';
import { useTypedSelector } from 'hooks/use-typed-selector';
import { ResponseModel, User } from 'common/interfaces';
import { EmailWarningIcon } from 'components/icons-new/email-warning';
import { useComponent } from 'hooks/use-component/use-component';
import { useOverlay } from 'hooks';
import { useDispatch } from 'react-redux';
import { getRequestFormData } from 'utils/get-response-data';
import { setSnakbar, toggleModal } from 'actions/app_action';
import { allFieldsCreateTask } from './formFields';
import { AxiosResponse } from 'axios';
import { getInfoForCreateTask } from 'components/aufgaben-component/utils';
import { createNewTask } from 'services/crm-invoices-service';
import { queryClient } from 'index';
import { blobToBase64 } from 'utils/decode-encode-base64';
import useAlert from 'hooks/use-alert';

const useTaskTypes = () => {
  const { getIsDisplayComponent } = useComponent();

  const taskTypes = [
    {
      label: TaskTypeName.Allgemein,
      value: TaskTypeId.Allgemein
    },
    {
      label: TaskTypeName.Checklist,
      value: TaskTypeId.Checklist,
      isHide: !getIsDisplayComponent('vehicleReturnChecklist')
    },
    {
      label: TaskTypeName.Hu,
      value: TaskTypeId.Hu
    },
    {
      label: TaskTypeName.Uvv,
      value: TaskTypeId.Uvv
    },
    {
      label: TaskTypeName.Replacement,
      value: TaskTypeId.Replacement,
      isHide: !getIsDisplayComponent('replacementPurchase')
    }
  ];

  return { taskTypes: taskTypes.filter(item => !item.isHide) };
};

const dateOptions = [
  {
    label: 'Heute',
    value: 'today'
  },
  {
    label: 'In 2 Tagen',
    value: 2
  },
  {
    label: 'In 1 Woche',
    value: 7
  },
  {
    label: 'In 2 Wochen',
    value: 14
  },
  {
    label: 'In 1 Monat',
    value: 30
  },
  {
    label: 'Auswählen',
    value: 'choose'
  }
];

export const useCreateNewTaskModal = (
  reactHookFormData: UseFormReturn<FieldValues, any>,
  isDriversFlow: boolean,
  selectedDrivers?: string[],
  updateSelectedDrivers?: (updatedListOfDrivers: string | null) => void,
  setIsSubmitted?: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const user = useTypedSelector<User>(state => state['app'].user);
  const isPoolManager = useIsUserHaveRole(USER_ROLES.poolManager.id);
  const [showOverlay, hideOverlay] = useOverlay();
  const dispatch = useDispatch();
  const { closeAlert } = useAlert();

  const {
    handleSubmit,
    formState: { errors },
    watch,
    setValue
  } = reactHookFormData;

  const currentTaskCode = watch(CrmName.TaskType);
  const currentVehicle = watch(CrmName.Vehicle);
  const isTaskRVO = currentTaskCode === TaskTypeId.Replacement;
  const currentEmployee = watch(CrmName.Employee);
  const currentDateOption = watch(CrmName.DateOptions);
  const currentCalendarValue = watch(CrmName.DateChosen);

  const { vehicleForCreateTaskData, isVehicleForCreateTaskFetching } = useVehicleForCreateTaskQuery();
  const { employeesOptions, isEmployeesLoading } = useEmployeesQuery(isTaskRVO, currentVehicle);

  const [isEmplenationModalOpened, setIsEmplenationModalOpened] = useState(false);

  const vehicleFormData = (taskTypeId: TaskTypeId) => {
    const cloneVehicleData = cloneDeep(vehicleForCreateTaskData);
    const filterDisabled = cloneVehicleData?.filter(
      item => item.exists_tasks_type.includes(taskTypeId) || item.not_allowed_for_task_types.includes(taskTypeId)
    );
    const removeDisabled = cloneVehicleData.filter(item => !filterDisabled.includes(item));

    return removeDisabled?.map(dataItem => ({
      value: dataItem.new_fuhrparkid.attributeValue!,
      label: dataItem.new_name.attributeValue || ''
    }));
  };

  const getVehicleFieldValidationState = () => {
    if (isPoolManager) {
      return isArray(currentEmployee) && currentEmployee.length > 1 ? false : 'Bitte auswählen';
    }

    return false;
  };

  const { taskTypes } = useTaskTypes();

  const createTaskFormFields = useMemo(() => {
    return [
      {
        name: CrmName.TaskType,
        type: FormFieldTypes.OptionSet,
        label: 'Aufgabentyp',
        options: taskTypes,
        defaultValue: TaskTypeId.Allgemein,
        disabled: isArray(currentEmployee) ? currentEmployee.length > 1 : false,
        validation: {
          required: 'Bitte auswählen'
        }
      },
      {
        name: CrmName.Title,
        type: FormFieldTypes.Text,
        defaultValue: '',
        label: 'Titel',
        hidden: currentTaskCode !== TaskTypeId.Allgemein,
        validation: {
          required: 'Bitte auswählen'
        }
      },
      {
        name: CrmName.Description,
        type: FormFieldTypes.TextArea,
        label: 'Beschreibung',
        defaultValue: '',
        hidden: currentTaskCode !== TaskTypeId.Allgemein,
        validation: {}
      },
      {
        name: CrmName.Files,
        type: FormFieldTypes.File,
        defaultValue: [],
        hidden:
          currentTaskCode !== TaskTypeId.Allgemein || (isArray(currentEmployee) ? currentEmployee.length > 1 : false),
        dropzoneOptions: {
          maxFiles: 6,
          multiple: true,
          maxSize: 1024 * 1024 * 10
        },
        validation: {}
      },
      {
        name: CrmName.Vehicle,
        type: FormFieldTypes.Lookup,
        defaultValue: '',
        label: 'Fahrzeug',
        options: vehicleFormData(currentTaskCode),
        disabled: isArray(currentEmployee) ? currentEmployee.length > 1 : false,
        validation: {
          required: getVehicleFieldValidationState()
        }
      },
      {
        name: CrmName.Employee,
        type: FormFieldTypes.Lookup,
        multiple: currentTaskCode === TaskTypeId.Allgemein,
        defaultValue: currentTaskCode === TaskTypeId.Allgemein ? [] : '',
        label: 'Mitarbeiter',
        options: employeesOptions || [],
        disabled: isTaskRVO && !currentVehicle,
        limitTags: 3,
        valueMaxWidth: '150px',
        dropDownAlwaysOnTop: true,
        validation: {
          required: 'Bitte auswählen'
        },
        endAdormentIcon: () => <EmailWarningIcon hovered />
      },
      {
        name: CrmName.DateOptions,
        type: FormFieldTypes.OptionSet,
        label: 'Fälligkeit',
        options: dateOptions,
        defaultValue: 14,
        validation: {
          required: 'Bitte auswählen'
        }
      },
      {
        name: CrmName.DateChosen,
        type: FormFieldTypes.Date,
        defaultValue: null,
        hidden: currentDateOption !== 'choose',
        validation: {
          required: currentDateOption !== 'choose' ? false : 'Bitte auswählen'
        },
        minDate: moment().startOf('day')
      }
    ];
  }, [
    currentTaskCode,
    isPoolManager,
    isTaskRVO,
    currentVehicle,
    currentDateOption,
    isDriversFlow,
    currentEmployee,
    employeesOptions
  ]);

  const setDefaultFields = (driverId: string, taskTypeId: number) => {
    const id = isArray(driverId) ? driverId[0] : driverId;

    const driversData = vehicleForCreateTaskData?.find(dataItem => dataItem?.new_fahrerid?.attributeValue?.id === id);

    const vehicleData = vehicleForCreateTaskData?.find(
      dataItem => dataItem.new_fuhrparkid.attributeValue === currentVehicle
    );

    const isDefaultVehicleEnabled =
      !driversData?.exists_tasks_type.includes(taskTypeId) &&
      !driversData?.not_allowed_for_task_types.includes(taskTypeId);

    const isCurrentVehicleEnabled =
      !vehicleData?.exists_tasks_type.includes(taskTypeId) &&
      !vehicleData?.not_allowed_for_task_types.includes(taskTypeId);

    const getVehicleData = () => {
      if (currentVehicle) {
        return isCurrentVehicleEnabled ? currentVehicle : null;
      }

      return isDefaultVehicleEnabled ? driversData?.new_fuhrparkid?.attributeValue : null;
    };

    setValue(CrmName.Employee, driverId);

    if (!isArray(driverId) || driverId.length === 1) {
      setValue(CrmName.Vehicle, getVehicleData());
    }
  };

  const convertDateValue = () => {
    setValue(CrmName.DueDate, '');

    if (currentDateOption === 'choose') {
      if (currentCalendarValue) {
        setValue(CrmName.DueDate, moment(currentCalendarValue).format('YYYY-MM-DD'));
      }
    } else {
      if (currentDateOption === 'today') {
        setValue(CrmName.DueDate, moment().format('YYYY-MM-DD'));
      } else {
        setValue(CrmName.DueDate, moment().add(currentDateOption, 'days').format('YYYY-MM-DD'));
      }
    }
  };

  useEffect(() => {
    currentDateOption && convertDateValue();
  }, [currentDateOption, currentCalendarValue]);

  useEffect(() => {
    if (!isVehicleForCreateTaskFetching && !isEmployeesLoading && currentTaskCode) {
      let employeesId;

      if (isDriversFlow) {
        if (currentTaskCode === TaskTypeId.Allgemein) {
          employeesId = selectedDrivers;
        } else {
          employeesId = selectedDrivers?.[0];
        }
      } else if (currentTaskCode === TaskTypeId.Allgemein) {
        employeesId = [currentEmployee || user.id];
      } else {
        employeesId = isArray(currentEmployee) ? currentEmployee?.[0] : currentEmployee || user.id;
      }

      setDefaultFields(employeesId, currentTaskCode);
    }
  }, [isVehicleForCreateTaskFetching, currentTaskCode, isEmployeesLoading, isDriversFlow, selectedDrivers, user.id]);

  useEffect(() => {
    if (isDriversFlow && currentEmployee) {
      updateSelectedDrivers &&
        updateSelectedDrivers(
          isArray(currentEmployee) ? (currentEmployee.length ? currentEmployee.join() : null) : currentEmployee
        );
    }
  }, [isDriversFlow, currentEmployee]);

  useEffect(() => {
    if (isArray(currentEmployee) && currentEmployee.length > 1) {
      setValue(CrmName.Vehicle, null);
    }
  }, [currentVehicle, currentEmployee]);

  const toggleExplenationModal = (isOpen: boolean) => {
    if (isOpen) {
      handleSubmit(() => {
        setIsEmplenationModalOpened(isOpen);
      })();
    } else {
      setIsEmplenationModalOpened(isOpen);
    }
  };

  useEffect(() => {
    const selectedVehicle = vehicleForCreateTaskData.find(
      option => option.new_fuhrparkid.attributeValue! === currentVehicle
    );

    const selectedVehicleDriversId =
      selectedVehicle?.new_fahrerid.attributeValue?.id || selectedVehicle?.new_kstverantw.attributeValue?.id;

    const isDriverIdExistInList = employeesOptions.find(option => option.value === selectedVehicleDriversId);

    const driversId = isDriverIdExistInList ? selectedVehicleDriversId : user.id;

    if (currentVehicle) {
      setValue(CrmName.Employee, currentTaskCode === TaskTypeId.Allgemein ? [driversId] : driversId);
    }
  }, [currentVehicle, currentTaskCode]);

  const submit = () => {
    handleSubmit(async data => {
      showOverlay();
      closeAlert();

      const newTaskData: NewTaskDataProps = {};
      newTaskData.createTask = {};
      newTaskData.createTask.entityName = 'task';
      newTaskData.createTask.attributes = getRequestFormData(data, allFieldsCreateTask);

      if (newTaskData.createTask.attributes.regardingobjectid.attributeValue) {
        newTaskData.createTask.attributes.regardingobjectid.attributeValue.logicalName = 'new_fuhrpark';
      } else {
        newTaskData.createTask.attributes.regardingobjectid = null;
      }

      const response: AxiosResponse<ResponseModel> = await getInfoForCreateTask();
      if (
        response.data?.level === RequestStatuses.Success &&
        newTaskData.createTask.attributes.uds_executor_contactid.attributeValue.id
      ) {
        const executor = response.data.data.contacts.filter(
          (contact: { id: string; logicalName: string; name: string }) =>
            data?.uds_executor_contactid.includes(contact.id)
        );

        if (data.uds_aufgabentyp_fuhrpark === TaskTypeId.Allgemein && executor.length > 1) {
          newTaskData.Executors = executor;
        } else if (executor.length === 1) {
          newTaskData.executorContactOrFirm = executor[0];
        }
        newTaskData.TaskType = data.uds_aufgabentyp_fuhrpark;

        if (data.files && data.files.length) {
          const files = await Promise.all(
            data.files.map(async (file: File) => {
              const base64Body = (await blobToBase64(file)) as string;
              return {
                filename: file.name,
                body: base64Body?.split(',')[1]
              };
            })
          );
          newTaskData.files = files;
        }

        delete newTaskData.createTask.attributes.uds_executor_contactid;

        try {
          await createNewTask(newTaskData);

          queryClient.resetQueries([PAGES.TASKS]);
          queryClient.resetQueries([ReactQueryKeys.GetVehiclesForCreateTask]);
          updateSelectedDrivers && updateSelectedDrivers(null);

          dispatch(
            setSnakbar({
              isOpen: true,
              message: 'Aufgabe wurde erstellt',
              type: 'success'
            })
          );

          setIsSubmitted && setIsSubmitted(prev => !prev);
          hideOverlay();
        } catch (err) {
          console.log(err);
          hideOverlay();

          dispatch(
            setSnakbar({
              isOpen: true,
              message: 'Aufgabe wurde nicht erstellt',
              type: 'error'
            })
          );
        }
      }

      if (currentTaskCode === TaskTypeId.Allgemein) {
        toggleExplenationModal(false);
      }
    })();
  };

  return {
    createTaskFormFields,
    currentTaskCode,
    isLoading: isEmployeesLoading || isVehicleForCreateTaskFetching,
    isSubmitButtonDisabled: !isEmpty(errors),
    isEmplenationModalOpened,
    submit,
    isExplenationModalNeeded: currentTaskCode === TaskTypeId.Allgemein ? currentEmployee?.length > 1 : false,
    openExplenationModal: () => toggleExplenationModal(true),
    closeExplenationModal: () => toggleExplenationModal(false)
  };
};
