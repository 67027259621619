import React, { useContext, useState } from 'react';
import countries from 'helpers/country-codes.json';
import { PhoneNumbersOptionSet } from './components/phone-option-set';
import { Flex } from 'common/common-components.styled';
import { StyledPhoneInput } from './phone-number.styled';
import { formFieldProvider } from '../../form-field';
import { PrefixForId } from 'common/enums';
import { CountyItem } from './phone-number.props';
import { FormLabel } from '../common/label/label';

export const PhoneNumberField = () => {
  const [phoneCountryCodeValue, setPhoneCountryCodeValue] = useState('+49');
  const { field, value, error, onChange } = useContext(formFieldProvider);

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    onChange(phoneCountryCodeValue + event.target.value);
  };

  const onChangeCountryCodeHandler = (value: string) => {
    setPhoneCountryCodeValue(value);
    onChange(value + getPhoneValue().phoneNumberWithoutCountryCode);
  };

  const getPhoneValue = () => {
    if (!value) {
      return { phoneNumberWithoutCountryCode: '', phoneCountryCode: '' };
    }

    let phoneNumberWithoutCountryCode = value;
    let phoneCountryCode = '';

    countries.forEach((item: CountyItem) => {
      if (value.includes(item.dial_code)) {
        phoneNumberWithoutCountryCode = value.replace(item.dial_code, '');
        phoneCountryCode = item.dial_code;
      }
    });

    return { phoneNumberWithoutCountryCode, phoneCountryCode };
  };

  const { phoneNumberWithoutCountryCode } = getPhoneValue();

  return (
    <Flex direction='column'>
      <FormLabel />
      <Flex width='100%'>
        <StyledPhoneInput
          id={field.name + PrefixForId.FormText}
          error={!!error}
          isPrefix={!!true}
          type={field.type}
          placeholder={'0 00 00 00 00'}
          disabled={field.disabled}
          InputProps={{
            startAdornment: (
              <PhoneNumbersOptionSet value={phoneCountryCodeValue} setValue={onChangeCountryCodeHandler} />
            )
          }}
          onChange={onChangeHandler}
          value={phoneNumberWithoutCountryCode}
          ref={(e: HTMLDivElement) => {
            if (error) {
              e?.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
          }}
        />
      </Flex>
    </Flex>
  );
};
