import React from 'react';
import { Flex, Text } from 'common/common-components.styled';
import { useFormContext } from 'react-hook-form';
import { useServiceInspections } from './use-service-inspections';
import FormHook from 'components/form-hook/form-hook';
import InfoHint from 'components/info-hint';

const ServiceInspectionsStep = () => {
  const reactHookFormData = useFormContext();
  const { serviceIntervalsField, HUCertificateField } = useServiceInspections(reactHookFormData);

  return (
    <Flex width='100%' direction='column'>
      <div>
        <Text size='16' color='darkBlue'>
          Service/Inspektionen
        </Text>
      </div>

      <Flex gap='20px' width='100%' top='30' bottom='24'>
        <Flex width='50%' direction='column'>
          <FormHook
            reactHookFormData={reactHookFormData}
            formFields={serviceIntervalsField}
            distanceBetweenFields='0'
          />

          <InfoHint>
            Siehe hierzu Serviceanzeige im Fahrzeug und ggf. Eintrag im Service-Heft, bitte beachten Sie, dass die
            Arbeiten entweder nach einem Jahr oder einem bestimmten Kilometerstand fällig sein können. Fällige Arbeiten
            müssen unter Vorlage der Servicekarte durchgeführt werden.
          </InfoHint>
        </Flex>

        <Flex width='50%' direction='column'>
          <FormHook reactHookFormData={reactHookFormData} formFields={HUCertificateField} distanceBetweenFields='0' />

          <InfoHint>
            Bitte die Original-Bescheinigung ins Fahrzeug legen. Bei Fälligkeit der Hauptuntersuchung (HU) diese bitte
            noch durchführen lassen. Ist die HU im Monat der Rückgabe oder im Folgemonat fällig, lassen Sie diese bitte
            noch durchführen
          </InfoHint>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ServiceInspectionsStep;
