import { Flex } from 'common/common-components.styled';
import React from 'react';
import { SuccessScreenDescription } from './success-screen.styled';

const SuccessScreen = () => {
  return (
    <Flex direction='column' align='center' width='80%'>
      <img src='/assets/images/car-cup.png' alt='maschinenbecher' />

      <SuccessScreenDescription>
        Checkliste erfolgreich abgeschickt.
        <br />
        Wir überprüfen die Checkliste und melden uns bei dir, sobald die Prüfung erledigt ist.
      </SuccessScreenDescription>
    </Flex>
  );
};

export default SuccessScreen;
