import styled from 'styled-components';

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  border-top: ${({ theme }) => `1px solid ${theme.colors.lightBlue}`};
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.lightBlue}`};
  margin-bottom: 24px;
`;
