import axios from 'axios';
import authService from './auth-service';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

export async function getUserCompanyCarPolicies(Pagination) {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/policy/companylist`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: Pagination
      ? {
          Pagination
        }
      : {},
    transformResponse(data) {
      return data ? JSON.parse(data) : null;
    }
  });
}

export async function getCarPolicy(id) {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'GET',
    url: `${API_ENDPOINT}/api/policy/${id}`,
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
}
