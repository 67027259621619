import React from 'react';
import { Flex, Text } from 'common/common-components.styled';
import FormHook from 'components/form-hook/form-hook';
import { TrashIcon } from 'components/icons-new/trash';
import { DamagePartSectionProps } from './damage-part-section.types';
import { useFormContext } from 'react-hook-form';
import { TitleWrapper } from './damage-part-section.styled';

const DamagePartSection: React.FC<DamagePartSectionProps> = ({ form, removeDamagedPart }) => {
  const { title, damageTypeField, commentField, fileField } = form;
  const reactHookFormData = useFormContext();

  return (
    <Flex direction='column'>
      <TitleWrapper>
        <Text color='grey900' bold size='16'>
          {title}
        </Text>

        <Flex pointer onClick={removeDamagedPart}>
          <TrashIcon />
        </Flex>
      </TitleWrapper>

      <Flex gap='10px' width='100%'>
        <FormHook reactHookFormData={reactHookFormData} formFields={damageTypeField} distanceBetweenFields='24' />
        <FormHook reactHookFormData={reactHookFormData} formFields={commentField} distanceBetweenFields='24' />
      </Flex>

      <Flex>
        <FormHook reactHookFormData={reactHookFormData} formFields={fileField} distanceBetweenFields='16' />
      </Flex>
    </Flex>
  );
};

export default DamagePartSection;
