import React, { ChangeEventHandler, KeyboardEventHandler, useContext, useState } from 'react';
import { StyledChip, StyledChipsField, StyledInput } from './chips-field.styled';
import { formFieldProvider } from 'components/form-hook/form-field/form-field';
import { CircleCloseIcon } from 'components/icons-new/circle-close-icon';
import { useTheme } from 'styled-components';
import cloneDeep from 'lodash/cloneDeep';
import { DataWithIdType } from 'pages/my-vehicles/my-vehicles.props';

const ChipsField = () => {
  const theme = useTheme();
  const { field, value, onChange } = useContext(formFieldProvider);

  const [inputValue, setInputValue] = useState('');

  const keyDownHandler: KeyboardEventHandler<HTMLInputElement> = event => {
    if (event.key === 'Tab') {
      event.preventDefault();
      const isUniqueItem = value.every((chip: DataWithIdType) => chip.label !== inputValue.trim());
      if (inputValue.trim() && isUniqueItem) {
        const newChips = cloneDeep(value);
        newChips.push({ label: inputValue, id: null });
        onChange(newChips);
        setInputValue('');
      }
    }
  };

  const onInputChange: ChangeEventHandler<HTMLInputElement> = event => {
    setInputValue(event.target.value);
  };

  const onDeleteChip = (chip: DataWithIdType) => {
    let newChips = cloneDeep(value);
    newChips = newChips.filter((newChip: DataWithIdType) => newChip.label !== chip.label);
    onChange(newChips);
  };

  return (
    <StyledChipsField wrap='wrap' gap='5px'>
      {value?.map((chip: DataWithIdType) => (
        <StyledChip
          key={chip.label}
          label={chip.label}
          onDelete={() => onDeleteChip(chip)}
          deleteIcon={<CircleCloseIcon width='20' height='20' color={theme.colors.white} />}
        />
      ))}

      <StyledInput
        name={field.name}
        onChange={onInputChange}
        onKeyDown={keyDownHandler}
        value={inputValue}
        placeholder={field.placeholder}
        type='text'
      />
    </StyledChipsField>
  );
};

export default ChipsField;
