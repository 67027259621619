import React, { useContext, useMemo, useState } from 'react';
import { TASK_STATUS_CODES } from '../../../../../../constants';
import { LeasingWidgetsContext } from 'pages/existing-vehicle-page/components/layout/leasing-widgets-layout/leasing-widgets-context';
import { WidgetContentText } from '../components/leasing-widget/leasing-widget.styled';

export const useChecklist = () => {
  const { car } = useContext(LeasingWidgetsContext);
  const { checklist } = car;
  const status = checklist.widgetState.attributeValue.value;

  const [showChecklistModal, setShowChecklistModal] = useState(false);

  const description = useMemo(() => {
    switch (status) {
      case TASK_STATUS_CODES.open:
      case TASK_STATUS_CODES.postponed:
        return (
          <WidgetContentText>
            Prüfe anhand der Checkliste, ob dein Fahrzeug für die Rückgabe bereit ist.
          </WidgetContentText>
        );

      case TASK_STATUS_CODES.processing:
        return (
          <WidgetContentText>
            Wir überprüfen die Checkliste und melden uns bei dir, sobald die Prüfung erledigt ist.
          </WidgetContentText>
        );

      case TASK_STATUS_CODES.completed:
        return (
          <WidgetContentText>
            Wir haben die Checkliste überprüft. Sollten wir Unstimmigkeiten festgestellt haben, melden wir uns bei dir.
          </WidgetContentText>
        );
    }
  }, [status]);

  return {
    statusLabel: checklist.widgetState.attributeValue.label,
    statusColor: TASK_STATUS_CODES.completed === checklist.widgetState.attributeValue.value ? 'green' : 'darkGold',
    description,
    showChecklistModal,
    setShowChecklistModal
  };
};
