/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable linebreak-style */
import React, { useEffect, useState } from 'react';
import styles from '../steps.module.scss';
import { Distance } from 'common/common-components.styled';
import TextFieldComponent from '../components/text-field-component';
import PhoneFieldComponent from '../components/phone-field-component';
import TwoButtonsComponent from '../components/two-buttons-component';
import TextareaFieldComponent from '../components/textarea-field-component';
import EmailFieldComponent from '../components/email-field-component';
import { CSSTransition } from 'react-transition-group';
import slide from '../transition/damage-slide-steps.module.css';
import slideReverse from '../transition/backward-slide-steps.module.css';
import slideReturn from '../transition/return-slide-steps.module.css';
import slideQuest from '../transition/backward-slide-quest.module.css';
import TwoChooseButtonsComponent from '../components/two-choose-buttons-component';
import TrashIcon from '../icons/trash-icon';
import TwoLongButtonsComponent from '../components/two-long-buttons-component';
import { StepProps, StepStylesProps } from '../report-damage.props';

const ParkingStepSix = ({ reactHookFormData, handleBack, handleNext }: StepProps) => {
  const [buttonActive, setButtonActive] = useState<boolean>(false);
  const [showBlock, setShowBlock] = useState<boolean>(false);
  const [showBlockCrash, setShowBlockCrash] = useState<boolean>(false);
  const [showBlockCrash2, setShowBlockCrash2] = useState<boolean>(false);
  const [showBlockCrash3, setShowBlockCrash3] = useState<boolean>(false);
  const [showBlockWitnesses, setShowBlockWitnesses] = useState<boolean>(false);
  const [showBlockCrashInsurance, setShowBlockCrashInsurance] = useState<boolean>(false);
  const [showBlockCrashCarOwnerIsDriver, setShowBlockCrashCarOwnerIsDriver] = useState<boolean>(false);
  const [showBlockCrashWhatFahrzeug, setShowBlockCrashWhatFahrzeug] = useState<boolean>(false);
  const [showBlockCrashWhatSonstiges, setShowBlockCrashWhatSonstiges] = useState<boolean>(false);
  const [showBlockCrashInsurance2, setShowBlockCrashInsurance2] = useState<boolean>(false);
  const [showBlockCrashCarOwnerIsDriver2, setShowBlockCrashCarOwnerIsDriver2] = useState<boolean>(false);
  const [showBlockCrashWhatFahrzeug2, setShowBlockCrashWhatFahrzeug2] = useState<boolean>(false);
  const [showBlockCrashWhatSonstiges2, setShowBlockCrashWhatSonstiges2] = useState<boolean>(false);
  const [showBlockCrashInsurance3, setShowBlockCrashInsurance3] = useState<boolean>(false);
  const [showBlockCrashCarOwnerIsDriver3, setShowBlockCrashCarOwnerIsDriver3] = useState<boolean>(false);
  const [showBlockCrashWhatFahrzeug3, setShowBlockCrashWhatFahrzeug3] = useState<boolean>(false);
  const [showBlockCrashWhatSonstiges3, setShowBlockCrashWhatSonstiges3] = useState<boolean>(false);
  const {
    register,
    watch,
    clearErrors,
    setValue,
    setError,
    handleSubmit,
    formState: { errors }
  } = reactHookFormData;

  useEffect(() => {
    setShowBlock(true);
    clearErrors();
  }, []);

  useEffect(() => {
    setTimeout(() => (watch('anyWitnesses') ? setShowBlockWitnesses(true) : setShowBlockWitnesses(false)), 100);
  }, [watch('anyWitnesses')]);

  useEffect(() => {
    setTimeout(() => (watch('peopleInvolvedInCrash') ? setShowBlockCrash(true) : setShowBlockCrash(false)), 100);
  }, [watch('peopleInvolvedInCrash')]);

  useEffect(() => {
    setTimeout(
      () =>
        watch('peopleInvolvedInCrashAnotherPeopleInvolved') ? setShowBlockCrash2(true) : setShowBlockCrash2(false),
      100
    );
  }, [watch('peopleInvolvedInCrashAnotherPeopleInvolved')]);

  useEffect(() => {
    setTimeout(
      () =>
        watch('peopleInvolvedInCrashAnotherPeopleInvolved2') ? setShowBlockCrash3(true) : setShowBlockCrash3(false),
      100
    );
  }, [watch('peopleInvolvedInCrashAnotherPeopleInvolved2')]);

  useEffect(() => {
    setTimeout(
      () =>
        watch('peopleInvolvedInCrashPeopleInvolvedInsuranse')
          ? setShowBlockCrashInsurance(true)
          : setShowBlockCrashInsurance(false),
      100
    );
  }, [watch('peopleInvolvedInCrashPeopleInvolvedInsuranse')]);

  useEffect(() => {
    setTimeout(
      () =>
        watch('peopleInvolvedInCrashCarOwnerIsDriver')
          ? setShowBlockCrashCarOwnerIsDriver(true)
          : setShowBlockCrashCarOwnerIsDriver(false),
      100
    );
  }, [watch('peopleInvolvedInCrashCarOwnerIsDriver')]);

  useEffect(() => {
    setTimeout(
      () =>
        watch('peopleInvolvedInCrashWhatHasDamage.Fahrzeug') === 'Fahrzeug'
          ? setShowBlockCrashWhatFahrzeug(true)
          : setShowBlockCrashWhatFahrzeug(false),
      100
    );
  }, [watch('peopleInvolvedInCrashWhatHasDamage.Fahrzeug')]);

  useEffect(() => {
    setTimeout(
      () =>
        watch('peopleInvolvedInCrashWhatHasDamage.Sonstiges') === 'Sonstiges'
          ? setShowBlockCrashWhatSonstiges(true)
          : setShowBlockCrashWhatSonstiges(false),
      100
    );
  }, [watch('peopleInvolvedInCrashWhatHasDamage.Sonstiges')]);

  useEffect(() => {
    setTimeout(
      () =>
        watch('peopleInvolvedInCrashPeopleInvolvedInsuranse2')
          ? setShowBlockCrashInsurance2(true)
          : setShowBlockCrashInsurance2(false),
      100
    );
  }, [watch('peopleInvolvedInCrashPeopleInvolvedInsuranse2')]);

  useEffect(() => {
    setTimeout(
      () =>
        watch('peopleInvolvedInCrashCarOwnerIsDriver2')
          ? setShowBlockCrashCarOwnerIsDriver2(true)
          : setShowBlockCrashCarOwnerIsDriver2(false),
      100
    );
  }, [watch('peopleInvolvedInCrashCarOwnerIsDriver2')]);

  useEffect(() => {
    setTimeout(
      () =>
        watch('peopleInvolvedInCrashWhatHasDamage.Fahrzeug2') === 'Fahrzeug'
          ? setShowBlockCrashWhatFahrzeug2(true)
          : setShowBlockCrashWhatFahrzeug2(false),
      100
    );
  }, [watch('peopleInvolvedInCrashWhatHasDamage.Fahrzeug2')]);

  useEffect(() => {
    setTimeout(
      () =>
        watch('peopleInvolvedInCrashWhatHasDamage.Sonstiges2') === 'Sonstiges'
          ? setShowBlockCrashWhatSonstiges2(true)
          : setShowBlockCrashWhatSonstiges2(false),
      100
    );
  }, [watch('peopleInvolvedInCrashWhatHasDamage.Sonstiges2')]);

  useEffect(() => {
    setTimeout(
      () =>
        watch('peopleInvolvedInCrashPeopleInvolvedInsuranse3')
          ? setShowBlockCrashInsurance3(true)
          : setShowBlockCrashInsurance3(false),
      100
    );
  }, [watch('peopleInvolvedInCrashPeopleInvolvedInsuranse3')]);

  useEffect(() => {
    setTimeout(
      () =>
        watch('peopleInvolvedInCrashCarOwnerIsDriver3')
          ? setShowBlockCrashCarOwnerIsDriver3(true)
          : setShowBlockCrashCarOwnerIsDriver3(false),
      100
    );
  }, [watch('peopleInvolvedInCrashCarOwnerIsDriver3')]);

  useEffect(() => {
    setTimeout(
      () =>
        watch('peopleInvolvedInCrashWhatHasDamage.Fahrzeug3') === 'Fahrzeug'
          ? setShowBlockCrashWhatFahrzeug3(true)
          : setShowBlockCrashWhatFahrzeug3(false),
      100
    );
  }, [watch('peopleInvolvedInCrashWhatHasDamage.Fahrzeug3')]);

  useEffect(() => {
    setTimeout(
      () =>
        watch('peopleInvolvedInCrashWhatHasDamage.Sonstiges3') === 'Sonstiges'
          ? setShowBlockCrashWhatSonstiges3(true)
          : setShowBlockCrashWhatSonstiges3(false),
      100
    );
  }, [watch('peopleInvolvedInCrashWhatHasDamage.Sonstiges3')]);

  useEffect(() => {
    if (
      !!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichenEmpty') ||
      (!!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichenEmpty') &&
        errors.peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichen)
    ) {
      clearErrors('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichen');
    }
  }, [
    watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichenEmpty'),
    watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichen'),
    errors
  ]);

  useEffect(() => {
    if (
      !!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichenEmpty2') ||
      (!!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichenEmpty2') &&
        errors.peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichen2)
    ) {
      clearErrors('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichen2');
    }
  }, [
    watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichenEmpty2'),
    watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichen2'),
    errors
  ]);

  useEffect(() => {
    if (
      !!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichenEmpty3') ||
      (!!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichenEmpty3') &&
        errors.peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichen3)
    ) {
      clearErrors('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichen3');
    }
  }, [
    watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichenEmpty3'),
    watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichen3'),
    errors
  ]);

  const additionalBlock1 =
    watch('peopleInvolvedInCrash') === false ||
    (watch('peopleInvolvedInCrash') === true &&
      (!!watch('peopleInvolvedInCrashDateEmpty') ||
        (watch('peopleInvolvedInCrashName') !== undefined &&
          watch('peopleInvolvedInCrashName').length > 0 &&
          watch('peopleInvolvedInCrashAdress') !== undefined &&
          watch('peopleInvolvedInCrashAdress').length > 0)) &&
      watch('peopleInvolvedInCrashPeopleDamage') !== undefined &&
      (watch('peopleInvolvedInCrashPeopleInvolvedInsuranse') === false ||
        (!!watch('peopleInvolvedInCrashPeopleInvolvedInsuranse') &&
          !!watch('peopleInvolvedInCrashPeopleInvolvedInsuranseName') &&
          watch('peopleInvolvedInCrashPeopleInvolvedInsuranseName').length > 0)) &&
      (watch('peopleInvolvedInCrashCarOwnerIsDriver') === false ||
        (!!watch('peopleInvolvedInCrashCarOwnerIsDriverName') &&
          watch('peopleInvolvedInCrashCarOwnerIsDriverName').length > 0)) &&
      watch('peopleInvolvedInCrashAnotherPeopleInvolved') !== undefined &&
      watch('peopleInvolvedInCrashWhatHasDamage') !== undefined);

  const additionalBlock2 =
    watch('peopleInvolvedInCrash') === false ||
    (!!watch('peopleInvolvedInCrash') &&
      (watch('peopleInvolvedInCrashAnotherPeopleInvolved') === false ||
        (!!watch('peopleInvolvedInCrashAnotherPeopleInvolved') &&
          (!!watch('peopleInvolvedInCrashDateEmpty2') ||
            (watch('peopleInvolvedInCrashName2') !== undefined &&
              watch('peopleInvolvedInCrashName2').length > 0 &&
              watch('peopleInvolvedInCrashAdress2') !== undefined &&
              watch('peopleInvolvedInCrashAdress2').length > 0)) &&
          watch('peopleInvolvedInCrashPeopleDamage2') !== undefined &&
          (watch('peopleInvolvedInCrashPeopleInvolvedInsuranse2') === false ||
            (!!watch('peopleInvolvedInCrashPeopleInvolvedInsuranse2') &&
              !!watch('peopleInvolvedInCrashPeopleInvolvedInsuranseName2') &&
              watch('peopleInvolvedInCrashPeopleInvolvedInsuranseName2').length > 0)) &&
          (watch('peopleInvolvedInCrashCarOwnerIsDriver2') === false ||
            (!!watch('peopleInvolvedInCrashCarOwnerIsDriverName2') &&
              watch('peopleInvolvedInCrashCarOwnerIsDriverName2').length > 0)) &&
          watch('peopleInvolvedInCrashAnotherPeopleInvolved2') !== undefined)));

  const additionalBlock3 =
    watch('peopleInvolvedInCrash') === false ||
    (!!watch('peopleInvolvedInCrash') &&
      (!watch('peopleInvolvedInCrashAnotherPeopleInvolved2') ||
        (!!watch('peopleInvolvedInCrashAnotherPeopleInvolved2') &&
          (!!watch('peopleInvolvedInCrashDateEmpty3') ||
            (watch('peopleInvolvedInCrashName3') !== undefined &&
              watch('peopleInvolvedInCrashName3').length > 0 &&
              watch('peopleInvolvedInCrashAdress3') !== undefined &&
              watch('peopleInvolvedInCrashAdress3').length > 0)) &&
          watch('peopleInvolvedInCrashPeopleDamage3') !== undefined &&
          (watch('peopleInvolvedInCrashPeopleInvolvedInsuranse3') === false ||
            (!!watch('peopleInvolvedInCrashPeopleInvolvedInsuranse3') &&
              !!watch('peopleInvolvedInCrashPeopleInvolvedInsuranseName3') &&
              watch('peopleInvolvedInCrashPeopleInvolvedInsuranseName3').length > 0)) &&
          (watch('peopleInvolvedInCrashCarOwnerIsDriver3') === false ||
            (!!watch('peopleInvolvedInCrashCarOwnerIsDriverName3') &&
              watch('peopleInvolvedInCrashCarOwnerIsDriverName3').length > 0)))));

  const additionalBlockSongstigesData =
    watch('peopleInvolvedInCrashWhatHasDamage.Sonstiges') === 'Sonstiges' &&
    !!watch('peopleInvolvedInCrashWhatHasDamageSonstigesDamageDescription') &&
    watch('peopleInvolvedInCrashWhatHasDamageSonstigesDamageDescription').length > 0;

  const additionalBlockSongstigesData2 =
    watch('peopleInvolvedInCrash') === false ||
    (!!watch('peopleInvolvedInCrash') && watch('peopleInvolvedInCrashAnotherPeopleInvolved') === false) ||
    (!!watch('peopleInvolvedInCrashAnotherPeopleInvolved') &&
      watch('peopleInvolvedInCrashWhatHasDamage.Sonstiges2') === 'Sonstiges' &&
      !!watch('peopleInvolvedInCrashWhatHasDamageSonstigesDamageDescription2') &&
      watch('peopleInvolvedInCrashWhatHasDamageSonstigesDamageDescription2').length > 0);

  const additionalBlockSongstigesData3 =
    watch('peopleInvolvedInCrash') === false ||
    (!!watch('peopleInvolvedInCrash') && watch('peopleInvolvedInCrashAnotherPeopleInvolved') === false) ||
    (!!watch('peopleInvolvedInCrash') &&
      !!watch('peopleInvolvedInCrashAnotherPeopleInvolved') &&
      watch('peopleInvolvedInCrashAnotherPeopleInvolved2') === false) ||
    (!!watch('peopleInvolvedInCrashAnotherPeopleInvolved2') &&
      watch('peopleInvolvedInCrashWhatHasDamage.Sonstiges3') === 'Sonstiges' &&
      !!watch('peopleInvolvedInCrashWhatHasDamageSonstigesDamageDescription3') &&
      watch('peopleInvolvedInCrashWhatHasDamageSonstigesDamageDescription3').length > 0);

  const additionalBlockFahrzeugData =
    (watch('peopleInvolvedInCrashWhatHasDamage.Fahrzeug') === 'Fahrzeug' &&
      !!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichenEmpty')) ||
    (watch('peopleInvolvedInCrashWhatHasDamage.Fahrzeug') === 'Fahrzeug' &&
      !!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichen') &&
      watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichen').length > 0);

  const additionalBlockFahrzeugData2 =
    watch('peopleInvolvedInCrash') === false ||
    (!!watch('peopleInvolvedInCrash') && watch('peopleInvolvedInCrashAnotherPeopleInvolved') === false) ||
    (!!watch('peopleInvolvedInCrashAnotherPeopleInvolved') &&
      watch('peopleInvolvedInCrashWhatHasDamage.Fahrzeug2') === 'Fahrzeug' &&
      !!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichenEmpty2')) ||
    (watch('peopleInvolvedInCrashWhatHasDamage.Fahrzeug2') === 'Fahrzeug' &&
      !!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichen2') &&
      watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichen2').length > 0);

  const additionalBlockFahrzeugData3 =
    watch('peopleInvolvedInCrash') === false ||
    (!!watch('peopleInvolvedInCrash') && watch('peopleInvolvedInCrashAnotherPeopleInvolved') === false) ||
    (!!watch('peopleInvolvedInCrash') &&
      !!watch('peopleInvolvedInCrashAnotherPeopleInvolved') &&
      watch('peopleInvolvedInCrashAnotherPeopleInvolved2') === false) ||
    (!!watch('peopleInvolvedInCrashAnotherPeopleInvolved2') &&
      watch('peopleInvolvedInCrashWhatHasDamage.Fahrzeug3') === 'Fahrzeug' &&
      !!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichenEmpty3')) ||
    (watch('peopleInvolvedInCrashWhatHasDamage.Fahrzeug3') === 'Fahrzeug' &&
      !!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichen3') &&
      watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichen3').length > 0);

  const additionalBlockSongstiges =
    watch('peopleInvolvedInCrash') === false ||
    (!watch('peopleInvolvedInCrashWhatHasDamage.Fahrzeug') && additionalBlockSongstigesData) ||
    (additionalBlockFahrzeugData && additionalBlockSongstigesData);

  const additionalBlockSongstiges2 =
    watch('peopleInvolvedInCrash') === false ||
    (additionalBlock2 && !watch('peopleInvolvedInCrashWhatHasDamage.Fahrzeug2') && additionalBlockSongstigesData2) ||
    (additionalBlock2 && additionalBlockFahrzeugData2 && additionalBlockSongstigesData2);

  const additionalBlockSongstiges3 =
    watch('peopleInvolvedInCrash') === false ||
    (additionalBlock3 && !watch('peopleInvolvedInCrashWhatHasDamage.Fahrzeug3') && additionalBlockSongstigesData3) ||
    (additionalBlock3 && additionalBlockFahrzeugData3 && additionalBlockSongstigesData3);

  const additionalBlockFahrzeug =
    watch('peopleInvolvedInCrash') === false ||
    (!watch('peopleInvolvedInCrashWhatHasDamage.Sonstiges') && additionalBlockFahrzeugData) ||
    ((!watch('peopleInvolvedInCrashWhatHasDamage.Sonstiges') || additionalBlockSongstigesData) &&
      additionalBlockFahrzeugData);

  const additionalBlockFahrzeug2 =
    watch('peopleInvolvedInCrash') === false ||
    (additionalBlock2 && !watch('peopleInvolvedInCrashWhatHasDamage.Sonstiges2') && additionalBlockFahrzeugData2) ||
    (((additionalBlock2 && !watch('peopleInvolvedInCrashWhatHasDamage.Sonstiges2')) ||
      additionalBlockSongstigesData2) &&
      additionalBlockFahrzeugData2);

  const additionalBlockFahrzeug3 =
    watch('peopleInvolvedInCrash') === false ||
    (additionalBlock3 && !watch('peopleInvolvedInCrashWhatHasDamage.Sonstiges3') && additionalBlockFahrzeugData3) ||
    (((additionalBlock3 && !watch('peopleInvolvedInCrashWhatHasDamage.Sonstiges3')) ||
      additionalBlockSongstigesData3) &&
      additionalBlockFahrzeugData3);

  const anyWitnessesFields =
    watch('anyWitnesses') === false ||
    !!watch('anyWitnessesDateEmpty') ||
    (!!watch('anyWitnessesName') &&
      watch('anyWitnessesName').length > 0 &&
      !!watch('anyWitnessesAdress') &&
      watch('anyWitnessesAdress').length > 0 &&
      !!watch('anyWitnessesEmail') &&
      watch('anyWitnessesEmail').length > 0 &&
      !!watch('anyWitnessesPhone') &&
      watch('anyWitnessesPhone').length > 0);

  const anyWitnessesFieldsSecond =
    watch('anyWitnesses') === false ||
    !watch('showSecondPersonAnyWitnesses') ||
    (!!watch('showSecondPersonAnyWitnesses') && !!watch('anyWitnessesDateSecondEmpty')) ||
    (!!watch('anyWitnessesNameSecond') &&
      watch('anyWitnessesNameSecond').length > 0 &&
      !!watch('anyWitnessesAdressSecond') &&
      watch('anyWitnessesAdressSecond').length > 0 &&
      !!watch('anyWitnessesEmailSecond') &&
      watch('anyWitnessesEmailSecond').length > 0 &&
      !!watch('anyWitnessesPhoneSecond') &&
      watch('anyWitnessesPhoneSecond').length > 0);

  useEffect(() => {
    watch('peopleInvolvedInCrash') !== undefined &&
    watch('anyWitnesses') !== undefined &&
    (watch('peopleInvolvedInCrash') === false ||
      watch('peopleInvolvedInCrashWhatHasDamage.Sonstiges') === 'Sonstiges' ||
      watch('peopleInvolvedInCrashWhatHasDamage.Fahrzeug') === 'Fahrzeug') &&
    additionalBlock1 &&
    anyWitnessesFields &&
    anyWitnessesFieldsSecond &&
    (additionalBlockFahrzeug || additionalBlockSongstiges) &&
    (watch('peopleInvolvedInCrash') === false ||
      (!!watch('peopleInvolvedInCrash') &&
        additionalBlock2 &&
        (additionalBlockSongstiges2 || additionalBlockFahrzeug2))) &&
    (watch('peopleInvolvedInCrash') === false ||
      (!!watch('peopleInvolvedInCrash') &&
        additionalBlock3 &&
        (additionalBlockSongstiges3 || additionalBlockFahrzeug3))) &&
    Object.keys(errors).length < 1
      ? setButtonActive(true)
      : setButtonActive(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch()]);

  let slideClassName: StepStylesProps = watch('previousStep') === 5 ? slide : slideReverse;

  const finValidation = /^[a-zA-Z0-9_\-\.]{1,100}$/;

  useEffect(() => {
    if (watch('peopleInvolvedInCrashPeopleInvolvedInsuranseNumber')) {
      const newFin = watch('peopleInvolvedInCrashPeopleInvolvedInsuranseNumber').toUpperCase();
      setValue('peopleInvolvedInCrashPeopleInvolvedInsuranseNumber', newFin);
      if (newFin.search(finValidation) === -1) {
        setError('peopleInvolvedInCrashPeopleInvolvedInsuranseNumber', {
          type: 'manual',
          message: 'Es gibt eine Beschränkung für maximal zulässige Symbole.'
        });
      } else {
        clearErrors('peopleInvolvedInCrashPeopleInvolvedInsuranseNumber');
      }
    }
    if (watch('peopleInvolvedInCrashPeopleInvolvedInsuranseNumber') === '') {
      clearErrors('peopleInvolvedInCrashPeopleInvolvedInsuranseNumber');
    }
  }, [watch('peopleInvolvedInCrashPeopleInvolvedInsuranseNumber')]);

  useEffect(() => {
    if (watch('peopleInvolvedInCrashPeopleInvolvedInsuranseNumber2')) {
      const newFin = watch('peopleInvolvedInCrashPeopleInvolvedInsuranseNumber2').toUpperCase();
      setValue('peopleInvolvedInCrashPeopleInvolvedInsuranseNumber2', newFin);
      if (newFin.search(finValidation) === -1) {
        setError('peopleInvolvedInCrashPeopleInvolvedInsuranseNumber2', {
          type: 'manual',
          message: 'Es gibt eine Beschränkung für maximal zulässige Symbole.'
        });
      } else {
        clearErrors('peopleInvolvedInCrashPeopleInvolvedInsuranseNumber2');
      }
    }
    if (watch('peopleInvolvedInCrashPeopleInvolvedInsuranseNumber2') === '') {
      clearErrors('peopleInvolvedInCrashPeopleInvolvedInsuranseNumber2');
    }
  }, [watch('peopleInvolvedInCrashPeopleInvolvedInsuranseNumber2')]);

  useEffect(() => {
    if (watch('peopleInvolvedInCrashPeopleInvolvedInsuranseNumber3')) {
      const newFin = watch('peopleInvolvedInCrashPeopleInvolvedInsuranseNumber3').toUpperCase();
      setValue('peopleInvolvedInCrashPeopleInvolvedInsuranseNumber3', newFin);
      if (newFin.search(finValidation) === -1) {
        setError('peopleInvolvedInCrashPeopleInvolvedInsuranseNumber3', {
          type: 'manual',
          message: 'Es gibt eine Beschränkung für maximal zulässige Symbole.'
        });
      } else {
        clearErrors('peopleInvolvedInCrashPeopleInvolvedInsuranseNumber3');
      }
    }
    if (watch('peopleInvolvedInCrashPeopleInvolvedInsuranseNumber3') === '') {
      clearErrors('peopleInvolvedInCrashPeopleInvolvedInsuranseNumber3');
    }
  }, [watch('peopleInvolvedInCrashPeopleInvolvedInsuranseNumber3')]);

  const checkButtons = (buttonsArray: Array<string>) => {
    buttonsArray.forEach((item: string) => {
      if (watch(item) === undefined) {
        setError(item, {
          type: 'manual',
          message: 'Error'
        });
      }
    });
  };

  const moveToNextStep = () => {
    if (buttonActive && handleNext) {
      setShowBlock(false);
      watch('previousStep') === 'detail'
        ? setTimeout(() => {
            setValue('neededStep', null);
            setValue('step', 'detail');
          }, 500)
        : setTimeout(() => handleNext(), 500);
      setTimeout(() => setValue('previousStep', 6), 500);
    } else {
      handleSubmit(
        () => {},
        () => {}
      );
      checkButtons(['peopleInvolvedInCrash', 'anyWitnesses']);
      !!watch('peopleInvolvedInCrash') &&
        checkButtons([
          'peopleInvolvedInCrashPeopleDamage',
          'peopleInvolvedInCrashPeopleInvolvedInsuranse',
          'peopleInvolvedInCrashCarOwnerIsDriver',
          'peopleInvolvedInCrashAnotherPeopleInvolved'
        ]);
      !!watch('peopleInvolvedInCrash') &&
        (watch('peopleInvolvedInCrashWhatHasDamage') === undefined ||
          (watch('peopleInvolvedInCrashWhatHasDamage.Fahrzeug') === undefined &&
            watch('peopleInvolvedInCrashWhatHasDamage.Sonstiges') === undefined)) &&
        setError('peopleInvolvedInCrashWhatHasDamage', {
          type: 'manual',
          message: 'Error'
        });
      !!watch('peopleInvolvedInCrash') &&
        !!watch('peopleInvolvedInCrashAnotherPeopleInvolved') &&
        checkButtons([
          'peopleInvolvedInCrashPeopleDamage2',
          'peopleInvolvedInCrashPeopleInvolvedInsuranse2',
          'peopleInvolvedInCrashCarOwnerIsDriver2',
          'peopleInvolvedInCrashAnotherPeopleInvolved2'
        ]);
      !!watch('peopleInvolvedInCrash') &&
        !!watch('peopleInvolvedInCrashAnotherPeopleInvolved') &&
        (watch('peopleInvolvedInCrashWhatHasDamage2') === undefined ||
          (watch('peopleInvolvedInCrashWhatHasDamage.Fahrzeug2') === undefined &&
            watch('peopleInvolvedInCrashWhatHasDamage.Sonstiges2') === undefined)) &&
        setError('peopleInvolvedInCrashWhatHasDamage2', {
          type: 'manual',
          message: 'Error'
        });
      !!watch('peopleInvolvedInCrash') &&
        !!watch('peopleInvolvedInCrashAnotherPeopleInvolved') &&
        !!watch('peopleInvolvedInCrashAnotherPeopleInvolved2') &&
        checkButtons([
          'peopleInvolvedInCrashPeopleDamage3',
          'peopleInvolvedInCrashPeopleInvolvedInsuranse3',
          'peopleInvolvedInCrashCarOwnerIsDriver3'
        ]);
      !!watch('peopleInvolvedInCrash') &&
        !!watch('peopleInvolvedInCrashAnotherPeopleInvolved') &&
        !!watch('peopleInvolvedInCrashAnotherPeopleInvolved2') &&
        (watch('peopleInvolvedInCrashWhatHasDamage3') === undefined ||
          (watch('peopleInvolvedInCrashWhatHasDamage.Fahrzeug3') === undefined &&
            watch('peopleInvolvedInCrashWhatHasDamage.Sonstiges3') === undefined)) &&
        setError('peopleInvolvedInCrashWhatHasDamage3', {
          type: 'manual',
          message: 'Error'
        });
    }
  };

  const moveToPreviousStep = () => {
    if (handleBack) {
      setValue('previousStep', 6);
      setShowBlock(false);
      setTimeout(() => {
        handleBack();
      }, 500);
    }
  };

  useEffect(() => {
    if (watch('peopleInvolvedInCrashDateEmpty')) {
      clearErrors(['peopleInvolvedInCrashAdress', 'peopleInvolvedInCrashName']);
    }
  }, [watch('peopleInvolvedInCrashDateEmpty')]);

  useEffect(() => {
    if (watch('peopleInvolvedInCrashDateEmpty2')) {
      clearErrors(['peopleInvolvedInCrashAdress2', 'peopleInvolvedInCrashName2']);
    }
  }, [watch('peopleInvolvedInCrashDateEmpty2')]);

  useEffect(() => {
    if (watch('peopleInvolvedInCrashDateEmpty3')) {
      clearErrors(['peopleInvolvedInCrashAdress3', 'peopleInvolvedInCrashName3']);
    }
  }, [watch('peopleInvolvedInCrashDateEmpty3')]);

  useEffect(() => {
    if (!watch('peopleInvolvedInCrash')) {
      clearErrors([
        'peopleInvolvedInCrashAdress',
        'peopleInvolvedInCrashAdress2',
        'peopleInvolvedInCrashAdress3',
        'peopleInvolvedInCrashCarOwnerIsDriverName',
        'peopleInvolvedInCrashCarOwnerIsDriverName2',
        'peopleInvolvedInCrashCarOwnerIsDriverName3',
        'peopleInvolvedInCrashName',
        'peopleInvolvedInCrashName2',
        'peopleInvolvedInCrashName3',
        'peopleInvolvedInCrashPeopleInvolvedInsuranseName',
        'peopleInvolvedInCrashPeopleInvolvedInsuranseName2',
        'peopleInvolvedInCrashPeopleInvolvedInsuranseName3',
        'peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichen',
        'peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichen2',
        'peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichen3',
        'peopleInvolvedInCrashWhatHasDamageSonstigesDamageDescription',
        'peopleInvolvedInCrashWhatHasDamageSonstigesDamageDescription2',
        'peopleInvolvedInCrashWhatHasDamageSonstigesDamageDescription3',
        'peopleInvolvedInCrashAnotherPeopleInvolved',
        'peopleInvolvedInCrashCarOwnerIsDriver',
        'peopleInvolvedInCrashPeopleDamage',
        'peopleInvolvedInCrashPeopleInvolvedInsuranse',
        'peopleInvolvedInCrashWhatHasDamage',
        'peopleInvolvedInCrashAnotherPeopleInvolved2',
        'peopleInvolvedInCrashCarOwnerIsDriver2',
        'peopleInvolvedInCrashPeopleDamage2',
        'peopleInvolvedInCrashPeopleInvolvedInsuranse2',
        'peopleInvolvedInCrashWhatHasDamage2',
        'peopleInvolvedInCrashCarOwnerIsDriver3',
        'peopleInvolvedInCrashPeopleDamage3',
        'peopleInvolvedInCrashPeopleInvolvedInsuranse3',
        'peopleInvolvedInCrashWhatHasDamage3'
      ]);
    }
  }, [watch('peopleInvolvedInCrash')]);

  useEffect(() => {
    if (!watch('peopleInvolvedInCrashPeopleInvolvedInsuranse')) {
      clearErrors('peopleInvolvedInCrashPeopleInvolvedInsuranseName');
    }
  }, [watch('peopleInvolvedInCrashPeopleInvolvedInsuranse')]);

  useEffect(() => {
    if (!watch('peopleInvolvedInCrashPeopleInvolvedInsuranse2')) {
      clearErrors('peopleInvolvedInCrashPeopleInvolvedInsuranseName2');
    }
  }, [watch('peopleInvolvedInCrashPeopleInvolvedInsuranse2')]);

  useEffect(() => {
    if (!watch('peopleInvolvedInCrashPeopleInvolvedInsuranse3')) {
      clearErrors('peopleInvolvedInCrashPeopleInvolvedInsuranseName3');
    }
  }, [watch('peopleInvolvedInCrashPeopleInvolvedInsuranse3')]);

  useEffect(() => {
    if (!watch('peopleInvolvedInCrashCarOwnerIsDriver')) {
      clearErrors('peopleInvolvedInCrashCarOwnerIsDriverName');
    }
  }, [watch('peopleInvolvedInCrashCarOwnerIsDriver')]);

  useEffect(() => {
    if (!watch('peopleInvolvedInCrashCarOwnerIsDriver2')) {
      clearErrors('peopleInvolvedInCrashCarOwnerIsDriverName2');
    }
  }, [watch('peopleInvolvedInCrashCarOwnerIsDriver2')]);

  useEffect(() => {
    if (!watch('peopleInvolvedInCrashCarOwnerIsDriver3')) {
      clearErrors('peopleInvolvedInCrashCarOwnerIsDriverName3');
    }
  }, [watch('peopleInvolvedInCrashCarOwnerIsDriver3')]);

  useEffect(() => {
    if (!watch('peopleInvolvedInCrashWhatHasDamage.Fahrzeug')) {
      clearErrors('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichen');
    }
  }, [watch('peopleInvolvedInCrashWhatHasDamage.Fahrzeug')]);

  useEffect(() => {
    if (!watch('peopleInvolvedInCrashWhatHasDamage.Fahrzeug2')) {
      clearErrors('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichen2');
    }
  }, [watch('peopleInvolvedInCrashWhatHasDamage.Fahrzeug2')]);

  useEffect(() => {
    if (!watch('peopleInvolvedInCrashWhatHasDamage.Fahrzeug3')) {
      clearErrors('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichen3');
    }
  }, [watch('peopleInvolvedInCrashWhatHasDamage.Fahrzeug3')]);

  useEffect(() => {
    if (!watch('peopleInvolvedInCrashWhatHasDamage.Sonstiges')) {
      clearErrors('peopleInvolvedInCrashWhatHasDamageSonstigesDamageDescription');
    }
  }, [watch('peopleInvolvedInCrashWhatHasDamage.Sonstiges')]);

  useEffect(() => {
    if (!watch('peopleInvolvedInCrashWhatHasDamage.Sonstiges2')) {
      clearErrors('peopleInvolvedInCrashWhatHasDamageSonstigesDamageDescription2');
    }
  }, [watch('peopleInvolvedInCrashWhatHasDamage.Sonstiges2')]);

  useEffect(() => {
    if (!watch('peopleInvolvedInCrashWhatHasDamage.Sonstiges3')) {
      clearErrors('peopleInvolvedInCrashWhatHasDamageSonstigesDamageDescription3');
    }
  }, [watch('peopleInvolvedInCrashWhatHasDamage.Sonstiges3')]);

  useEffect(() => {
    if (!watch('peopleInvolvedInCrashAnotherPeopleInvolved')) {
      clearErrors([
        'peopleInvolvedInCrashAdress2',
        'peopleInvolvedInCrashAdress3',
        'peopleInvolvedInCrashCarOwnerIsDriverName2',
        'peopleInvolvedInCrashCarOwnerIsDriverName3',
        'peopleInvolvedInCrashName2',
        'peopleInvolvedInCrashName3',
        'peopleInvolvedInCrashPeopleInvolvedInsuranseName2',
        'peopleInvolvedInCrashPeopleInvolvedInsuranseName3',
        'peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichen2',
        'peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichen3',
        'peopleInvolvedInCrashWhatHasDamageSonstigesDamageDescription2',
        'peopleInvolvedInCrashWhatHasDamageSonstigesDamageDescription3',
        'peopleInvolvedInCrashAnotherPeopleInvolved',
        'peopleInvolvedInCrashCarOwnerIsDriver',
        'peopleInvolvedInCrashPeopleDamage',
        'peopleInvolvedInCrashPeopleInvolvedInsuranse',
        'peopleInvolvedInCrashWhatHasDamage',
        'peopleInvolvedInCrashAnotherPeopleInvolved2',
        'peopleInvolvedInCrashCarOwnerIsDriver2',
        'peopleInvolvedInCrashPeopleDamage2',
        'peopleInvolvedInCrashPeopleInvolvedInsuranse2',
        'peopleInvolvedInCrashWhatHasDamage2',
        'peopleInvolvedInCrashCarOwnerIsDriver3',
        'peopleInvolvedInCrashPeopleDamage3',
        'peopleInvolvedInCrashPeopleInvolvedInsuranse3',
        'peopleInvolvedInCrashWhatHasDamage3'
      ]);
    }
  }, [watch('peopleInvolvedInCrashAnotherPeopleInvolved')]);

  useEffect(() => {
    if (!watch('peopleInvolvedInCrashAnotherPeopleInvolved2')) {
      clearErrors([
        'peopleInvolvedInCrashAdress3',
        'peopleInvolvedInCrashCarOwnerIsDriverName3',
        'peopleInvolvedInCrashName3',
        'peopleInvolvedInCrashPeopleInvolvedInsuranseName3',
        'peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichen3',
        'peopleInvolvedInCrashWhatHasDamageSonstigesDamageDescription3',
        'peopleInvolvedInCrashCarOwnerIsDriver3',
        'peopleInvolvedInCrashPeopleDamage3',
        'peopleInvolvedInCrashPeopleInvolvedInsuranse3',
        'peopleInvolvedInCrashWhatHasDamage3'
      ]);
    }
  }, [watch('peopleInvolvedInCrashAnotherPeopleInvolved2')]);

  useEffect(() => {
    if (!watch('anyWitnesses')) {
      clearErrors([
        'anyWitnessesAdress',
        'anyWitnessesEmail',
        'anyWitnessesName',
        'anyWitnessesPhone',
        'anyWitnessesAdressSecond',
        'anyWitnessesEmailSecond',
        'anyWitnessesNameSecond',
        'anyWitnessesPhoneSecond'
      ]);
    }
  }, [watch('anyWitnesses')]);

  useEffect(() => {
    if (!watch('showSecondPersonAnyWitnesses')) {
      clearErrors([
        'anyWitnessesAdressSecond',
        'anyWitnessesEmailSecond',
        'anyWitnessesNameSecond',
        'anyWitnessesPhoneSecond'
      ]);
    }
  }, [watch('showSecondPersonAnyWitnesses')]);

  return (
    <CSSTransition
      in={showBlock}
      timeout={800}
      classNames={watch('previousStep') === 6 ? slideReturn : slideClassName}
      mountOnEnter
      unmountOnExit
    >
      <section className={styles.container}>
        <TwoLongButtonsComponent
          reactHookFormData={reactHookFormData}
          fieldName='Waren weitere Personen und/oder Fahrzeuge am Unfall beteiligt?'
          fieldRegister='peopleInvolvedInCrash'
          fieldRegisterSecond='peopleInvolvedInCrash'
          firstButtonText='Ja'
          secondButtonText='Nein, nur bereits Angegebene/mein Fahrzeug'
        />

        {watch('peopleInvolvedInCrash') === true && (
          <div style={{ overflow: 'hidden' }}>
            <CSSTransition in={showBlockCrash} timeout={400} classNames={slideQuest} mountOnEnter unmountOnExit>
              <div>
                <Distance top='20' />
                <h6>Fahrzeuglenker zum Schadenzeitpunkt:</h6>

                <div className={styles.checkbox}>
                  <input
                    {...register('peopleInvolvedInCrashDateEmpty', { required: false })}
                    id='peopleInvolvedInCrashDateEmpty'
                    type='checkbox'
                  />
                  <label htmlFor='peopleInvolvedInCrashDateEmpty'>Daten unbekannt</label>
                </div>
                <Distance top='15' />

                <TextFieldComponent
                  reactHookFormData={reactHookFormData}
                  fieldRegister='peopleInvolvedInCrashName'
                  fieldName='Name'
                  fieldPlaceholder='Max Mustermann'
                  optional={!!watch('peopleInvolvedInCrashDateEmpty')}
                  optionalText=''
                  readOnly={!!watch('peopleInvolvedInCrashDateEmpty')}
                />
                <Distance top='5' />

                <TextFieldComponent
                  reactHookFormData={reactHookFormData}
                  fieldRegister='peopleInvolvedInCrashAdress'
                  fieldName='Anschrift'
                  fieldPlaceholder='Musterstr. 100 12345 Berlin'
                  optional={watch('peopleInvolvedInCrashDateEmpty') === true}
                  optionalText=''
                  readOnly={!!watch('peopleInvolvedInCrashDateEmpty')}
                />
                <Distance top='5' />

                <PhoneFieldComponent
                  reactHookFormData={reactHookFormData}
                  fieldRegister='peopleInvolvedInCrashPhone'
                  fieldName='Telefon'
                  optional={true}
                  readOnly={!!watch('peopleInvolvedInCrashDateEmpty')}
                />
                <Distance top='20' />

                <TwoButtonsComponent
                  reactHookFormData={reactHookFormData}
                  fieldName='Wurde diese Person verletzt?'
                  fieldRegister='peopleInvolvedInCrashPeopleDamage'
                  fieldRegisterSecond='peopleInvolvedInCrashPeopleDamage'
                />
                <Distance top='20' />

                <TwoButtonsComponent
                  reactHookFormData={reactHookFormData}
                  fieldName='Ist die Versicherung der beteiligten Person bekannt?'
                  fieldRegister='peopleInvolvedInCrashPeopleInvolvedInsuranse'
                  fieldRegisterSecond='peopleInvolvedInCrashPeopleInvolvedInsuranse'
                />
                <Distance top='20' />

                {!!watch('peopleInvolvedInCrashPeopleInvolvedInsuranse') && (
                  <div style={{ overflow: 'hidden' }}>
                    <CSSTransition
                      in={showBlockCrashInsurance}
                      timeout={400}
                      classNames={slideQuest}
                      mountOnEnter
                      unmountOnExit
                    >
                      <div>
                        <TextFieldComponent
                          reactHookFormData={reactHookFormData}
                          fieldRegister='peopleInvolvedInCrashPeopleInvolvedInsuranseName'
                          fieldName='Versicherungsname'
                          fieldPlaceholder='MusterVersicherung GmbH'
                        />
                        <Distance top='5' />

                        <TextFieldComponent
                          reactHookFormData={reactHookFormData}
                          fieldRegister='peopleInvolvedInCrashPeopleInvolvedInsuranseNumber'
                          fieldName='Versicherungsschein-Nr.'
                          fieldPlaceholder='0000AAA000'
                          optional={true}
                        />
                        <Distance top='20' />
                      </div>
                    </CSSTransition>
                  </div>
                )}

                <TwoButtonsComponent
                  reactHookFormData={reactHookFormData}
                  fieldName='Weicht der Fahrzeughalter vom Fahrzeuglenker ab?'
                  fieldRegister='peopleInvolvedInCrashCarOwnerIsDriver'
                  fieldRegisterSecond='peopleInvolvedInCrashCarOwnerIsDriver'
                />
                <Distance top='20' />

                {!!watch('peopleInvolvedInCrashCarOwnerIsDriver') && (
                  <div style={{ overflow: 'hidden' }}>
                    <CSSTransition
                      in={showBlockCrashCarOwnerIsDriver}
                      timeout={400}
                      classNames={slideQuest}
                      mountOnEnter
                      unmountOnExit
                    >
                      <div>
                        <h6>Daten über der Fahrzeughalter:</h6>

                        <TextFieldComponent
                          reactHookFormData={reactHookFormData}
                          fieldRegister='peopleInvolvedInCrashCarOwnerIsDriverName'
                          fieldName='Name'
                          fieldPlaceholder='Max Mustermann'
                        />
                        <Distance top='5' />

                        <TextFieldComponent
                          reactHookFormData={reactHookFormData}
                          fieldRegister='peopleInvolvedInCrashCarOwnerIsDriverAdress'
                          fieldName='Anschrift'
                          fieldPlaceholder='Musterstr. 100 12345 Berlin'
                          optional={true}
                        />
                        <Distance top='5' />

                        <PhoneFieldComponent
                          reactHookFormData={reactHookFormData}
                          fieldRegister='peopleInvolvedInCrashCarOwnerIsDriverPhone'
                          fieldName='Telefon'
                          optional={true}
                        />
                        <Distance top='20' />
                      </div>
                    </CSSTransition>
                  </div>
                )}

                <TwoChooseButtonsComponent
                  reactHookFormData={reactHookFormData}
                  fieldName='Was wurde beschädigt?'
                  fieldErrorCheck='peopleInvolvedInCrashWhatHasDamage'
                  fieldRegister='peopleInvolvedInCrashWhatHasDamage.Fahrzeug'
                  fieldRegisterSecond='peopleInvolvedInCrashWhatHasDamage.Sonstiges'
                  firstButtonText='Fahrzeug'
                  firstButtonValue='Fahrzeug'
                  secondButtonText='Sonstiges'
                  secondButtonValue='Sonstiges'
                  optional={true}
                  optionalText='(Mehrfachauswahl möglich)'
                />
                <Distance top='10' />

                {watch('peopleInvolvedInCrashWhatHasDamage.Fahrzeug') === 'Fahrzeug' && (
                  <div style={{ overflow: 'hidden' }}>
                    <CSSTransition
                      in={showBlockCrashWhatFahrzeug}
                      timeout={400}
                      classNames={slideQuest}
                      mountOnEnter
                      unmountOnExit
                    >
                      <div>
                        <Distance top='10' />
                        <h6>Daten über das beteiligte Fahrzeug:</h6>

                        <TextFieldComponent
                          reactHookFormData={reactHookFormData}
                          fieldRegister='peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichen'
                          fieldName='Kennzeichen'
                          optional={!!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichenEmpty')}
                          optionalText=''
                          readOnly={!!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichenEmpty')}
                          upperCase={true}
                          fieldPlaceholder='TE-ST 1234'
                        />
                        <Distance top='5' />

                        <div className={styles.checkbox}>
                          <input
                            {...register('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichenEmpty', {
                              required: false
                            })}
                            id='peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichenEmpty'
                            type='checkbox'
                          />
                          <label htmlFor='peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichenEmpty'>
                            Kennzeichen unbekannt
                          </label>
                        </div>
                        <Distance top='15' />

                        <TextFieldComponent
                          reactHookFormData={reactHookFormData}
                          fieldRegister='peopleInvolvedInCrashWhatHasDamageFahrzeugHersteller'
                          fieldName='Hersteller'
                          fieldPlaceholder='BMW'
                          optional={true}
                        />
                        <Distance top='5' />

                        <TextFieldComponent
                          reactHookFormData={reactHookFormData}
                          fieldRegister='peopleInvolvedInCrashWhatHasDamageFahrzeugTyp'
                          fieldName='Typ'
                          fieldPlaceholder='PKW'
                          optional={true}
                        />
                        <Distance top='20' />
                      </div>
                    </CSSTransition>
                  </div>
                )}

                {watch('peopleInvolvedInCrashWhatHasDamage.Sonstiges') === 'Sonstiges' && (
                  <div style={{ overflow: 'hidden' }}>
                    <CSSTransition
                      in={showBlockCrashWhatSonstiges}
                      timeout={400}
                      classNames={slideQuest}
                      mountOnEnter
                      unmountOnExit
                    >
                      <div>
                        <TextareaFieldComponent
                          reactHookFormData={reactHookFormData}
                          fieldRegister='peopleInvolvedInCrashWhatHasDamageSonstigesDamageDescription'
                          fieldName='Sachschaden Beschreibung'
                          needTooltip={true}
                          tooltipText='Unter den Begriff Sachschaden fallen z.B. beschädigte Gartenzäune, Laternen oder andere Gegenstände. Nicht gemeint sind Schäden an Fahrzeugen, die am Unfall beteiligt sind.'
                        />
                        <Distance top='15' />
                      </div>
                    </CSSTransition>
                  </div>
                )}

                <Distance top='10' />
                {watch('peopleInvolvedInCrashWhatHasDamage.Fahrzeug') !== 'Fahrzeug' &&
                  watch('peopleInvolvedInCrashWhatHasDamage.Sonstiges') !== 'Sonstiges' && <Distance top='20' />}

                <TwoButtonsComponent
                  reactHookFormData={reactHookFormData}
                  fieldName='Waren noch weitere Personen und/oder Fahrzeuge am Unfall beteiligt?'
                  fieldRegister='peopleInvolvedInCrashAnotherPeopleInvolved'
                  fieldRegisterSecond='peopleInvolvedInCrashAnotherPeopleInvolved'
                />
                <Distance top='10' />

                {watch('peopleInvolvedInCrashAnotherPeopleInvolved') === true && (
                  <div style={{ overflow: 'hidden' }}>
                    <Distance top='10' />
                    <CSSTransition
                      in={showBlockCrash2}
                      timeout={400}
                      classNames={slideQuest}
                      mountOnEnter
                      unmountOnExit
                    >
                      <div>
                        <h6>Daten über die beteiligte Person:</h6>

                        <div className={styles.checkbox}>
                          <input
                            {...register('peopleInvolvedInCrashDateEmpty2', { required: false })}
                            id='peopleInvolvedInCrashDateEmpty2'
                            type='checkbox'
                          />
                          <label htmlFor='peopleInvolvedInCrashDateEmpty2'>Daten unbekannt</label>
                        </div>
                        <Distance top='15' />

                        <TextFieldComponent
                          reactHookFormData={reactHookFormData}
                          fieldRegister='peopleInvolvedInCrashName2'
                          fieldName='Name'
                          fieldPlaceholder='Max Mustermann'
                          optional={!!watch('peopleInvolvedInCrashDateEmpty2')}
                          optionalText=''
                          readOnly={!!watch('peopleInvolvedInCrashDateEmpty2')}
                        />
                        <Distance top='5' />

                        <TextFieldComponent
                          reactHookFormData={reactHookFormData}
                          fieldRegister='peopleInvolvedInCrashAdress2'
                          fieldName='Anschrift'
                          fieldPlaceholder='Musterstr. 100 12345 Berlin'
                          optional={!!watch('peopleInvolvedInCrashDateEmpty2')}
                          optionalText=''
                          readOnly={!!watch('peopleInvolvedInCrashDateEmpty2')}
                        />
                        <Distance top='5' />

                        <PhoneFieldComponent
                          reactHookFormData={reactHookFormData}
                          fieldRegister='peopleInvolvedInCrashPhone2'
                          fieldName='Telefon'
                          optional={true}
                          readOnly={!!watch('peopleInvolvedInCrashDateEmpty2')}
                        />
                        <Distance top='20' />

                        <TwoButtonsComponent
                          reactHookFormData={reactHookFormData}
                          fieldName='Wurde diese Person verletzt?'
                          fieldRegister='peopleInvolvedInCrashPeopleDamage2'
                          fieldRegisterSecond='peopleInvolvedInCrashPeopleDamage2'
                        />
                        <Distance top='20' />

                        <TwoButtonsComponent
                          reactHookFormData={reactHookFormData}
                          fieldName='Ist die Versicherung der beteiligten Person bekannt?'
                          fieldRegister='peopleInvolvedInCrashPeopleInvolvedInsuranse2'
                          fieldRegisterSecond='peopleInvolvedInCrashPeopleInvolvedInsuranse2'
                        />
                        <Distance top='20' />

                        {!!watch('peopleInvolvedInCrashPeopleInvolvedInsuranse2') && (
                          <div style={{ overflow: 'hidden' }}>
                            <CSSTransition
                              in={showBlockCrashInsurance2}
                              timeout={400}
                              classNames={slideQuest}
                              mountOnEnter
                              unmountOnExit
                            >
                              <div>
                                <TextFieldComponent
                                  reactHookFormData={reactHookFormData}
                                  fieldRegister='peopleInvolvedInCrashPeopleInvolvedInsuranseName2'
                                  fieldName='Versicherungsname'
                                  fieldPlaceholder='MusterVersicherung GmbH'
                                />
                                <Distance top='5' />

                                <TextFieldComponent
                                  reactHookFormData={reactHookFormData}
                                  fieldRegister='peopleInvolvedInCrashPeopleInvolvedInsuranseNumber2'
                                  fieldName='Versicherungsschein-Nr.'
                                  fieldPlaceholder='0000AAA000'
                                  optional={true}
                                />
                                <Distance top='20' />
                              </div>
                            </CSSTransition>
                          </div>
                        )}

                        <TwoButtonsComponent
                          reactHookFormData={reactHookFormData}
                          fieldName='Weicht der Fahrzeughalter vom Fahrzeuglenker ab?'
                          fieldRegister='peopleInvolvedInCrashCarOwnerIsDriver2'
                          fieldRegisterSecond='peopleInvolvedInCrashCarOwnerIsDriver2'
                        />
                        <Distance top='20' />

                        {!!watch('peopleInvolvedInCrashCarOwnerIsDriver2') && (
                          <div style={{ overflow: 'hidden' }}>
                            <CSSTransition
                              in={showBlockCrashCarOwnerIsDriver2}
                              timeout={400}
                              classNames={slideQuest}
                              mountOnEnter
                              unmountOnExit
                            >
                              <div>
                                <h6>Daten über der Fahrzeughalter:</h6>

                                <TextFieldComponent
                                  reactHookFormData={reactHookFormData}
                                  fieldRegister='peopleInvolvedInCrashCarOwnerIsDriverName2'
                                  fieldName='Name'
                                  fieldPlaceholder='Max Mustermann'
                                />
                                <Distance top='5' />

                                <TextFieldComponent
                                  reactHookFormData={reactHookFormData}
                                  fieldRegister='peopleInvolvedInCrashCarOwnerIsDriverAdress2'
                                  fieldName='Anschrift'
                                  fieldPlaceholder='Musterstr. 100 12345 Berlin'
                                  optional={true}
                                />
                                <Distance top='5' />

                                <PhoneFieldComponent
                                  reactHookFormData={reactHookFormData}
                                  fieldRegister='peopleInvolvedInCrashCarOwnerIsDriverPhone2'
                                  fieldName='Telefon'
                                  optional={true}
                                />
                                <Distance top='20' />
                              </div>
                            </CSSTransition>
                          </div>
                        )}

                        <TwoChooseButtonsComponent
                          reactHookFormData={reactHookFormData}
                          fieldName='Was wurde beschädigt?'
                          fieldErrorCheck='peopleInvolvedInCrashWhatHasDamage2'
                          fieldRegister='peopleInvolvedInCrashWhatHasDamage.Fahrzeug2'
                          fieldRegisterSecond='peopleInvolvedInCrashWhatHasDamage.Sonstiges2'
                          firstButtonText='Fahrzeug'
                          firstButtonValue='Fahrzeug'
                          secondButtonText='Sonstiges'
                          secondButtonValue='Sonstiges'
                          optional={true}
                          optionalText='(Mehrfachauswahl möglich)'
                        />
                        <Distance top='20' />

                        {watch('peopleInvolvedInCrashWhatHasDamage.Fahrzeug2') === 'Fahrzeug' && (
                          <div style={{ overflow: 'hidden' }}>
                            <CSSTransition
                              in={showBlockCrashWhatFahrzeug2}
                              timeout={400}
                              classNames={slideQuest}
                              mountOnEnter
                              unmountOnExit
                            >
                              <div>
                                <h6>Daten über das beteiligte Fahrzeug:</h6>

                                <TextFieldComponent
                                  reactHookFormData={reactHookFormData}
                                  fieldRegister='peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichen2'
                                  optional={!!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichenEmpty2')}
                                  optionalText=''
                                  readOnly={!!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichenEmpty2')}
                                  fieldName='Kennzeichen'
                                  upperCase={true}
                                  fieldPlaceholder='TE-ST 1234'
                                />
                                <Distance top='5' />

                                <div className={styles.checkbox}>
                                  <input
                                    {...register('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichenEmpty2', {
                                      required: false
                                    })}
                                    id='peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichenEmpty2'
                                    type='checkbox'
                                  />
                                  <label htmlFor='peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichenEmpty2'>
                                    Kennzeichen unbekannt
                                  </label>
                                </div>
                                <Distance top='15' />

                                <TextFieldComponent
                                  reactHookFormData={reactHookFormData}
                                  fieldRegister='peopleInvolvedInCrashWhatHasDamageFahrzeugHersteller2'
                                  fieldName='Hersteller'
                                  fieldPlaceholder='BMW'
                                  optional={true}
                                />
                                <Distance top='5' />

                                <TextFieldComponent
                                  reactHookFormData={reactHookFormData}
                                  fieldRegister='peopleInvolvedInCrashWhatHasDamageFahrzeugTyp2'
                                  fieldName='Typ'
                                  fieldPlaceholder='PKW'
                                  optional={true}
                                />
                                <Distance top='20' />
                              </div>
                            </CSSTransition>
                          </div>
                        )}

                        {watch('peopleInvolvedInCrashWhatHasDamage.Sonstiges2') === 'Sonstiges' && (
                          <div style={{ overflow: 'hidden' }}>
                            <CSSTransition
                              in={showBlockCrashWhatSonstiges2}
                              timeout={400}
                              classNames={slideQuest}
                              mountOnEnter
                              unmountOnExit
                            >
                              <div>
                                <TextareaFieldComponent
                                  reactHookFormData={reactHookFormData}
                                  fieldRegister='peopleInvolvedInCrashWhatHasDamageSonstigesDamageDescription2'
                                  fieldName='Sachschaden Beschreibung'
                                  needTooltip={true}
                                  tooltipText='Unter den Begriff Sachschaden fallen z.B. beschädigte Gartenzäune, Laternen oder andere Gegenstände. Nicht gemeint sind Schäden an Fahrzeugen, die am Unfall beteiligt sind.'
                                />
                                <Distance top='15' />
                              </div>
                            </CSSTransition>
                          </div>
                        )}

                        <TwoButtonsComponent
                          reactHookFormData={reactHookFormData}
                          fieldName='Waren noch weitere Personen und/oder Fahrzeuge am Unfall beteiligt?'
                          fieldRegister='peopleInvolvedInCrashAnotherPeopleInvolved2'
                          fieldRegisterSecond='peopleInvolvedInCrashAnotherPeopleInvolved2'
                        />
                        <Distance top='10' />

                        {watch('peopleInvolvedInCrashAnotherPeopleInvolved2') === true && (
                          <div style={{ overflow: 'hidden' }}>
                            <Distance top='10' />
                            <CSSTransition
                              in={showBlockCrash3}
                              timeout={400}
                              classNames={slideQuest}
                              mountOnEnter
                              unmountOnExit
                            >
                              <div>
                                <h6>Daten über die beteiligte Person:</h6>

                                <div className={styles.checkbox}>
                                  <input
                                    {...register('peopleInvolvedInCrashDateEmpty3', { required: false })}
                                    id='peopleInvolvedInCrashDateEmpty3'
                                    type='checkbox'
                                  />
                                  <label htmlFor='peopleInvolvedInCrashDateEmpty3'>Daten unbekannt</label>
                                </div>
                                <Distance top='15' />

                                <TextFieldComponent
                                  reactHookFormData={reactHookFormData}
                                  fieldRegister='peopleInvolvedInCrashName3'
                                  fieldName='Name'
                                  fieldPlaceholder='Max Mustermann'
                                  optional={!!watch('peopleInvolvedInCrashDateEmpty3')}
                                  optionalText=''
                                  readOnly={!!watch('peopleInvolvedInCrashDateEmpty3')}
                                />
                                <Distance top='5' />

                                <TextFieldComponent
                                  reactHookFormData={reactHookFormData}
                                  fieldRegister='peopleInvolvedInCrashAdress3'
                                  fieldName='Anschrift'
                                  fieldPlaceholder='Musterstr. 100 12345 Berlin'
                                  optional={!!watch('peopleInvolvedInCrashDateEmpty3')}
                                  optionalText=''
                                  readOnly={!!watch('peopleInvolvedInCrashDateEmpty3')}
                                />
                                <Distance top='5' />

                                <PhoneFieldComponent
                                  reactHookFormData={reactHookFormData}
                                  fieldRegister='peopleInvolvedInCrashPhone3'
                                  fieldName='Telefon'
                                  optional={true}
                                  readOnly={!!watch('peopleInvolvedInCrashDateEmpty3')}
                                />
                                <Distance top='20' />

                                <TwoButtonsComponent
                                  reactHookFormData={reactHookFormData}
                                  fieldName='Wurde diese Person verletzt?'
                                  fieldRegister='peopleInvolvedInCrashPeopleDamage3'
                                  fieldRegisterSecond='peopleInvolvedInCrashPeopleDamage3'
                                />
                                <Distance top='20' />

                                <TwoButtonsComponent
                                  reactHookFormData={reactHookFormData}
                                  fieldName='Ist die Versicherung der beteiligten Person bekannt?'
                                  fieldRegister='peopleInvolvedInCrashPeopleInvolvedInsuranse3'
                                  fieldRegisterSecond='peopleInvolvedInCrashPeopleInvolvedInsuranse3'
                                />
                                <Distance top='20' />

                                {!!watch('peopleInvolvedInCrashPeopleInvolvedInsuranse3') && (
                                  <div style={{ overflow: 'hidden' }}>
                                    <CSSTransition
                                      in={showBlockCrashInsurance3}
                                      timeout={400}
                                      classNames={slideQuest}
                                      mountOnEnter
                                      unmountOnExit
                                    >
                                      <div>
                                        <TextFieldComponent
                                          reactHookFormData={reactHookFormData}
                                          fieldRegister='peopleInvolvedInCrashPeopleInvolvedInsuranseName3'
                                          fieldName='Versicherungsname'
                                          fieldPlaceholder='MusterVersicherung GmbH'
                                        />
                                        <Distance top='5' />

                                        <TextFieldComponent
                                          reactHookFormData={reactHookFormData}
                                          fieldRegister='peopleInvolvedInCrashPeopleInvolvedInsuranseNumber3'
                                          fieldName='Versicherungsschein-Nr.'
                                          fieldPlaceholder='0000AAA000'
                                          optional={true}
                                        />
                                        <Distance top='20' />
                                      </div>
                                    </CSSTransition>
                                  </div>
                                )}

                                <TwoButtonsComponent
                                  reactHookFormData={reactHookFormData}
                                  fieldName='Weicht der Fahrzeughalter vom Fahrzeuglenker ab?'
                                  fieldRegister='peopleInvolvedInCrashCarOwnerIsDriver3'
                                  fieldRegisterSecond='peopleInvolvedInCrashCarOwnerIsDriver3'
                                />
                                <Distance top='20' />

                                {!!watch('peopleInvolvedInCrashCarOwnerIsDriver3') && (
                                  <div style={{ overflow: 'hidden' }}>
                                    <CSSTransition
                                      in={showBlockCrashCarOwnerIsDriver3}
                                      timeout={400}
                                      classNames={slideQuest}
                                      mountOnEnter
                                      unmountOnExit
                                    >
                                      <div>
                                        <h6>Daten über der Fahrzeughalter:</h6>

                                        <TextFieldComponent
                                          reactHookFormData={reactHookFormData}
                                          fieldRegister='peopleInvolvedInCrashCarOwnerIsDriverName3'
                                          fieldName='Name'
                                          fieldPlaceholder='Max Mustermann'
                                        />
                                        <Distance top='5' />

                                        <TextFieldComponent
                                          reactHookFormData={reactHookFormData}
                                          fieldRegister='peopleInvolvedInCrashCarOwnerIsDriverAdress3'
                                          fieldName='Anschrift'
                                          fieldPlaceholder='Musterstr. 100 12345 Berlin'
                                          optional={true}
                                        />
                                        <Distance top='5' />

                                        <PhoneFieldComponent
                                          reactHookFormData={reactHookFormData}
                                          fieldRegister='peopleInvolvedInCrashCarOwnerIsDriverPhone3'
                                          fieldName='Telefon'
                                          optional={true}
                                        />
                                        <Distance top='20' />
                                      </div>
                                    </CSSTransition>
                                  </div>
                                )}

                                <TwoChooseButtonsComponent
                                  reactHookFormData={reactHookFormData}
                                  fieldName='Was wurde beschädigt?'
                                  fieldErrorCheck='peopleInvolvedInCrashWhatHasDamage3'
                                  fieldRegister='peopleInvolvedInCrashWhatHasDamage.Fahrzeug3'
                                  fieldRegisterSecond='peopleInvolvedInCrashWhatHasDamage.Sonstiges3'
                                  firstButtonText='Fahrzeug'
                                  firstButtonValue='Fahrzeug'
                                  secondButtonText='Sonstiges'
                                  secondButtonValue='Sonstiges'
                                  optional={true}
                                  optionalText='(Mehrfachauswahl möglich)'
                                />
                                <Distance top='20' />

                                {watch('peopleInvolvedInCrashWhatHasDamage.Fahrzeug3') === 'Fahrzeug' && (
                                  <div style={{ overflow: 'hidden' }}>
                                    <CSSTransition
                                      in={showBlockCrashWhatFahrzeug3}
                                      timeout={400}
                                      classNames={slideQuest}
                                      mountOnEnter
                                      unmountOnExit
                                    >
                                      <div>
                                        <h6>Daten über das beteiligte Fahrzeug:</h6>

                                        <TextFieldComponent
                                          reactHookFormData={reactHookFormData}
                                          fieldRegister='peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichen3'
                                          optional={
                                            !!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichenEmpty3')
                                          }
                                          optionalText=''
                                          readOnly={
                                            !!watch('peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichenEmpty3')
                                          }
                                          fieldName='Kennzeichen'
                                          upperCase={true}
                                          fieldPlaceholder='TE-ST 1234'
                                        />
                                        <Distance top='5' />

                                        <div className={styles.checkbox}>
                                          <input
                                            {...register(
                                              'peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichenEmpty3',
                                              {
                                                required: false
                                              }
                                            )}
                                            id='peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichenEmpty3'
                                            type='checkbox'
                                          />
                                          <label htmlFor='peopleInvolvedInCrashWhatHasDamageFahrzeugKennzeichenEmpty3'>
                                            Kennzeichen unbekannt
                                          </label>
                                        </div>
                                        <Distance top='15' />

                                        <TextFieldComponent
                                          reactHookFormData={reactHookFormData}
                                          fieldRegister='peopleInvolvedInCrashWhatHasDamageFahrzeugHersteller3'
                                          fieldName='Hersteller'
                                          fieldPlaceholder='BMW'
                                          optional={true}
                                        />
                                        <Distance top='5' />

                                        <TextFieldComponent
                                          reactHookFormData={reactHookFormData}
                                          fieldRegister='peopleInvolvedInCrashWhatHasDamageFahrzeugTyp3'
                                          fieldName='Typ'
                                          fieldPlaceholder='PKW'
                                          optional={true}
                                        />
                                        <Distance top='20' />
                                      </div>
                                    </CSSTransition>
                                  </div>
                                )}

                                {watch('peopleInvolvedInCrashWhatHasDamage.Sonstiges3') === 'Sonstiges' && (
                                  <div style={{ overflow: 'hidden' }}>
                                    <CSSTransition
                                      in={showBlockCrashWhatSonstiges3}
                                      timeout={400}
                                      classNames={slideQuest}
                                      mountOnEnter
                                      unmountOnExit
                                    >
                                      <div>
                                        <TextareaFieldComponent
                                          reactHookFormData={reactHookFormData}
                                          fieldRegister='peopleInvolvedInCrashWhatHasDamageSonstigesDamageDescription3'
                                          fieldName='Sachschaden Beschreibung'
                                          needTooltip={true}
                                          tooltipText='Unter den Begriff Sachschaden fallen z.B. beschädigte Gartenzäune, Laternen oder andere Gegenstände. Nicht gemeint sind Schäden an Fahrzeugen, die am Unfall beteiligt sind.'
                                        />
                                        <Distance top='15' />
                                      </div>
                                    </CSSTransition>
                                  </div>
                                )}
                              </div>
                            </CSSTransition>
                          </div>
                        )}
                      </div>
                    </CSSTransition>
                  </div>
                )}
                <Distance top='10' />
              </div>
            </CSSTransition>
          </div>
        )}

        {watch('peopleInvolvedInCrashAnotherPeopleInvolved2') === true && <Distance top='0' />}
        {!!watch('peopleInvolvedInCrash') &&
          !watch('peopleInvolvedInCrashAnotherPeopleInvolved2') &&
          !!watch('peopleInvolvedInCrashAnotherPeopleInvolved') && <Distance top='10' />}
        {!!watch('peopleInvolvedInCrash') && !watch('peopleInvolvedInCrashAnotherPeopleInvolved') && (
          <Distance top='20' />
        )}
        {!watch('peopleInvolvedInCrash') && <Distance top='20' />}

        <TwoButtonsComponent
          reactHookFormData={reactHookFormData}
          fieldName='Sind dir Zeugen bekannt?'
          fieldRegister='anyWitnesses'
          fieldRegisterSecond='anyWitnesses'
        />

        {watch('anyWitnesses') === true && (
          <div style={{ overflow: 'hidden' }}>
            <CSSTransition in={showBlockWitnesses} timeout={400} classNames={slideQuest} mountOnEnter unmountOnExit>
              <div>
                <Distance top='20' />
                <h6>Daten der Zeuge:</h6>

                <div
                  className={styles.checkbox}
                  onClick={() =>
                    clearErrors(['anyWitnessesName', 'anyWitnessesAdress', 'anyWitnessesEmail', 'anyWitnessesPhone'])
                  }
                >
                  <input
                    {...register('anyWitnessesDateEmpty', { required: false })}
                    id='anyWitnessesDateEmpty'
                    type='checkbox'
                  />
                  <label htmlFor='anyWitnessesDateEmpty'>Daten unbekannt</label>
                </div>
                <Distance top='15' />

                <TextFieldComponent
                  reactHookFormData={reactHookFormData}
                  fieldRegister='anyWitnessesName'
                  fieldName='Name'
                  fieldPlaceholder='Max Mustermann'
                  optional={!!watch('anyWitnessesDateEmpty')}
                  optionalText=''
                  readOnly={!!watch('anyWitnessesDateEmpty')}
                />
                <Distance top='5' />

                <TextFieldComponent
                  reactHookFormData={reactHookFormData}
                  fieldRegister='anyWitnessesAdress'
                  fieldName='Anschrift'
                  fieldPlaceholder='Musterstr. 100 12345 Berlin'
                  optional={!!watch('anyWitnessesDateEmpty')}
                  optionalText=''
                  readOnly={!!watch('anyWitnessesDateEmpty')}
                />
                <Distance top='5' />

                <EmailFieldComponent
                  reactHookFormData={reactHookFormData}
                  fieldRegister='anyWitnessesEmail'
                  fieldName='E-mail adresse'
                  fieldPlaceholder='Muster@mail.com'
                  optional={!!watch('anyWitnessesDateEmpty')}
                  optionalText=''
                  readOnly={!!watch('anyWitnessesDateEmpty')}
                />
                <Distance top='5' />

                <PhoneFieldComponent
                  reactHookFormData={reactHookFormData}
                  fieldRegister='anyWitnessesPhone'
                  fieldName='Telefon'
                  optional={!!watch('anyWitnessesDateEmpty')}
                  optionalText=''
                  readOnly={!!watch('anyWitnessesDateEmpty')}
                />
                <Distance top='10' />

                <div
                  style={{ width: '100%' }}
                  className={watch('showSecondPersonAnyWitnesses') ? '' : styles.hide__block}
                >
                  <Distance top='15' />

                  <div className={styles.close_header}>
                    <h6>Daten der zweite Zeuge:</h6>
                    <div
                      className={styles.close_block}
                      onClick={() => {
                        setValue('showSecondPersonAnyWitnesses', false);
                        clearErrors([
                          'anyWitnessesNameSecond',
                          'anyWitnessesAdressSecond',
                          'anyWitnessesEmailSecond',
                          'anyWitnessesPhoneSecond'
                        ]);
                      }}
                    >
                      <TrashIcon />
                    </div>
                  </div>

                  <div
                    className={styles.checkbox}
                    onClick={() =>
                      clearErrors([
                        'anyWitnessesNameSecond',
                        'anyWitnessesAdressSecond',
                        'anyWitnessesEmailSecond',
                        'anyWitnessesPhoneSecond'
                      ])
                    }
                  >
                    <input
                      {...register('anyWitnessesDateSecondEmpty', { required: false })}
                      id='anyWitnessesDateSecondEmpty'
                      type='checkbox'
                    />
                    <label htmlFor='anyWitnessesDateSecondEmpty'>Daten unbekannt</label>
                  </div>
                  <Distance top='15' />

                  <TextFieldComponent
                    reactHookFormData={reactHookFormData}
                    fieldRegister='anyWitnessesNameSecond'
                    fieldName='Name'
                    fieldPlaceholder='Max Mustermann'
                    optional={!watch('showSecondPersonAnyWitnesses') || !!watch('anyWitnessesDateSecondEmpty')}
                    optionalText=''
                    readOnly={!!watch('anyWitnessesDateSecondEmpty')}
                  />
                  <Distance top='5' />

                  <TextFieldComponent
                    reactHookFormData={reactHookFormData}
                    fieldRegister='anyWitnessesAdressSecond'
                    fieldName='Anschrift'
                    fieldPlaceholder='Musterstr. 100 12345 Berlin'
                    optional={!watch('showSecondPersonAnyWitnesses') || !!watch('anyWitnessesDateSecondEmpty')}
                    optionalText=''
                    readOnly={!!watch('anyWitnessesDateSecondEmpty')}
                  />
                  <Distance top='5' />

                  <EmailFieldComponent
                    reactHookFormData={reactHookFormData}
                    fieldRegister='anyWitnessesEmailSecond'
                    fieldName='E-mail adresse'
                    fieldPlaceholder='Muster@mail.com'
                    optional={!watch('showSecondPersonAnyWitnesses') || !!watch('anyWitnessesDateSecondEmpty')}
                    optionalText=''
                    readOnly={!!watch('anyWitnessesDateSecondEmpty')}
                  />
                  <Distance top='5' />

                  <PhoneFieldComponent
                    reactHookFormData={reactHookFormData}
                    fieldRegister='anyWitnessesPhoneSecond'
                    fieldName='Telefon'
                    optional={!watch('showSecondPersonAnyWitnesses') || !!watch('anyWitnessesDateSecondEmpty')}
                    optionalText=''
                    readOnly={!!watch('anyWitnessesDateSecondEmpty')}
                  />
                  <Distance top='10' />
                </div>

                <div
                  id='showSecondPersonAnyWitnessesButton'
                  style={{ width: '100%' }}
                  onClick={() => setValue('showSecondPersonAnyWitnesses', true)}
                  className={watch('showSecondPersonAnyWitnesses') ? styles.hide__block : styles.hide__block__show}
                >
                  <Distance top='-10' />+ Weitere Person
                </div>

                <Distance top='10' />
              </div>
            </CSSTransition>
          </div>
        )}

        <Distance top='20' />
        {showBlock && (
          <div className={styles.row_columns}>
            <div className={watch('previousStep') === 'detail' ? styles.button_hide : styles.half_input}>
              <button id='moveToPreviousStepButton' onClick={moveToPreviousStep} className={styles.button_back}>
                Zurück
              </button>
            </div>
            <div className={watch('previousStep') === 'detail' ? styles.full_input : styles.half_input}>
              <button
                id='moveToNextStepButton'
                onClick={moveToNextStep}
                className={buttonActive ? styles.button_active : styles.button_wait}
              >
                {watch('previousStep') === 'detail' ? 'Änderungen speichern' : 'Weiter'}
              </button>
            </div>
          </div>
        )}
      </section>
    </CSSTransition>
  );
};

export default ParkingStepSix;
