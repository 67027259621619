import styled, { css } from 'styled-components';
import { Flex } from 'common/common-components.styled';

interface StyledProgressStepProps {
  pointer: boolean;
  whiteSrace?: string;
}

export const StyledProgressStep = styled(Flex)<StyledProgressStepProps>`
  cursor: ${({ pointer }) => (pointer ? 'pointer' : 'not-allowed')};

  ${({ whiteSrace }) =>
    whiteSrace &&
    css`
      white-space: ${whiteSrace};
    `};
`;
