import React, {FunctionComponent , ReactElement} from 'react';
import {StyledModal, LoadingMessage, LoadingContainer} from './damage-form-modal-styled';
import {getDamageReportSavingContext} from '../damage-report-saving-context/damage-report-saving-context';
import Spinner from 'components/spinner';

export const DamageFormModal:FunctionComponent = ():ReactElement=>{
    const {loading , loadingMessage} = getDamageReportSavingContext();

    return (
        <StyledModal open={loading}>
            <LoadingContainer>
               <Spinner />
               <LoadingMessage>{loadingMessage}</LoadingMessage>
            </LoadingContainer>
        </StyledModal>
    )
};