import React, { FunctionComponent, ReactElement} from 'react';
import {ButtonProps} from '../interfaces';
import {email} from '../constants';
import classes from '../menu-buttons.module.scss';
import classNames from 'classnames';

export const EmailButton: FunctionComponent<ButtonProps>= ({children}:ButtonProps):ReactElement=>{
    return (
    <button className={classNames(classes.button_view)} onClick={()=> (window.location.href = `mailto:${email}`)}>
       {children}
    </button>)
};