import React from 'react';
import { Flex, Text } from 'common/common-components.styled';
import { useFormContext } from 'react-hook-form';
import FormHook from 'components/form-hook/form-hook';
import { useFillLevelsStep } from './use-mileage-step';

const MileageStep = () => {
  const reactHookFormData = useFormContext();
  const { mileageField, treadDepthField } = useFillLevelsStep();

  return (
    <Flex width='100%' direction='column'>
      <div>
        <Text size='16' color='darkBlue'>
          Laufleistung
        </Text>
      </div>

      <Flex gap='10px' width='100%' top='30' bottom='24'>
        <FormHook reactHookFormData={reactHookFormData} formFields={mileageField} distanceBetweenFields='0' />
        <FormHook reactHookFormData={reactHookFormData} formFields={treadDepthField} distanceBetweenFields='0' />
      </Flex>
    </Flex>
  );
};

export default MileageStep;
