import useAlert from 'hooks/use-alert';
import { AlertData } from 'components/alert/alert-view';
import React from 'react';
import { useFetch } from 'hooks/use-fetch/use-fetch';
import RequestedVehicles from '../requested-vehicles';
import { vehicleRequestConfig } from 'request-config/vehicle/vehicle.request-config';
import { RequestedVehicle } from '../requested-vehicles/requested-vehicles.types';

const useRequestedVehiclesModal = (driverId: string) => {
  const { openAlert, closeAlert } = useAlert();
  const { fetch: checkRequestedVehicles } = useFetch({
    loadingKey: 'checkRequestedVehicles',
    isShowErrorSnackbar: false,
    ...vehicleRequestConfig.getRequestedPersonalConfig(driverId)
  });

  const openRequestedVehiclesModal = (requestedVehicles: RequestedVehicle[], callback: () => void) => {
    const alertData: AlertData = {
      title: 'Fahrzeug anfordern',
      children: <RequestedVehicles requestedVehicles={requestedVehicles} />,
      buttons: [
        {
          type: 'cancel',
          title: 'Abbrechen',
          action: closeAlert
        },
        {
          type: 'submit',
          title: 'Trotzdem anfordern',
          action: () => {
            closeAlert();
            callback();
          }
        }
      ]
    };
    openAlert(alertData);
  };

  return { openRequestedVehiclesModal, checkRequestedVehicles };
};

export default useRequestedVehiclesModal;
