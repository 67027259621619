import { useContext, useEffect, useMemo, useState } from 'react';
import { FormFieldTypes } from 'common/enums';
import { Direction } from 'common/common-components.styled';
import { errorMessages } from 'utils/error-messages';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { DamageFormSection, DamagePart, GroupedItems } from './use-condition-step.types';
import { cardDamageOptions } from 'components/form-hook/form-field/components/car-damage/car-damage-option-set/form-field';
import { cloneDeep } from 'lodash';
import { ChecklistModalContext } from 'components/checklist-modal/checklist-modal-context';

const OTHER_PARTS_TYPE_CODE = 100000041;

enum FieldNames {
  InteriorCondition = 'uds_condition_condition',
  SuspensionCondition = 'uds_condition_fittings',
  UpholsteryCondition = 'uds_condition_upholstery'
}

const getPartForms = (part: DamagePart, index: number) => {
  return {
    title: part.name,
    value: part.value,
    index: part.index,
    damageTypeField: [
      {
        name: `${part.name}DamageType${index}`,
        type: FormFieldTypes.OptionSet,
        label: 'Art des Schadens',
        options: cardDamageOptions,
        validation: {
          required: errorMessages.required
        }
      }
    ],
    commentField: [
      {
        name: `${part.name}Comment${index}`,
        type: FormFieldTypes.Text,
        placeholder: 'Bitte eintragen',
        label: 'Hinweis',
        validation: {
          maxLength: {
            value: 1000,
            message: errorMessages.getMaxTextLength(1000)
          }
        }
      }
    ],
    fileField: [
      {
        name: `${part.name}Files${index}`,
        type: FormFieldTypes.File,
        defaultValue: [],
        dropzoneOptions: {
          accept: ['.png', '.jpg', '.jpeg', '.heic'],
          maxSize: 1024 * 1024 * 5,
          multiple: true,
          maxFiles: 5
        },
        validation: {}
      }
    ]
  };
};

export const useConditionStep = (reactHookFormData: UseFormReturn<FieldValues, any>) => {
  const { setDamagedSections } = useContext(ChecklistModalContext);
  const { watch, setValue } = reactHookFormData;
  const [damagedPartsSections, setDamagedPartsSections] = useState<DamageFormSection[][]>([]);

  const interiorConditionField = useMemo(() => {
    return [
      {
        name: FieldNames.InteriorCondition,
        type: FormFieldTypes.OptionSet,
        label: 'Zustand Ihnen',
        options: [
          { label: 'Stark verschmutzt', value: 1 },
          { label: 'Leicht verschmutzt', value: 2 },
          { label: 'Sauber', value: 3 }
        ],
        validation: {
          required: errorMessages.required
        }
      }
    ];
  }, []);

  const suspensionConditionField = useMemo(() => {
    return [
      {
        name: FieldNames.SuspensionCondition,
        type: FormFieldTypes.Text,
        placeholder: 'Bitte eintragen',
        label: 'Zustand Armaturen',
        validation: {
          maxLength: {
            value: 250,
            message: errorMessages.getMaxTextLength(250)
          }
        }
      }
    ];
  }, []);

  const upholsteryConditionField = useMemo(() => {
    return [
      {
        name: FieldNames.UpholsteryCondition,
        type: FormFieldTypes.Text,
        placeholder: 'Bitte eintragen',
        label: 'Zustand Polster',
        width: 'calc(50% - 5px)',
        validation: {
          maxLength: {
            value: 250,
            message: errorMessages.getMaxTextLength(250)
          }
        }
      }
    ];
  }, []);

  const carDamageField = useMemo(() => {
    return [
      {
        name: '',
        defaultValue: '',
        type: FormFieldTypes.CarDamage,
        damageIconSettings: {
          hideCarDamageOptionSet: true,
          damageIconDirection: Direction.Bottom,
          damageIconSize: {
            width: 400,
            height: 400
          },
          disableOtherPartsButton: watch('damageList')?.other?.length
        },
        validation: {}
      }
    ];
  }, [watch('damageList')]);

  const removeDamagedPart = (partValue: number, index: number, sectionIndex: number) => {
    const parts = watch('damageList') || {};
    const damagedSections = cloneDeep(damagedPartsSections);
    const isLastSectionItem = damagedSections[sectionIndex].length === 1;

    if (isLastSectionItem) {
      const filteredParts = Object.keys(parts)
        .filter(key => parts[key].value !== partValue)
        .reduce((result, key) => {
          result[key] = parts[key];

          return result;
        }, {} as FieldValues);

      const filteredSections = damagedSections.filter(section => section[0].value !== partValue);

      if (partValue === OTHER_PARTS_TYPE_CODE) {
        delete filteredParts.other;
      }

      setDamagedPartsSections(filteredSections);
      setValue('damageList', filteredParts);
    } else {
      const values = watch();
      const idArray = [
        damagedSections[sectionIndex][index].commentField[0].name,
        damagedSections[sectionIndex][index].damageTypeField[0].name,
        damagedSections[sectionIndex][index].fileField[0].name,
        'damageList'
      ];

      damagedSections[sectionIndex].splice(index, 1);
      setDamagedPartsSections(damagedSections);

      setTimeout(() => {
        for (const [key, value] of Object.entries(values)) {
          if (idArray.includes(key)) {
            continue;
          }

          setValue(key, value);
        }
      });
    }
  };

  const addAdditionalSection = (sectionIndex: number) => {
    const damagedSections = cloneDeep(damagedPartsSections);
    const updateedSection = damagedSections[sectionIndex];
    const index = updateedSection.length + 1;

    updateedSection.push({
      title: updateedSection[0]?.title,
      value: updateedSection[0]?.value,
      damageTypeField: [
        {
          name: `${updateedSection[0]?.title}DamageType${index}`,
          type: FormFieldTypes.OptionSet,
          label: 'Art des Schadens',
          options: cardDamageOptions,
          validation: {
            required: errorMessages.required
          }
        }
      ],
      commentField: [
        {
          name: `${updateedSection[0]?.title}Comment${index}`,
          type: FormFieldTypes.Text,
          placeholder: 'Bitte eintragen',
          label: 'Hinweis',
          validation: {
            maxLength: {
              value: 1000,
              message: errorMessages.getMaxTextLength(1000)
            }
          }
        }
      ],
      fileField: [
        {
          name: `${updateedSection[0]?.title}Files${index}`,
          type: FormFieldTypes.File,
          defaultValue: [],
          dropzoneOptions: {
            accept: ['.png', '.jpg', '.jpeg', '.heic'],
            maxSize: 1024 * 1024 * 5,
            multiple: true,
            maxFiles: 5
          },
          validation: {}
        }
      ]
    });

    setDamagedPartsSections(damagedSections);
  };

  useEffect(() => {
    const parts: DamagePart[] = Object.values(watch('damageList') || {});

    const damagedPartsForms: DamageFormSection[] = parts.flat().map((part, index) => {
      return getPartForms(part, damagedPartsSections?.[index]?.length ? damagedPartsSections[index].length + 1 : 1);
    });

    const groupedParts = damagedPartsForms.reduce((acc, curr) => {
      if (!acc[curr.value]) {
        acc[curr.value] = [];
      }

      acc[curr.value].unshift(curr);

      return acc;
    }, {} as GroupedItems);

    const existingSectionsValues = damagedPartsSections.map(section => section[0]?.value);

    const addingSection = Object.values(groupedParts).filter(
      section => !existingSectionsValues.includes(section[0].value)
    );

    const damagedSections = cloneDeep(damagedPartsSections);

    const values = watch();
    const idArray = damagedSections.flat().map(section => {
      return {
        comment: section.commentField[0].name,
        damageType: section.damageTypeField[0].name,
        file: section.fileField[0].name
      };
    });

    if (addingSection.length) {
      damagedSections.unshift(addingSection.flat());
    }

    setDamagedPartsSections(damagedSections);

    setTimeout(() => {
      idArray.forEach(section => {
        setValue(section.comment, values[section.comment]);
        setValue(section.damageType, values[section.damageType]);
        setValue(section.file, values[section.file]);
      });
    });
  }, [watch('damageList')]);

  useEffect(() => {
    setDamagedSections(damagedPartsSections);
  }, [damagedPartsSections]);

  return {
    interiorConditionField,
    suspensionConditionField,
    upholsteryConditionField,
    carDamageField,
    damagedPartsSections,
    removeDamagedPart,
    addAdditionalSection
  };
};
