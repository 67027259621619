import React, { useState } from 'react';
import { Button, Divider, Flex, Text } from 'common/common-components.styled';
import Step from '../step';
import { Gallery } from 'components/gallery/image-gallery/gallery';
import { defaultCarImageUrl } from '../../../constants';
import {
  StyledApproveComponentContainer,
  StyledCarPolicy,
  StyledConfirmButton,
  StyledDeclineButton,
  StyledFieldSection,
  StyledGalleryContainer,
  StyledStepsContainer
} from '../release-external-page.styled';
import { AnyObject } from 'common/interfaces';
import CustomAccordion from 'components/custom-accordion/custom-accordion';
import useReleaseData from '../hooks/use-release-data';
import useSteps from '../hooks/use-steps';
import useVehicleDetailsFields from '../hooks/use-vehicle-details-fields';
import useCarPhotos from '../hooks/use-car-photos';
import useVehicleExpandSections from '../hooks/use-vehicle-expand-sections';
import EditableFieldMain from 'pages/existing-vehicle-page/editable-field-main';
import ReleaseExternalPlaceholder from '../release-external-placeholder';
import { useFetch } from 'hooks/use-fetch/use-fetch';
import { vehicleRequestConfig } from 'request-config/vehicle/vehicle.request-config';
import { useQuery } from 'hooks';
import ApproveComponentPrice from './approve-component-price';
import useBudgetDetailsFields from '../hooks/use-budget-details-fields';
import useExportDomElementAsPdf from 'hooks/use-export-dom-element-as-pdf';
import { getDateString } from 'utils/convert-UTC-date-to-local-date';
import { ReleaseStatuses } from '../hooks/use-release-data/use-release-data.types';

export enum ApproveStatuses {
  WaitingForApprove = 100000001,
  Approved = 100000002,
  Declined = 100000003
}

const ApproveComponent = () => {
  const query = useQuery();
  const queryId = query.id as string;

  const [status, setStatus] = useState<ApproveStatuses>(ApproveStatuses.WaitingForApprove);

  const { vehicleOfferData, driverData, carPolicies, globalVehicleOfferData, status: recordStatus } = useReleaseData();
  const { steps, activeStep } = useSteps(status, recordStatus);
  const { vehicleMainDetailsFields } = useVehicleDetailsFields();
  const { budgetDetailsFields } = useBudgetDetailsFields();
  const { carPhotos } = useCarPhotos();
  const { vehicleExpandSections, onToggleVehicleExpandSections } = useVehicleExpandSections();
  const { fetch } = useFetch({ loadingKey: 'setExternalConfigurationStatus', isExcludeToken: true });
  const { exportDomElementAsPdf, elementRef } = useExportDomElementAsPdf<HTMLDivElement>(
    `Freigabe_${vehicleOfferData?.uds_name || ''}_${getDateString(new Date())}`
  );

  const setExternalConfigurationStatus = (statusCode: ApproveStatuses) => {
    const scrollContainer = document.querySelector('#unauthorizedPageContainer');
    if (scrollContainer) {
      scrollContainer.scrollTo({ top: 0, behavior: 'smooth' });
    }

    fetch({
      requestConfig: vehicleRequestConfig.setExternalConfigurationStatus({ id: queryId, statusCode }),
      callback: () => {
        setStatus(statusCode);
      }
    });
  };

  return (
    <StyledApproveComponentContainer>
      <div ref={elementRef}>
        <Flex direction='column' align='center' margin='10px 0 100px 0'>
          <StyledStepsContainer justify='space-between'>
            {steps.map((step, index) => (
              <Step key={index} steps={steps} currentStep={step} isActive={activeStep === index} index={index} />
            ))}
          </StyledStepsContainer>
        </Flex>

        {status === ApproveStatuses.WaitingForApprove && (
          <>
            <Flex justify='center'>
              <Flex justify='flex-end' width='100%'>
                <Button onClick={exportDomElementAsPdf}>Als PDF exportieren</Button>
              </Flex>
            </Flex>
            <Flex direction='column' align='center'>
              <Flex width='100%' direction='column'>
                <Text size='34' color='blue' bottom='10'>
                  Anfrage zur Bestellung eines Fahrzeuges
                </Text>
                <Text size='24' color='blue' bottom='30'>
                  Bitte geben Sie folgende Bestellung für {driverData?.driver.attributes.fullname || ''} frei.
                </Text>
                <Text bottom='10' bold size='16' color='blackPearl'>
                  Konfiguration: {globalVehicleOfferData?.uds_configurationtypecode?.label || ''}
                </Text>
                <Flex wrap='wrap' bottom='40'>
                  <Text bottom='10' right='30' size='16' color='blackPearl'>
                    Fahrer: {driverData?.driver.attributes.fullname || ''}
                  </Text>
                  <Text bottom='10' right='30' size='16' color='blackPearl'>
                    Car-Policy: {driverData?.carPolicy.attributes.uds_name || ''}
                  </Text>
                  <Text bottom='10' size='16' color='blackPearl'>
                    Mobilitätsbudget: {driverData?.budget.attributes.uds_name || ''} -{' '}
                    {driverData?.budget.attributes.uds_budget_eur?.label || ''}
                  </Text>
                </Flex>
                <Text bold size='20' color='grey900' bottom='10'>
                  {vehicleOfferData?.uds_title || ''}
                </Text>
                <Text bottom='10' color='grey500'>
                  Anfragenummer: {vehicleOfferData?.uds_name}
                </Text>
                <Flex wrap='wrap' align='center'>
                  <Text right='10' bottom='10' color='blackPearl'>
                    Car-Policy:
                  </Text>
                  {carPolicies?.map(carPolicy => (
                    <StyledCarPolicy key={carPolicy.id}>{carPolicy.uds_name}</StyledCarPolicy>
                  ))}
                </Flex>

                <StyledGalleryContainer margin='40px 0'>
                  <Flex position='relative' width='60%'>
                    <Gallery images={carPhotos} gallery={[]} placeholder={defaultCarImageUrl} />
                  </Flex>
                  <Flex width='40%' direction='column'>
                    {vehicleMainDetailsFields.map((section, i) => (
                      <Flex key={i}>
                        {section.map(field => (
                          <StyledFieldSection key={field.name}>
                            <EditableFieldMain
                              disableHover={field.disabled}
                              edit={() => {}}
                              cancelEdit={() => {}}
                              save={() => {}}
                              data={field}
                            />
                            <div className='mobexo-divider' />
                          </StyledFieldSection>
                        ))}
                      </Flex>
                    ))}
                    {!!budgetDetailsFields.length && (
                      <Flex padding='20px 10px' direction='column'>
                        {budgetDetailsFields.map(field => (
                          <ApproveComponentPrice key={field.label} {...field} />
                        ))}
                      </Flex>
                    )}
                  </Flex>
                </StyledGalleryContainer>

                {recordStatus === ReleaseStatuses.Wait && (
                  <Flex justify='flex-end'>
                    <StyledDeclineButton
                      onClick={() => setExternalConfigurationStatus(ApproveStatuses.Declined)}
                      right='10'
                    >
                      Ablehnen
                    </StyledDeclineButton>
                    <StyledConfirmButton onClick={() => setExternalConfigurationStatus(ApproveStatuses.Approved)}>
                      Freigeben
                    </StyledConfirmButton>
                  </Flex>
                )}

                <Text bold size='20' color='grey900' top='20'>
                  Fahrzeug
                </Text>

                {vehicleExpandSections.map(
                  (section: AnyObject, i: number) =>
                    !section.hidden && (
                      <div key={i}>
                        <CustomAccordion
                          section={section}
                          key={section.name}
                          onToggle={onToggleVehicleExpandSections}
                        />
                        {i < vehicleExpandSections.length - 1 && <Divider />}
                      </div>
                    )
                )}
              </Flex>
            </Flex>
          </>
        )}

        {status === ApproveStatuses.Approved && (
          <ReleaseExternalPlaceholder title='Vielen Dank!' description='Sie haben die Freigabe erfolgreich erteilt.' />
        )}

        {status === ApproveStatuses.Declined && (
          <ReleaseExternalPlaceholder title='Vielen Dank!' description='Sie haben die Freigabe abgelehnt.' />
        )}
      </div>
    </StyledApproveComponentContainer>
  );
};

export default ApproveComponent;
